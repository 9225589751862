import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { satisfies } from 'semver';
import { environment } from '../../environments/environment';
import { DbApp } from './dbapp';
import { Observable } from 'rxjs';

@Injectable()
export class CryptoPaymentService {
    onramp: any = null;
  constructor(
    public dbapp: DbApp,
    private http: HttpClient,
    public translate: TranslateService,
  ) {}

  async sendCryptoPayment(cryptoPaymentHeaderClient: any): Promise<any> {
    const postCryptoPaymentHeader = {
      destination_currency: cryptoPaymentHeaderClient.destination_currency,
      destination_exchange_amount: cryptoPaymentHeaderClient.destination_exchange_amount,
      destination_network: cryptoPaymentHeaderClient.destination_network
    };

    console.log(cryptoPaymentHeaderClient);

    return this.http.post(environment.apiUrl,postCryptoPaymentHeader).toPromise().then(async (data: any) => {
      data = data || {};
      if (data.version && !satisfies(data.version, this.dbapp.requiredApiVersion)) {
        await this.translate.get("INCOMPATIBLE_API", { s: data.version }).toPromise();
      }
      
      if (data.success) {
        console.log('Payment Sent');
      }
      
      return data;
    });
  }

  // New method to send a payment header and return an Observable
  sendCryptoPaymentHeader(postCryptoPaymentHeader: any): Observable<any> {
    const cryptoPaymentHeaderClient = {
      destination_currency: postCryptoPaymentHeader.destination_currency,
      destination_exchange_amount: postCryptoPaymentHeader.destination_exchange_amount,
      destination_network: postCryptoPaymentHeader.destination_network
    };
    console.log("cryptoPaymentHeaderClient",cryptoPaymentHeaderClient);
    
    return new Observable((observer) => {
      this.sendCryptoPayment(cryptoPaymentHeaderClient)
        .then((data) => {
          observer.next(data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }
}