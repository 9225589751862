import { OnDestroy, OnInit, Input } from '@angular/core';
import { NavController, ModalController, AlertController, LoadingController, PopoverController, ActionSheetController, ToastController, IonSearchbar  } from '@ionic/angular';
import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, NgForm } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Observable, Subscription, BehaviorSubject, from, timer } from 'rxjs';
import { IonInfiniteScroll, Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { File, Entry, FileEntry } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FileUploadComponent, FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { UserData, DbRecord, DbFile, LocaleService, Settings, DbApp, DbAppValidators, History } from '../../providers';
import { environment } from '../../../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { neighborhoods } from '../../providers';
import { ratingsAddPage } from '../ratingsadd/ratingsadd';

// Component
@Component({
	selector: 'page-neighborhoods-view',
	templateUrl: 'neighborhoodsview.html',
	styleUrls: ['neighborhoodsview.scss']
})
export class neighborhoodsViewPage implements OnDestroy, OnInit {
	@Input() filter: string;
	key: any;
	keyCount: number = 1;
	item: any;
	item$: BehaviorSubject<DbRecord>;
	pageId: string = "view";
	pageUrl: string = "neighborhoodsview";
	loadingMessage: string;
	dataLoaded: boolean;
	isLoggedIn: boolean;
	isAdmin: boolean;
	userId: string;
	permissions: any;
	userIdAllowed: boolean;
	locations_neighborhood_id: string;
	openedNeighborhoodsViewModal: string;
	fitBounds: boolean = true;
	private navigationSubscription: Subscription;

	// Constructor
	constructor(
		public dbapp: DbApp,
		public translate: TranslateService,
		public locale: LocaleService,
		public navController: NavController,
		public loadingController: LoadingController,
		public actionSheetController: ActionSheetController,
		public modalController: ModalController,
		public alertController: AlertController,
		public toastController: ToastController,
		public router: Router,
		public formBuilder: FormBuilder,
		public user: UserData,
		public inAppBrowser: InAppBrowser,
		private activatedRoute: ActivatedRoute,
		public history: History,
		private camera: Camera,
		private file: File,
		private webview: WebView,
		private platform: Platform,
		private changeDetectorRef: ChangeDetectorRef,
		private filePath: FilePath,
		private fileChooser: FileChooser,
		private sanitizer: DomSanitizer,
		public items: neighborhoods,
		) {
		this.key = this.getPrimaryKey();
		this.item$ = new BehaviorSubject<DbRecord>(null);
		this.userIdAllowed = this.dbapp.userIdAllow("neighborhoods", this.pageId);
		this.getUserData();
	}

	// OnInit
	ngOnInit() {
		this.navigationSubscription = this.router.events.subscribe(async (e: any) => {
		  if (e instanceof NavigationEnd && this.history.isCurrentPage(this.pageUrl)) { // Re-init component
			await this.init();
			this.getParams(); // Call getParams() when the navigation ends and the page is the current page
		  }
		});
		this.key = this.getPrimaryKey();
		if (this.locations_neighborhood_id) {
		  this.init();
		  this.getParams(); // Call getParams() when the component initializes if locations_neighborhood_id is truthy
		}
	  }
	  

	// OnDestroy
	ngOnDestroy() {
		if (this.navigationSubscription)
			this.navigationSubscription.unsubscribe(); // Clean up
	}

	// Init
	async init() {
		this.loadingMessage = await this.translate.get("LOADING").toPromise();
		if (this.dbapp.isObject(this.key)) {
		const loading = await this.loadingController.create({
			spinner: null,
			cssClass: 'global-loader',
			showBackdrop: false,
		});
		this.dataLoaded = true;
		await loading.present();
			try {
				let item = await this.items.query(Object.assign({ action: "view" }, this.key)); // Use "view" action to get the record
				if (this.dbapp.isObject(item)) {
					await this.items.lookup(item, this.pageId);
					this.item = await this.items.renderRow(item, this.pageId);
					this.item$.next(this.item);
				}
			} catch(err) {
				this.showError(err);
			} finally {
				await loading.dismiss();
			}
		}
	}

	/**
	 * Get query parameters
	 */
	private getParams() {
		let params = {};
		if (this.filter)
			params = Object.assign(params, {filter: this.filter });
		return Object.assign(params);
	}

	/**
	 * Get primary key
	 */
	getPrimaryKey(): any {
		let keys = {}, key;
		key = this.locations_neighborhood_id ? this.locations_neighborhood_id : this.activatedRoute.snapshot.paramMap.get("locations_neighborhood_id");
		if (!this.dbapp.isEmpty(key))
			keys["locations_neighborhood_id"] = key;
			console.log("this.locations_neighborhood_id", this.locations_neighborhood_id)
		return (Object.keys(keys).length === this.keyCount) ? keys : false;
	}

	// Get user data
	getUserData() {
		this.user.isLoggedIn.subscribe(res => {
			this.isLoggedIn = res;
			this.isAdmin = this.user.isAdmin;
			this.userId = this.user.userId;
			this.permissions = this.user.permissions;
		});
	}

	/**
	 * Show message
	 */
	async showMessage(msg: string, header: string) {
		let values = await this.translate.get(["OK_BUTTON", header]).toPromise();
		const alert = await this.alertController.create({
			header: values[header],
			message: msg,
			buttons: [values.OK_BUTTON]
		});
		await alert.present();
	}

	/**
	 * Show error
	 */
	async showError(err: any) {
		let msg = (err instanceof Error) ? err.message : err;
		return this.showMessage(msg, "ERROR");
	}

	/**
	 * Show success message
	 */
	async showSuccess(msg: string) {
		return this.showMessage(msg, "SUCCESS");
	}

	// Open URL
	openUrl(url: string, target?: string) {
		this.inAppBrowser.create(url, target);
	}

	async openModalAddRatings(neighborhoodId: string) {
		const modalAddRating = await this.modalController.create({
		  component: ratingsAddPage,
		  componentProps  : {openedAddRateModal: 'openedAddRateModal',ratingStatusId: 3, locations_neighborhood_id: neighborhoodId},
		  initialBreakpoint: 0.8,
		  cssClass: 'custom-modal',
		  backdropDismiss: true,
		});
		modalAddRating.onDidDismiss().then(item => {
		  modalAddRating.dismiss();
		});
		return await modalAddRating.present();

	  }

	
	mapNeighborhoodsReady(map: google.maps.Map, polygonData: any): void {
		if(polygonData){
		// const locations_city_name = this.properties_city_name;
		const parsedPolygonData = JSON.parse(polygonData);
		const polygonCoordinates = parsedPolygonData[0].geometry[0].map(([lat, lng]) => ({ lat, lng }));
		const polygon = new google.maps.Polygon({
		  paths: polygonCoordinates,
		  strokeColor: '#000000',
		  strokeOpacity: 0.8,
		  strokeWeight: 1,
		  fillColor: '#FF0000',
		  fillOpacity: 0.35
		});
		this.fitBounds= false;
		polygon.setMap(map);
					
		// Calculate the centroid of the polygon
		const polygonBounds = new google.maps.LatLngBounds();
		for (let i = 0; i < polygonCoordinates.length; i++) {
			polygonBounds.extend(polygonCoordinates[i]);
		}
		const polygonCenter = polygonBounds.getCenter();
	
		// Create a marker with a label at the centroid
		// const marker = new google.maps.Marker({
		// 	position: polygonCenter,
		// 	label: locations_neighborhood_name,
		// 	map: map
		// });
	
		let bounds = new google.maps.LatLngBounds();
		for (let i = 0; i < polygonCoordinates.length; i++) {
			bounds.extend(polygonCoordinates[i]);
		}
	
		const ne = bounds.getNorthEast();
		const sw = bounds.getSouthWest();
	
		console.log('Polygon bounds - NE:', ne.toString());
		console.log('Polygon bounds - SW:', sw.toString());
		map.fitBounds(bounds);
		console.log(parsedPolygonData)
		}
	}
	
	cancelNeighborhoodsViewModal() {
		this.modalController.dismiss();
	}
}