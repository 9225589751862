import { NgModule, LOCALE_ID } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { UserData, Settings, LocaleService, DbApp, History } from './';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  constructor(
    public translate: TranslateService,
  ) {}

  transform(value: any): string {
    if (value == null) {
      return '';
    }

    const startDate = new Date(value);
    const endDate = new Date();
    const duration = endDate.getTime() - startDate.getTime();

    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;
    const month = 30 * day;
    const year = 365 * day;

    if (duration < minute) {
      return this.translate.instant('just_now');
    } else if (duration < hour) {
      const minutes = Math.floor(duration / minute);
      return `${minutes} ${this.translate.instant('minute', { count: minutes })}`;
    } else if (duration < day) {
      const hours = Math.floor(duration / hour);
      return `${hours} ${this.translate.instant('hour', { count: hours })}`;
    } else if (duration < month) {
      const days = Math.floor(duration / day);
      return `${days} ${this.translate.instant('day', { count: days })}`;
    } else if (duration < year) {
      const months = Math.floor(duration / month);
      return `${months} ${this.translate.instant('month', { count: months })}`;
    } else {
      const years = Math.floor(duration / year);
      return `${years} ${this.translate.instant('year', { count: years })}`;
    }
  }
}
