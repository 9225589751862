import { NgModule, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserData, Settings, LocaleService, DbApp, History } from './';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyType'
})
export class PropertyTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(properties_property_type_id: string): string {
    const propertyTypeId = parseInt(properties_property_type_id, 10);

    switch (propertyTypeId) {
      case 1:
        return this.translate.instant("lands");
      case 2:
        return this.translate.instant("apartments");
      case 3:
        return this.translate.instant("building");
      case 4:
        return this.translate.instant("residential_compounds");
      case 5:
        return this.translate.instant("tower");
      case 6:
        return this.translate.instant("villa");
      case 7:
        return this.translate.instant("palace");
      case 8:
        return this.translate.instant("traditional_house");
      case 9:
        return this.translate.instant("farm");
      case 10:
        return this.translate.instant("commercial_center");
      case 11:
        return this.translate.instant("market");
      case 12:
        return this.translate.instant("commercial_shop");
      case 13:
        return this.translate.instant("office");
      case 14:
        return this.translate.instant("restshop");
      case 15:
        return this.translate.instant("resort");
      case 16:
        return this.translate.instant("auditorius");
      case 17:
        return this.translate.instant("hotel");
      case 18:
        return this.translate.instant("hospital");
      case 19:
        return this.translate.instant("schools");
      case 20:
        return this.translate.instant("sports_clubs");
      case 21:
        return this.translate.instant("gas_station");
      case 22:
        return this.translate.instant("warehouse");
      case 23:
        return this.translate.instant("industry");
      case 24:
        return this.translate.instant("other");
      default:
        return this.translate.instant("other");
    }
  }
}

