import { Input, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { NavController, ModalController, AlertController, LoadingController, PopoverController, ActionSheetController, ToastController, IonSearchbar, IonModal  } from '@ionic/angular';
import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
  NgForm,
} from "@angular/forms";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Observable, Subscription, BehaviorSubject, from, timer } from 'rxjs';
import { IonInfiniteScroll, Platform } from '@ionic/angular';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { File, Entry, FileEntry } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FileUploadComponent, FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { UserData, DbRecord, DbFile, LocaleService, Settings, DbApp, DbAppValidators, History } from '../../providers';
import { environment } from '../../../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { UserOptions } from '../../interfaces/user-options';
import { SignupPage } from '../signup/signup';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { Storage } from '@ionic/storage-angular';
import { profiles_public, users, EmailService, SMSService, NafazService } from "../../providers";
import { CallNumber } from '@ionic-native/call-number/ngx';
import { countriesListPage } from '../countrieslist/countrieslist';
import { v4 as uuidv4 } from 'uuid';


// Constants
const GOOGLE_LOGIN_KEY = 'googleLoggedIn';
const FACEBOOK_LOGIN_KEY = 'facebookLoggedIn';
// Component
@Component({
	selector: 'page-login',
	templateUrl: 'login.html',
	styleUrls: ['./login.scss'],
	providers: [CallNumber],
})
export class LoginPage implements OnDestroy, OnInit {
	login: UserOptions = { username: '', password: ''};
	system_identity_no: any;
	submitted: boolean;
	@Input() openedLoginModal: string;
	key: any;
	keyCount: number = 1;
	item: any;
	uuidv4: string = uuidv4();
	loadingMessage: string;
	dataLoaded: boolean;
	formGroup: FormGroup;
	formGroupPwd: FormGroup;
	mobileVerfyForm: FormGroup;
	emailVerfyForm: FormGroup;
	idVerfyForm: FormGroup;
	otpForm: FormGroup;
	nafazForm: FormGroup;
	nafazCodeForm: FormGroup;
	item$: BehaviorSubject<DbRecord>;
	currentItems: any;
	currentUserItems: any;
	system_user_id: string;
	system_full_name: string;
	get_system_user_id: string;
	system_user_wallet_in: any;
	locations_country_id: string;
	locations_country_name: string;
	locations_country_phone_code: number = 966;
	locations_country_flag: string = 'sa.png';
	smsQueueRecipient: string;
	smsQueueContent: string;
	fromEmail: string;
	toEmail: string;
	ccEmail: string;
	bccEmail: string;
	subject: string;
	emailContent: string;
	format: string;
	charset: string;
	smtpSecure: string;
	arAttachments: any[];
	arImages: any[];
	arProperties: any;
	brandName: string;
	userTitleFullName: string;
	transactionDetailsTitle: string;
	transactionDetailsLink: string;
	instructionsTitle: string;
	instructionsSubTitle: string;
	instructionsContent: string;
	termsConditionsContent: string;
	extra_brand_name: string;
	extra_transaction_details_title: string;
	extra_transaction_details_link: string;
	extra_instructions_title: string;
	extra_instructions_subtitle: string;
	extra_instructions_content: string;
	extra_terms_conditions_content: string;
	extra_locale: string;
	
	otpVerificationFinished: boolean = false;
	otpSentMobile: boolean;
	otpSentEmail: boolean;
	otpSMSDelivered: boolean;
	otpEmailDelivered: boolean;
	otpInput1: string;
	otpInput2: string;
	otpInput3: string;
	otpInput4: string;
	otp: string;
	otpMatched: boolean = false;
	resendingOTP: boolean = false;
	countdown = 60; // Reset countdown to initial value
	mobileNumberRequiredError: boolean = false;
	mobileNumberValidateError: boolean = false;
	mobileNumberAvailableError: boolean = false;
	userEmailRequiredError: boolean = false;
	userEmailValidateError: boolean = false;
	userEmailAvailableError: boolean = false;
	idNumberAvailableError: boolean = false;
  
	@ViewChild("otp1") otp1: ElementRef<HTMLInputElement>;
	@ViewChild("otp2") otp2: ElementRef<HTMLInputElement>;
	@ViewChild("otp3") otp3: ElementRef<HTMLInputElement>;
	@ViewChild("otp4") otp4: ElementRef<HTMLInputElement>;

	private loginErrorString: string;
	private saving: boolean;
	sendNafazRequestApplied: boolean = false;
	nafazNationalId: boolean = true;
	nafazRandomCode: any;
	nafazRandomCodeModal: boolean = false;
	nafazRandomCodeError: string;

	// Constructor
	constructor(public dbapp: DbApp,
		public user: UserData,
		public locale: LocaleService,
		public formBuilder: FormBuilder,
		public router: Router,
		public toastController: ToastController,
		public alertController: AlertController,
		public loadingController: LoadingController,
		public translate: TranslateService,
		public history: History,
		public modalController: ModalController,
		private googlePlus: GooglePlus,
		private facebook: Facebook,
		private storage: Storage,
		private platform: Platform,
		private inAppBrowser: InAppBrowser,
		public profilesItems: profiles_public,
		private userItems: users,
		private emailService: EmailService,
		private smsService: SMSService,
		private nafazService: NafazService,
		private cdr: ChangeDetectorRef,
		private callNumber: CallNumber,
		) {
		this.translate.get('LOGIN_ERROR').subscribe(value => {
			this.loginErrorString = value;
		});
		this.storage.create();
		this.item$ = new BehaviorSubject<DbRecord>(null);
		this.init();
	}

	// OnInit
	async ngOnInit() {
			this.otpSentMobile = localStorage.getItem('otpSentMobile') === 'true';
			this.otpSentEmail = localStorage.getItem('otpSentEmail') === 'true';
			this.otpSMSDelivered = localStorage.getItem('otpSMSDelivered') === 'true';
			this.otpEmailDelivered = localStorage.getItem('otpEmailDelivered') === 'true';
		  
			if (this.otpSMSDelivered || this.otpEmailDelivered) {
			  this.countdown = Number(localStorage.getItem('countdown')) || 60;
			  this.startCountdown();
			}
			// Initialize the otpForm FormGroup
			this.otpForm = new FormGroup({
			  otpInput1: new FormControl(this.otpInput1, Validators.required),
			  otpInput2: new FormControl(this.otpInput2, Validators.required),
			  otpInput3: new FormControl(this.otpInput3, Validators.required),
			  otpInput4: new FormControl(this.otpInput4, Validators.required),
			});
			
			this.createNafazForm();
			this.createNafazCodeForm();
	  }
	  
	// OnDestroy
	ngOnDestroy() {
		localStorage.clear();
		this.otpForm.reset();
	}

	  // Init
	  async init() {
		this.formGroup = this.formBuilder.group(
		  {
			system_user_id: [
			  { value: "", disabled: false },
			  [DbAppValidators.guid],
			],
			system_mobile_1: [{ value: "", disabled: false }, []],
			system_email: [{ value: "", disabled: false }, []],
		  }
		);

		this.formGroupPwd = this.formBuilder.group(
		  {
			system_user_id: [
			  { value: "", disabled: false },
			  [DbAppValidators.guid],
			],
			system_password: [
			  { value: "", disabled: false },
			  [
				Validators.required,
				Validators.minLength(8),
				Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/)
			  ],
			],
			// c_system_password: [{ value: "", disabled: false },[Validators.required],
			// ],
		  },
		//   {
		// 	validators: [
		// 	  DbAppValidators.mustMatch("system_password", "c_system_password"),
		// 	],
		//   }
		);
		this.idVerfyForm = this.formBuilder.group({
		  system_identity_no: ['', {
		  validators: [Validators.required, Validators.pattern('^[123456]{1}\\d{9}$')],
		  updateOn: 'blur' // Run validation when the input loses focus
		  }],
		});
		
		this.mobileVerfyForm = this.formBuilder.group({
		  system_mobile_1_verfy: ['', {
		  validators: [Validators.required],
		  updateOn: 'blur' // Run validation when the input loses focus
		  }],
		});
		
		this.emailVerfyForm = this.formBuilder.group({
		  system_email_verfy: ['', {
		  validators: [Validators.required],
		  updateOn: 'blur' // Run validation when the input loses focus
		  }],
		});
	  }

	/**
	 * Show message
	 */
	async showMessage(msg: string, header: string) {
		let values = await this.translate.get(["OK_BUTTON", header]).toPromise();
		const alert = await this.alertController.create({
			header: values[header],
			message: msg,
			buttons: [values.OK_BUTTON]
		});
		await alert.present();
	}

	/**
	 * Show error
	 */
	async showError(err: any) {
		let msg = (err instanceof Error) ? err.message : err;
		return this.showMessage(msg, "ERROR");
	}

	/**
	 * Show success message
	 */
	async showSuccess(msg: string) {
		return this.showMessage(msg, "SUCCESS");
	}

	// Unable to log in
	async doToast(msg?: string) {
		const toast = await this.toastController.create({
			message: msg || this.loginErrorString,
			duration: 3000,
			position: 'top'
		});
		await toast.present();
	}

	// Attempt to login in through the User service
	  /**
   * Get query parameters
   */

	isCheckModalOpen = false;

	async setCheckOpen(isCheckOpen: boolean) {
	this.isCheckModalOpen = isCheckOpen;
	}

	async loginCheck(isCheckOpen: boolean): Promise<boolean> {
	this.setCheckOpen(isCheckOpen);
	return true;
	}

	async onLogin(form: NgForm) {
		this.submitted = true;
		const loading = await this.loadingController.create({
			spinner: null,
			cssClass: 'global-loader',
			showBackdrop: true,
		});
		this.dataLoaded = false;
		await loading.present();
		if (form.valid) {
			try {
				// await this.loginCheck(true);
				const itemsExist = await this.checkUserItems();
				console.log('itemsExist', itemsExist)
				if (itemsExist === true) {
				let resp = await this.user.login(this.login);
				if (resp && resp.success){
					try {
						await this.modalController.dismiss();
						await this.loginCheck(false);
						this.nafazForm.reset();
						this.isNafazModalOpen = false;
						await this.router.navigateByUrl("");
						} catch(e) {
							try {
								let item = await this.profilesItems.query(Object.assign({ action: "view" }, { 'system_user_id': this.system_user_id }));
								if (this.dbapp.isObject(item)) {
									await this.profilesItems.lookup(item, 'view');
									this.item = await this.profilesItems.renderRow(item, 'view');
									this.item$.next(this.item);
									this.system_identity_no = item.system_identity_no;
									this.system_user_wallet_in = item.system_user_wallet_in;
									localStorage.setItem('system_user_wallet_in', this.system_user_wallet_in);
									console.log("system_identity_no:", this.system_identity_no);
									console.log("system_user_wallet_in:", this.system_user_wallet_in);
									await this.router.navigateByUrl("");
								}
								} catch(err) {
									this.showError(err);
								} finally {
									this.sendNafazRequestApplied = false;
									console.log("Username:", this.login.username);
									console.log("system_user_wallet_in:", this.system_user_wallet_in);
							}
						}

						// save user name password
						
					} else {// Unable to log in
						
					await this.loginCheck(false);
					await this.showError(this.loginErrorString);
					}
				} else {
					await this.showError(this.loginErrorString);
				}
			} catch(err) {
				await this.loginCheck(false);
				await this.showError(this.loginErrorString);
			} finally {
				loading.dismiss();
				
			}
		}
	}
	
	private getUserParams() {
		if(this.login.username && !this.nafazForm.get('system_identity_no').value){
		let params = {
			system_user_name_check: true,
			system_user_name: this.login.username,
			system_mobile_1: isNaN(Number(this.login.username)) ? null : Number(this.login.username),
			system_email: this.login.username,
			system_identity_no: isNaN(Number(this.login.username)) ? null : Number(this.login.username),
		};
		return Object.assign(params);
	} else if(this.nafazForm.get('system_identity_no').value) {
		let params = {
			system_identity_no: Number(this.nafazForm.get('system_identity_no').value)
		};
		return Object.assign(params);
	}
	  }

	async checkUserItems(): Promise<boolean> {
		try {
		  let items = await this.profilesItems.query(this.getUserParams());
		  if (items) {
			console.log("Items:", items);
			await this.profilesItems.lookup(items, 'list');
			this.currentUserItems = await Promise.all(items.map(async (item) => await this.profilesItems.renderRow(item, 'list')));
			
			this.currentUserItems.forEach(item => {
				this.login.username = item.system_user_name.value;
				this.system_user_id = item.system_user_id.value; if(this.nafazForm.get('system_identity_no').value) {
					if(this.sendNafazRequestApplied) {
						this.login.password = item.system_password.value;
					}
			  }
			});
		  return this.currentUserItems.length === 1;
		}
		} catch (err) {
		  console.error(err);
		  return false;
		} finally {
		}
	  }

	  createNafazForm() {
		this.nafazForm = this.formBuilder.group({
		  system_identity_no: ['', {
			validators: [Validators.required, Validators.pattern('^[123456]{1}\\d{9}$')],
			updateOn: 'blur' // Run validation when the input loses focus
		  }],
		});
	  }

	  createNafazCodeForm() {
		this.nafazCodeForm = this.formBuilder.group({
		  nafazRandomCode: ['', {
			validators: [Validators.required],
			updateOn: 'blur' // Run validation when the input loses focus
		  }],
		});
	  }

	  isNafazModalOpen = false;
	  isResetPasswordNafaz = false;

	  setNafazOpen(isNafazOpen: boolean) {
		this.nafazNationalId = true;
		this.nafazRandomCodeModal = false;
		this.system_identity_no = null;
		this.nafazForm.reset();
		this.nafazCodeForm.reset();
		this.isNafazModalOpen = isNafazOpen;
		
		console.log('Random UUID: ',uuidv4())
	  }
	  resetPasswordNafaz(isRPN: boolean) {
		this.isResetPasswordNafaz = isRPN;
	  }
	  generateNafazRandom(): string {
		const length = 2;
		const characters = '0123456789';
		let nafazRandom = '';
	
		for (let i = 0; i < length; i++) {
		  const nafazRandomIndex = Math.floor(Math.random() * characters.length);
		  nafazRandom += characters.charAt(nafazRandomIndex);
		}
	
		return nafazRandom;
	  }

	  nafazCode = this.generateNafazRandom();
	  nafazCodeMatched: boolean = true;
	  nafazCodeVerificationFinished: boolean;

	  verifyNafazCode(loginForm: any): void {
		const enteredNafazCode = this.nafazCodeForm.get('nafazRandomCode').value;
		if (enteredNafazCode.length === 2 && /^\d+$/.test(enteredNafazCode) && enteredNafazCode === this.nafazCode) {
		  this.nafazCodeMatched = true;
		  this.nafazCodeVerificationFinished = true;
		  this.otpVerificationFinished = true;
		  this.onLogin(loginForm);
		} else {
		  this.nafazCodeMatched = false;
		}
	  }

	  async verifyNafazCodeResetPwd(): Promise<void> {
		try {
			const enteredNafazCode = this.nafazCodeForm.get('nafazRandomCode').value;
			
			if (/^\d{2}$/.test(enteredNafazCode) && enteredNafazCode === this.nafazCode) {
			  this.nafazCodeMatched = true;
			  this.resetType = '';
			  this.otpVerificationFinished = true;
			} else {
			  this.nafazCodeMatched = false;
			  this.resetType = '';
			}
		} catch (error) {
		  console.error(error);
		  // Handle the error, show an error message, or perform other actions
		}
	  }
	  
	
	nafazQueueNationalId: string;
	nafazQueueService: string;
	nafazQueueLocale: string;
	nafazQueueRequestId: string;
	async sendNafazRequest(loginForm: any) {
		const itemsExist = await this.checkUserItems();
		console.log('itemsExist', itemsExist)
			if (itemsExist === true) {
				this.nafazQueueNationalId = this.nafazForm.get('system_identity_no').value;
				this.nafazQueueService = 'Login';
				this.nafazQueueLocale = this.locale.locale;
				this.nafazQueueRequestId = this.uuidv4;
				this.sendNafazRequestApplied = true;
				const nafazHeaderClient = {
					nafazQueueNationalId: this.nafazQueueNationalId,
					nafazQueueService: this.nafazQueueService,
					nafazQueueLocale: this.nafazQueueLocale,
					nafazQueueRequestId: this.nafazQueueRequestId,
				};
				try {
					const response = await this.nafazService.sendNafazHeader(
						this.nafazQueueNationalId,
						this.nafazQueueService,
						this.nafazQueueLocale,
						this.nafazQueueRequestId
					);
					console.log("API Response:", response);
					if (response) {
						if(response['status'] === 'WAITING'){
							console.log('Request Status Response:', response);
							this.saving = false;
							// this.isNafazModalOpen = false;
							this.nafazNationalId = false;
							this.nafazRandomCodeModal = true;
							this.nafazRandomCode = response['random'];
							// this.redirectToNafazApp();
						} else if(response['status'] === 'EXPIRED'){
							console.log('Request Status Response:', response);
							this.saving = false;
							// this.isNafazModalOpen = false;
							this.nafazNationalId = false;
							this.nafazRandomCodeModal = true;
							this.nafazRandomCode = false;
							this.nafazRandomCodeError = await this.translate.get("EXPIRED").toPromise();

						} else if(response['status'] === 'REJECTED'){
							console.log('Request Status Response:', response);
							this.saving = false;
							// this.isNafazModalOpen = false;
							this.nafazNationalId = false;
							this.nafazRandomCodeModal = true;
							this.nafazRandomCode = false;
							this.nafazRandomCodeError = await this.translate.get("REJECTED").toPromise();
						} else if(response['status'] === 'COMPLETED'){
							console.log('Request Status Response:', response);
							this.saving = false;
							this.isNafazModalOpen = false;
							this.nafazNationalId = false;
							this.nafazRandomCodeModal = false;
							if(this.isResetPasswordNafaz){
								this.otpVerificationFinished = true;
								this.isResetPasswordNafaz = false;
							} else {
								this.onLogin(loginForm);
							}
						}
					} else {
						console.error("Request To Nafaz Failed:", response.error);
						this.saving = false;
						this.nafazNationalId = true;
						// this.nafazRandomCodeModal = false;
					}
				} catch (error) {
					console.error("Failed to send ID", error);
					this.saving = false;
				}
			} else {
				this.idNumberAvailableError = true;
			}
	}

  redirectToNafazApp() {
    if (this.platform.is('desktop')) {
	  this.openNafazWebView();
      window.location.href = 'https://www.iam.gov.sa/authservice/userauthservice?lang='+this.locale.locale;
    } else if (this.platform.is('ios')) {
      window.location.href = 'nafath://home';
    } else if (this.platform.is('android')) {
      window.location.href = 'nic://nafath';
    }
  }

  openNafazWebView() {
	const url = 'https://www.iam.gov.sa/authservice/userauthservice?lang='+this.locale.locale; // Replace with your desired URL
	const target = '_blank'; // Open in a new browser window
  
	const options: InAppBrowserOptions = {
	  zoom: 'no', // Disable zoom
	  location: 'no', // Hide location bar
	  clearcache: 'yes', // Clear WebView cache
	  clearsessioncache: 'yes' // Clear session cache
	};
  
	const browser = this.inAppBrowser.create(url, target, options);
	// You can listen to events on the in-app browser if needed
	browser.on('loadstart').subscribe(event => {
	  console.log('Load Start', event);
	});
}
	//   createMfaRequest() {
	// 	const system_identity_no = this.nafazForm.get('system_identity_no').value;
	// 	this.saving = true;
	// 	this.nafazService.createRequest(system_identity_no, 'nafath', 'ar', this.uuidv4).subscribe(
	// 	  response => {
	// 		console.log('Create Request Response:', response);
	// 		this.saving = false;
	// 	  },
	// 	  error => {
	// 		console.error('Create Request Error:', error);
	// 		this.saving = false;
	// 	  }
	// 	);
	//   }
	
	//   async getMfaRequestStatus() {
	// 	const itemsExist = await this.checkUserItems();
	// 	console.log('itemsExist', itemsExist)
	// 		if (itemsExist === true) {
	// 			this.idNumberAvailableError = false;
	// 		const random = this.nafazCode;
	// 		const system_identity_no = this.nafazForm.get('system_identity_no').value;
	// 		this.saving = true;
	// 		this.nafazService.getMfaRequestStatus(system_identity_no, this.uuidv4, random).subscribe(
	// 		response => {
	// 			console.log('Request Status Response:', response);
	// 			console.log('Generated Random:', random);
	// 			console.log('system_identity_no', system_identity_no);
	// 			console.log('this.uuidv4', this.uuidv4);
	// 			this.saving = false;
	// 			this.isNafazModalOpen = false;
	// 			this.nafazNationalId = false;
	// 			this.nafazRandomCodeModal = true;
	// 		},
	// 		error => {
	// 			console.error('Request Status Error:', error);
	// 			console.log('Generated Random:', random);
	// 			console.log('system_identity_no', system_identity_no);
	// 			console.log('this.uuidv4', this.uuidv4);
	// 			this.saving = false;
	// 			this.nafazNationalId = false;
	// 			this.nafazRandomCodeModal = true;
	// 		}
	// 		);
	// 	} else {
	// 		this.idNumberAvailableError = true;
	// 	  throw new Error(this.loginErrorString);
	// 	}
	//   }
	  

	// Sign up
	onSignup() {
		this.router.navigateByUrl("/signup");
	}

	async goSignupModal() {
		const modalSignup = await this.modalController.create({
			component: SignupPage,
			componentProps: { openedSignupModal: 'openedSignupModal'},
			cssClass: 'centered-modal'
		  });
		  return await modalSignup.present();
	}


// // Google login
// async googleLogin() {
//   try {
//     const webClientId = '85947035689-hcqd9ss6cf21p5t11gucggg6sctk4fvm.apps.googleusercontent.com';
//     const { userId, userEmail } = await this.googlePlus.login({
//       webClientId,
//       offline: true,
//     });

//     await this.storage.set(GOOGLE_LOGIN_KEY, true);

//     // Redirect to the next page or perform any other actions
//     this.router.navigateByUrl('');
//   } catch (error) {
//     console.error('Error logging in with Google', error);
//   }
// }

// Google login
async googleLogin() {
	try {
	  console.log('Google login function called'); // Log function call
	  
	  const webClientId = '85947035689-hcqd9ss6cf21p5t11gucggg6sctk4fvm.apps.googleusercontent.com';
	  console.log('webClientId:', webClientId); // Log webClientId value
	  
	  const { userId, userEmail, idToken } = await this.googlePlus.login({
		webClientId,
		offline: true,
	  });
	  
	  console.log('userId:', userId); // Log userId value
	  console.log('userEmail:', userEmail); // Log userEmail value
	  console.log('idToken:', idToken); // Log idToken value
	  
	  if (userId && userEmail && idToken) {
		await this.storage.set(GOOGLE_LOGIN_KEY, true);
	
		// Redirect to the next page or perform any other actions
		this.router.navigateByUrl('/properties_listslist');
	  } else {
		throw new Error('Incomplete Google login data');
	  }
	} catch (error) {
		console.error('Error logging in with Google', error);
		console.error(error); // Log the complete error object for more details
	  }
	  
  }
  
// Facebook login
async facebookLogin() {
  try {
    const isLoggedIn = await this.storage.get(FACEBOOK_LOGIN_KEY);
    if (isLoggedIn) return;

    const { authResponse } = await this.facebook.login(['email']);
    
    if (authResponse) {
      await this.storage.set(FACEBOOK_LOGIN_KEY, true);
      
      const { id, email } = await this.facebook.api('/me', ['email']);
      
      // Perform further actions with user data
      // Redirect to the desired page or show a welcome message
    } else {
      // User cancelled the Facebook login or an error occurred
      // Handle the cancellation or error
    }
  } catch (error) {
    // Error occurred while performing the Facebook login
    // Handle the error
  }
}

  // Unformat field values before submit
  async unformatValue(value: any) {
	let gpSep = this.locale.groupSeparator,
		decSep = this.locale.decimalSeparator;
	if (typeof value === 'object' && value !== null) {
	  if (value.hasOwnProperty('system_password')) {
		value['system_password'] = this.dbapp.parseNumber(value['system_password'], gpSep, decSep);
	  }
	}
	return value;
  }

  isModalOpen = false;
  resetType: string;

  async setOpen(isOpen: boolean) {
	this.isModalOpen = isOpen;
  }
  async cancel() {
	  await this.modalController.dismiss();
	}
  async resetPasswordChoise() {
    let values = await this.translate
      .get(['resetpassword', 'mobile', 'email', 'nafaz'])
      .toPromise();
    const alert = await this.alertController.create({
      header: values['resetpassword'],
      buttons: [
			{
			text: values['nafaz'],
			handler: () => {
				this.resetType = 'nafaz',
				this.isModalOpen = true
			},
			},
		  {
			text: values['email'],
			handler: () => {
				this.resetType = 'email',
				this.isModalOpen = true
			},
        },
        {
          text: values['mobile'],
          handler: () => {
				this.resetType = 'mobile',
				this.isModalOpen = true
          },
		},
      ],
    });
    await alert.present();
  }
    /** Start OTP System */
  /**
   * Get query parameters
   */

  private getParams() {
    const mobileValue = String(this.mobileVerfyForm.get("system_mobile_1_verfy").value);
    const mobileWithoutLeadingZero = mobileValue.replace(/^0+/, "");
    const mobileWithCountryCode = this.locations_country_phone_code + mobileWithoutLeadingZero.padStart(9, "0");

    const emailValue = String(this.emailVerfyForm.get("system_email_verfy").value);
    const idValue = this.nafazForm.get("system_identity_no_verfy").value;
    const userNameValue = String(this.formGroup.get("system_user_name").value);

    const params: any = {};

    if (mobileValue) {
      params.system_mobile_1 = parseInt(mobileWithCountryCode);
    }
    if (emailValue) {
      params.system_email = emailValue;
    }
    if (emailValue) {
      params.system_email = emailValue;
    }
    if (idValue) {
      params.system_identity_no = idValue;
    }
    if (userNameValue) {
      params.system_user_name = userNameValue;
    }

    return params;
  }

  /**
   * Search for the proper items
   */
  
  async checkItems(): Promise<boolean> {
    try {
      const items = await this.profilesItems.query(this.getParams());
      if (items) {
        await this.profilesItems.lookup(items, "list");
        this.currentItems = await Promise.all(items.map(async (item) => await this.profilesItems.renderRow(item, "list")));
		this.system_user_id = this.currentItems.map(item => item.system_user_id.value)[0];
		this.system_user_id = this.system_user_id[0];
		this.system_full_name = this.system_full_name[0];

      }
      return this.currentItems.length === 0; // Return true if length is greater than 0, false otherwise
    } catch (err) {
    //   await this.showMobileExistsError(err);
      return false; // Return false in case of an error
    } finally {
      // Any final cleanup or actions can be performed here
    }
  }

  sendOTP(sendType: string): void {
	if (sendType === "mobile") {
	  this.mobileNumberRequiredError = false;
	  this.mobileNumberValidateError = false;
	  this.mobileNumberAvailableError = false;
  
	  const mobileNumber = String(this.mobileVerfyForm.get("system_mobile_1_verfy").value);
	  
	  if (!mobileNumber) {
		this.mobileNumberRequiredError = true;
		return;
	  }
  
	  if (mobileNumber.length !== 9 || !/^\d+$/.test(mobileNumber)) {
		this.mobileNumberValidateError = true;
		return;
	  }
  
	  this.checkItems()
		.then((hasItems) => {
		  if (hasItems) {
			this.mobileNumberAvailableError = true;
			return;
		  }
  
		  const paddedMobileNumber = mobileNumber.padStart(12, this.locations_country_phone_code.toString());
		  this.saving = true;
		  this.otp = this.generateOTP();
		  this.otpSentMobile = true;
  
		  this.simulateSMSDelivery().then((SMSdelivered) => {
			if (SMSdelivered) {
				this.otpSMSDelivered = true;
			  this.sendOTPSms(paddedMobileNumber, this.otp);
			  this.saving = false;
			  this.countdown = 60; // Reset countdown to initial value
			  this.startCountdown();
			  // this.openAlertForAutoFill();
			  console.log('otp', this.otp)
			} else {
			  this.resendOTP();
			}
		  });
		})
		.catch((err) => {
		  console.error(err);
		});
	} else if (sendType === "email") {
	  this.userEmailRequiredError = false;
	  this.userEmailValidateError = false;
	  this.userEmailAvailableError = false;
  
	  const userEmail = String(this.emailVerfyForm.get("system_email_verfy").value);
	  
	  if (!userEmail) {
		this.userEmailRequiredError = true;
		return;
	  }
  
	  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmail)) {
		this.userEmailValidateError = true;
		return;
	  }
  
	  this.checkItems()
		.then((hasItems) => {
		  if (hasItems) {
			this.userEmailAvailableError = true;
			return;
		  }
  
		  this.saving = true;
		  this.otp = this.generateOTP();
		  this.otpSentEmail = true;
  
		  this.simulateSMSDelivery().then((Emaildelivered) => {
			if (Emaildelivered) {
				this.otpEmailDelivered = true;
			  this.sendOTPEmail(userEmail, this.otp);
			  this.saving = false;
			  this.countdown = 60; // Reset countdown to initial value
			  this.startCountdown();
			  // this.openAlertForAutoFill();
			} else {
			  this.resendOTP();
			}
		  });
		})
		.catch((err) => {
		  console.error(err);
		});
	} else if (sendType === "nafaz") {
		this.idNumberAvailableError = false;
  
	  const idNumber = Number(this.nafazForm.get('system_identity_no').value);
	  
	//   if (!idNumber) {
	// 	this.createNafazForm();
	// 	return;
	//   }
  
	//   if (idNumber.length !== 9 || !/^\d+$/.test(idNumber)) {
	// 	// this.idNumberValidateError = true;
	// 	return;
	//   }
  if(this.nafazForm.valid){
	  this.checkItems()
		.then((hasItems) => {
		  if (hasItems) {
			this.idNumberAvailableError = true;
			return;
		  }
  
		  this.saving = true;
		//   this.sendNafazRequest();
		//   this.otpSentID = true;
  
			// if (IDdelivered) {
			// 	this.otpSMSDelivered = true;
			//   	this.saving = false;
			// } else {
			// //   this.resendNafazCode();
			// }
		})
		.catch((err) => {
		  console.error(err);
		});
	}
	}
  }
  

  generateOTP(): string {
    const length = 4; // Length of the OTP
    const characters = "0123456789"; // Characters to use for generating the OTP
    let otp = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      otp += characters.charAt(randomIndex);
    }

    return otp;
  }

  otpController(event: KeyboardEvent, next: any, prev: any) {
    const target = event.target as HTMLInputElement;

    if (target.value.length < 1 && prev) {
      prev.setFocus();
    } else if (next && target.value.length > 0) {
      next.setFocus();
    } else {
      this.updateOTPInputValues();

      // Check if all inputs are filled
      if (this.isOTPComplete()) {
        this.verifyOTP();
      }
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const pastedText = event.clipboardData?.getData("text/plain");
    if (pastedText) {
      const digits = pastedText.trim().split("");

      if (digits.length === 4) {
        this.otpForm.setValue({
          otpInput1: digits[0],
          otpInput2: digits[1],
          otpInput3: digits[2],
          otpInput4: digits[3],
        });

        // Set focus on the last input if available
        if (this.otp4 && this.otp4.nativeElement) {
          this.otp4.nativeElement.focus();
        }

        this.updateOTPInputValues();
        this.verifyOTP();
      }
    }
  }

  verifyOTP(): void {
    const enteredOTP = this.getEnteredOTP();
    if (enteredOTP.length === 4 && /^\d+$/.test(enteredOTP) && enteredOTP === this.otp) {
      this.otpMatched = true;
      this.otpVerificationFinished = true;
      this.resetType = '';
      this.hideOTPInputFields();
    } else {
      this.otpMatched = false;
    }
  }

  updateOTPInputValues(): void {
    this.otpInput1 = String(this.otpForm.get("otpInput1")?.value);
    this.otpInput2 = String(this.otpForm.get("otpInput2")?.value);
    this.otpInput3 = String(this.otpForm.get("otpInput3")?.value);
    this.otpInput4 = String(this.otpForm.get("otpInput4")?.value);
  }

  isOTPComplete(): boolean {
    return (
      this.otpInput1 &&
      this.otpInput2 &&
      this.otpInput3 &&
      this.otpInput4 &&
      this.otpInput1.length === 1 &&
      this.otpInput2.length === 1 &&
      this.otpInput3.length === 1 &&
      this.otpInput4.length === 1
    );
  }

  getEnteredOTP(): string {
    return `${String(this.otpInput1)}${String(this.otpInput2)}${String(this.otpInput3)}${String(this.otpInput4)}`;
  }

  hideOTPInputFields(): void {
    this.otpInput1 = "";
    this.otpInput2 = "";
    this.otpInput3 = "";
    this.otpInput4 = "";
  }

  //   openAlertForAutoFill(): void {
  // 	const alertMessage = `OTP delivered via SMS. Do you want to set it automatically?`;
  // 	const confirmation = confirm(alertMessage);
  // 	if (confirmation) {
  // 	  this.autoFillOTP();
  // 	  this.verifyOTP();
  // 	}
  //   }

  //   autoFillOTP(): void {
  // 	if (this.otp.length === 4) {
  // 	  this.otpInput1 = this.otp.charAt(0);
  // 	  this.otpInput2 = this.otp.charAt(1);
  // 	  this.otpInput3 = this.otp.charAt(2);
  // 	  this.otpInput4 = this.otp.charAt(3);
  // 	}
  //   }
//   cancelOTP(): void {
//     alert("Please enter the OTP manually.");
//   }


simulateSMSDelivery(): Promise<[boolean, boolean]> {
	return new Promise<[boolean, boolean]>((resolve) => {
	  const SMSdelivered = true; // Example value
	  const Emaildelivered = true; // Example value
  
	  resolve([SMSdelivered, Emaildelivered]);
	});
  }
  
resendOTPHelper(): void {
	this.otpSentMobile = true;
	this.otpSentEmail = true;
	this.resendingOTP = false;
	this.simulateSMSDelivery().then(([SMSdelivered, Emaildelivered]: [boolean, boolean]) => {
	  if (SMSdelivered) {
		this.otpSMSDelivered = true;
	  } else if (Emaildelivered) {
		this.otpEmailDelivered = true;
	  } else {
		this.resendOTPHelper();
	  }
	});
  }
  
  resendOTP(): void {
	this.resendingOTP = true;
	this.countdown = 60; // Reset countdown to initial value
	this.startCountdown();
	setTimeout(() => {
	  this.resendOTPHelper();
	}, 60000); // Resend OTP after 1 minute (60 seconds)
  }

  startCountdown(): void {
	let count = Number(localStorage.getItem('countdown')) || this.countdown;
	const interval = setInterval(() => {
	  count--;
	  this.countdown = count;
	  localStorage.setItem('countdown', count.toString());
	  this.cdr.detectChanges();
  
	  if (count === 0) {
		clearInterval(interval);
		this.otpSentMobile = false;
		this.otpSentEmail = false;
		this.otpSMSDelivered = false;
		this.otpEmailDelivered = false;
		localStorage.setItem('otpSentMobile', 'false');
		localStorage.setItem('otpSentEmail', 'false');
		localStorage.setItem('otpSMSDelivered', 'false');
		localStorage.setItem('otpEmailDelivered', 'false');
	  } else {
		localStorage.setItem('otpSentMobile', this.otpSentMobile.toString());
		localStorage.setItem('otpSentEmail', this.otpSentEmail.toString());
		localStorage.setItem('otpSMSDelivered', this.otpSMSDelivered.toString());
		localStorage.setItem('otpEmailDelivered', this.otpEmailDelivered.toString());
	  }
	}, 1000);
  }
	
	showPassword: boolean = false;

	togglePasswordVisibility() {
	this.showPassword = !this.showPassword;
	}
	/**
	 * passwordEdit
	 */
		name: string;
	async passwordEdit() {
		this.submitted = true;
		if (!this.formGroupPwd.valid) {
			let err = await this.translate.get("VALIDATION_ERROR").toPromise();
			if (err != "VALIDATION_ERROR")
				this.showError(err);
			return false;
		} else {
			let key = this.getPrimaryKey();
			let value = { system_password: await this.unformatValue(this.formGroupPwd.get("system_password").value) }, res;
			console.log('key', key)
			console.log('value', value)
			this.saving = true;
			try {
				res = await this.userItems.edit(key, value);
			} catch (err) {
		  } finally {
				this.saving = false;
			}
			//console.log(res);
			if (res && res.success) {
				this.formGroupPwd.reset();
				this.submitted = false;
				this.modalController.dismiss();
			} else if (res && !res.success && res.failureMessage) {
				if (environment.production) {
					let err = await this.translate.get("FAILED_TO_EDIT").toPromise();
					await this.showError(err);
				} else {
					await this.showError(res.failureMessage);
				}
			}
		}
	}
  
	/**
	 * Get primary key
	 */
	getPrimaryKey(): any {
		let keys = {}, key;
		key = this.system_user_id;
		if (!this.dbapp.isEmpty(key))
			keys["system_user_id"] = key;
		return (Object.keys(keys).length === this.keyCount) ? keys : false;
	}

	isSystemPasswordLengthValid(): boolean {
		const systemPasswordControl = this.formGroupPwd.get('system_password');
		const systemPassword = systemPasswordControl?.value || '';
		return systemPassword.length >= 8;
	  }	  
	  
	  isSystemPasswordContainsNumber(): boolean {
		const systemPassword = this.formGroupPwd.get('system_password').value;
		return /[0-9]/.test(systemPassword);
	  }
	  
	  isSystemPasswordContainsSpecialChar(): boolean {
		const systemPassword = this.formGroupPwd.get('system_password').value;
		return /[!@#$%^&*]/.test(systemPassword);
	  }
	
  async sendOTPSms(userMobile: string, otpcode: string) {
    this.smsQueueRecipient = "+" + userMobile;
    this.smsQueueContent = await this.translate
      .get("otp_sms_content", { s: otpcode })
      .toPromise();
    const msgHeaderClient = {
      smsQueueRecipient: this.smsQueueRecipient,
      smsQueueContent: this.smsQueueContent,
    };
    try {
      const response = await this.smsService.sendMessageHeader(
        this.smsQueueRecipient,
        this.smsQueueContent
      );
      console.log("SMS sent successfully", response);
    } catch (error) {
      console.error("Failed to send SMS", error);
    }
  }

  async sendOTPEmail(userEmail: string, otpcode: string) {
    this.fromEmail = "admin@toor.ooo";
    this.toEmail = userEmail;
    this.ccEmail = "";
    this.bccEmail = "";
    this.subject = await this.translate
      .get("otp_code_email_subject", {
        s: otpcode,
      })
      .toPromise();
    this.emailContent = await this.translate
      .get("otp_code_email_content", {
        s: otpcode,
        d: this.dbapp.formatTimestamp(Date.now()),
      })
      .toPromise();
    this.format = "text";
    this.charset = "UTF-8";
    this.smtpSecure = "smtp";
    this.arAttachments = [];
    this.arImages = [];
    this.arProperties = null;
    this.userTitleFullName = await this.translate.get("mr_mrs", { s: this.system_full_name }).toPromise();
    this.brandName = await this.translate.get("toorprime").toPromise();
    this.transactionDetailsTitle = await this.translate
      .get("go_to_your_account")
      .toPromise();
    this.transactionDetailsLink =
      "https://www.toor.ooo/profilesview/";
    this.instructionsTitle = await this.translate
      .get("account_instructions_title")
      .toPromise();
    this.instructionsSubTitle = await this.translate
      .get("account_instructions_subtitle")
      .toPromise();
    this.instructionsContent = await this.translate
      .get("account_instructions_content")
      .toPromise();
    this.termsConditionsContent = await this.translate
      .get("terms_conditions_for_email")
      .toPromise();
    const emailHeaderClient = {
      from_email: this.fromEmail,
      to_email: this.toEmail,
      cc_email: this.ccEmail,
      bcc_email: this.bccEmail,
      subject: this.subject,
      email_content: this.emailContent,
      format: this.format,
      charset: this.charset,
      smtp_secure: this.smtpSecure,
      ar_attachments: this.arAttachments,
      ar_images: this.arImages,
      ar_properties: this.arProperties,
      extra_userfullname: this.userTitleFullName,
      extra_brand_name: this.brandName,
      extra_transaction_details_title: this.transactionDetailsTitle,
      extra_transaction_details_link: this.transactionDetailsLink,
      extra_instructions_title: this.instructionsTitle,
      extra_instructions_subtitle: this.instructionsSubTitle,
      extra_instructions_content: this.instructionsContent,
      extra_terms_conditions_content: this.termsConditionsContent,
      extra_locale: this.locale.locale,
    };

    try {
      const response = await this.emailService.sendEmailHeader(
        this.fromEmail,
        this.toEmail,
        this.ccEmail,
        this.bccEmail,
        this.subject,
        this.emailContent,
        this.format,
        this.charset,
        this.smtpSecure,
        this.arAttachments,
        this.arImages,
        this.arProperties,
        this.userTitleFullName,
        this.brandName,
        this.transactionDetailsTitle,
        this.transactionDetailsLink,
        this.instructionsTitle,
        this.instructionsSubTitle,
        this.instructionsContent,
        this.termsConditionsContent,
        this.locale.locale
      );
      console.log("Email sent successfully", response);
    } catch (error) {
      console.error("Failed to send Email", error);
    }
  }
  /** End Otp System */  
  
  
  launchDialer(n: string) {
    this.callNumber
      .callNumber(n, true)
      .then(() => console.log("Launched dialer!"))
      .catch(() => console.log("Error launching dialer"));
    }

  isLoading = false;

  async openModalCountries() {
    this.isLoading = true;
    const modalCountries = await this.modalController.create({
      component: countriesListPage,
      componentProps: { openedViewCountriesModal: true },
      initialBreakpoint: 1,
    });
    modalCountries.onDidDismiss().then(item => {
      this.isLoading = false;
      if (item && item.data) {
        this.locations_country_id = item.data.selectedCountryId;
        this.locations_country_name = item.data.selectedCountryName;
        this.locations_country_phone_code = item.data.selectedCountryPhoneCode;
        this.locations_country_flag = item.data.selectedCountryFlag;
      }
    });
    return await modalCountries.present();
  }
}