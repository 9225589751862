import { OnDestroy, OnInit, NgZone, ElementRef, Renderer2, HostBinding} from "@angular/core";
import { Component, ViewChild, ViewEncapsulation, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import {
  MenuController,
  Platform,
  ModalController,
  ToastController,
  AlertController,
  ActionSheetController,
  LoadingController,
  IonRouterOutlet,
  IonModal,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { TranslateService } from "@ngx-translate/core";
import { Storage } from "@ionic/storage";
import { UserData, Settings, LocaleService, DbApp, History } from "./providers";
import { CallNumber } from "@ionic-native/call-number/ngx";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { Stripe } from '@capacitor-community/stripe';
import { environment } from '../environments/environment';
// Component
declare var window: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})

export class AppComponent implements OnDestroy, OnInit {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  @ViewChild(IonModal) modal: IonModal;
  @ViewChild('dfMessenger') dfMessenger: ElementRef;
  appPages: any[];
  loggedIn: boolean = false;
  dark: boolean = false;
  public current_user_latitude: number;
  public current_user_longitude: number;
  showBottomBar: boolean = true;
  lastTimeBackPress: number = 0;
  timePeriodToExit: number = 2000;
  options = {
  timeout: 10000, 
  enableHighAccuracy: true, 
  maximumAge: 3600
  };
  public PosLatitude: number;
  public PosLongitude: number;
  @HostBinding('class.custom-user-message')
  public customUserMessage = true;
  // Constructor
  constructor(
    public dbapp: DbApp,
    public translate: TranslateService,
    public menu: MenuController,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public actionSheetController: ActionSheetController,
    public platform: Platform,
    public settings: Settings,
    public locale: LocaleService,
    public router: Router,
		private activatedRoute: ActivatedRoute,
    public splashScreen: SplashScreen,
    public statusBar: StatusBar,
    public storage: Storage,
    public user: UserData,
    public swUpdate: SwUpdate,
    public toastController: ToastController,
    public history: History,
    private menuFilter: MenuController,
    public callNumber: CallNumber,
    public modalController: ModalController,
    private push: Push,
    private geolocation: Geolocation,
    private nativeGeocoder: NativeGeocoder,
    private renderer: Renderer2,
    private fcm: FCM,
    private elementRef: ElementRef
  ) {
    this.initializeApp();
    this.initLanguage();
    this.listenForEvents();
    let prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
    prefersDark.addListener((e) => this.setDark(e.matches)); // Listen for changes to the prefers-color-scheme media query
    this.settings.load().then((settings) => {
      // Load settings
      let isDark = this.dbapp.isBoolean(settings.dark)
        ? settings.dark
        : prefersDark.matches;
      this.setDark(isDark);
      let langId = settings.language || this.dbapp.defaultLanguage; // Language ID
      this.switchDir(langId);
      this.translate.use(langId); // Set language
      this.locale.use(langId); // Set localee
      this.statusBar.overlaysWebView(false);
    //   this.statusBar.styleDefault();
    //   this.statusBar.backgroundColorByHexString("#ffffff");
    
    });
    this.user.load().then(async (data) => {
      if (data && data.failureMessage)
        await this.showError(data.failureMessage);
    });
    this.appPages = this.dbapp.menuItems;
    let titles = this.appPages.map((p) => p.title);
    this.translate
      .get(titles)
      .subscribe((values) =>
        this.appPages.forEach((p) => (p.title = values[p.title]))
      );

    this.backButtonEvent(); // Init back button
    Stripe.initialize({
      publishableKey: environment.stripe.publishablekey,
    });
    this.initializePropertiesLists();
  }
  // Set dark mode
  setDark(enabled: boolean) {
    this.settings.set("dark", enabled);
    document.body.classList.toggle("dark", enabled);
    this.statusBar.styleLightContent();
    this.statusBar.backgroundColorByHexString("#121212");
  }

  // Init
  async init() {
    this.checkLoginStatus();
    this.swUpdate.available.subscribe(async (res) => {
      const toast = await this.toastController.create({
        message: await this.translate.get("UPDATE_AVAILABLE").toPromise(),
        duration: 2000,
      });
      await toast.present();
      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }
  // OnInit
  ngOnInit() {
    Promise.resolve().then(async () => {
      await this.init();
    });
    
    this.initializePropertiesLists();
    this.dialogFlowSettings();
    this.addStyleToNextSibling();
  }
  
  initializePropertiesLists(){
    if(this.router.url === '/properties_listslist'){
      this.getCurrentUserPosition();
    }
    console.log('this.router.url', this.router.url);
  }

  // OnDestroy
  ngOnDestroy() {}

  // Pass routerOutlet to history
  ngAfterViewInit(): void {
    this.history.routerOutlet = this.routerOutlet;
    // this.applyMessengerStyling();

        // Wait for the df-messenger to be fully loaded
        const checkExist = setInterval(() => {
          if (document.getElementById('df-messenger')) {
            clearInterval(checkExist);
            this.addStyleToNextSibling(); // Call the class method
          }
        }, 100);
  }
  
  ionViewDidEnter() {
    // Get the input element using the parent class
    const input = document.querySelector('.input-box-wrapper input');

    // Change the placeholder dynamically
    if (input && input instanceof HTMLInputElement) {
      input.placeholder = "Your new placeholder text...";
    }
  }

  ionViewWillEnter() {
  }
  // Init app
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.getCurrentUserPosition();
    // this.geolocation.getCurrentPosition().then((resp) => {
    //   this.PosLatitude = resp.coords.latitude
    //   this.PosLongitude = resp.coords.longitude
    //   this.current_user_latitude = resp.coords.latitude
    //   this.current_user_longitude = resp.coords.longitude
    //   console.log("PosLatitude: "+this.PosLatitude);
    //   console.log("PosLongitude: "+this.PosLongitude);
    //  }).catch((error) => {
    //    console.log('Error getting location', error);
    //  });
     
     let watch = this.geolocation.watchPosition();
     watch.subscribe((data) => {
      // data can be a set of coordinates, or an error (if an error occurred).
      // data.coords.latitude
      // data.coords.longitude
     });

     this.platform.ready().then(() => {
      this.setupFCM();
      this.handlePushNotifications();
    });
      
    // if(localStorage.getItem('welcome_message') !== 'seen' || this.router.url !== '/'){
    //   this.router.navigateByUrl("/welcome");
    //   this.showBottomBar = false;
    // }
  }
  setupFCM() {
    if (this.platform.is('cordova')) {
      this.fcm.getToken().then(token => {
        console.log('Device token:', token);
        // Store the token or send it to your server for sending push notifications
      }).catch(error => {
        console.error('Error getting device token:', error);
      });
    }
  }

  handlePushNotifications() {
    if (this.platform.is('cordova')) {
      this.fcm.onNotification().subscribe(notification => {
        console.log('Received push notification:', notification);
        // Handle the received notification here (e.g., show a toast, navigate to a specific page)
      }, error => {
        console.error('Error receiving push notification:', error);
      });
    }
  }
  // Check login status
  checkLoginStatus() {
    this.user.isLoggedIn.subscribe((loggedIn) => {
      this.updateLoggedInStatus(loggedIn);
    });
  }

  // Update logged in status
  updateLoggedInStatus(loggedIn: boolean) {
    this.loggedIn = loggedIn;

    // Update menu items
    for (let p of this.appPages) {
      // Do not use forEach()
      if (this.dbapp.isString(p.security)) p.allowed = eval(p.security); // Do not use Function() due to "this" in code
    }
  }
  
	// // Update language in status
	// updateLanguageStatus(langId: string) {
	// 	this.langId = langId;
	//   }
  // Listen for events
  listenForEvents() {
    window.addEventListener("user:login", () => {
      this.updateLoggedInStatus(true);
    });
    window.addEventListener("user:signup", () => {
      this.updateLoggedInStatus(true);
    });
    window.addEventListener("user:logout", () => {
      this.updateLoggedInStatus(false);
    });
    window.addEventListener("language:lang", () => {
		window.location.reload();
    });
    window.addEventListener("theme:dark", () => {
      document.body.classList.toggle("dark", true);
      this.statusBar.styleLightContent();
      this.statusBar.backgroundColorByHexString("#121212");
    });
    window.addEventListener("theme:light", () => {
      document.body.classList.toggle("dark", false);
      this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString("#ffffff");
    });
  }

	// async handleWelcomeScreen(){
	// 	await this.router.navigateByUrl("/properties_listslist");
	// 	localStorage.setItem('welcome_message', 'seen');
	// 	this.showBottomBar = true;
	// }
  // Logout
  logout() {
    this.user.logout();
    this.router.navigateByUrl(""); // Go to start page
  }

  // Init translation and locale service
  initLanguage() {
    let languages = Object.keys(this.dbapp.languages);

    // Load all languages phrases
    this.translate.addLangs(languages);

    // Set the default language for translation strings, and the current language.
    this.translate.setDefaultLang(this.dbapp.defaultLanguage);
  }

  // Present toast
  async presentToast(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
    });
    toast.present();
  }

  // Back button
  backButtonEvent() {
    this.platform.backButton.subscribe(async () => {
      if (this.history.canGoBack) {
        this.history.goBack();
      } else {
        if (
          new Date().getTime() - this.lastTimeBackPress <
          this.timePeriodToExit
        ) {
          navigator["app"].exitApp(); // Exit from app
        } else {
          let msg = await this.translate.get("EXIT").toPromise();
          await this.presentToast(msg);
          this.lastTimeBackPress = new Date().getTime();
        }
      }
    });
  }

  /**
   * Show message
   */
  async showMessage(msg: string, header: string) {
    let values = await this.translate.get(["OK_BUTTON", header]).toPromise();
    const alert = await this.alertController.create({
      header: values[header],
      message: msg,
      buttons: [values.OK_BUTTON],
    });
    await alert.present();
  }

  /**
   * Show error
   */
  async showError(err: any) {
    let msg = err instanceof Error ? err.message : err;
    return this.showMessage(msg, "ERROR");
  }

  /**
   * Show success message
   */
  async showSuccess(msg: string) {
    return this.showMessage(msg, "SUCCESS");
  }

  switchDir(langId: string) {
    const htmlTag = document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = langId === "ar" ? "rtl" : "ltr";
    htmlTag.dir = langId !== "ar" ? "ltr" : "rtl";
    htmlTag.lang = langId;
	// this.platform.isRTL;
  }

  async liveStreaming() {
      let values = await this.translate
        .get(['livestream_header', 'livestream_msg', 'download_toor_agents'])
        .toPromise();
      const alert = await this.alertController.create({
        cssClass: 'livestream',
        header: values['livestream_header'],
        animated: true,
        message: values['livestream_msg'],
        buttons: [
          {
            text: values['download_toor_agents'],
            handler: () => {
              if(!this.loggedIn){
                this.router.navigateByUrl("/login");
              } else {
                const queryParams = {
                  service_type_id: 1
                };
                this.router.navigate(['/add_property_1add'], { replaceUrl: true, queryParams });
              }
            },
      },
        ],
      },);
      await alert.present();
    }
    async joinToUsMessage() {
      let values = await this.translate
        .get(['join_to_us_header', 'join_to_us_subheader', 'join_to_us_msg', 'download_toor_agents', 'join_to_us_call_button'])
        .toPromise();
      const alert = await this.alertController.create({
        header: values['join_to_us_header'],
        subHeader: values['join_to_us_subheader'],
        message: values['join_to_us_msg'],
        buttons: [
          {
            text: values['download_toor_agents'],
            handler: () => {
              if(!this.loggedIn){
                this.router.navigateByUrl("/login");
              } else {
                const queryParams = {
                  service_type_id: 1
                };
                this.router.navigate(['/add_property_1add'], { replaceUrl: true, queryParams });
              }
            },
      },
        {
        text: values['join_to_us_call_button'],
        handler: () => {
          this.launchDialer("+966570169501"); // Go to start page
        },
          },
        ],
      });
      await alert.present();
    }
  async addPropertyOptionsMessage(
    header: string,
    subHeader: string,
    msg: string,
    addButton: string,
    callButton: string
  ) {
    let values = await this.translate
      .get([header, subHeader, msg, addButton, callButton])
      .toPromise();
    const alert = await this.alertController.create({
      header: values[header],
      subHeader: values[subHeader],
      message: values[msg],
      buttons: [
        {
          text: values[addButton],
          handler: () => {
            if(!this.loggedIn){
              this.router.navigateByUrl("/login");
            } else {
              const queryParams = {
                service_type_id: 1
              };
              this.router.navigate(['/add_property_1add'], { replaceUrl: true, queryParams });
            }
          },
		},
		  {
			text: values[callButton],
			handler: () => {
			  this.launchDialer("+966570169501"); // Go to start page
			},
        },
      ],
    });
    await alert.present();
  }
  async requestProperty(
    header: string,
    subHeader: string,
    msg: string,
    addButton: string,
    callButton: string
  ) {
    let values = await this.translate
      .get([header, subHeader, msg, addButton, callButton])
      .toPromise();
    const alert = await this.alertController.create({
      header: values[header],
      subHeader: values[subHeader],
      message: values[msg],
      buttons: [
        {
          text: values[addButton],
          handler: () => {
            if(!this.loggedIn){
              this.router.navigateByUrl("/login");
            } else {
              const queryParams = {
                service_type_id: 2
              };
              this.router.navigate(['/add_property_1add'], { replaceUrl: true, queryParams });
            }
          },
		},
		  {
			text: values[callButton],
			handler: () => {
			  this.launchDialer("+966570169501"); // Go to start page
			},
        },
      ],
    });
    await alert.present();
  }
  
  async appraisalPropertyMessage(
    msg: string,
    header: string,
    subHeader: string,
    notapprovalButton: string,
    approvalButton: string
  ) {
    let values = await this.translate
      .get([header, subHeader, msg, notapprovalButton, approvalButton])
      .toPromise();
    const alert = await this.alertController.create({
      header: values[header],
      subHeader: values[subHeader],
      message: values[msg],
      buttons: [
        {
          text: values[notapprovalButton],
          handler: () => {
            if(!this.loggedIn){
              this.router.navigateByUrl("/login");
            } else {
              const queryParams = {
                service_type_id: 3
              };
            this.router.navigate(['/add_property_1add'], { replaceUrl: true, queryParams });
            }
          },
        },
          {
          text: values[approvalButton],
          handler: () => {
            this.launchDialer("+966570169501"); // Go to start page
          },
            },
          ],
    });
    await alert.present();
  }

  // async blockchainRegistrationMessage(
  //   msg: string,
  //   header: string,
  //   subHeader: string,
  //   regButton: string
  // ) {
  //   let values = await this.translate
  //     .get([header, subHeader, msg, regButton])
  //     .toPromise();
  //   const alert = await this.alertController.create({
  //     header: values[header],
  //     subHeader: values[subHeader],
  //     message: values[msg],
  //     buttons: [
  //       {
  //         text: values[regButton],
  //         handler: () => {
  //           const queryParams = {
  //             service_type_id: 3
  //           };
  //         this.router.navigate(['/add_property_1add'], { replaceUrl: true, queryParams });
  //         },
  //       },
  //     ],
  //   });
  //   await alert.present();
  // }

  manageProperty() {
    const queryParams = {
      service_type_id: 5
    };
    this.router.navigate(['/add_property_1add'], { replaceUrl: true, queryParams });
  }
  otherServices() {
    this.router.navigateByUrl("/other_services"); // Go to start page
  }
  async presentAddPropertyOptions() {
    let ids = [
      "show_property",
      "request_property",
      "manage_property",
      "appraisal_property",
      // "blockchain_registration",
      "other_services",
      "what_you_want",
      "CANCEL_BUTTON",
    ];
    let doAddProperty = (fldvar) => {
      this.addPropertyOptionsMessage(
        "add_property_header",
        "add_property_subheader",
        "add_property_message",
        "add_property_button",
        "add_property_call"
      );
    };
    let doRequestProperty = (fldvar) => {
      // this.requestProperty();
      this.addPropertyOptionsMessage(
        "request_property_header",
        "request_property_subheader",
        "request_property_message",
        "request_property_button",
        "request_property_call"
      );
    };
    let doappraisalPropertyMessage = (fldvar) => {
      this.appraisalPropertyMessage(
        "appraisal_property_message",
        "appraisal_property_header",
        "appraisal_property_subheader",
        "notapprovalButton",
        "approvalButton"
      );
    };
    // let doblockchainRegistrationMessage = (fldvar) => {
    //   this.blockchainRegistrationMessage(
    //     "blockchain_registration_message",
    //     "blockchain_registration_header",
    //     "blockchain_registration_subheader",
    //     "register_your_property_now"
    //   );
    // };
    let doManageProperty = (fldvar) => {
      this.manageProperty();
    };
    let doOtherServices = (fldvar) => {
      this.otherServices();
    };
    let values = await this.translate.get(ids).toPromise();
    let actionSheet = await this.actionSheetController.create({
      header: values.what_you_want,
      buttons: [
        {
          text: values["show_property"],
          icon: "./assets/icon/commercial_colored.svg",
          handler: () => {
            doAddProperty("");
          },
        },
        {
          text: values["request_property"],
          icon: "./assets/icon/ask_question_colored.svg",
          handler: () => {
            doRequestProperty("/add_property_1add");
          },
        },
        {
          text: values["manage_property"],
          icon: "./assets/icon/business_building_colored.svg",
          handler: () => {
            doManageProperty("/clientsrequestsadd");
          },
        },
        {
          text: values["appraisal_property"],
          icon: "./assets/icon/accounting_colored.svg",
          handler: () => {
            doappraisalPropertyMessage("");
          },
        },
        // {
        //   text: values["blockchain_registration"],
        //   icon: "./assets/icon/edit_link.svg",
        //   handler: () => {
        //     doblockchainRegistrationMessage("");
        //   },
        // },
        {
          text: values["other_services"],
          icon: "./assets/icon/checklist_colored.svg",
          handler: () => {
            doOtherServices("/other_services");
          },
        },
        {
          text: values.CANCEL_BUTTON,
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked");
          },
        },
      ],
      cssClass: "secondary",
    });
    await actionSheet.present();
  }

  launchDialer(n: string) {
    this.callNumber
      .callNumber(n, true)
      .then(() => console.log("Launched dialer!"))
      .catch(() => console.log("Error launching dialer"));
  }

  // openShare() {
  // // Check if sharing via email is supported
  // 	this.socialSharing.canShareViaEmail().then(() => {
  // 		// Sharing via email is possible
  // 	}).catch(() => {
  // 		// Sharing via email is not possible
  // 	});
  // 	// Share via email
  // 	this.socialSharing.shareViaEmail('Body', 'Subject', ['recipient@example.org']).then(() => {
  // 		// Success!
  // 	}).catch(() => {
  // 		// Error!
  // 	});
  // }

  AuctionDisclaimers() {
    this.router.navigateByUrl("/auction_disclaimers"); // Go to start page
  }
  async presentAuctionInstructions() {
    let ids = [
      "auction_instructions_1",
      "auction_instructions_2",
      "auction_instructions_3",
      "auction_instructions_4",
      "auction_instructions_5",
      "auction_instructions",
      "CANCEL_BUTTON",
    ];
    let doAuctionDisclaimers = (fldvar) => {
      this.AuctionDisclaimers();
    };
    let values = await this.translate.get(ids).toPromise();
    let actionSheet = await this.actionSheetController.create({
      header: values.auction_instructions,
      buttons: [
        {
          text: values["auction_instructions_1"],
        },
        {
          text: values["auction_instructions_2"],
        },
        {
          text: values["auction_instructions_3"],
        },
        {
          text: values["auction_instructions_4"],
        },
        {
          text: values["auction_instructions_5"],
          handler: () => {
            doAuctionDisclaimers("/other_services");
          },
        },
        {
          text: values.CANCEL_BUTTON,
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked");
          },
        },
      ],
      cssClass: "actionsheet-custom",
    });
    await actionSheet.present();
  }

  		async presentChatbot(appType: string) {
    }

    isChatbotModalOpen = false;

    setChatbotOpen(isOpen: boolean) {
      this.isChatbotModalOpen = isOpen;
      if(isOpen)
      this.openModalChatbotToast('top');
    }
    async openModalChatbotToast(position: 'top') {
      let values = await this.translate.get(["chat_bot_messege"]).toPromise();
      const toast = await this.toastController.create({
        message: values['chat_bot_messege'],
        duration: 4000,
        position: position,
        icon: './assets/icon/chatbot.svg',
        cssClass: 'custom-toast'
      });
      toast.present();
      }
	  
      async getCurrentUserPosition() {
		if (navigator.geolocation) {
		  // Use the maximumAge and enableHighAccuracy options for optimization
		  const options = {
			// maximumAge: 0, // Fetch a fresh position, do not use cached position
			enableHighAccuracy: true, // Request higher accuracy
		  };
	
		  navigator.geolocation.getCurrentPosition(
			(position) => {
			  // Extract latitude and longitude from the position object
			  const userLocation = {
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			  };
	
			  this.current_user_latitude = userLocation.latitude;
			  this.current_user_longitude = userLocation.longitude;
			  localStorage.setItem('current_user_latitude', this.current_user_latitude.toString());
			  localStorage.setItem('current_user_longitude', this.current_user_longitude.toString());
			  // Prepare the query parameters for navigation
			  const queryParams = {
				current_user_latitude: this.current_user_latitude,
				current_user_longitude: this.current_user_longitude
			  };
	
			  // Use router navigation with optimized options
			  this.router.navigate([], {
				queryParams,
				replaceUrl: true,
				// Add other navigation options if needed
			  });
			},
			(error) => {
			  // Handle errors that occur during location retrieval
			  console.error('Error getting user location:', error.message);
			},
			options // Pass the options object with maximumAge and enableHighAccuracy
		  );
		} else {
		  // Geolocation is not supported by the user's browser
		  console.error('Geolocation is not supported by this browser.');
		}
	}
  

      // applyMessengerStyling(): void {
      //   const dfMessenger = document.querySelector('df-messenger');
      
      //   dfMessenger.addEventListener('df-messenger-loaded', function () {
      //     let dfIcon = dfMessenger?.shadowRoot?.querySelector("#widgetIcon");
      //     // if (dfIcon) {
      //     //   dfIcon.style.display = "none";
      //     // }
      
      //     const style = document.createElement('style');
      //     style.textContent = `
      //       div.df-messenger-wrapper {
      //         background-color: white;
      //         border: 0;
      //         bottom: unset;
      //         color: rgba(0, 0, 0, 0.87);
      //         font-size: 14px;
      //         font-weight: normal;
      //         margin: 0;
      //         padding: 0;
      //         position: unset;
      //         right: unset;
      //         text-decoration: none;
      //         z-index: 100;
      //         height: 80vh;
      //       }
      //     `;
      //     dfMessenger.shadowRoot.appendChild(style);
      
      //     const style1 = document.createElement('style');
      //     style1.textContent = `
      //       div.chat-wrapper {
      //         bottom: 0;
      //         right: 0;
      //         width: 100%;
      //         border-radius: 0;
      //         bottom: 0;
      //         height: 100%;
      //         box-shadow: none;
      //         opacity: 0;
      //         position: relative;
      //         right: 0;
      //         transform: none;
      //         transition: none;
      //       }
      
      //       .expanded > #widgetIcon {
      //         visibility: hidden;
      //       }
      
      //       div.chat-wrapper[opened="true"] {
      //         height: 100%;
      //         transform: none;
      //         transition: none;
      //       }
      
      //       html[dir=rtl] #messageList .message.user-message {
      //         align-self: flex-start !important;
      //         margin-left: unset;
      //       }
      
      //       .title-wrapper {
      //         padding-right: 15px;
      //       }
      //     `;
      //     dfMessenger.shadowRoot.querySelector('df-messenger-chat').shadowRoot.appendChild(style1);
      
      //     const style2 = document.createElement('style');
      //     style2.textContent = `
      //       .title-wrapper {
      //         display: none;
      //       }
      //     `;
      //     dfMessenger.shadowRoot.querySelector('df-messenger-chat').shadowRoot.querySelector('df-messenger-titlebar').shadowRoot.appendChild(style2);
      
      //     const style3 = document.createElement('style');
      //     style3.textContent = `
      //       #messageList .message.user-message {
      //         align-self: unset !important;
      //         margin-left: unset;
      //         background-color: #fdfdfd;
      //         text-align: right;
      //       }
      
      //       #messageList .message.bot-message {
      //         align-self: unset;
      //         background-color: #f7f7f8;
      //         margin-right: unset;
      //         line-height: 1.4;
      //       }
      
      //       #messageList > :first-child {
      //         margin-top: unset !important;
      //       }
      
      //       #messageList .message {
      //         background: transparent;
      //         border: 0;
      //         border-bottom: 1px solid #f1f1f1;
      //         border-radius: 0;
      //         color: rgba(0, 0, 0, 0.87);
      //         color: var(--df-messenger-font-color);
      //         flex: 0 0 auto;
      //         font-family: 'Roboto', sans-serif;
      //         font-size: 14px;
      //         margin-top: 0;
      //         max-width: calc(100% - 28px);
      //         padding: 7px 16px;
      //         word-break: break-word;
      //         word-wrap: break-word;
      //         height: 40px;
      //       }
      //     `;
      //     dfMessenger.shadowRoot.querySelector('df-messenger-chat').shadowRoot.querySelector('df-message-list').shadowRoot.appendChild(style3);
      
          // dfMessenger.shadowRoot.querySelector("df-messenger-chat").shadowRoot.querySelector("df-messenger-user-input").shadowRoot.querySelector(".input-box-wrapper > input").placeholder = "xxxx";
      //   });
      // }
      
      
    // const script = document.createElement('script');
    // script.src = 'https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1';
    // script.async = true;
    // document.head.appendChild(script);

    // // Customize the style and placeholder
    // const messengerStyle = document.createElement('style');
    // messengerStyle.innerHTML = `
    //   #df-messenger {
    //     /* Your custom CSS styles here */
    //   }

    //   #df-messenger input {
    //     /* Your custom CSS styles for the input field here */
    //   }

    //   #df-messenger input::placeholder {
    //     /* Your custom CSS styles for the input field placeholder here */
    //   }
    // `;
    // document.head.appendChild(messengerStyle);

  dialogFlowSettings(){
    // const script = document.createElement('script');
    // script.src = 'https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1';
    // script.async = true;
    // document.head.appendChild(script);

    // const link = this.renderer.createElement('link');
    // this.renderer.setAttribute(link, 'rel', 'stylesheet');
    // this.renderer.setAttribute(link, 'type', 'text/css');
    // this.renderer.setAttribute(link, 'href', 'path/to/dialogflow-messenger.css');
    // this.renderer.appendChild(this.elementRef.nativeElement, link);


    const script = document.createElement('script');
    script.src = 'https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1';
    script.async = true;
    document.head.appendChild(script);

    // Customize the style and placeholder
    const messengerStyle = document.createElement('style');
    messengerStyle.setAttribute('scope', 'df-message-list');
    messengerStyle.innerHTML = `
      #df-messenger {
        /* Your custom CSS styles here */
      }
      #messageList .message.user-message {
        align-self: flex-start !important;
    }
      #df-messenger input {
        /* Your custom CSS styles for the input field here */
      }

      #df-messenger input::placeholder {
        /* Your custom CSS styles for the input field placeholder here */
      }
    `;
    document.body.appendChild(messengerStyle);
  }

  // Define the function as a class method
  addStyleToNextSibling(): void {
    const dfMessenger = document.getElementById('df-messenger') as HTMLElement;

    if (dfMessenger) {
      const messageList = dfMessenger.querySelector('#messageList') as HTMLElement;

      if (messageList) {
        const nextSibling = messageList.nextElementSibling as HTMLElement;

        if (nextSibling && nextSibling.classList.contains('message')) {
          nextSibling.style.alignSelf = 'flex-start';
        }
      }
    }
  }
}
