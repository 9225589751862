import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserData } from './providers';
import { properties_listsViewPage } from './pages/properties_listsview/properties_listsview'; // Replace with the correct path
import { PropertyIdResolver } from './providers/properties_property_id.resolver';
import { CanMatchGuard } from './providers/auth.guard';
 
const routes: Routes = [
	{
		path: 'add_appointmentslist',
		loadChildren: () => import('./pages/add_appointmentslist/add_appointmentslist.module').then(m => m.add_appointmentsListPageModule),
	},
	{
		path: 'add_appointmentsadd',
		loadChildren: () => import('./pages/add_appointmentsadd/add_appointmentsadd.module').then(m => m.add_appointmentsAddPageModule),
	},
	{
		path: 'add_appointmentsview/:crm_appointment_id',
		loadChildren: () => import('./pages/add_appointmentsview/add_appointmentsview.module').then(m => m.add_appointmentsViewPageModule),
	},
	{
		path: 'add_property_1list',
		loadChildren: () => import('./pages/add_property_1list/add_property_1list.module').then(m => m.add_property_1ListPageModule),
	},
	{
		path: 'add_property_1add',
		loadChildren: () => import('./pages/add_property_1add/add_property_1add.module').then(m => m.add_property_1AddPageModule),
	},
	{
		path: 'add_property_1edit/:properties_property_id',
		loadChildren: () => import('./pages/add_property_1edit/add_property_1edit.module').then(m => m.add_property_1EditPageModule),
	},
	{
		path: 'add_property_1view/:properties_property_id',
		loadChildren: () => import('./pages/add_property_1view/add_property_1view.module').then(m => m.add_property_1ViewPageModule),
	},
	{
		path: 'add_property_confirmation',
		loadChildren: () => import('./pages/add_property_confirmation/add_property_confirmation.module').then(m => m.add_property_confirmationPageModule),
	},
	{
		path: 'appointment_confirmation',
		loadChildren: () => import('./pages/appointment_confirmation/appointment_confirmation.module').then(m => m.appointment_confirmationPageModule),
	},
	{
		path: 'auction_disclaimers',
		loadChildren: () => import('./pages/auction_disclaimers/auction_disclaimers.module').then(m => m.auction_disclaimersPageModule),
	},
	{
		path: 'auctions_logslist',
		loadChildren: () => import('./pages/auctions_logslist/auctions_logslist.module').then(m => m.auctions_logsListPageModule),
	},
	{
		path: 'auctions_logsadd',
		loadChildren: () => import('./pages/auctions_logsadd/auctions_logsadd.module').then(m => m.auctions_logsAddPageModule),
	},
	{
		path: 'browse',
		loadChildren: () => import('./pages/browse/browse.module').then(m => m.browsePageModule),
	},
	{
		path: 'buy',
		loadChildren: () => import('./pages/buy/buy.module').then(m => m.buyPageModule),
	},
	{
		path: 'citieslist',
		loadChildren: () => import('./pages/citieslist/citieslist.module').then(m => m.citiesListPageModule),
	},
	{
		path: 'citiesadd',
		loadChildren: () => import('./pages/citiesadd/citiesadd.module').then(m => m.citiesAddPageModule),
	},
	{
		path: 'citiesedit/:locations_city_id',
		loadChildren: () => import('./pages/citiesedit/citiesedit.module').then(m => m.citiesEditPageModule),
	},
	{
		path: 'citiesview/:locations_city_id',
		loadChildren: () => import('./pages/citiesview/citiesview.module').then(m => m.citiesViewPageModule),
	},
	{
		path: 'clientsrequestslist',
		loadChildren: () => import('./pages/clientsrequestslist/clientsrequestslist.module').then(m => m.clientsrequestsListPageModule),
	},
	{
		path: 'clientsrequestsadd',
		loadChildren: () => import('./pages/clientsrequestsadd/clientsrequestsadd.module').then(m => m.clientsrequestsAddPageModule),
	},
	{
		path: 'clientsrequestsview/:crm_request_id',
		loadChildren: () => import('./pages/clientsrequestsview/clientsrequestsview.module').then(m => m.clientsrequestsViewPageModule),
	},
	{
		path: 'countrieslist',
		loadChildren: () => import('./pages/countrieslist/countrieslist.module').then(m => m.countriesListPageModule),
	},
	{
		path: 'countriesadd',
		loadChildren: () => import('./pages/countriesadd/countriesadd.module').then(m => m.countriesAddPageModule),
	},
	{
		path: 'countriesedit/:locations_country_id',
		loadChildren: () => import('./pages/countriesedit/countriesedit.module').then(m => m.countriesEditPageModule),
	},
	{
		path: 'countriesview/:locations_country_id',
		loadChildren: () => import('./pages/countriesview/countriesview.module').then(m => m.countriesViewPageModule),
	},
	{
		path: 'files_media_panoslist',
		loadChildren: () => import('./pages/files_media_panoslist/files_media_panoslist.module').then(m => m.files_media_panosListPageModule),
	},
	{
		path: 'files_media_panoslist/:properties_property_id',
		loadChildren: () => import('./pages/files_media_panoslist/files_media_panoslist.module').then(m => m.files_media_panosListPageModule),
	},
	{
		path: 'files_media_panosadd',
		loadChildren: () => import('./pages/files_media_panosadd/files_media_panosadd.module').then(m => m.files_media_panosAddPageModule),
	},
	{
		path: 'files_media_panosedit/:properties_files_media_id',
		loadChildren: () => import('./pages/files_media_panosedit/files_media_panosedit.module').then(m => m.files_media_panosEditPageModule),
	},
	{
		path: 'files_media_panosview/:properties_files_media_id',
		loadChildren: () => import('./pages/files_media_panosview/files_media_panosview.module').then(m => m.files_media_panosViewPageModule),
	},
	{
		path: 'files_media_photoslist',
		loadChildren: () => import('./pages/files_media_photoslist/files_media_photoslist.module').then(m => m.files_media_photosListPageModule),
	},
	{
		path: 'files_media_photoslist/:properties_property_id',
		loadChildren: () => import('./pages/files_media_photoslist/files_media_photoslist.module').then(m => m.files_media_photosListPageModule),
	},
	{
		path: 'files_media_photosadd',
		loadChildren: () => import('./pages/files_media_photosadd/files_media_photosadd.module').then(m => m.files_media_photosAddPageModule),
	},
	{
		path: 'files_media_photosedit/:properties_files_media_id',
		loadChildren: () => import('./pages/files_media_photosedit/files_media_photosedit.module').then(m => m.files_media_photosEditPageModule),
	},
	{
		path: 'files_media_photosview/:properties_files_media_id',
		loadChildren: () => import('./pages/files_media_photosview/files_media_photosview.module').then(m => m.files_media_photosViewPageModule),
	},
	{
		path: 'files_media_videoslist',
		loadChildren: () => import('./pages/files_media_videoslist/files_media_videoslist.module').then(m => m.files_media_videosListPageModule),
	},
	{
		path: 'files_media_videoslist/:properties_property_id',
		loadChildren: () => import('./pages/files_media_videoslist/files_media_videoslist.module').then(m => m.files_media_videosListPageModule),
	},
	{
		path: 'files_media_videosadd',
		loadChildren: () => import('./pages/files_media_videosadd/files_media_videosadd.module').then(m => m.files_media_videosAddPageModule),
	},
	{
		path: 'files_media_videosedit/:properties_files_media_id',
		loadChildren: () => import('./pages/files_media_videosedit/files_media_videosedit.module').then(m => m.files_media_videosEditPageModule),
	},
	{
		path: 'files_media_videosview/:properties_files_media_id',
		loadChildren: () => import('./pages/files_media_videosview/files_media_videosview.module').then(m => m.files_media_videosViewPageModule),
	},
	{
		path: 'finance_mortgage',
		loadChildren: () => import('./pages/finance_mortgage/finance_mortgage.module').then(m => m.finance_mortgagePageModule),
	},
	{
		path: 'investment',
		loadChildren: () => import('./pages/investment/investment.module').then(m => m.investmentPageModule),
	},
	{
		path: 'messageslist',
		loadChildren: () => import('./pages/messageslist/messageslist.module').then(m => m.messagesListPageModule),
	},
	{
		path: 'messagesadd',
		loadChildren: () => import('./pages/messagesadd/messagesadd.module').then(m => m.messagesAddPageModule),
	},
	{
		path: 'messagesview/:messages_id',
		loadChildren: () => import('./pages/messagesview/messagesview.module').then(m => m.messagesViewPageModule),
	},
	{
		path: 'my_propertieslist',
		loadChildren: () => import('./pages/my_propertieslist/my_propertieslist.module').then(m => m.my_propertiesListPageModule),
	},
	{
		path: 'my_propertiesview/:properties_property_id',
		loadChildren: () => import('./pages/my_propertiesview/my_propertiesview.module').then(m => m.my_propertiesViewPageModule),
	},
	{
		path: 'nearby_serviceslist',
		loadChildren: () => import('./pages/nearby_serviceslist/nearby_serviceslist.module').then(m => m.nearby_servicesListPageModule),
	},
	{
		path: 'nearby_services_typeslist',
		loadChildren: () => import('./pages/nearby_services_typeslist/nearby_services_typeslist.module').then(m => m.nearby_services_typesListPageModule),
	},
	{
		path: 'neighborhoodslist',
		loadChildren: () => import('./pages/neighborhoodslist/neighborhoodslist.module').then(m => m.neighborhoodsListPageModule),
	},
	{
		path: 'neighborhoodslist/:locations_city_id',
		loadChildren: () => import('./pages/neighborhoodslist/neighborhoodslist.module').then(m => m.neighborhoodsListPageModule),
	},
	{
		path: 'neighborhoodsadd',
		loadChildren: () => import('./pages/neighborhoodsadd/neighborhoodsadd.module').then(m => m.neighborhoodsAddPageModule),
	},
	{
		path: 'neighborhoodsedit/:locations_neighborhood_id',
		loadChildren: () => import('./pages/neighborhoodsedit/neighborhoodsedit.module').then(m => m.neighborhoodsEditPageModule),
	},
	{
		path: 'neighborhoodsview/:locations_neighborhood_id',
		loadChildren: () => import('./pages/neighborhoodsview/neighborhoodsview.module').then(m => m.neighborhoodsViewPageModule),
	},
	{
		path: 'neighborhoodslist/:locations_city_id',
		loadChildren: () => import('./pages/neighborhoodslist/neighborhoodslist.module').then(m => m.neighborhoodsListPageModule),
	},
	{
		path: 'neighborhoodsview/:locations_neighborhood_id',
		loadChildren: () => import('./pages/neighborhoodsview/neighborhoodsview.module').then(m => m.neighborhoodsViewPageModule),
	},
	{
		path: 'neom',
		loadChildren: () => import('./pages/neom/neom.module').then(m => m.neomPageModule),
	},
	{
		path: 'notificationslist',
		loadChildren: () => import('./pages/notificationslist/notificationslist.module').then(m => m.notificationsListPageModule),
	},
	{
		path: 'notificationsadd',
		loadChildren: () => import('./pages/notificationsadd/notificationsadd.module').then(m => m.notificationsAddPageModule),
	},
	{
		path: 'notificationsedit/:notifications_id',
		loadChildren: () => import('./pages/notificationsedit/notificationsedit.module').then(m => m.notificationsEditPageModule),
	},
	{
		path: 'notificationsview/:notifications_id',
		loadChildren: () => import('./pages/notificationsview/notificationsview.module').then(m => m.notificationsViewPageModule),
	},
	{
		path: 'other_real_estate_services',
		loadChildren: () => import('./pages/other_real_estate_services/other_real_estate_services.module').then(m => m.other_real_estate_servicesPageModule),
	},
	{
		path: 'other_services',
		loadChildren: () => import('./pages/other_services/other_services.module').then(m => m.other_servicesPageModule),
	},
	{
		path: 'payment',
		loadChildren: () => import('./pages/payment/payment.module').then(m => m.paymentPageModule),
	},
	{
		path: 'profileslist',
		loadChildren: () => import('./pages/profileslist/profileslist.module').then(m => m.profilesListPageModule),
	},
	{
		path: 'profilesedit/:system_user_id',
		loadChildren: () => import('./pages/profilesedit/profilesedit.module').then(m => m.profilesEditPageModule),
	},
	{
		path: 'profilesview/:system_user_id',
		loadChildren: () => import('./pages/profilesview/profilesview.module').then(m => m.profilesViewPageModule),
	},
	{
		path: 'profiles_publiclist',
		loadChildren: () => import('./pages/profiles_publiclist/profiles_publiclist.module').then(m => m.profiles_publicListPageModule),
	},
	{
		path: 'profiles_publicedit/:system_user_id',
		loadChildren: () => import('./pages/profiles_publicedit/profiles_publicedit.module').then(m => m.profiles_publicEditPageModule),
	},
	{
		path: 'profiles_publicview/:system_user_id',
		loadChildren: () => import('./pages/profiles_publicview/profiles_publicview.module').then(m => m.profiles_publicViewPageModule),
	},
	{
		path: 'profiles_linkedlist',
		loadChildren: () => import('./pages/profiles_linkedlist/profiles_linkedlist.module').then(m => m.profiles_linkedListPageModule),
	},
	{
		path: 'properties_schemaslist',
		loadChildren: () => import('./pages/properties_schemaslist/properties_schemaslist.module').then(m => m.properties_schemasListPageModule),
	},
	{
		path: 'properties_schemaslist/:schema_segments',
		loadChildren: () => import('./pages/properties_schemaslist/properties_schemaslist.module').then(m => m.properties_schemasListPageModule),
	},
	{
		path: 'properties_schemasview/:properties_property_schema_id',
		loadChildren: () => import('./pages/properties_schemasview/properties_schemasview.module').then(m => m.properties_schemasViewPageModule),
	},
	{
		path: 'properties_schemas_segmentslist',
		loadChildren: () => import('./pages/properties_schemas_segmentslist/properties_schemas_segmentslist.module').then(m => m.properties_schemas_segmentsListPageModule),
	},
	{
		path: 'properties_schemas_segmentslist/:properties_property_schema_id',
		loadChildren: () => import('./pages/properties_schemas_segmentslist/properties_schemas_segmentslist.module').then(m => m.properties_schemas_segmentsListPageModule),
	},
	{
		path: 'properties_schemas_segmentsview/:properties_property_schema_segment_id',
		loadChildren: () => import('./pages/properties_schemas_segmentsview/properties_schemas_segmentsview.module').then(m => m.properties_schemas_segmentsViewPageModule),
	},
	{
		path: 'regionslist',
		loadChildren: () => import('./pages/regionslist/regionslist.module').then(m => m.regionsListPageModule),
	},
	{
		path: 'regionsadd',
		loadChildren: () => import('./pages/regionsadd/regionsadd.module').then(m => m.regionsAddPageModule),
	},
	{
		path: 'regionsedit/:locations_region_id',
		loadChildren: () => import('./pages/regionsedit/regionsedit.module').then(m => m.regionsEditPageModule),
	},
	{
		path: 'regionsview/:locations_region_id',
		loadChildren: () => import('./pages/regionsview/regionsview.module').then(m => m.regionsViewPageModule),
	},
	{
		path: 'rent',
		loadChildren: () => import('./pages/rent/rent.module').then(m => m.rentPageModule),
	},
	{
		path: 'request_confirmation',
		loadChildren: () => import('./pages/request_confirmation/request_confirmation.module').then(m => m.request_confirmationPageModule),
	},
	{
		path: 'request_property_confirmation',
		loadChildren: () => import('./pages/request_property_confirmation/request_property_confirmation.module').then(m => m.request_property_confirmationPageModule),
	},
	{
		path: 'sell',
		loadChildren: () => import('./pages/sell/sell.module').then(m => m.sellPageModule),
	},
	{
		path: 'serviceslist',
		loadChildren: () => import('./pages/serviceslist/serviceslist.module').then(m => m.servicesListPageModule),
	},
	{
		path: 'services_prices',
		loadChildren: () => import('./pages/services_prices/services_prices.module').then(m => m.services_pricesPageModule),
	},
	{
		path: 'streetslist',
		loadChildren: () => import('./pages/streetslist/streetslist.module').then(m => m.streetsListPageModule),
	},
	{
		path: 'streetsadd',
		loadChildren: () => import('./pages/streetsadd/streetsadd.module').then(m => m.streetsAddPageModule),
	},
	{
		path: 'streetsedit/:locations_street_id',
		loadChildren: () => import('./pages/streetsedit/streetsedit.module').then(m => m.streetsEditPageModule),
	},
	{
		path: 'streetsview/:locations_street_id',
		loadChildren: () => import('./pages/streetsview/streetsview.module').then(m => m.streetsViewPageModule),
	},
	{
		path: 'taif',
		loadChildren: () => import('./pages/taif/taif.module').then(m => m.taifPageModule),
	},
	{
		path: 'termsandconditions',
		loadChildren: () => import('./pages/termsandconditions/termsandconditions.module').then(m => m.termsandconditionsPageModule),
	},
	{
		path: 'user_level_permissionslist',
		loadChildren: () => import('./pages/user_level_permissionslist/user_level_permissionslist.module').then(m => m.user_level_permissionsListPageModule),
	},
	{
		path: 'user_levelslist',
		loadChildren: () => import('./pages/user_levelslist/user_levelslist.module').then(m => m.user_levelsListPageModule),
	},
	{
		path: 'userslist',
		loadChildren: () => import('./pages/userslist/userslist.module').then(m => m.usersListPageModule),
	},
	{
		path: 'usersadd',
		loadChildren: () => import('./pages/usersadd/usersadd.module').then(m => m.usersAddPageModule),
	},
	{
		path: 'usersedit/:system_user_id',
		loadChildren: () => import('./pages/usersedit/usersedit.module').then(m => m.usersEditPageModule),
	},
	{
		path: 'usersview/:system_user_id',
		loadChildren: () => import('./pages/usersview/usersview.module').then(m => m.usersViewPageModule),
	},
	{
		path: 'wallet',
		loadChildren: () => import('./pages/wallet/wallet.module').then(m => m.walletPageModule),
	},
	{
		path: 'wishlistslist',
		loadChildren: () => import('./pages/wishlistslist/wishlistslist.module').then(m => m.wishlistsListPageModule),
	},
	{
		path: 'wishlistsadd',
		loadChildren: () => import('./pages/wishlistsadd/wishlistsadd.module').then(m => m.wishlistsAddPageModule),
	},
	{
		path: 'wishlistsedit/:wishlists_id',
		loadChildren: () => import('./pages/wishlistsedit/wishlistsedit.module').then(m => m.wishlistsEditPageModule),
	},
	{
		path: 'wishlistsview/:wishlists_id',
		loadChildren: () => import('./pages/wishlistsview/wishlistsview.module').then(m => m.wishlistsViewPageModule),
	},
	{
		path: 'zip_codeslist',
		loadChildren: () => import('./pages/zip_codeslist/zip_codeslist.module').then(m => m.zip_codesListPageModule),
	},
	{
		path: 'zip_codesadd',
		loadChildren: () => import('./pages/zip_codesadd/zip_codesadd.module').then(m => m.zip_codesAddPageModule),
	},
	{
		path: 'zip_codesedit/:locations_zip_code_id',
		loadChildren: () => import('./pages/zip_codesedit/zip_codesedit.module').then(m => m.zip_codesEditPageModule),
	},
	{
		path: 'zip_codesview/:locations_zip_code_id',
		loadChildren: () => import('./pages/zip_codesview/zip_codesview.module').then(m => m.zip_codesViewPageModule),
	},
	{
		path: 'properties_listslist',
		loadChildren: () => import('./pages/properties_listslist/properties_listslist.module').then(m => m.properties_listsListPageModule),
	},
	{
		path: 'properties_listslist/:properties_property_schema_segment_id',
		loadChildren: () => import('./pages/properties_listslist/properties_listslist.module').then(m => m.properties_listsListPageModule),
	},
	{
		path: 'properties_listslist/:properties_schema_id',
		loadChildren: () => import('./pages/properties_listslist/properties_listslist.module').then(m => m.properties_listsListPageModule),
	},
	{
		path: 'properties_listsview/:properties_property_id',
		loadChildren: () => import('./pages/properties_listsview/properties_listsview.module').then(m => m.properties_listsViewPageModule),
	},
	{
		path: 'properties_editslist',
		loadChildren: () => import('./pages/properties_editslist/properties_editslist.module').then(m => m.properties_editsListPageModule),
	},
	{
		path: 'properties_editsadd',
		loadChildren: () => import('./pages/properties_editsadd/properties_editsadd.module').then(m => m.properties_editsAddPageModule),
	},
	{
		path: 'properties_editsedit/:properties_property_id',
		loadChildren: () => import('./pages/properties_editsedit/properties_editsedit.module').then(m => m.properties_editsEditPageModule),
	},
	{
		path: 'properties_editsview/:properties_property_id',
		loadChildren: () => import('./pages/properties_editsview/properties_editsview.module').then(m => m.properties_editsViewPageModule),
	},
	{
		path: 'properties_linkedlist',
		loadChildren: () => import('./pages/properties_linkedlist/properties_linkedlist.module').then(m => m.properties_linkedListPageModule),
	},
	{
		path: 'properties_linkedview/:properties_property_id',
		loadChildren: () => import('./pages/properties_linkedview/properties_linkedview.module').then(m => m.properties_linkedViewPageModule),
	},
	{
		path: 'newslist',
		loadChildren: () => import('./pages/newslist/newslist.module').then(m => m.newsListPageModule),
	},
	{
		path: 'newsview/:properties_news_article_id',
		loadChildren: () => import('./pages/newsview/newsview.module').then(m => m.newsViewPageModule),
	},
	{
		path: 'invoice',
		loadChildren: () => import('./pages/invoice/invoice.module').then(m => m.invoicePageModule),
	},
	{
		path: 'check_out',
		loadChildren: () => import('./pages/check_out/check_out.module').then(m => m.check_outPageModule),
	},
	{
		path: 'offers_priceslist',
		loadChildren: () => import('./pages/offers_priceslist/offers_priceslist.module').then(m => m.offers_pricesListPageModule),
	},
	{
		path: 'offers_pricesadd',
		loadChildren: () => import('./pages/offers_pricesadd/offers_pricesadd.module').then(m => m.offers_pricesAddPageModule),
	},
	{
		path: 'offers_pricesedit/:properties_price_offer_id',
		loadChildren: () => import('./pages/offers_pricesedit/offers_pricesedit.module').then(m => m.offers_pricesEditPageModule),
	},
	{
		path: 'offers_pricesview/:properties_price_offer_id',
		loadChildren: () => import('./pages/offers_pricesview/offers_pricesview.module').then(m => m.offers_pricesViewPageModule),
	},
	{
		path: 'ticketslist',
		loadChildren: () => import('./pages/ticketslist/ticketslist.module').then(m => m.ticketsListPageModule),
	},
	{
		path: 'ticketsadd',
		loadChildren: () => import('./pages/ticketsadd/ticketsadd.module').then(m => m.ticketsAddPageModule),
	},
	{
		path: 'ticketsview/:crm_ticket_id',
		loadChildren: () => import('./pages/ticketsview/ticketsview.module').then(m => m.ticketsViewPageModule),
	},
	{
		path: 'earnests_logslist',
		loadChildren: () => import('./pages/earnests_logslist/earnests_logslist.module').then(m => m.earnests_logsListPageModule),
	},
	{
		path: 'property_historylist',
		loadChildren: () => import('./pages/property_historylist/property_historylist.module').then(m => m.property_historyListPageModule),
	},
	{
		path: 'ratingslist',
		loadChildren: () => import('./pages/ratingslist/ratingslist.module').then(m => m.ratingsListPageModule),
	},
	{
		path: 'ratingsadd',
		loadChildren: () => import('./pages/ratingsadd/ratingsadd.module').then(m => m.ratingsAddPageModule),
	},
	{
		path: 'ratingsedit/:crm_rating_id',
		loadChildren: () => import('./pages/ratingsedit/ratingsedit.module').then(m => m.ratingsEditPageModule),
	},
	{
		path: 'ratingsview/:crm_rating_id',
		loadChildren: () => import('./pages/ratingsview/ratingsview.module').then(m => m.ratingsViewPageModule),
	},
	{
		path: 'clients_viewslist',
		loadChildren: () => import('./pages/clients_viewslist/clients_viewslist.module').then(m => m.clients_viewsListPageModule),
	},
	{
		path: 'clients_viewsadd',
		loadChildren: () => import('./pages/clients_viewsadd/clients_viewsadd.module').then(m => m.clients_viewsAddPageModule),
	},
	{
		path: 'clients_viewsedit/:crm_client_view_id',
		loadChildren: () => import('./pages/clients_viewsedit/clients_viewsedit.module').then(m => m.clients_viewsEditPageModule),
	},
	{
		path: 'invests_logslist',
		loadChildren: () => import('./pages/invests_logslist/invests_logslist.module').then(m => m.invests_logsListPageModule),
	},
	{
		path: 'invests_logsadd',
		loadChildren: () => import('./pages/invests_logsadd/invests_logsadd.module').then(m => m.invests_logsAddPageModule),
	},
	{
		path: 'invests_logsedit/:properties_invest_id',
		loadChildren: () => import('./pages/invests_logsedit/invests_logsedit.module').then(m => m.invests_logsEditPageModule),
	},
	{
		path: 'invests_logsview/:properties_invest_id',
		loadChildren: () => import('./pages/invests_logsview/invests_logsview.module').then(m => m.invests_logsViewPageModule),
	},
	{
		path: 'rents_logslist',
		loadChildren: () => import('./pages/rents_logslist/rents_logslist.module').then(m => m.rents_logsListPageModule),
	},
	{
		path: 'rents_logsadd',
		loadChildren: () => import('./pages/rents_logsadd/rents_logsadd.module').then(m => m.rents_logsAddPageModule),
	},
	{
		path: 'rents_logsedit/:properties_rent_id',
		loadChildren: () => import('./pages/rents_logsedit/rents_logsedit.module').then(m => m.rents_logsEditPageModule),
	},
	{
		path: 'clients_viewsview/:crm_client_view_id',
		loadChildren: () => import('./pages/clients_viewsview/clients_viewsview.module').then(m => m.clients_viewsViewPageModule),
	},
	{
		path: 'paymentslist',
		loadChildren: () => import('./pages/paymentslist/paymentslist.module').then(m => m.paymentsListPageModule),
	},
	{
		path: 'paymentsadd',
		loadChildren: () => import('./pages/paymentsadd/paymentsadd.module').then(m => m.paymentsAddPageModule),
	},
	{
		path: 'paymentsedit/:system_payment_info_id',
		loadChildren: () => import('./pages/paymentsedit/paymentsedit.module').then(m => m.paymentsEditPageModule),
	},
	{
		path: 'paymentsview/:system_payment_info_id',
		loadChildren: () => import('./pages/paymentsview/paymentsview.module').then(m => m.paymentsViewPageModule),
	},
	{
		path: 'financial_supportslist',
		loadChildren: () => import('./pages/financial_supportslist/financial_supportslist.module').then(m => m.financial_supportsListPageModule),
	},
	{
		path: 'financial_supportsadd',
		loadChildren: () => import('./pages/financial_supportsadd/financial_supportsadd.module').then(m => m.financial_supportsAddPageModule),
	},
	{
		path: 'financial_supportsedit/:financial_support_id',
		loadChildren: () => import('./pages/financial_supportsedit/financial_supportsedit.module').then(m => m.financial_supportsEditPageModule),
	},
	{
		path: 'financial_supportsview/:financial_support_id',
		loadChildren: () => import('./pages/financial_supportsview/financial_supportsview.module').then(m => m.financial_supportsViewPageModule),
	},
	{
		path: 'sell_orderslist',
		loadChildren: () => import('./pages/sell_orderslist/sell_orderslist.module').then(m => m.sell_ordersListPageModule),
	},
	{
		path: 'sell_ordersadd',
		loadChildren: () => import('./pages/sell_ordersadd/sell_ordersadd.module').then(m => m.sell_ordersAddPageModule),
	},
	{
		path: 'sell_ordersview/:inventory_sell_orders_details_id',
		loadChildren: () => import('./pages/sell_ordersview/sell_ordersview.module').then(m => m.sell_ordersViewPageModule),
	},
	{
		path: 'sell_orders_detailslist',
		loadChildren: () => import('./pages/sell_orders_detailslist/sell_orders_detailslist.module').then(m => m.sell_orders_detailsListPageModule),
	},
	{
		path: 'sell_orders_detailslist/:inventory_sell_orders_details_sell_orders_id',
		loadChildren: () => import('./pages/sell_orders_detailslist/sell_orders_detailslist.module').then(m => m.sell_orders_detailsListPageModule),
	},
	{
		path: 'sell_orders_detailsadd',
		loadChildren: () => import('./pages/sell_orders_detailsadd/sell_orders_detailsadd.module').then(m => m.sell_orders_detailsAddPageModule),
	},
	{
		path: 'sell_orders_detailsview/:inventory_sell_orders_details_id',
		loadChildren: () => import('./pages/sell_orders_detailsview/sell_orders_detailsview.module').then(m => m.sell_orders_detailsViewPageModule),
	},
	{
		path: 'welcome',
		loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.welcomePageModule),
	},
	{
		path: 'my_operations',
		loadChildren: () => import('./pages/my_operations/my_operations.module').then(m => m.my_operationsPageModule),
	},
	{
		path: 'mediation_contractslist',
		loadChildren: () => import('./pages/mediation_contractslist/mediation_contractslist.module').then(m => m.mediation_contractsListPageModule),
	},
	{
		path: 'mediation_contractsadd',
		loadChildren: () => import('./pages/mediation_contractsadd/mediation_contractsadd.module').then(m => m.mediation_contractsAddPageModule),
	},
	{
		path: 'mediation_contractsedit/:mediation_contract_id',
		loadChildren: () => import('./pages/mediation_contractsedit/mediation_contractsedit.module').then(m => m.mediation_contractsEditPageModule),
	},
	{
		path: 'mediation_contractsview/:mediation_contract_id',
		loadChildren: () => import('./pages/mediation_contractsview/mediation_contractsview.module').then(m => m.mediation_contractsViewPageModule),
	},
	{
		path: 'roomslist',
		loadChildren: () => import('./pages/roomslist/roomslist.module').then(m => m.roomsListPageModule),
	},
	{
		path: 'roomsadd',
		loadChildren: () => import('./pages/roomsadd/roomsadd.module').then(m => m.roomsAddPageModule),
	},
	{
		path: 'roomsedit/:properties_room_id',
		loadChildren: () => import('./pages/roomsedit/roomsedit.module').then(m => m.roomsEditPageModule),
	},
	{
		path: 'roomsview/:properties_room_id',
		loadChildren: () => import('./pages/roomsview/roomsview.module').then(m => m.roomsViewPageModule),
	},
	{
		path: 'properties_schemas_segments_editlist',
		loadChildren: () => import('./pages/properties_schemas_segments_editlist/properties_schemas_segments_editlist.module').then(m => m.properties_schemas_segments_editListPageModule),
	},
	{
		path: 'properties_schemas_segments_editadd',
		loadChildren: () => import('./pages/properties_schemas_segments_editadd/properties_schemas_segments_editadd.module').then(m => m.properties_schemas_segments_editAddPageModule),
	},
	{
		path: 'properties_schemas_segments_editedit/:properties_property_schema_segment_id',
		loadChildren: () => import('./pages/properties_schemas_segments_editedit/properties_schemas_segments_editedit.module').then(m => m.properties_schemas_segments_editEditPageModule),
	},
	{
		path: 'properties_schemas_segments_editview/:properties_property_schema_segment_id',
		loadChildren: () => import('./pages/properties_schemas_segments_editview/properties_schemas_segments_editview.module').then(m => m.properties_schemas_segments_editViewPageModule),
	},
	{
		path: 'properties_schemas_editlist',
		loadChildren: () => import('./pages/properties_schemas_editlist/properties_schemas_editlist.module').then(m => m.properties_schemas_editListPageModule),
	},
	{
		path: 'properties_schemas_editadd',
		loadChildren: () => import('./pages/properties_schemas_editadd/properties_schemas_editadd.module').then(m => m.properties_schemas_editAddPageModule),
	},
	{
		path: 'properties_schemas_editedit/:properties_property_schema_id',
		loadChildren: () => import('./pages/properties_schemas_editedit/properties_schemas_editedit.module').then(m => m.properties_schemas_editEditPageModule),
	},
	{
		path: 'properties_schemas_editview/:properties_property_schema_id',
		loadChildren: () => import('./pages/properties_schemas_editview/properties_schemas_editview.module').then(m => m.properties_schemas_editViewPageModule),
	},
	{
		path: 'control_operationslist',
		loadChildren: () => import('./pages/control_operationslist/control_operationslist.module').then(m => m.control_operationsListPageModule),
	},
	{
		path: 'control_operationsedit/:properties_property_id',
		loadChildren: () => import('./pages/control_operationsedit/control_operationsedit.module').then(m => m.control_operationsEditPageModule),
	},
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
	},
	{
		path: 'signup',
		loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule),
	},
	{
		path: 'settings',
		loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
	},
	{
		path: 'Details',
		children: [
		  {
			path: '',
			pathMatch: 'full',
			component: properties_listsViewPage,
			resolve: {
			  properties_property_id: PropertyIdResolver
			}
		  },
		  {
			  path: 'properties_listsview/:properties_property_id',
			  loadChildren: () => import('./pages/properties_listsview/properties_listsview.module').then(m => m.properties_listsViewPageModule),
		  }
		],
	},
	{
		path: '',
		redirectTo: 'properties_listslist',
		pathMatch: 'full'
	}
];
@NgModule({
	imports: [RouterModule.forRoot(routes, {
		enableTracing: false,
		onSameUrlNavigation: 'reload', // Set to 'reload' or 'ignore' as needed
		useHash: true, // Set to true to use hash routing, false for path routing
	  })],
	exports: [RouterModule]
  })
  
export class AppRoutingModule {}