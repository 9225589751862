import { Injectable } from '@angular/core';
import { HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Mode } from '@ionic/core';
import { CameraOptions } from '@ionic-native/camera/ngx';
import { NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';

// Class DbApp
@Injectable({
	providedIn: 'root'
})
export class DbApp {
	id: string = "proptech"; // App ID
	multiLanguage: any = true; // Multi-Language
	languages: any = {"ar":{"file":"ar.json","description":"العربية"},"bs":{"file":"bs.json","description":"Bosanski"},"en":{"file":"en.json","description":"English"},"fr":{"file":"fr.json","description":"français"},"de":{"file":"de.json","description":"Deutsch"},"zh":{"file":"zh.json","description":"繁體中文"},"ru":{"file":"ru.json","description":"русский"},"tr":{"file":"tr.json","description":"Türkçe"}}; // Language list
	defaultLanguage: string = "ar"; // Default language

	// Folders
	imageFolder: string = "assets/img/";
	mediaFolder: string = "assets/media/";
	fileFolder: string = "assets/files/";

	// Thumbnail width/height
	thumbnailWidth: number = 80;
	thumbnailHeight: number = 80;
	thumbnailWidthView: number = 0;
	thumbnailHeightView: number = 400;

	// API
	requiredApiVersion: string = "^4.0.0 || ^16.0.15";
	apiAuthHeader = "X-Authorization";
	timeout: number = 0;
	useSecurity = true;
	multipleOptionSeparator: string = ","; // Seperator for splitting dbvalue
	optionSeparator: string = ", "; // Separator for displaying multiple options
	autoSuggestPageSize: number = 20;

	// Master/Detail
	TABLE_MASTER: string = "master";

	// Tables as Array([tablename, tablevar])
	tables: any[] = [["add_appointments","add_appointments"],
		["add_property_1","add_property_1"],
		["add_property_confirmation","add_property_confirmation"],
		["appointment_confirmation","appointment_confirmation"],
		["auction_disclaimers","auction_disclaimers"],
		["auctions_logs","auctions_logs"],
		["browse","browse"],
		["buy","buy"],
		["cities","cities"],
		["clientsrequests","clientsrequests"],
		["countries","countries"],
		["files_media_panos","files_media_panos"],
		["files_media_photos","files_media_photos"],
		["files_media_videos","files_media_videos"],
		["finance_mortgage","finance_mortgage"],
		["investment","investment"],
		["messages","messages"],
		["my_properties","my_properties"],
		["nearby_services","nearby_services"],
		["nearby_services_types","nearby_services_types"],
		["neighborhoods","neighborhoods"],
		["neom","neom"],
		["notifications","notifications"],
		["other_real_estate_services","other_real_estate_services"],
		["other_services","other_services"],
		["payment","payment"],
		["profiles","profiles"],
		["profiles_public","profiles_public"],
		["properties_schemas","properties_schemas"],
		["properties_schemas_segments","properties_schemas_segments"],
		["regions","regions"],
		["rent","rent"],
		["request_confirmation","request_confirmation"],
		["request_property_confirmation","request_property_confirmation"],
		["sell","sell"],
		["services","services"],
		["services_prices","services_prices"],
		["streets","streets"],
		["taif","taif"],
		["termsandconditions","termsandconditions"],
		["user_level_permissions","user_level_permissions"],
		["user_levels","user_levels"],
		["users","users"],
		["wallet","wallet"],
		["wishlists","wishlists"],
		["zip_codes","zip_codes"],
		["properties_lists","properties_lists"],
		["properties_edits","properties_edits"],
		["news","news"],
		["invoice","invoice"],
		["check_out","check_out"],
		["offers_prices","offers_prices"],
		["tickets","tickets"],
		["tickets_responds","tickets_responds"],
		["earnests_logs","earnests_logs"],
		["property_history","property_history"],
		["ratings","ratings"],
		["clients_views","clients_views"],
		["invests_logs","invests_logs"],
		["rents_logs","rents_logs"],
		["payments","payments"],
		["financial_supports","financial_supports"],
		["sell_orders","sell_orders"],
		["sell_orders_details","sell_orders_details"],
		["my_operations","my_operations"],
		["mediation_contracts","mediation_contracts"],
		["rooms","rooms"],
		["properties_schemas_segments_edit","properties_schemas_segments_edit"],
		["properties_schemas_edit","properties_schemas_edit"],
		["control_operations","control_operations"],
		["accounts","accounts"],
		["journals","journals"],
		["journals_details","journals_details"],
		["receipts_vouchers","receipts_vouchers"],
		["live_streams","live_streams"]];

	// Permission
	permission: any = {
		ADD: 1,
		DELETE: 2,
		EDIT: 4,
		LIST: 8,
		VIEW: 32,
		SEARCH: 64,
		LOOKUP: 256,
	};

	// Default permissions
	permissions: any = {};

	// User ID permissions
	userIdPermissions: any = {
		"users": 0
	};
	USER_ID_ALLOW: number = this.permission.LIST + this.permission.VIEW + this.permission.SEARCH;

	// Menu options
	menuItems: any = [
		{
			"id": 119,
			"name": "mi_profiles",
			"title": "__menu._119",
			"url": "profileslist",
			"selected": false,
			"parentId": -1,
			"tableName": "profiles",
			"security": "this.user.permissions.list.profiles",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "person-circle-outline"
		},
		{
			"id": 114,
			"name": "mi_wallet",
			"title": "__menu._114",
			"url": "wallet",
			"selected": false,
			"parentId": -1,
			"tableName": "wallet",
			"security": "this.user.permissions.list.wallet",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "wallet-outline"
		},
		{
			"id": 144,
			"name": "mi_my_operations",
			"title": "__menu._144",
			"url": "my_operations",
			"selected": false,
			"parentId": -1,
			"tableName": "my_operations",
			"security": "this.user.permissions.list.my_operations",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "operations"
		},
		{
			"id": 12,
			"name": "mi_my_properties",
			"title": "__menu._12",
			"url": "my_propertieslist",
			"selected": false,
			"parentId": -1,
			"tableName": "my_properties",
			"security": "this.user.permissions.list.my_properties",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "reader-outline"
		},
		{
			"id": 13,
			"name": "mi_clientsrequests",
			"title": "__menu._13",
			"url": "clientsrequestslist",
			"selected": false,
			"parentId": -1,
			"tableName": "clientsrequests",
			"security": "this.user.permissions.list.clientsrequests",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "file-tray-full-outline"
		},
		{
			"id": 54,
			"name": "mi_notifications",
			"title": "__menu._54",
			"url": "notificationslist",
			"selected": false,
			"parentId": -1,
			"tableName": "notifications",
			"security": "this.user.permissions.list.notifications",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "notifications-outline"
		},
		{
			"id": 55,
			"name": "mi_messages",
			"title": "__menu._55",
			"url": "messageslist",
			"selected": false,
			"parentId": -1,
			"tableName": "messages",
			"security": "this.user.permissions.list.messages",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "mail-outline"
		},
		{
			"id": 24,
			"name": "mi_wishlists",
			"title": "__menu._24",
			"url": "wishlistslist",
			"selected": false,
			"parentId": -1,
			"tableName": "wishlists",
			"security": "this.user.permissions.add.wishlists",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "heart-outline"
		},
		{
			"id": 121,
			"name": "mi_properties_lists",
			"title": "__menu._121",
			"url": "properties_listslist",
			"selected": true,
			"parentId": -1,
			"tableName": "properties_lists",
			"security": "this.user.permissions.list.properties_lists",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "home-outline"
		},
		{
			"id": 117,
			"name": "mi_properties_schemas",
			"title": "__menu._117",
			"url": "properties_schemaslist",
			"selected": false,
			"parentId": -1,
			"tableName": "properties_schemas",
			"security": "this.user.permissions.list.properties_schemas",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "scan-outline"
		},
		// {
		// 	"id": 131,
		// 	"name": "mi_profiles_public",
		// 	"title": "__menu._131",
		// 	"url": "profiles_publiclist",
		// 	"selected": false,
		// 	"parentId": -1,
		// 	"tableName": "profiles_public",
		// 	"security": "this.user.permissions.list.profiles_public",
		// 	"allowed": false,
		// 	"group": false,
		// 	"custom": false,
		// 	"icon": "people-circle-outline"
		// },
		{
			"id": 25,
			"name": "mi_browse",
			"title": "__menu._25",
			"url": "browse",
			"selected": false,
			"parentId": -1,
			"tableName": "browse",
			"security": "this.user.permissions.list.browse",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "options-outline"
		},
		// {
		// 	"id": 900,
		// 	"name": "mi_live_streaming",
		// 	"title": "__menu._900",
		// 	"function": "liveStreaming()",
		// 	"selected": false,
		// 	"parentId": -1,
		// 	"security": "this.user.permissions.list.browse",
		// 	"allowed": false,
		// 	"group": false,
		// 	"custom": false,
		// 	"icon": "videocam-outline",
		// 	"badge": "soon"
		// },
		{
			"id": 42,
			"name": "mi_other_services",
			"title": "__menu._42",
			"url": "other_services",
			"selected": false,
			"parentId": -1,
			"tableName": "other_services",
			"security": "this.user.permissions.list.other_services",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "grid-outline"
		},
		{
			"id": 128,
			"name": "mi_tickets",
			"title": "__menu._128",
			"url": "ticketslist",
			"selected": false,
			"parentId": -1,
			"tableName": "tickets",
			"security": "this.user.permissions.list.tickets",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "headset-outline"
		},
		{
			"id": 11,
			"name": "mi_termsandconditions",
			"title": "__menu._11",
			"url": "termsandconditions",
			"selected": false,
			"parentId": -1,
			"tableName": "termsandconditions",
			"security": "this.user.permissions.list.termsandconditions",
			"allowed": false,
			"group": false,
			"custom": false,
			"icon": "alert-circle-outline"
		},
		{
			"id": 154,
			"name": "mi_control_operations",
			"title": "__menu._154",
			"url": "control_operationslist",
			"selected": false,
			"parentId": -1,
			"tableName": "control_operations",
			"security": "this.user.permissions.list.control_operations",
			"allowed": false,
			"group": true,
			"custom": true,
			"icon": ""
		},
		{
			"id": 153,
			"name": "mi_properties_schemas_edit",
			"title": "__menu._153",
			"url": "properties_schemas_editlist",
			"selected": false,
			"parentId": -1,
			"tableName": "properties_schemas_edit",
			"security": "this.user.permissions.list.properties_schemas_edit",
			"allowed": false,
			"group": true,
			"custom": false,
			"icon": ""
		},
		{
			"id": 152,
			"name": "mi_properties_schemas_segments_edit",
			"title": "__menu._152",
			"url": "properties_schemas_segments_editlist",
			"selected": false,
			"parentId": -1,
			"tableName": "properties_schemas_segments_edit",
			"security": "this.user.permissions.list.properties_schemas_segments_edit",
			"allowed": false,
			"group": true,
			"custom": false,
			"icon": ""
		},
		{
			"id": 17,
			"name": "mi_countries",
			"title": "__menu._17",
			"url": "countrieslist",
			"selected": false,
			"parentId": -1,
			"tableName": "countries",
			"security": "this.user.permissions.edit.countries",
			"allowed": false,
			"group": true,
			"custom": false,
			"icon": "countries"
		},
		{
			"id": 20,
			"name": "mi_regions",
			"title": "__menu._20",
			"url": "regionslist",
			"selected": false,
			"parentId": -1,
			"tableName": "regions",
			"security": "this.user.permissions.edit.regions",
			"allowed": false,
			"group": true,
			"custom": false,
			"icon": "regions"
		},
		{
			"id": 16,
			"name": "mi_cities",
			"title": "__menu._16",
			"url": "citieslist",
			"selected": false,
			"parentId": -1,
			"tableName": "cities",
			"security": "this.user.permissions.edit.cities",
			"allowed": false,
			"group": true,
			"custom": false,
			"icon": "cities"
		},
		{
			"id": 21,
			"name": "mi_neighborhoods",
			"title": "__menu._21",
			"url": "neighborhoodslist",
			"selected": false,
			"parentId": -1,
			"tableName": "neighborhoods",
			"security": "this.user.permissions.edit.neighborhoods",
			"allowed": false,
			"group": true,
			"custom": false,
			"icon": "neiborhoods"
		},
		{
			"id": 22,
			"name": "mi_zip_codes",
			"title": "__menu._22",
			"url": "zip_codeslist",
			"selected": false,
			"parentId": -1,
			"tableName": "zip_codes",
			"security": "this.user.permissions.edit.zip_codes",
			"allowed": false,
			"group": true,
			"custom": false,
			"icon": "zipcodes"
		},
		{
			"id": 19,
			"name": "mi_streets",
			"title": "__menu._19",
			"url": "streetslist",
			"selected": false,
			"parentId": -1,
			"tableName": "streets",
			"security": "this.user.permissions.edit.streets",
			"allowed": false,
			"group": true,
			"custom": false,
			"icon": "streets"
		}
	];

	// Icons
	icon: any = {
		menu: "menu-outline",
		map: "map-outline",
		sort: "reorder-two-outline",
		up: "arrow-up-outline",
		down: "arrow-down-outline",
		cloud: "cloud-upload-outline",
		checked: "checkmark",
		unchecked: "close",
		logout: "log-out",
		login: "log-in",
		signup: "person-add",
		settings: "settings",
		delete: "trash-outline",
		copy: "copy-outline",
		edit: "create-outline",
		text: "text",
		details: "ellipsis-vertical",
		pull: "arrow-dropdown",
		document: "document-outline",
		camera: "camera-outline",
		image: "image-outline",
		save: "dots",
		back: "chevron-back-outline",
		eye: "eye-outline",
		filetrayfull: "file-tray-full-outline",
		peoplecircle: "people-circle-outline",
		videocam: "videocam-outline",
		countries: "countries",
		regions: "regions",
		cities: "cities",
		neiborhoods: "neiborhoods",
		zipcodes: "zipcodes",
		streets: "streets",
		operations: "operations"
	};

	// Spinners
	spinner: any = {
		refreshing: "circles",
		loading: "bubbles"
	};

	// Colors
	color: any = {
		"primary": "primary",
		"secondary": "secondary",
		"tertiary": "tertiary",
		"success": "success",
		"warning": "warning",
		"danger": "danger",
		"light": "light",
		"medium": "medium",
		"dark": "dark",
		"true": "success",
		"false": "danger"
	};

	// Loading mode
	loadingMode: Mode = "ios";

	// Camera options (https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-camera/index.html#module_Camera.EncodingType)
	cameraOptions: CameraOptions = {
		quality: 100,
		saveToPhotoAlbum: false,
		correctOrientation: true,
		encodingType: 0 // 0 = JPEG, 1 = PNG
	};

	// Camera image file extension
	get cameraImageExtension() {
		switch (this.cameraOptions.encodingType) {
			case 0:
				return ".jpg";
			case 1:
				return ".png";
		}
	}
	geocoderOptions: NativeGeocoderOptions = {
		useLocale: true,
		maxResults: 5
	};

	// App settings (default)
	settings: any = {
		dark: "",
		aboutus: "https://www.alobaikan.net/",
		language: "ar", // Default language
		fontsize: 100
	};

	// Start page
	startPage: string = "properties_listslist";

	// Constructor
	constructor() {
		let p = 0;
		if (!this.useSecurity) // If no security, grant all permissions
			p = Number(Object.values(this.permission).reduce((acc: number, cur: number) => acc + cur, 0));
		this.tables.forEach(t => this.permissions[t[1]] = p); // Init permissions
	}

	// Helper functions
	isBoolean(val: any): boolean {
		return typeof val === 'boolean';
	}
	isString(val: any): boolean {
		return typeof val === 'string';
	}
	isNumber(val: any): boolean {
		return typeof val === 'number' && isFinite(val);
	}
	isFunction(val: any): boolean {
		return (typeof val === 'function') || Object.prototype.toString.apply(val) === '[object Function]';
	}
	isDefined(val: any): boolean {
		return typeof val !== 'undefined';
	}
	isUndefined(val: any): boolean {
		return typeof val === 'undefined';
	}
	isObject(val: any): boolean {
		return val && (typeof val === 'object' || this.isFunction(val));
	}
	isValue(val: any): boolean {
		return this.isObject(val) || this.isString(val) || this.isNumber(val) || this.isBoolean(val);
	}
	isEmpty(val: any): boolean {
		return !this.isValue(val) || this.isString(val) && val.trim() == "" || Array.isArray(val) && val.length == 0;
	}

	// Get file extension in lowercase
	getExtension(val: string): string {
		val = val.trim();
		if (val) {
			let i = val.lastIndexOf(".");
			if (i !== -1)
				return val.substr(i + 1).toLowerCase();
		}
		return "";
	}

	// Is video
	isVideo(val: string): boolean {
		val = val.trim();
		return /^https?\:\/\/(www\.youtube\.com|youtu\.be)\//i.test(val) || // YouTube URL
			/^https?\:\/\/(player\.)?vimeo\.com\//i.test(val) || // Vimeo URL
			/^https?\:\/\/(www\.)?dailymotion\.com\//i.test(val) || // Dailymotion URL
			/^[\w\-]{11}$/.test(val) || // Youtube video ID
			["mp4", "webm"].includes(this.getExtension(val)); // File
	}

	// Is audio
	isAudio(val: string): boolean {
		return ["mp3", "wav"].includes(this.getExtension(val));
	}

	// Is image
	isImage(val: string): boolean {
		return ["png", "jpg", "jpeg", "gif", "bmp"].includes(this.getExtension(val));
	}

	// Display value
	displayValue(row: any, sep?: string | string[]): string {
		sep = sep || ", ";
		return this.isObject(row) ? ["df", "df2", "df3", "df4"].reduce((val, fld, i) => {
			if (row[fld]) {
				if (i > 0) {
					if (typeof sep === "string")
						val += sep;
					else if (Array.isArray(sep))
						val += sep[i - 1] || "";
				}
				return val + row[fld];
			}
			return val;
		}, "") : "";
	}

	// HTML encode (replace &, ", <, >)
	htmlEncode(t: any): string {
		return (t) ? t.toString().replace(/&/g,"&amp;").replace(/"/g,"&quot;").replace(/</g,"&lt;").replace(/>/g,"&gt;") : "";
	}

	// Build HTML attributes
	htmlAttributes(attrs: any): string {
		return this.isObject(attrs) ? Object.keys(attrs).reduce((val, name) => {
			val += " " + name;
			let attr = attrs[name];
			if (String(attr) != "")
				val += '="' + this.htmlEncode(attr) + '"';
			return val;
		}, "") : "";
	}

	// Build HTML element
	htmlElement(tagname: string, attrs?: any, innerhtml?: string, endtag?: string): string {
		let html = "<" + tagname + this.htmlAttributes(attrs) + ">";
		if (innerhtml)
			html += innerhtml;
		if (typeof endtag === "undefined" || endtag)
			html += "</" + tagname + ">";
		return html;
	}

	// Escape regular expression chars
	escapeRegExChars(str: string): string {
		return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
	}

	// Convert data to number
	parseNumber(data, thouSep, decSep): number {
		if (this.isString(data) && (data.includes(thouSep) || data.includes(decSep))) {
			var regexBits = [], regex;
			if (thouSep)
				regexBits.push(this.escapeRegExChars(thouSep) + "(?=\\d)");
			regex = new RegExp("(?:" + regexBits.join("|") + ")", "g");
			if (decSep === ".")
				decSep = null;
			data = data.replace(regex, "");
			data = (decSep) ? data.replace(decSep, ".") : data;
		}
		if (this.isString(data) && data.trim() !== "")
			data = +data;
		if (!this.isNumber || !isFinite(data)) // Catch NaN and Infinity
			data = null;
		return data;
	}

	// Parse date
	parseDate(str: string): string {
		if (!str)
			return str;
		let m = str.match(/^(\d{4}[^d]\d{2}[^d]\d{2})T?(\d{2}[^d]\d{2}([^d]\d{2})?)(.+)/);
		return m ? m[1] + " " + m[2] : str;
	}

	// Random string
	get random(): string {
		return (new Date()).getTime().toString();
	}

	// Convert value to boolean
	getBool(value): boolean {
		return value && ["1", "y", "t", "true"].includes(value.toLowerCase());
	}

	// Convert data to FormData
	getFormData(data: any): FormData {
		let formData = new FormData();
		for (let k in data) {
			let v = data[k];
			if (Array.isArray(v) && v.some(f => f.blob)) { // File(s)
				let key = (v.length > 1) ? k + "[]" : k;
				for (let f of v) {
					if (f.blob)
						formData.append(key, f.blob, f.name);
				}
			} else {
				formData.set(k, this.isValue(v) ? v : "");
			}
		}
		return formData;
	}

	// Get User ID permission
	userIdAllow(table, pageId): boolean {
		if (table in this.userIdPermissions)
			return (this.userIdPermissions[table] & pageId) == pageId;
		return (this.USER_ID_ALLOW & pageId) == pageId;
	}

	// Title case
	titleCase(input): string {
		if (!input)
			return "";
		return input.toLocaleLowerCase().replace(/\b\w/g, first => first.toLocaleUpperCase());
	}

	// Parse JSON
	parseJson(json: string, reviver?: any): any {
		try {
			return JSON.parse(json, reviver);
		} catch (error) {
			return null;
		}
	}

	// Convert user values from string to array
	convertUserValues(values): any[] {
		values = this.parseJson(values) || [];
		return values.map(value => {
				let ar = value.map(v => v.replace(/&#44;/g, ",").replace(/&#124;/g, "|").replace(/&#61;/g, "="));
				return { lf: ar[0], name: ar[1] };
			});
	}

	/**
	 * Get options for HttpClient.post()
	 * Note: Must specify the return type as "object", not "any"
	 * Set "observe" of options as "body"
	 * See: https://angular.io/api/common/http/HttpClient
	 * @param {any} params Request paramters
	 * @param {any} reqOpts Request options
	 */
	getHttpOptions(params?: any, reqOpts?: any) {
		reqOpts = reqOpts || {
			params: new HttpParams(),
			observe: "body"
		};
		if (params) {
			reqOpts.params = new HttpParams();
			reqOpts.observe = "body";
			for (let k in params)
				reqOpts.params = reqOpts.params.set(k, params[k]);
		}
		return reqOpts;
	}

	// Create new file name
	createFileName(ext?: string) {
		return (new Date()).getTime() + (ext || this.cameraImageExtension);
	}

	// Is URL
	isUrl(value: any) {
		return String(value).trim().match(/https?:\/\//i);
	}

	formatTimestamp(timestamp: number): string {
		const date = new Date(timestamp);
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const seconds = date.getSeconds();
	  
		// Format the date and time
		const formattedDate = `${day}/${month}/${year}`;
		const formattedTime = `${hours}:${minutes}:${seconds}`;
	  
		return `${formattedDate} ${formattedTime}`;
	  }
}