import { OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavController, ModalController, AlertController, LoadingController, PopoverController, ActionSheetController, ToastController, IonSearchbar  } from '@ionic/angular';
import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, NgForm } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Observable, Subscription, BehaviorSubject, from, timer } from 'rxjs';
import { IonInfiniteScroll, Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { File, Entry, FileEntry } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FileUploadComponent, FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { UserData, DbRecord, DbFile, LocaleService, Settings, DbApp, DbAppValidators, History } from '../../providers';
import { environment } from '../../../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { profiles_public, profiles, clients_views, properties_lists, notifications } from '../../providers';
import { ratingsAddPage } from '../../pages/ratingsadd/ratingsadd';
import { messagesAddPage } from '../../pages/messagesadd/messagesadd';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { v4 as uuidv4 } from 'uuid';
import { EmailServiceFirstParty } from '../../providers/email.firstparty.service';
import { SMSServiceFirstParty } from '../../providers/sms.firstparty.service';
import { EmailServiceSecondParty } from '../../providers/email.secondparty.service';
import { SMSServiceSecondParty } from '../../providers/sms.secondparty.service';

// Component
@Component({
	selector: 'page-profiles_public-view',
	templateUrl: 'profiles_publicview.html',
	styleUrls: ['profiles_publicview.scss'],
	providers: [CallNumber, SocialSharing, EmailServiceFirstParty, SMSServiceFirstParty, EmailServiceSecondParty, SMSServiceSecondParty]
})
export class profiles_publicViewPage implements OnDestroy, OnInit {
	key: any;
	keyCount: number = 1;
	item: any;
	item$: BehaviorSubject<DbRecord>;
	currentItems: any;
	currentLinkedItems: any;
	pageId: string = "view";
	pageUrl: string = "profiles_publicview";
	loadingMessage: string;
	dataLoaded: boolean;
	loadingStatus = false;
	isLoggedIn: boolean;
	isAdmin: boolean;
	userId: string;
	permissions: any;
	userIdAllowed: boolean;
	uuidv4: string = uuidv4();
	viewsupervisor: string;
	public system_user_id: string;
	public property_supervisor_id: string;
	segmentViewProfile: string = 'business_profile';
	private saving: boolean;
	auctionCountdownFinished: boolean = false;
	profiles_linked_id: string;
	private navigationSubscription: Subscription;

	userIdFirstParty: string;
	userIdSecondParty: string;
	userEmailFirstParty: string;
	userMobileFirstParty: number;
	userFullNameFirstParty: string;
	smsQueueRecipientFirstParty: string;
	smsQueueContentFirstParty: string;
	fromEmailFirstParty: string;
	toEmailFirstParty: string;
	ccEmailFirstParty: string;
	bccEmailFirstParty: string;
	subjectFirstParty: string;
	emailContentFirstParty: string;
	formatFirstParty: string;
	charsetFirstParty: string;
	smtpSecureFirstParty: string;
	arAttachmentsFirstParty: any[];
	arImagesFirstParty: any[];
	arPropertiesFirstParty: any;
	brandName: string;
	userTitleFullNameFirstParty: string;
	transactionDetailsTitleFirstParty: string;
	transactionDetailsLinkFirstParty: string;
	instructionsTitleFirstParty: string;
	instructionsSubTitleFirstParty: string;
	instructionsContentFirstParty: string;
	termsConditionsContent: string;
	extra_brand_name: string;
	extra_transaction_details_title_first_party: string;
	extra_transaction_details_link_first_party: string;
	extra_instructions_title_first_party: string;
	extra_instructions_subtitle_first_party: string;
	extra_instructions_content_first_party: string;
	extra_terms_conditions_content: string;
	extra_locale: string;
	posted_offer_amount: number;
	userEmailSecondParty: string;
	userMobileSecondParty: number;
	smsQueueRecipientSecondParty: string;
	smsQueueContentSecondParty: string;
	fromEmailSecondParty: string;
	toEmailSecondParty: string;
	ccEmailSecondParty: string;
	bccEmailSecondParty: string;
	subjectSecondParty: string;
	emailContentSecondParty: string;
	formatSecondParty: string;
	charsetSecondParty: string;
	smtpSecureSecondParty: string;
	arAttachmentsSecondParty: any[];
	arImagesSecondParty: any[];
	arPropertiesSecondParty: any;
	userTitleFullNameSecondParty: string;
	userFullNameSecondParty: string
	transactionDetailsTitleSecondParty: string;
	transactionDetailsLinkSecondParty: string;
	instructionsTitleSecondParty: string;
	instructionsSubTitleSecondParty: string;
	instructionsContentSecondParty: string;
	extra_transaction_details_title_second_party: string;
	extra_transaction_details_link_second_party: string;
	extra_instructions_title_second_party: string;
	extra_instructions_subtitle_second_party: string;
	extra_instructions_content_second_party: string;
	// Constructor
	constructor(
		public dbapp: DbApp,
		public translate: TranslateService,
		public locale: LocaleService,
		public navController: NavController,
		public loadingController: LoadingController,
		public actionSheetController: ActionSheetController,
		public modalController: ModalController,
		public alertController: AlertController,
		public toastController: ToastController,
		public router: Router,
		private http: HttpClient,
		public formBuilder: FormBuilder,
		public user: UserData,
		public inAppBrowser: InAppBrowser,
		private activatedRoute: ActivatedRoute,
		public history: History,
		private camera: Camera,
		private file: File,
		private webview: WebView,
		private platform: Platform,
		private changeDetectorRef: ChangeDetectorRef,
		private filePath: FilePath,
		private fileChooser: FileChooser,
		private sanitizer: DomSanitizer,
		public items: profiles_public,
		private profilesLinkedItem: profiles_public,
		private profilesItem: profiles,
		public clients_viewsItems: clients_views,
		public propItems: properties_lists,
		private callNumber: CallNumber,
		private socialSharing: SocialSharing,
		public notificationItems: notifications,
		private emailServiceFirstParty: EmailServiceFirstParty,
		private smsServiceFirstParty: SMSServiceFirstParty,
		private emailServiceSecondParty: EmailServiceSecondParty,
		private smsServiceSecondParty: SMSServiceSecondParty,
		) {
		this.key = this.getPrimaryKey();
		this.item$ = new BehaviorSubject<DbRecord>(null);
		this.userIdAllowed = this.dbapp.userIdAllow("profiles_public", this.pageId);
		this.getUserData();
		this.propItems.currentPage = 1;
	}

	// OnInit
	ngOnInit() {
		this.navigationSubscription = this.router.events.subscribe(async (e: any) => {
		  if (e instanceof NavigationEnd && this.history.isCurrentPage(this.pageUrl)) { // Re-init component
			await this.init();
		  } else {
			}
		});
		this.key = this.getPrimaryKey();
		if (this.property_supervisor_id) {
		  this.init();
		//   this.getParams(); // Call getParams() when the component initializes if locations_neighborhood_id is truthy
		}
	}
	
	// OnDestroy
	ngOnDestroy() {
		if (this.navigationSubscription)
			this.navigationSubscription.unsubscribe(); // Clean up
	}

	// Init
	async init() {
		this.loadingMessage = await this.translate.get("LOADING").toPromise();
		if (this.dbapp.isObject(this.key)) {
			const loading = await this.loadingController.create({
				spinner: null,
				cssClass: 'global-loader',
				message: this.loadingMessage,
				showBackdrop: true,
			});
			await loading.present();
			await this.postViewCountData();
			try {
				let item = await this.items.query(Object.assign({ action: "view" }, this.key)); // Use "view" action to get the record
				if (this.dbapp.isObject(item)) {
					await this.items.lookup(item, this.pageId);
					this.item = await this.items.renderRow(item, this.pageId);
					this.item$.next(this.item);
					this.getProfilesLinked();

					// if(this.item['system_parent_user_id'].dbValue){
					// 	this.getProfilesLinked();
					// 	console.log('system_parent_user_id', this.item['system_parent_user_id'].dbValue)
					// }
				}
			} catch(err) {
				this.showError(err);
			} finally {
				this.loadingStatus = true;
				await loading.dismiss();
			}
		}
	}

	segmentViewProfileChanged(event){
		if(this.segmentViewProfile === 'advertisements'){
			this.getPropertiesLists();
		}
	}

	async getPropertiesLists() {
		this.loadingMessage = await this.translate.get("LOADING").toPromise();
		if (this.dbapp.isObject(this.key)) {
			const loading = await this.loadingController.create({
				spinner: null,
				cssClass: 'global-loader',
				message: this.loadingMessage,
				showBackdrop: true,
			});
			await loading.present();
			try {
				// Properties init
				let propItems = await this.propItems.query({ property_supervisor_id : this.activatedRoute.snapshot.paramMap.get("system_user_id") ? this.activatedRoute.snapshot.paramMap.get("system_user_id") : this.property_supervisor_id });
				if (propItems) {
					await this.propItems.lookup(propItems, 'list');
					this.currentItems = await Promise.all(propItems.map(async item => await this.propItems.renderRow(item, 'list')));
				} else {
					this.currentItems = [];
				}
			} catch(err) {
				this.showError(err);
			} finally {
				this.loadingStatus = true;
				await loading.dismiss();
			}
		}
	}
	/**
	 * Get query parameters
	 */
	// private getParams() {
	// 	let params = { property_supervisor_id: this.property_supervisor_id };
	// 	if (this.property_supervisor_id)
	// 		params = Object.assign(params);
	// 	return Object.assign(params);
	// }

	/**
	 * Get primary key
	 */
	getPrimaryKey(): any {
		let keys = {}, key;
		
		this.property_supervisor_id ? this.system_user_id = this.property_supervisor_id : this.system_user_id = this.activatedRoute.snapshot.paramMap.get("system_user_id");
		console.log('this.system_user_id', this.system_user_id, 'this.property_supervisor_id', this.property_supervisor_id);
		key = this.system_user_id;
		console.log('key', this.property_supervisor_id)
		if (!this.dbapp.isEmpty(key))
			keys["system_user_id"] = key;
		return (Object.keys(keys).length === this.keyCount) ? keys : false;
	}

	// Get user data
	getUserData() {
		this.user.isLoggedIn.subscribe(res => {
			this.isLoggedIn = res;
			this.isAdmin = this.user.isAdmin;
			this.userId = this.user.userId;
			this.permissions = this.user.permissions;
		});
	}

	/**
	 * Show message
	 */
	async showMessage(msg: string, header: string) {
		let values = await this.translate.get(["OK_BUTTON", header]).toPromise();
		const alert = await this.alertController.create({
			header: values[header],
			message: msg,
			buttons: [values.OK_BUTTON]
		});
		await alert.present();
	}

	/**
	 * Search for the proper items
	 */
	async getPropItems(ev?: any) {
		this.propItems.currentPage = 1; // Reset to the first page
		const loading = await this.loadingController.create({
			spinner: null,
			cssClass: 'global-loader',
			showBackdrop: true,
		});
		this.dataLoaded = false;
		await loading.present();
		try {
			let propItems = await this.propItems.query(this.getPropItems());
			if (propItems) {
				await this.propItems.lookup(propItems, 'list');
				this.currentItems = await Promise.all(propItems.map(async item => await this.propItems.renderRow(item, 'list')));
			}
		} catch(err) {
			await this.showError(err);
		} finally {
			this.loadingStatus = true;
			await loading.dismiss();
		}
	}

	/**
	 * Add items (Infinite scroll) News
	 */
	async addPropItems() {
		try {
			let propItems = await this.propItems.query(this.addPropItems());
			if (propItems) {
				await this.propItems.lookup(propItems, 'list');
				propItems = await Promise.all(propItems.map(async item => await this.propItems.renderRow(item, 'list')));
				for (let item of propItems)
					this.currentItems.push(item)
			}
		} catch(err) {
			await this.showError(err);
		}
	}
	
	/**
	 * Infinite scroll
	 */
	async loadData(event) {

		if (this.propItems.currentPage >= this.propItems.totalPage) {
			event.target.disabled = true;
			return;
		}
		this.propItems.currentPage++;
		await this.addPropItems();
		event.target.complete();
	}
	/**
	 * Show error
	 */
	async showError(err: any) {
		let msg = (err instanceof Error) ? err.message : err;
		return this.showMessage(msg, "ERROR");
	}

	/**
	 * Show success message
	 */
	async showSuccess(msg: string) {
		return this.showMessage(msg, "SUCCESS");
	}

	// Open URL
	openUrl(url: string, target?: string) {
		this.inAppBrowser.create(url, target);
	}

	async onCancelSupervisor() {
		await this.modalController.dismiss();
	  }
	  dismiss() {
		if (this.viewsupervisor == 'viewsupervisor') {
		  this.modalController.dismiss();
		} else {
		  this.modalController.dismiss();
		}
	  }
	
	  async getProfilesLinked() {
		this.loadingMessage = await this.translate.get("LOADING").toPromise();
		if (this.dbapp.isObject(this.key)) {
			const loading = await this.loadingController.create({
				spinner: null,
				cssClass: 'global-loader',
				message: this.loadingMessage,
				showBackdrop: true,
			});
			await loading.present();
			try {
				// Profiles init
				let profilesLinkedItem = await this.profilesLinkedItem.query({ system_parent_user_id : this.activatedRoute.snapshot.paramMap.get("system_user_id") ? this.activatedRoute.snapshot.paramMap.get("system_user_id") : this.property_supervisor_id });
				console.log("profilesLinkedItem", profilesLinkedItem)
				if (profilesLinkedItem) {
					await this.profilesLinkedItem.lookup(profilesLinkedItem, 'list');
					this.currentLinkedItems = await Promise.all(profilesLinkedItem.map(async item => await this.profilesLinkedItem.renderRow(item, 'list')));
				} else {
					this.currentLinkedItems = [];
				}
			} catch(err) {
				this.showError(err);
			} finally {
				this.loadingStatus = true;
				await loading.dismiss();
			}
		}
	}

	  async openModalAddRatings(userId: string) {
		  const modalAddRating = await this.modalController.create({
			component: ratingsAddPage,
			componentProps  : {openedAddRateModal: 'openedAddRateModal',ratingStatusId: 2, system_user_id: userId},
			initialBreakpoint: 0.8,
			cssClass: 'custom-modal',
			backdropDismiss: true,
		  });
		  modalAddRating.onDidDismiss().then(item => {
			modalAddRating.dismiss();
		  });
		  return await modalAddRating.present();
  
		}
	
		async openModalAddMessage(userId: string) {
			const modalAddMessage = await this.modalController.create({
			  component: messagesAddPage,
			  componentProps  : {openedAddRateModal: 'openedAddRateModal',ratingStatusId: 2, system_user_id: userId},
			  initialBreakpoint: 0.8,
			  cssClass: 'custom-modal',
			  backdropDismiss: true,
			});
			modalAddMessage.onDidDismiss().then(item => {
			  modalAddMessage.dismiss();
			});
			return await modalAddMessage.present();
	
		  }
  

	  
		  async launchDialer(agentMobileNB: string) {
			const agentMobileDialer = `tel:${encodeURIComponent(agentMobileNB)}`;
			window.location.href = agentMobileDialer;
		  }
	
		  async whatsappShare(agentFullName: string) {
			const agentMessage = this.loadingMessage = await this.translate.get("hello").toPromise() + ' ' + agentFullName;
			const whatsappLink = `whatsapp://send?text=${encodeURIComponent(agentMessage)}`;
			window.location.href = whatsappLink;
		  }
		
	async postViewCountData() {
		let value = await {
			crm_client_view_id: uuidv4(),
			crm_client_view_for_type_id: 2,
			crm_client_view_for: this.system_user_id,
			crm_client_view_by: this.userId,
			crm_client_view_datetime: "",
			crm_client_view_added_gregorian: "",
			crm_client_view_added_by: ""
			}, res;
		  try {
			res = await this.clients_viewsItems.add(value);
			  console.log(this.clients_viewsItems);
			} finally {
		  }
}

	  parseCloseAuctionDate(dateString: string): number {
		try {
		  const timestamp = Date.parse(dateString)/1000;
		  if (!isNaN(timestamp)) {
			return timestamp;
		  }
		  console.log('Date string:', dateString);

		} catch (error) {
		  console.error('Error parsing date:', error);
		}
		return 0; // Default fallback value
	  }

	  onFinish(event: any) {
		this.auctionCountdownFinished = true;
	  }
	  
	cancel() {
		this.modalController.dismiss();
	  }

	  async inviteToJoinTeam() {
		const header = 'invite_agent_to_join';
		let values = await this.translate.get(["confirm", "CANCEL_BUTTON", header ], { s: this.item['system_full_name'].value }).toPromise();
		const alert = await this.alertController.create({
		  header: values[header],
		  buttons: [
			{
			  text: values['CANCEL_BUTTON'],
			  role: 'cancel',
			  cssClass: 'secondary',
			  handler: () => {
				console.log('Cancel clicked');
			  }
			},
			{
			  text: values['confirm'],
			  handler: () => {
				this.inviteToJoinTeamConfirmed();
				console.log('We are Here');
			  }
			}
		  ]
		});
	  
		await alert.present();
	  }

	  async inviteToJoinTeamConfirmed(){
			this.saving = true;
			let key = { system_user_id: this.item['system_user_id'].dbValue };
			let value = { system_parent_user_id: this.userId }, res;
			try {
				res = await this.profilesItem.edit(key, value);
				console.log("system_parent_user_id", this.userId);
			} catch (err) {
		  	} finally {
				this.saving = false;
			}
			if (res && res.success) {
				if(this.userEmailFirstParty)
					this.sendEmailFirstParty()
				if(this.userEmailSecondParty)
					this.sendEmailSecondParty()
				if(this.userMobileFirstParty)
					this.sendSMSFirstParty()
				if(this.userMobileSecondParty)
					this.sendSMSSecondParty()
				if(this.userIdFirstParty){
					const value = {					  
						notifications_id: this.uuidv4,
						notifications_type_id: '5',
						// notifications_publish: this.durationPipe.transform(this.dbapp.formatTimestamp),
						notifications_source: this.userIdFirstParty,
						notifications_user_id: this.userIdSecondParty,
						notifications_seen: '0',
						notifications_title: await this.translate.get("new_offer_submitted_title", { s: 'this.properties_reference_code' }).toPromise(),
						notifications_body: await this.translate.get("new_offer_submitted_body", { p: this.userFullNameFirstParty, d: this.dbapp.formatTimestamp(Date.now()), s: this.posted_offer_amount, c: this.translate.instant("SAR") }).toPromise(),
						notifications_link: this.uuidv4,
						notifications_status_id: '1'
					};
					try {
					const res = await this.notificationItems.add(value);
						console.log("Add", this.notificationItems);
						} catch (err) {
					}
			} else if (res && !res.success && res.failureMessage) {
				if (environment.production) {
					let err = await this.translate.get("FAILED_TO_EDIT").toPromise();
					await this.showError(err);
				} else {
					await this.showError(res.failureMessage);
				}
			}
	  	}
	}
	  /** Start Notifications First Party Functions */
	  async sendEmailFirstParty() {
		this.fromEmailFirstParty = 'admin@toor.ooo';
		this.toEmailFirstParty = this.userEmailFirstParty;
		this.ccEmailFirstParty = '';
		this.bccEmailFirstParty = '';
		this.subjectFirstParty = await this.translate.get("your_bid_for_offer_done_email_subject_first_party", { s: 'this.properties_reference_code' }).toPromise();
		this.emailContentFirstParty = await this.translate.get("your_bid_for_offer_done_email_content_first_party", { s: this.posted_offer_amount, m: this.translate.instant("SAR"), f: 'this.properties_reference_code', v: 'this.properties_property_type_id', d: this.dbapp.formatTimestamp(Date.now()) }).toPromise();
		this.formatFirstParty = 'text';
		this.charsetFirstParty = 'UTF-8';
		this.smtpSecureFirstParty = 'smtp';
		this.arAttachmentsFirstParty = [];
		this.arImagesFirstParty = [];
		this.arPropertiesFirstParty = null;
		this.userTitleFullNameFirstParty = await this.translate.get("mr_mrs", { s: this.userFullNameFirstParty }).toPromise();
		this.transactionDetailsTitleFirstParty = await this.translate.get("see_offer_bid_details_first_party").toPromise();
		this.transactionDetailsLinkFirstParty = 'https://www.toor.ooo/offers_logsview/'+this.uuidv4;
		this.instructionsTitleFirstParty = await this.translate.get("offer_instructions_title_first_party").toPromise();
		this.instructionsSubTitleFirstParty = await this.translate.get("offer_instructions_subtitle_first_party").toPromise();
		this.instructionsContentFirstParty = await this.translate.get("offer_instructions_content_first_party").toPromise();
		this.brandName = await this.translate.get("toorprime").toPromise();
		this.termsConditionsContent = await this.translate.get("terms_conditions_for_email").toPromise();
		const emailHeaderClientFirstParty = {
			from_email_first_party: this.fromEmailFirstParty,
			to_email_first_party: this.toEmailFirstParty,
			cc_email_first_party: this.ccEmailFirstParty,
			bcc_email_first_party: this.bccEmailFirstParty,
			subject_first_party: this.subjectFirstParty,
			email_content_first_party: this.emailContentFirstParty,
			format_first_party: this.formatFirstParty,
			charset_first_party: this.charsetFirstParty,
			smtp_secure_first_party: this.smtpSecureFirstParty,
			ar_attachments_first_party: this.arAttachmentsFirstParty,
			ar_images_first_party: this.arImagesFirstParty,
			ar_properties_first_party:this.arPropertiesFirstParty,
			extra_userfullname_first_party: this.userTitleFullNameFirstParty,
			extra_transaction_details_title_first_party: this.transactionDetailsTitleFirstParty,
			extra_transaction_details_link_first_party: this.transactionDetailsLinkFirstParty,
			extra_instructions_title_first_party: this.instructionsTitleFirstParty,
			extra_instructions_subtitle_first_party: this.instructionsSubTitleFirstParty,
			extra_instructions_content_first_party: this.instructionsContentFirstParty,
			extra_brand_name: this.brandName,
			extra_terms_conditions_content: this.termsConditionsContent,
			extra_locale: this.locale.locale
		};
	  
		try {
		  const response = await this.emailServiceFirstParty.sendEmailHeaderFirstParty(
			this.fromEmailFirstParty,
			this.toEmailFirstParty,
			this.ccEmailFirstParty,
			this.bccEmailFirstParty,
			this.subjectFirstParty,
			this.emailContentFirstParty,
			this.formatFirstParty,
			this.charsetFirstParty,
			this.smtpSecureFirstParty,
			this.arAttachmentsFirstParty,
			this.arImagesFirstParty,
			this.arPropertiesFirstParty,
			this.userTitleFullNameFirstParty,
			this.transactionDetailsTitleFirstParty,
			this.transactionDetailsLinkFirstParty,
			this.instructionsTitleFirstParty,
			this.instructionsSubTitleFirstParty,
			this.instructionsContentFirstParty,
			this.brandName,
			this.termsConditionsContent,
			this.locale.locale
		  );
		  console.log('Email sent successfully', response);
		} catch (error) {
		  console.error('Failed to send Email', error);
		}
	  }
	  
		async sendSMSFirstParty() {
		  this.smsQueueRecipientFirstParty = '+966'+this.userMobileFirstParty;
		  this.smsQueueContentFirstParty = await this.translate.get("your_bid_for_offer_done_sms_content_first_party", { s: 'this.properties_reference_code' }).toPromise();
		  const msgHeaderClientFirstParty = {
			  smsQueueRecipientFirstParty: this.smsQueueRecipientFirstParty,
			  smsQueueContentFirstParty: this.smsQueueContentFirstParty
		  };
		  try {
			const response = await this.smsServiceFirstParty.sendMessageHeaderFirstParty(this.smsQueueRecipientFirstParty, this.smsQueueContentFirstParty);
			console.log('SMS sent successfully', response);
		  } catch (error) {
			console.error('Failed to send SMS', error);
		  }
		}
/** End Notifications First Party Functions */



/** Start Notifications Second Party Functions */
		async sendEmailSecondParty() {
		this.fromEmailSecondParty = 'admin@toor.ooo';
		this.toEmailSecondParty = this.userEmailSecondParty;
		this.ccEmailSecondParty = '';
		this.bccEmailSecondParty = '';
		this.subjectSecondParty = await this.translate.get("your_bid_for_offer_done_email_subject_second_party", { s: 'this.properties_reference_code' }).toPromise();
		this.emailContentSecondParty = await this.translate.get("your_bid_for_offer_done_email_content_second_party", { s: this.posted_offer_amount, m: this.translate.instant("riyal_saudi"), f: 'this.properties_reference_code', v: 'this.properties_property_type_id', d: this.dbapp.formatTimestamp(Date.now()) }).toPromise();
		this.formatSecondParty = 'text';
		this.charsetSecondParty = 'UTF-8';
		this.smtpSecureSecondParty = 'smtp';
		this.arAttachmentsSecondParty = [];
		this.arImagesSecondParty = [];
		this.arPropertiesSecondParty = null;
		this.userTitleFullNameSecondParty = await this.translate.get("mr_mrs", { s: this.userFullNameSecondParty }).toPromise();
		this.transactionDetailsTitleSecondParty = await this.translate.get("see_offer_bid_details_second_party").toPromise();
		this.transactionDetailsLinkSecondParty = 'https://www.toor.ooo/offers_logsview/'+this.uuidv4;
		this.instructionsTitleSecondParty = await this.translate.get("offer_instructions_title_second_party").toPromise();
		this.instructionsSubTitleSecondParty = await this.translate.get("offer_instructions_subtitle_second_party").toPromise();
		this.instructionsContentSecondParty = await this.translate.get("offer_instructions_content_second_party").toPromise();
		this.brandName = await this.translate.get("toorprime").toPromise();
		this.termsConditionsContent = await this.translate.get("terms_conditions_for_email").toPromise();
		const emailHeaderClientSecondParty = {
			from_email_second_party: this.fromEmailSecondParty,
			to_email_second_party: this.toEmailSecondParty,
			cc_email_second_party: this.ccEmailSecondParty,
			bcc_email_second_party: this.bccEmailSecondParty,
			subject_second_party: this.subjectSecondParty,
			email_content_second_party: this.emailContentSecondParty,
			format_second_party: this.formatSecondParty,
			charset_second_party: this.charsetSecondParty,
			smtp_secure_second_party: this.smtpSecureSecondParty,
			ar_attachments_second_party: this.arAttachmentsSecondParty,
			ar_images_second_party: this.arImagesSecondParty,
			ar_properties_second_party:this.arPropertiesSecondParty,
			extra_userfullname_second_party: this.userFullNameSecondParty,
			extra_transaction_details_title_second_party: this.transactionDetailsTitleSecondParty,
			extra_transaction_details_link_second_party: this.transactionDetailsLinkSecondParty,
			extra_instructions_title_second_party: this.instructionsTitleSecondParty,
			extra_instructions_subtitle_second_party: this.instructionsSubTitleSecondParty,
			extra_instructions_content_second_party: this.instructionsContentSecondParty,
			extra_brand_name: this.brandName,
			extra_terms_conditions_content: this.termsConditionsContent,
			extra_locale: this.locale.locale
		};

		try {
		const response = await this.emailServiceSecondParty.sendEmailHeaderSecondParty(
			this.fromEmailSecondParty,
			this.toEmailSecondParty,
			this.ccEmailSecondParty,
			this.bccEmailSecondParty,
			this.subjectSecondParty,
			this.emailContentSecondParty,
			this.formatSecondParty,
			this.charsetSecondParty,
			this.smtpSecureSecondParty,
			this.arAttachmentsSecondParty,
			this.arImagesSecondParty,
			this.arPropertiesSecondParty,
			this.userTitleFullNameSecondParty,
			this.transactionDetailsTitleSecondParty,
			this.transactionDetailsLinkSecondParty,
			this.instructionsTitleSecondParty,
			this.instructionsSubTitleSecondParty,
			this.instructionsContentSecondParty,
			this.brandName,
			this.termsConditionsContent,
			this.locale.locale
		);
		console.log('Email sent successfully', response);
		} catch (error) {
		console.error('Failed to send Email', error);
		}
		}

		async sendSMSSecondParty() {
		this.smsQueueRecipientSecondParty = '+966'+this.userMobileSecondParty;
		this.smsQueueContentSecondParty = await this.translate.get("your_bid_for_offer_done_sms_content_second_party", { s: 'this.properties_reference_code' }).toPromise();
		const msgHeaderClientSecondParty = {
			smsQueueRecipientSecondParty: this.smsQueueRecipientSecondParty,
			smsQueueContentSecondParty: this.smsQueueContentSecondParty
		};
		try {
		const response = await this.smsServiceSecondParty.sendMessageHeaderSecondParty(this.smsQueueRecipientSecondParty, this.smsQueueContentSecondParty);
		console.log('SMS sent successfully', response);
		} catch (error) {
		console.error('Failed to send SMS', error);
		}
	}
	/** End Notifications Second Party Functions */
}