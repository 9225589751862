import { OnDestroy, OnInit, ElementRef } from "@angular/core";
import {
  NavController,
  ModalController,
  AlertController,
  LoadingController,
  PopoverController,
  ActionSheetController,
  ToastController,
  IonSearchbar,
} from "@ionic/angular";
import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  Validators,
  NgForm,
} from "@angular/forms";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";
import { Observable, Subscription, BehaviorSubject, from, timer } from "rxjs";
import { IonInfiniteScroll, Platform } from "@ionic/angular";
import { InAppBrowser, InAppBrowserOptions } from "@ionic-native/in-app-browser/ngx";
import {
  Camera,
  CameraOptions,
  PictureSourceType,
} from "@ionic-native/camera/ngx";
import { File, Entry, FileEntry } from "@ionic-native/file/ngx";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { FilePath } from "@ionic-native/file-path/ngx";
import { FileChooser } from "@ionic-native/file-chooser/ngx";
import { TranslateService } from "@ngx-translate/core";
import { IonicSelectableComponent } from "ionic-selectable";
import {
  FileUploadComponent,
  FileUploadControl,
  FileUploadValidators,
} from "@iplab/ngx-file-upload";
import {
  UserData,
  DbRecord,
  DbFile,
  LocaleService,
  Settings,
  DbApp,
  DbAppValidators,
  History,
} from "../../providers";
import { environment } from "../../../environments/environment";
import { AgmCoreModule } from "@agm/core";
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { users, profiles_public, notifications, EmailService, SMSService, NafazService, RegaService  } from "../../providers";
import { Storage } from '@ionic/storage-angular';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { countriesListPage } from '../countrieslist/countrieslist';
import { v4 as uuidv4 } from "uuid";


// Constants
const GOOGLE_LOGIN_KEY = 'googleLoggedIn';
const FACEBOOK_LOGIN_KEY = 'facebookLoggedIn';
// Component
@Component({
  selector: "page-signup",
  templateUrl: "signup.html",
  styleUrls: ["./signup.scss"],
  providers: [CallNumber, RegaService],
})
export class SignupPage implements OnDestroy, OnInit {
  key: any;
  keyCount: number = 1;
  item: any;
  pageId: string = "signup";
  loadingMessage: string;
  formGroup: FormGroup;
	otpForm: FormGroup;
	nafazForm: FormGroup;
	regaForm: FormGroup;
	nafazCodeForm: FormGroup;
  mobileVerfyForm: FormGroup;
  emailVerfyForm: FormGroup;
	idVerfyForm: FormGroup;
	valVerfyForm: FormGroup;
  item$: BehaviorSubject<DbRecord>;
  currentItems: any;
	system_user_id: string;
  submitted: boolean;
  pageUrl: string = "signup";
	locations_country_id: string;
	locations_country_name: string;
	locations_country_phone_code: number = 966;
	locations_country_flag: string = 'sa.png';
  uuidv4: string = uuidv4();
  uuidv4notifications: string = uuidv4();
  system_identity_no_verfy: any;
	system_mobile_1_verfy: boolean = false;
	system_email_verfy: boolean = false;
  agent_registration: boolean = false;
  userUserName: string;
  userEmail: string;
  userMobile: number;
  userPadMobile: string;
  userIdentityNo: number;
  userPermitNo: number;
  userFullName: string;
  smsQueueRecipient: string;
  smsQueueContent: string;
  fromEmail: string;
  toEmail: string;
  ccEmail: string;
  bccEmail: string;
  subject: string;
  emailContent: string;
  format: string;
  charset: string;
  smtpSecure: string;
  arAttachments: any[];
  arImages: any[];
  arProperties: any;
  brandName: string;
  userTitleFullName: string;
  transactionDetailsTitle: string;
  transactionDetailsLink: string;
  instructionsTitle: string;
  instructionsSubTitle: string;
  instructionsContent: string;
  termsConditionsContent: string;
  extra_brand_name: string;
  extra_transaction_details_title: string;
  extra_transaction_details_link: string;
  extra_instructions_title: string;
  extra_instructions_subtitle: string;
  extra_instructions_content: string;
  extra_terms_conditions_content: string;
  extra_locale: string;
  segmentSignupSections: string = "mobileSection";
  otpVerificationFinished: boolean = false;
  otpSentMobile: boolean;
  otpSentEmail: boolean;
  otpSentID: boolean;
  otpSMSDelivered: boolean;
  otpEmailDelivered: boolean;
  otpIDDelivered: boolean;
  otpInput1: string;
  otpInput2: string;
  otpInput3: string;
  otpInput4: string;
  otp: string;
  otpMatched: boolean = false;
  resendingOTP: boolean = false;
  countdown = 60; // Reset countdown to initial value
  mobileNumberRequiredError: boolean = false;
  mobileNumberValidateError: boolean = false;
  mobileNumberAvailableError: boolean = false;
  userEmailRequiredError: boolean = false;
  userEmailValidateError: boolean = false;
  userEmailAvailableError: boolean = false;
  idNumberAvailableError: boolean = false;

  segmentAgentSignupSections: string = 'NotAgentSignup';

  @ViewChild("otp1") otp1: ElementRef<HTMLInputElement>;
  @ViewChild("otp2") otp2: ElementRef<HTMLInputElement>;
  @ViewChild("otp3") otp3: ElementRef<HTMLInputElement>;
  @ViewChild("otp4") otp4: ElementRef<HTMLInputElement>;

  private saving: boolean;
  private subscription: Subscription; // For AutoSuggest
  private currentPage: number; // For AutoSuggest
  private navigationSubscription: Subscription;
	sendNafazRequestApplied: boolean = false;
	nafazNationalId: boolean = true;
	nafazRandomCode: any;
	nafazRandomCodeModal: boolean = false;
	nafazRandomCodeError: string;
  
	RegaValIdForm: FormGroup;
	rega: any = { valId: null };
	regaValId: boolean = true;
	regaRandomCode: boolean = false;
  valIdAvailableError: boolean = false;
  valIdPatternError: boolean = false;
  nafazRandom: number = 24;
  regaAgentInfo: boolean = false;
  agentMobileName: string;
  agentMobileNB: number;

  // Constructor
  constructor(
    public dbapp: DbApp,
    public translate: TranslateService,
    public locale: LocaleService,
    public navController: NavController,
    public loadingController: LoadingController,
    public actionSheetController: ActionSheetController,
    public modalController: ModalController,
    public alertController: AlertController,
    public toastController: ToastController,
    public router: Router,
    public formBuilder: FormBuilder,
    public user: UserData,
    public inAppBrowser: InAppBrowser,
    private activatedRoute: ActivatedRoute,
    public history: History,
    private camera: Camera,
    private file: File,
    private webview: WebView,
    private platform: Platform,
    private changeDetectorRef: ChangeDetectorRef,
    private filePath: FilePath,
    private fileChooser: FileChooser,
    private sanitizer: DomSanitizer,
    public items: users,
    public profilesItems: profiles_public,
		public notificationItems: notifications,
		private googlePlus: GooglePlus,
		private facebook: Facebook,
		private storage: Storage,
    private emailService: EmailService,
    private smsService: SMSService,
    private nafazService: NafazService,
    private regaService: RegaService,
    private cdr: ChangeDetectorRef,
    private callNumber: CallNumber,
  ) {
    this.storage.create();
    this.item$ = new BehaviorSubject<DbRecord>(null);
    this.init();
  }

  // Get empty item
  getEmptyItem() {
    return {
      "system_user_id": this.uuidv4,
      "system_user_name": "",
      "system_password": "",
      "system_mobile_1": "",
      "system_email": "",
			"system_identity_no": "",
			"sellers_permit_nb": "",
			"system_confirm_mobile_1": "",
			"system_confirm_mobile_2": "",
			"system_confirm_email": "",
			"system_confirm_identity_no": "",
			"system_confirm_permit_nb": "",
      "c_system_password": "",
    };
  }

  // OnInit
  ngOnInit() {
    this.navigationSubscription = this.router.events.subscribe(
      async (e: any) => {
        if (
          e instanceof NavigationEnd &&
          this.history.isCurrentPage(this.pageUrl)
        ) {
			// this.segmentSignupSections = localStorage.getItem('segmentSignupSections') || "mobileSection";
			// this.otpVerificationFinished = localStorage.getItem('otpVerificationFinished') === 'true';
			// this.otpSentMobile = localStorage.getItem('otpSentMobile') === 'true';
			// this.otpSentEmail = localStorage.getItem('otpSentEmail') === 'true';
			// this.otpSMSDelivered = localStorage.getItem('otpSMSDelivered') === 'true';
			// this.otpEmailDelivered = localStorage.getItem('otpEmailDelivered') === 'true';
    this.otpVerificationFinished = false;
		  
			if (this.otpSMSDelivered || this.otpEmailDelivered || this.otpIDDelivered) {
			  this.countdown = 60;
			  this.startCountdown();
			}
      // this.otpForm.reset();
          // Initialize the otpForm FormGroup
          // this.otpForm = new FormGroup({
          //   otpInput1: new FormControl(this.otpInput1, Validators.required),
          //   otpInput2: new FormControl(this.otpInput2, Validators.required),
          //   otpInput3: new FormControl(this.otpInput3, Validators.required),
          //   otpInput4: new FormControl(this.otpInput4, Validators.required),
          // });
        }
      }
    );
  }

  // OnDestroy
  ngOnDestroy() {
    if (this.navigationSubscription) this.navigationSubscription.unsubscribe(); // Clean up
    localStorage.clear();
    this.otpForm.reset();
  }

  // Init
  async init() {
    this.formGroup = this.formBuilder.group(
      {
        system_user_id: [
          { value: "", disabled: false },
          [DbAppValidators.guid],
        ],
        system_user_name: [
          { value: "", disabled: false },
          [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+$')],
        ],
        system_password: [
          { value: "", disabled: false }, 
          [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/)
          ]],
        system_mobile_1: [{ value: "", disabled: false }, []],
        system_email: [{ value: "", disabled: false }, []],
        system_identity_no: [{ value: "", disabled: false }, []],
        sellers_permit_nb: [{ value: "", disabled: false }, []],
        system_confirm_mobile_1: [{ value: "", disabled: false }, []],
        system_confirm_mobile_2: [{ value: "", disabled: false }, []],
        system_confirm_email: [{ value: "", disabled: false }, []],
        system_confirm_identity_no: [{ value: "", disabled: false }, []],
        system_confirm_permit_nb: [{ value: "", disabled: false }, []],
        c_system_password: [
          { value: "", disabled: false },
          [Validators.required],
        ],
      },
      {
        validators: [
          DbAppValidators.mustMatch("system_password", "c_system_password"),
        ],
      }
    );
    
    this.otpForm = new FormGroup({
      otpInput1: new FormControl(this.otpInput1, Validators.required),
      otpInput2: new FormControl(this.otpInput2, Validators.required),
      otpInput3: new FormControl(this.otpInput3, Validators.required),
      otpInput4: new FormControl(this.otpInput4, Validators.required),
    });
    
    this.mobileVerfyForm = this.formBuilder.group({
      system_mobile_1_verfy: ['', {
      validators: [Validators.required],
      updateOn: 'blur' // Run validation when the input loses focus
      }],
    });
    
    this.emailVerfyForm = this.formBuilder.group({
      system_email_verfy: ['', {
      validators: [Validators.required],
      updateOn: 'blur' // Run validation when the input loses focus
      }],
    });

		this.nafazForm = this.formBuilder.group({
		  system_identity_no_verfy: ['', {
			validators: [Validators.required, Validators.pattern('^[123456]{1}\\d{9}$')],
			updateOn: 'blur' // Run validation when the input loses focus
		  }],
		});

    this.nafazCodeForm = this.formBuilder.group({
      nafazRandomCode: ['', {
      validators: [Validators.required],
      updateOn: 'blur' // Run validation when the input loses focus
      }],
    });

		this.regaForm = this.formBuilder.group({
		  sellers_permit_nb_verfy: ['', {
			validators: [Validators.required, Validators.pattern('^[123456]{1}\\d{9}$')],
			updateOn: 'blur' // Run validation when the input loses focus
		  }],
		});

    await this.getItem();
  }

  // Get item
  async getItem() {
    this.loadingMessage = await this.translate.get("LOADING").toPromise();
    const loading = await this.loadingController.create({
			spinner: null,
			cssClass: 'global-loader',
			message: this.loadingMessage,
			showBackdrop: true,
    });
    await loading.present();
    let item = this.getEmptyItem();
    try {
      if (this.dbapp.isObject(item)) {
        await this.items.lookup(item, this.pageId);
        this.item = await this.items.renderRow(item, this.pageId);
        this.item$.next(this.item);
        this.formGroup.patchValue({
          system_user_id: this.item["system_user_id"].dbValue,
          system_user_name: this.item["system_user_name"].dbValue,
          system_password: this.item["system_password"].dbValue,
          system_mobile_1: this.item["system_mobile_1"].dbValue,
          system_email: this.item["system_email"].dbValue,
					system_identity_no: this.item['system_identity_no'].value,
					sellers_permit_nb: this.item['sellers_permit_nb'].value,
					system_confirm_mobile_1: this.item['system_confirm_mobile_1'].value,
					system_confirm_mobile_2: this.item['system_confirm_mobile_2'].value,
					system_confirm_email: this.item['system_confirm_email'].value,
					system_confirm_identity_no: this.item['system_confirm_identity_no'].value,
					system_confirm_permit_nb: this.item['system_confirm_permit_nb'].value,
          c_system_password: this.item["c_system_password"].value,
        });
      }
    } catch (err) {
      this.showError(err);
    } finally {
      await loading.dismiss();
    }
  }

  // let items = await this.wishlistsItems.query(this.getParams());

  // if (items) {
  //   await this.wishlistsItems.lookup(items, this.pageId);
  //   this.currentItems = await Promise.all(items.map(async item => await this.wishlistsItems.renderRow(item, this.pageId)));

  //   this.currentItems.forEach(item => {
  // 	this.wishlists_id = item.wishlists_id.value;
  // 	this.wishlists_product_id = item.wishlists_product_id.value
  // 	this.wishlists_user_id = item.wishlists_user_id.value
  // 	this.get_status = item.wishlists_status.value;
  //   });

  // try {
  // 	let item = await this.items.query(Object.assign({ action: "view" }, this.key)); // Use "view" action to get the record
  // 	if (this.dbapp.isObject(item)) {
  // 		await this.items.lookup(item, this.pageId);
  // 		this.item = await this.items.renderRow(item, this.pageId);
  // 		this.item$.next(this.item);
  // 		// this.loanAmount = this.item['properties_last_sale_price'].value;
  // 		// this.interestRate = this.item['property_commission_rate'].value;
  // 		// this.loanTerm = this.item['property_installments_period'].value;
  // 		// this.calculateMonthlyPayment();
  // 	}
  // } catch(err) {
  // 	this.showError(err);
  // } finally {
  // 	await loading.dismiss();
  // }
  
  /**
   * Register
   */
  async register() {
    this.submitted = true;
    if (!this.formGroup.valid) {
      let err = await this.translate.get("VALIDATION_ERROR").toPromise();
      if (err != "VALIDATION_ERROR") this.showError(err);
      return false;
    } else {
      this.userUserName = this.formGroup.get("system_user_name").value;
      this.userMobile = this.formGroup.get("system_mobile_1").value;
      this.userEmail = this.formGroup.get("system_email").value;
      
      if(this.mobileVerfyForm.get("system_mobile_1_verfy").value){
        this.formGroup.get('system_confirm_mobile_1').setValue("1");
      } else if(this.emailVerfyForm.get("system_email_verfy").value){
        this.formGroup.get('system_confirm_email').setValue("1");
      } else if(this.nafazForm.get("system_identity_no_verfy").value){
        this.formGroup.get('system_confirm_identity_no').setValue("1");
      } else if(this.regaForm.get("system_confirm_permit_nb_verfy").value){
        this.formGroup.get('system_confirm_permit_nb').setValue("1");
      }
      let value = await this.unformatValue(this.formGroup.value),
        res;
        console.log(value);
      this.saving = true;
      try {
        res = await this.items.register(value);
      } finally {
        this.otpForm.reset();
        this.saving = false;
      }
      if (res && res.success) {
        let msg = await this.translate.get("SIGNUP_SUCCESS").toPromise();
        this.showSuccess(msg);
        this.submitted = false;
        this.router.navigateByUrl("/login");
        this.modalController.dismiss();
        this.otpVerificationFinished = false;
        if (this.userEmail)
        this.sendEmail(this.userEmail);
        if(this.userMobile)
        this.userPadMobile = "+"+String(this.userMobile).padStart(12, this.locations_country_phone_code.toString());
        	this.sendSMS(this.userPadMobile);
        if(this.uuidv4){
            const value = {					  
              notifications_id: this.uuidv4notifications,
              notifications_type_id: '10',
              notifications_source: this.uuidv4,
              notifications_user_id: this.uuidv4,
              notifications_seen: '0',
              notifications_title: await this.translate
              .get("create_account_email_subject", { 
                s: this.userUserName 
              }).toPromise(),
              notifications_body:  await this.translate
              .get("create_account_email_content", {
                s: this.userUserName,
                d: this.dbapp.formatTimestamp(Date.now()),
              }),
              notifications_link: this.uuidv4,
              notifications_status_id: '1'
            };
            try {
            const res = await this.notificationItems.add(value);
              console.log("Add", this.notificationItems);
              } catch (err) {
            }
          }
          this.formGroup.reset();
      } else if (
        res &&
        !res.success &&
        res.failureMessage &&
        !environment.production
      ) {
        await this.showError(res.failureMessage);
      } else {
        let msg = await this.translate.get("username_exists").toPromise();
        await this.showError(msg);
      }
    }
  }

  // try {
  // 	let item = await this.items.query(Object.assign({ action: "view" }, this.key)); // Use "view" action to get the record
  // 	if (this.dbapp.isObject(item)) {
  // 		await this.items.lookup(item, this.pageId);
  // 		this.item = await this.items.renderRow(item, this.pageId);
  // 		this.item$.next(this.item);
  // 		// this.loanAmount = this.item['properties_last_sale_price'].value;
  // 		// this.interestRate = this.item['property_commission_rate'].value;
  // 		// this.loanTerm = this.item['property_installments_period'].value;
  // 		// this.calculateMonthlyPayment();
  // 	}
  /**
   * Get foreign key
   */
  async getForeignKey() {
    return (await this.user.get("users_foreignKey")) || {};
  }

  /**
   * Get primary key
   */
  getPrimaryKey(): any {
    let keys = {},
      key;
    key = this.activatedRoute.snapshot.paramMap.get("system_user_id");
    if (!this.dbapp.isEmpty(key)) keys["system_user_id"] = key;
    return Object.keys(keys).length === this.keyCount ? keys : false;
  }

  /**
   * Show message
   */
  async showMessage(msg: string, header: string) {
    let values = await this.translate.get(["OK_BUTTON", header]).toPromise();
    const alert = await this.alertController.create({
      header: values[header],
      message: msg,
      buttons: [values.OK_BUTTON],
    });
    await alert.present();
  }

  /**
   * Show Mobile Exists Error
   */
  async showMobileExistsError(err: any) {
    let msg = err instanceof Error ? err.message : err;
    return this.showMessage(msg, "showMobileExistsError");
  }

  /**
   * Show error
   */
  async showError(err: any) {
    let msg = err instanceof Error ? err.message : err;
    return this.showMessage(msg, "ERROR");
  }

  /**
   * Show success message
   */
  async showSuccess(msg: string) {
    return this.showMessage(msg, "SUCCESS");
  }

  /**
   * Filter (SELECT)
   */
  filter(items: any, text: string) {
    return items.filter((item) => item.name.toLowerCase().includes(text));
  }

  /**
   * On search fail
   */
  onSearchFail(event: { component: IonicSelectableComponent; text: string }) {
    if (event.component.addItemTemplate) {
      let name = (event.component as any)._element.nativeElement.getAttribute(
          "formControlName"
        ),
        fg = this.formGroup.get(name + "Option"),
        f = fg.get("displayField") || fg.get("linkField");
      if (f) f.setValue(event.text);
      event.component.showAddItemTemplate();
    }
  }

  /**
   * On search success
   */
  onSearchSuccess(event: {
    component: IonicSelectableComponent;
    text: string;
  }) {
    if (event.component.addItemTemplate) event.component.hideAddItemTemplate();
  }

  /**
   * Get AutoSuggest parameters (TEXT)
   */
  private getAutoSuggestParams(component: IonicSelectableComponent) {
    let fldname = (component as any)._element.nativeElement.dataset.field,
      params = {
        action: "lookup",
        ajax: "autosuggest",
        page: this.items.name + "_" + this.pageId,
        field: fldname,
        n: this.dbapp.autoSuggestPageSize,
        start: (this.currentPage - 1) * this.dbapp.autoSuggestPageSize,
      };
    return params;
  }

  /**
   * Get suggestions (TEXT)
   */
  async getSuggestions(event: {
    component: IonicSelectableComponent;
    text: string;
  }) {
    let component = event.component;
    let oldItems = component.hasValue()
      ? component.items.filter((item) =>
          (component as any)._valueItems.includes(
            item[component.itemValueField]
          )
        )
      : [];
    let text = event.text.trim();
    component.startSearch();

    // Close any running subscription.
    if (this.subscription) this.subscription.unsubscribe();
    if (!text) {
      // Close any running subscription
      if (this.subscription) this.subscription.unsubscribe();
      component.items = oldItems;
      component.endSearch();
      return;
    }
    this.currentPage = 1;
    let params = this.getAutoSuggestParams(component);
    params["q"] = text;
    this.subscription = from(this.items.query(params)).subscribe((items) => {
      // Subscription will be closed when unsubscribed manually
      if (this.subscription.closed) return;
      let fldvar = (component as any)._element.nativeElement.getAttribute(
        "formControlName"
      );
      items.forEach(
        (item) =>
          (item["name"] = this.dbapp.displayValue(
            item,
            this.items.displayValueSeparators[fldvar]
          ))
      );
      if (items.length) {
        for (let oldItem of oldItems) {
          // Add old items
          if (
            !items.some(
              (item) =>
                oldItem[component.itemValueField] ==
                item[component.itemValueField]
            )
          )
            items = [oldItem, ...items];
        }
        component.items = items;
        component.endSearch();

        // Infinite scroll
        if (items.totalRecordCount > params.start + items.length) {
          this.currentPage++;
          component.enableInfiniteScroll();
        } else {
          component.disableInfiniteScroll();
        }
      } else {
        component.items = oldItems;
        component.endSearch();
        if (text && component.addItemTemplate) {
          this.formGroup
            .get(fldvar + "Option")
            .get("displayField")
            .setValue(text);
          component.showAddItemTemplate();
        }
      }
    });
  }

  /**
   * Get more suggestions (TEXT)
   */
  getMoreSuggestions(event: {
    component: IonicSelectableComponent;
    text: string;
  }) {
    let params = this.getAutoSuggestParams(event.component);
    params["q"] = (event.text || "").trim();
    from(this.items.query(params)).subscribe((items) => {
      let fldvar = (event.component as any)._element.nativeElement.getAttribute(
        "formControlName"
      );
      items.forEach(
        (item) =>
          (item["name"] = this.dbapp.displayValue(
            item,
            this.items.displayValueSeparators[fldvar]
          ))
      );
      event.component.items = event.component.items.concat(items);
      event.component.endInfiniteScroll();

      // Infinite scroll
      if (items.totalRecordCount > params.start + items.length) {
        this.currentPage++;
        event.component.enableInfiniteScroll();
      } else {
        event.component.disableInfiniteScroll();
      }
    });
  }

  /**
   * Create form group for new option
   */
  getNewOptionFormGroup() {
    return this.formBuilder.group({
      linkField: "",
      displayField: "",
      displayField2: "",
      displayField3: "",
      displayField4: "",
    });
  }


// // Google login
// async googleLogin() {
//   try {
//     const webClientId = '85947035689-hcqd9ss6cf21p5t11gucggg6sctk4fvm.apps.googleusercontent.com';
//     const { userId, userEmail } = await this.googlePlus.login({
//       webClientId,
//       offline: true,
//     });

//     await this.storage.set(GOOGLE_LOGIN_KEY, true);

//     // Redirect to the next page or perform any other actions
//     this.router.navigateByUrl('');
//   } catch (error) {
//     console.error('Error logging in with Google', error);
//   }
// }

// Google login
async googleLogin() {
	try {
	  console.log('Google login function called'); // Log function call
	  
	  const webClientId = '85947035689-hcqd9ss6cf21p5t11gucggg6sctk4fvm.apps.googleusercontent.com';
	  console.log('webClientId:', webClientId); // Log webClientId value
	  
	  const { userId, userEmail, idToken } = await this.googlePlus.login({
		webClientId,
		offline: true,
	  });
	  
	  console.log('userId:', userId); // Log userId value
	  console.log('userEmail:', userEmail); // Log userEmail value
	  console.log('idToken:', idToken); // Log idToken value
	  
	  if (userId && userEmail && idToken) {
		await this.storage.set(GOOGLE_LOGIN_KEY, true);
	
		// Redirect to the next page or perform any other actions
		this.router.navigateByUrl('/properties_listslist');
	  } else {
		throw new Error('Incomplete Google login data');
	  }
	} catch (error) {
		console.error('Error logging in with Google', error);
		console.error(error); // Log the complete error object for more details
	  }
	  
  }
  
// Facebook login
async facebookLogin() {
  try {
    const isLoggedIn = await this.storage.get(FACEBOOK_LOGIN_KEY);
    if (isLoggedIn) return;

    const { authResponse } = await this.facebook.login(['email']);
    
    if (authResponse) {
      await this.storage.set(FACEBOOK_LOGIN_KEY, true);
      
      const { id, email } = await this.facebook.api('/me', ['email']);
      
      // Perform further actions with user data
      // Redirect to the desired page or show a welcome message
    } else {
      // User cancelled the Facebook login or an error occurred
      // Handle the cancellation or error
    }
  } catch (error) {
    // Error occurred while performing the Facebook login
    // Handle the error
  }
}


	//   openNafazWebView() {
	// 	const url = 'https://www.iam.gov.sa'; // Replace with your desired URL
	// 	const target = '_blank'; // Open in a new browser window
	  
	// 	const options: InAppBrowserOptions = {
	// 	  zoom: 'no', // Disable zoom
	// 	  location: 'no', // Hide location bar
	// 	  clearcache: 'yes', // Clear WebView cache
	// 	  clearsessioncache: 'yes' // Clear session cache
	// 	};
	  
	// 	const browser = this.inAppBrowser.create(url, target, options);
	// 	// You can listen to events on the in-app browser if needed
	// 	browser.on('loadstart').subscribe(event => {
	// 	  console.log('Load Start', event);
	// 	});
	// }

  /**
   * Add option
   */
  async addOption(name: string) {
    let component = this[name];
    if (!(component instanceof IonicSelectableComponent)) return;

    // Get values
    let el = (component as any)._element.nativeElement,
      dataset = el.dataset,
      linkTable = dataset.lt,
      option = this.formGroup.get(name + "Option"),
      value = option.value,
      data = {},
      item = {};
    if (dataset.lf) item["lf"] = data[dataset.lf] = value.linkField;
    if (dataset.df) item["df"] = data[dataset.df] = value.displayField;
    if (dataset.df2) item["df2"] = data[dataset.df2] = value.displayField2;
    if (dataset.df3) item["df3"] = data[dataset.df3] = value.displayField3;
    if (dataset.df4) item["df4"] = data[dataset.df4] = value.displayField4;

    // Add new option
    try {
      let result = await this.items.send(
        Object.assign({ object: linkTable, action: "add" }, data)
      );

      // Add item
      if (!dataset.autoSuggest) {
        let fldvar = el.getAttribute("formControlName");
        if (dataset.lfAuto)
          // Link field is autoinc
          item["lf"] = result[linkTable][dataset.lfAuto];
        item["df"] = item["df"] || item["lf"]; // Display field = Link field
        item["name"] = this.dbapp.displayValue(
          item,
          this.items.displayValueSeparators[fldvar]
        );
        await component.addItem(item);
      }

      // Search the new option
      component.search(data[dataset.df]);

      // Reset
      option.reset();

      // Show list
      component.hideAddItemTemplate();
    } catch (err) {
      await this.showError(err);
    }
  }

	// Unformat field values before submit
	async unformatValue(value: any) {
		let gpSep = this.locale.groupSeparator,
			decSep = this.locale.decimalSeparator;
		for (let fldvar of ["system_identity_no","system_mobile_1","sellers_permit_nb"])
			value[fldvar] = this.dbapp.parseNumber(value[fldvar], gpSep, decSep);
		for (let fldvar of ["system_confirm_mobile_1","system_confirm_mobile_2","system_confirm_email","system_confirm_identity_no","system_confirm_permit_nb"])
			value[fldvar] = value[fldvar] ? '1' : '0';
		return value;
	}

  // Quick access to form controls
  get f() {
    return this.formGroup.controls;
  }

  // Open URL
  openUrl(url: string, target?: string) {
    this.inAppBrowser.create(url, target);
  }

  /**
   * Select image
   * @param {string} sourceType Source type ('PHOTOLIBRARY'|'CAMERA'|'SAVEDPHOTOALBUM'|'FILE')
   * @param {string} fldvar Field variable name
   * e.g. <ion-button (click)="selectImage('CAMERA', 'fldvar')"><ion-icon slot="start" name="camera"></ion-icon></ion-button>
   */
  async selectImage(sourceType: string, fldvar: string) {
    let entry = await this.takePicture(sourceType),
      file = new DbFile(entry);
    file.previewUrl = this.webview.convertFileSrc(
      this.file.dataDirectory + entry.name
    );
    this.item[fldvar].addFiles([file]);
  }

  /**
   * Take picture and copy to this.file.dataDirectory
   * e.g. this.takePicture(this.camera.PictureSourceType.CAMERA)
   * @param {string} sourceType Source type ('PHOTOLIBRARY'|'CAMERA'|'SAVEDPHOTOALBUM'|'FILE')
   * @returns {Entry}
   */
  async takePicture(sourceType: string) {
    let options = Object.assign({}, this.dbapp.cameraOptions, {
        sourceType: this.camera.PictureSourceType[sourceType],
      }),
      isCamera = ["PHOTOLIBRARY", "CAMERA", "SAVEDPHOTOALBUM"].includes(
        sourceType
      ),
      correctPath,
      currentName;
    try {
      let imagePath = isCamera
        ? await this.camera.getPicture(options)
        : await this.fileChooser.open();
      if (this.platform.is("android") && sourceType === "PHOTOLIBRARY") {
        currentName = imagePath.substring(
          imagePath.lastIndexOf("/") + 1,
          imagePath.lastIndexOf("?")
        );
        let filePath = await this.filePath.resolveNativePath(imagePath);
        correctPath = filePath.substr(0, filePath.lastIndexOf("/") + 1);
      } else {
        currentName = imagePath.substr(imagePath.lastIndexOf("/") + 1);
        correctPath = imagePath.substr(0, imagePath.lastIndexOf("/") + 1);
      }
      let entry = await this.file.copyFile(
        correctPath,
        currentName,
        this.file.dataDirectory,
        this.dbapp.createFileName()
      );
      return await this.file.resolveLocalFilesystemUrl(
        this.file.dataDirectory + entry.name
      );
    } catch (err) {
      // e.g. Cordova not available
      this.showError(err);
    }
  }

  /**
   * Remove a file from a field
   * @param {string} fldvar Field name
   * @param {DbFile} dbfile File to be removed
   */
  async removeFile(fldvar: string, dbfile: DbFile) {
    let i = this.item[fldvar].files.indexOf(dbfile),
      f = dbfile.file;
    if (f) {
      if (f instanceof Blob) {
        // File upload component
        this[fldvar].control.removeFile(f); // Will trigger valueChanges
      } else if (f.isFile) {
        // Entry (Take picture)
        let fullPath = this.file.dataDirectory + f.name,
          correctPath = fullPath.substr(0, fullPath.lastIndexOf("/") + 1);
        await this.file.removeFile(correctPath, f.name);
      }
    }
    if (dbfile) this.item[fldvar].removeFile(dbfile);
  }

  /**
   * Remove files from a field
   * @param {string} fldvar Field variable name
   */
  async removeFiles(fldvar: string) {
    let files = this.item[fldvar].files;
    for (let i = files.length - 1; i >= 0; i--) {
      if (files[i].isBlob)
        this.item[fldvar].removeFile(
          i
        ); // Do not remove files from the File upload component
      else this.removeFile(fldvar, files[i]);
    }
  }

  cancel() {
    this.modalController.dismiss();
  }

  /** Start OTP System */
  /**
   * Get query parameters
   */

  private getParams() {
    const mobileValue = String(this.mobileVerfyForm.get("system_mobile_1_verfy").value);
    const mobileWithoutLeadingZero = mobileValue.replace(/^0+/, "");
    const mobileWithCountryCode = this.locations_country_phone_code + mobileWithoutLeadingZero.padStart(9, "0");

    const emailValue = String(this.emailVerfyForm.get("system_email_verfy").value);
    const idValue = this.nafazForm.get("system_identity_no_verfy").value;
    const valValue = this.regaForm.get("sellers_permit_nb_verfy").value;
    const userNameValue = String(this.formGroup.get("system_user_name").value);

    const params: any = {};

    if (mobileValue) {
      params.system_mobile_1 = parseInt(mobileWithCountryCode);
    }
    if (emailValue) {
      params.system_email = emailValue;
    }
    if (emailValue) {
      params.system_email = emailValue;
    }
    if (idValue) {
      params.system_identity_no = idValue;
    }
    if (valValue) {
      params.sellers_permit_nb = valValue;
    }
    if (userNameValue) {
      params.system_user_name = userNameValue;
    }

    return params;
  }

  /**
   * Search for the proper items
   */
  
  async checkItems(): Promise<boolean> {
    try {
      const items = await this.profilesItems.query(this.getParams());
      if (items) {
        await this.profilesItems.lookup(items, "list");
        this.currentItems = await Promise.all(items.map(async (item) => await this.profilesItems.renderRow(item, "list")));
        this.system_user_id = this.currentItems.map(item => item.system_user_id.value);
        this.system_user_id = this.system_user_id[0];
        console.log("this.system_user_id", this.system_user_id);
        console.log(this.getParams());
      }
      return this.currentItems.length > 0; // Return true if length is greater than 0, false otherwise
    } catch (err) {
    //   await this.showMobileExistsError(err);
      return false; // Return false in case of an error
    } finally {
      // Any final cleanup or actions can be performed here
    }
  }

  sendOTP(sendType: string): void {
	if (sendType === "mobile") {
	  this.mobileNumberRequiredError = false;
	  this.mobileNumberValidateError = false;
	  this.mobileNumberAvailableError = false;
  
	  const mobileNumber = this.mobileVerfyForm.get("system_mobile_1_verfy").value.toString();
	  
	  if (!mobileNumber) {
		  this.mobileNumberRequiredError = true;
		return;
	  }
    
    if (
      mobileNumber.length !== 9 ||
      !/^\d+$/.test(mobileNumber)
    ) {
      this.mobileNumberValidateError = true;
      return;
    }
    
  
	  this.checkItems()
		.then((hasItems) => {
		  if (hasItems) {
        this.mobileNumberAvailableError = true;
			return;
		  }
  
		  const paddedMobileNumber = mobileNumber.padStart(12, this.locations_country_phone_code.toString());
		  this.saving = true;
		  this.otp = this.generateOTP();
		  this.otpSentMobile = true;
  
		  this.simulateSMSDelivery().then((SMSdelivered) => {
			if (SMSdelivered) {
				this.otpSMSDelivered = true;
			  this.sendOTPSms(paddedMobileNumber, this.otp);
			  this.saving = false;
			  this.countdown = 60; // Reset countdown to initial value
			  this.startCountdown();
			  // this.openAlertForAutoFill();
			  console.log('otp', this.otp)
			} else {
			  this.resendOTP();
			}
		  });
		})
		.catch((err) => {
		  console.error(err);
		});
	} else if (sendType === "email") {
	  this.userEmailRequiredError = false;
	  this.userEmailValidateError = false;
	  this.userEmailAvailableError = false;
  
	  const userEmail = String(this.emailVerfyForm.get("system_email_verfy").value);
	  console.log("system_email", userEmail)
	  if (!userEmail) {
		this.userEmailRequiredError = true;
		return;
	  }
  
	  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmail)) {
		this.userEmailValidateError = true;
		return;
	  }
  
	  this.checkItems()
		.then((hasItems) => {
		  if (hasItems) {
			this.userEmailAvailableError = true;
			return;
		  }
  
		  this.saving = true;
		  this.otp = this.generateOTP();
		  this.otpSentEmail = true;
  
		  this.simulateSMSDelivery().then((Emaildelivered) => {
			if (Emaildelivered) {
				this.otpEmailDelivered = true;
			  this.sendOTPEmail(userEmail, this.otp);
			  this.saving = false;
			  this.countdown = 60; // Reset countdown to initial value
			  this.startCountdown();
			  // this.openAlertForAutoFill();
			  console.log('otp', this.otp);
			} else {
			  this.resendOTP();
			}
		  });
		})
		.catch((err) => {
		  console.error(err);
		});
	}
  }
  

  generateOTP(): string {
    const length = 4; // Length of the OTP
    const characters = "0123456789"; // Characters to use for generating the OTP
    let otp = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      otp += characters.charAt(randomIndex);
    }

    return otp;
  }

  otpController(event: KeyboardEvent, next: any, prev: any) {
    const target = event.target as HTMLInputElement;

    if (target.value.length < 1 && prev) {
      prev.setFocus();
    } else if (next && target.value.length > 0) {
      next.setFocus();
    } else {
      this.updateOTPInputValues();

      // Check if all inputs are filled
      if (this.isOTPComplete()) {
        this.verifyOTP();
      }
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const pastedText = event.clipboardData?.getData("text/plain");
    if (pastedText) {
      const digits = pastedText.trim().split("");

      if (digits.length === 4) {
        this.otpForm.setValue({
          otpInput1: digits[0],
          otpInput2: digits[1],
          otpInput3: digits[2],
          otpInput4: digits[3],
        });

        // Set focus on the last input if available
        if (this.otp4 && this.otp4.nativeElement) {
          this.otp4.nativeElement.focus();
        }

        this.updateOTPInputValues();
        this.verifyOTP();
      }
    }
  }

  verifyOTP(): void {
    const enteredOTP = this.getEnteredOTP();
    if (enteredOTP.length === 4 && /^\d+$/.test(enteredOTP) && enteredOTP === this.otp) {
      this.otpMatched = true;
      this.otpVerificationFinished = true;
      this.hideOTPInputFields();
    } else {
      this.otpMatched = false;
    }
  }

  updateOTPInputValues(): void {
    this.otpInput1 = String(this.otpForm.get("otpInput1")?.value);
    this.otpInput2 = String(this.otpForm.get("otpInput2")?.value);
    this.otpInput3 = String(this.otpForm.get("otpInput3")?.value);
    this.otpInput4 = String(this.otpForm.get("otpInput4")?.value);
  }

  isOTPComplete(): boolean {
    return (
      this.otpInput1 &&
      this.otpInput2 &&
      this.otpInput3 &&
      this.otpInput4 &&
      this.otpInput1.length === 1 &&
      this.otpInput2.length === 1 &&
      this.otpInput3.length === 1 &&
      this.otpInput4.length === 1
    );
  }

  getEnteredOTP(): string {
    return `${String(this.otpInput1)}${String(this.otpInput2)}${String(this.otpInput3)}${String(this.otpInput4)}`;
  }

  hideOTPInputFields(): void {
    this.otpInput1 = "";
    this.otpInput2 = "";
    this.otpInput3 = "";
    this.otpInput4 = "";
  }

  //   openAlertForAutoFill(): void {
  // 	const alertMessage = `OTP delivered via SMS. Do you want to set it automatically?`;
  // 	const confirmation = confirm(alertMessage);
  // 	if (confirmation) {
  // 	  this.autoFillOTP();
  // 	  this.verifyOTP();
  // 	}
  //   }

  //   autoFillOTP(): void {
  // 	if (this.otp.length === 4) {
  // 	  this.otpInput1 = this.otp.charAt(0);
  // 	  this.otpInput2 = this.otp.charAt(1);
  // 	  this.otpInput3 = this.otp.charAt(2);
  // 	  this.otpInput4 = this.otp.charAt(3);
  // 	}
  //   }
//   cancelOTP(): void {
//     alert("Please enter the OTP manually.");
//   }


simulateSMSDelivery(): Promise<[boolean, boolean, boolean]> {
	return new Promise<[boolean, boolean, boolean]>((resolve) => {
	  const SMSdelivered = true; // Example value
	  const Emaildelivered = true; // Example value
	  const IDdelivered = true; // Example value
  
	  resolve([SMSdelivered, Emaildelivered, IDdelivered]);
	});
  }
  
resendOTPHelper(): void {
	this.otpSentMobile = true;
	this.otpSentEmail = true;
	this.otpSentID = true;
	this.resendingOTP = false;
	this.simulateSMSDelivery().then(([SMSdelivered, Emaildelivered, IDdelivered]: [boolean, boolean, boolean]) => {
	  if (SMSdelivered) {
      this.otpSMSDelivered = true;
	  } else if (Emaildelivered) {
      this.otpEmailDelivered = true;
    }  else if (IDdelivered) {
      this.otpIDDelivered = true;
    } else {
		this.resendOTPHelper();
	  }
	});
  }
  
  resendOTP(): void {
	this.resendingOTP = true;
	this.countdown = 60; // Reset countdown to initial value
	this.startCountdown();
	setTimeout(() => {
	  this.resendOTPHelper();
	}, 60000); // Resend OTP after 1 minute (60 seconds)
  }

  startCountdown(): void {
    // let count = Number(localStorage.getItem('countdown')) || this.countdown;
    let count = this.countdown;
	  const interval = setInterval(() => {
	  count--;
	  this.countdown = count;
	  // localStorage.setItem('countdown', count.toString());
	  this.cdr.detectChanges();
  
	  if (count === 0) {
		clearInterval(interval);
    this.otpForm.reset();
		this.otpSentMobile = false;
		this.otpSentEmail = false;
		this.otpSentID = false;
		this.otpSMSDelivered = false;
		this.otpEmailDelivered = false;
		this.otpIDDelivered = false;
		// localStorage.setItem('otpSentMobile', 'false');
		// localStorage.setItem('otpSentEmail', 'false');
		// localStorage.setItem('otpSMSDelivered', 'false');
		// localStorage.setItem('otpEmailDelivered', 'false');
	  } else {
		// localStorage.setItem('otpSentMobile', this.otpSentMobile.toString());
		// localStorage.setItem('otpSentEmail', this.otpSentEmail.toString());
		// localStorage.setItem('otpSMSDelivered', this.otpSMSDelivered.toString());
		// localStorage.setItem('otpEmailDelivered', this.otpEmailDelivered.toString());
	  }
	}, 1000);
  }
  

onSegmentChange(): void {
	this.formGroup.reset();
  
	// Reset functions or variables
	this.mobileNumberRequiredError = false;
	this.mobileNumberValidateError = false;
	this.mobileNumberAvailableError = false;
	this.userEmailRequiredError = false;
	this.userEmailValidateError = false;
	this.userEmailAvailableError = false;
  
	// Save the values to local storage
	localStorage.setItem('segmentSignupSections', this.segmentSignupSections);
	// localStorage.setItem('otpVerificationFinished', this.otpVerificationFinished.toString());
	// localStorage.setItem('otpSentMobile', this.otpSentMobile.toString());
	// localStorage.setItem('otpSentEmail', this.otpSentEmail.toString());
	// localStorage.setItem('otpInput1', this.otpInput1);
	// localStorage.setItem('otpInput2', this.otpInput2);
	// localStorage.setItem('otpInput3', this.otpInput3);
	// localStorage.setItem('otpInput4', this.otpInput4);
	// localStorage.setItem('otp', this.otp);
	// localStorage.setItem('otpSMSDelivered', this.otpSMSDelivered.toString());
	// localStorage.setItem('otpEmailDelivered', this.otpEmailDelivered.toString());
	// localStorage.setItem('otpMatched', this.otpMatched.toString());
	// localStorage.setItem('resendingOTP', this.resendingOTP.toString());
	// localStorage.setItem('countdown', this.countdown.toString());
	// localStorage.setItem('mobileNumberRequiredError', this.mobileNumberRequiredError.toString());
	// localStorage.setItem('mobileNumberValidateError', this.mobileNumberValidateError.toString());
	// localStorage.setItem('mobileNumberAvailableError', this.mobileNumberAvailableError.toString());
	// localStorage.setItem('userEmailRequiredError', this.userEmailRequiredError.toString());
	// localStorage.setItem('userEmailValidateError', this.userEmailValidateError.toString());
	// localStorage.setItem('userEmailAvailableError', this.userEmailAvailableError.toString());
  }
  
  
	showPassword: boolean = false;

	togglePasswordVisibility() {
	this.showPassword = !this.showPassword;
	}
	isSystemPasswordLengthValid(): boolean {
		const systemPasswordControl = this.formGroup.get('system_password');
		const systemPassword = systemPasswordControl?.value || '';
		return systemPassword.length >= 8;
	  }	  
	  
	  isSystemPasswordContainsNumber(): boolean {
		const systemPassword = this.formGroup.get('system_password').value;
		return /[0-9]/.test(systemPassword);
	  }
	  
	  isSystemPasswordContainsSpecialChar(): boolean {
		const systemPassword = this.formGroup.get('system_password').value;
		return /[!@#$%^&*]/.test(systemPassword);
	  }
	  
  async sendOTPSms(userMobile: string, otpcode: string) {
    this.smsQueueRecipient = "+" + userMobile;
    this.smsQueueContent = await this.translate
      .get("otp_sms_content", { s: otpcode })
      .toPromise();
    const msgHeaderClient = {
      smsQueueRecipient: this.smsQueueRecipient,
      smsQueueContent: this.smsQueueContent,
    };
    try {
      const response = await this.smsService.sendMessageHeader(
        this.smsQueueRecipient,
        this.smsQueueContent
      );
      console.log("SMS sent successfully", response);
    } catch (error) {
      console.error("Failed to send SMS", error);
    }
  }

  async sendOTPEmail(userEmail: string, otpcode: string) {
    this.fromEmail = "admin@toor.ooo";
    this.toEmail = userEmail;
    this.ccEmail = "";
    this.bccEmail = "";
    this.subject = await this.translate
      .get("otp_code_email_subject", {
        s: otpcode,
      })
      .toPromise();
    this.emailContent = await this.translate
      .get("otp_code_email_content", {
        s: otpcode,
        d: this.dbapp.formatTimestamp(Date.now()),
      })
      .toPromise();
    this.format = "text";
    this.charset = "UTF-8";
    this.smtpSecure = "smtp";
    this.arAttachments = [];
    this.arImages = [];
    this.arProperties = null;
    this.userTitleFullName = await this.translate.get("mr_mrs", { s: "registerer" }).toPromise();
    this.brandName = await this.translate.get("toorprime").toPromise();
    this.transactionDetailsTitle = await this.translate
      .get("go_to_your_account")
      .toPromise();
    this.transactionDetailsLink =
      "https://www.toor.ooo/profilesview/";
    this.instructionsTitle = await this.translate
      .get("account_instructions_title")
      .toPromise();
    this.instructionsSubTitle = await this.translate
      .get("account_instructions_subtitle")
      .toPromise();
    this.instructionsContent = await this.translate
      .get("account_instructions_content")
      .toPromise();
    this.termsConditionsContent = await this.translate
      .get("terms_conditions_for_email")
      .toPromise();
    const emailHeaderClient = {
      from_email: this.fromEmail,
      to_email: this.toEmail,
      cc_email: this.ccEmail,
      bcc_email: this.bccEmail,
      subject: this.subject,
      email_content: this.emailContent,
      format: this.format,
      charset: this.charset,
      smtp_secure: this.smtpSecure,
      ar_attachments: this.arAttachments,
      ar_images: this.arImages,
      ar_properties: this.arProperties,
      extra_userfullname: await this.translate.get("registerer").toPromise(),
      extra_brand_name: this.brandName,
      extra_transaction_details_title: this.transactionDetailsTitle,
      extra_transaction_details_link: this.transactionDetailsLink,
      extra_instructions_title: this.instructionsTitle,
      extra_instructions_subtitle: this.instructionsSubTitle,
      extra_instructions_content: this.instructionsContent,
      extra_terms_conditions_content: this.termsConditionsContent,
      extra_locale: this.locale.locale,
    };

    try {
      const response = await this.emailService.sendEmailHeader(
        this.fromEmail,
        this.toEmail,
        this.ccEmail,
        this.bccEmail,
        this.subject,
        this.emailContent,
        this.format,
        this.charset,
        this.smtpSecure,
        this.arAttachments,
        this.arImages,
        this.arProperties,
        this.userTitleFullName,
        this.brandName,
        this.transactionDetailsTitle,
        this.transactionDetailsLink,
        this.instructionsTitle,
        this.instructionsSubTitle,
        this.instructionsContent,
        this.termsConditionsContent,
        this.locale.locale
      );
      console.log("Email sent successfully", response);
    } catch (error) {
      console.error("Failed to send Email", error);
    }
  }
  /** End Otp System */
  /** Start Notifications Functions */
  async sendEmail(userEmail) {
    this.fromEmail = "admin@toor.ooo";
    this.toEmail = userEmail;
    this.ccEmail = "";
    this.bccEmail = "";
    this.subject = await this.translate
      .get("create_account_email_subject")
      .toPromise();
    this.emailContent = await this.translate
      .get("create_account_email_content", {
        s: this.userUserName,
        d: this.dbapp.formatTimestamp(Date.now()),
      })
      .toPromise();
    this.format = "text";
    this.charset = "UTF-8";
    this.smtpSecure = "smtp";
    this.arAttachments = [];
    this.arImages = [];
    this.arProperties = null;
    this.userTitleFullName = await this.translate
      .get("mr_mrs", { s: this.userUserName })
      .toPromise();
    this.brandName = await this.translate.get("toorprime").toPromise();
    this.transactionDetailsTitle = await this.translate
      .get("go_to_your_account")
      .toPromise();
    this.transactionDetailsLink =
      "https://www.toor.ooo/profilesview/" + this.uuidv4;
    this.instructionsTitle = await this.translate
      .get("account_instructions_title")
      .toPromise();
    this.instructionsSubTitle = await this.translate
      .get("account_instructions_subtitle")
      .toPromise();
    this.instructionsContent = await this.translate
      .get("account_instructions_content")
      .toPromise();
    this.termsConditionsContent = await this.translate
      .get("terms_conditions_for_email")
      .toPromise();
    const emailHeaderClient = {
      from_email: this.fromEmail,
      to_email: this.toEmail,
      cc_email: this.ccEmail,
      bcc_email: this.bccEmail,
      subject: this.subject,
      email_content: this.emailContent,
      format: this.format,
      charset: this.charset,
      smtp_secure: this.smtpSecure,
      ar_attachments: this.arAttachments,
      ar_images: this.arImages,
      ar_properties: this.arProperties,
      extra_userfullname: this.userTitleFullName,
      extra_brand_name: this.brandName,
      extra_transaction_details_title: this.transactionDetailsTitle,
      extra_transaction_details_link: this.transactionDetailsLink,
      extra_instructions_title: this.instructionsTitle,
      extra_instructions_subtitle: this.instructionsSubTitle,
      extra_instructions_content: this.instructionsContent,
      extra_terms_conditions_content: this.termsConditionsContent,
      extra_locale: this.locale.locale,
    };
    try {
      const response = await this.emailService.sendEmailHeader(
        this.fromEmail,
        this.toEmail,
        this.ccEmail,
        this.bccEmail,
        this.subject,
        this.emailContent,
        this.format,
        this.charset,
        this.smtpSecure,
        this.arAttachments,
        this.arImages,
        this.arProperties,
        this.userTitleFullName,
        this.brandName,
        this.transactionDetailsTitle,
        this.transactionDetailsLink,
        this.instructionsTitle,
        this.instructionsSubTitle,
        this.instructionsContent,
        this.termsConditionsContent,
        this.locale.locale
      );
      console.log("Email sent successfully", response);
    } catch (error) {
      console.error("Failed to send Email", error);
    }
  }

  async sendSMS(userPadMobile) {
    this.smsQueueRecipient = userPadMobile;
    this.smsQueueContent = await this.translate
      .get("create_account_sms_content", { s: this.userUserName })
      .toPromise();
    const msgHeaderClient = {
      smsQueueRecipient: this.smsQueueRecipient,
      smsQueueContent: this.smsQueueContent,
    };
    try {
      const response = await this.smsService.sendMessageHeader(
        this.smsQueueRecipient,
        this.smsQueueContent
      );
      console.log("SMS sent successfully", response);
    } catch (error) {
      console.error("Failed to send SMS", error);
    }
  }
  /** End Notifications Functions */
  
  launchDialer(n: string) {
    this.callNumber
      .callNumber(n, true)
      .then(() => console.log("Launched dialer!"))
      .catch(() => console.log("Error launching dialer"));
    }
      
  isLoading = false;

  async openModalCountries() {
    this.isLoading = true;
    const modalCountries = await this.modalController.create({
      component: countriesListPage,
      componentProps: { openedViewCountriesModal: 'openedViewCountriesModal' },
      initialBreakpoint: 1,
    });
    modalCountries.onDidDismiss().then(item => {
      this.isLoading = false;
      if (item && item.data) {
        this.locations_country_id = item.data.selectedCountryId;
        this.locations_country_name = item.data.selectedCountryName;
        this.locations_country_phone_code = item.data.selectedCountryPhoneCode;
        this.locations_country_flag = item.data.selectedCountryFlag;
      }
    });
    return await modalCountries.present();
  }
  
  isNafazModalOpen = false;


  setNafazOpen(isNafazOpen: boolean) {
		this.nafazNationalId = true;
		this.nafazRandomCodeModal = false;
		this.system_identity_no_verfy = null;
		this.regaForm.reset();
		this.nafazForm.reset();
		this.nafazCodeForm.reset();
		this.isNafazModalOpen = isNafazOpen;
		
		console.log('Random UUID: ',uuidv4())
	  }

	nafazQueueNationalId: string;
	nafazQueueService: string;
	nafazQueueLocale: string;
	nafazQueueRequestId: string;
	async sendNafazRequest(loginForm: any) {
		const itemsExist = await this.checkItems();
			if (itemsExist === false) {
				this.nafazQueueNationalId = this.nafazForm.get('system_identity_no_verfy').value;
				this.nafazQueueService = 'Login';
				this.nafazQueueLocale = this.locale.locale;
				this.nafazQueueRequestId = this.uuidv4;
				this.sendNafazRequestApplied = true;
				const nafazHeaderClient = {
					nafazQueueNationalId: this.nafazQueueNationalId,
					nafazQueueService: this.nafazQueueService,
					nafazQueueLocale: this.nafazQueueLocale,
					nafazQueueRequestId: this.nafazQueueRequestId,
				};
				try {
					const response = await this.nafazService.sendNafazHeader(
						this.nafazQueueNationalId,
						this.nafazQueueService,
						this.nafazQueueLocale,
						this.nafazQueueRequestId
					);
					console.log("API Response:", response);
					if (response) {
						if(response['status'] === 'WAITING'){
							console.log('Request Status Response:', response);
							this.saving = false;
							this.isNafazModalOpen = true;
							this.nafazNationalId = false;
							this.nafazRandomCodeModal = true;
							this.nafazRandomCode = response['random'];
							// this.redirectToNafazApp();
						} else if(response['status'] === 'EXPIRED'){
							console.log('Request Status Response:', response);
							this.saving = false;
							this.isNafazModalOpen = true;
							this.nafazNationalId = false;
							this.nafazRandomCodeModal = true;
							this.nafazRandomCode = false;
							this.nafazRandomCodeError = await this.translate.get("EXPIRED").toPromise();

						} else if(response['status'] === 'REJECTED'){
							console.log('Request Status Response:', response);
							this.saving = false;
							this.isNafazModalOpen = true;
							this.nafazNationalId = false;
							this.nafazRandomCodeModal = true;
							this.nafazRandomCode = false;
							this.nafazRandomCodeError = await this.translate.get("REJECTED").toPromise();
						} else if(response['status'] === 'COMPLETED'){
							console.log('Request Status Response:', response);
							this.saving = false;
							this.isNafazModalOpen = false;
							this.nafazNationalId = false;
							this.nafazRandomCodeModal = false;
								// this.onLogin(loginForm);
						}
					} else {
						console.error("Request To Nafaz Failed:", response.error);
						this.saving = false;
						this.nafazNationalId = true;
						// this.nafazRandomCodeModal = false;
					}
				} catch (error) {
					console.error("Failed to send ID", error);
					this.saving = false;
				}
			} else {
				this.idNumberAvailableError = true;
			}
	}

  redirectToNafazApp() {
    if (this.platform.is('desktop')) {
	  this.openNafazWebView();
      window.location.href = 'https://www.iam.gov.sa/authservice/userauthservice?lang='+this.locale.locale;
    } else if (this.platform.is('ios')) {
      window.location.href = 'nafath://home';
    } else if (this.platform.is('android')) {
      window.location.href = 'nic://nafath';
    }
  }

  openNafazWebView() {
	const url = 'https://www.iam.gov.sa/authservice/userauthservice?lang='+this.locale.locale; // Replace with your desired URL
	const target = '_blank'; // Open in a new browser window
  
	const options: InAppBrowserOptions = {
	  zoom: 'no', // Disable zoom
	  location: 'no', // Hide location bar
	  clearcache: 'yes', // Clear WebView cache
	  clearsessioncache: 'yes' // Clear session cache
	};
  
	const browser = this.inAppBrowser.create(url, target, options);
	// You can listen to events on the in-app browser if needed
	browser.on('loadstart').subscribe(event => {
	  console.log('Load Start', event);
	});
}

  onRegisterTypeChange(){
    
  }

  
	regaQueueValId: string;
	regaQueueService: string;
	regaQueueLocale: string;
	regaQueueRequestId: string;
	async sendRegaRequest() {
		this.submitted = true;
        const valIdExist = await this.checkItems();
        if (valIdExist === false) {
				this.regaQueueValId = this.regaForm.get('sellers_permit_nb_verfy').value;
				this.regaQueueService = 'Login';
				this.regaQueueLocale = this.locale.locale;
				this.regaQueueRequestId = this.uuidv4;
        this.regaAgentInfo = false;
				const regaHeaderClient = {
					regaQueueValId: this.regaQueueValId,
					regaQueueService: this.regaQueueService,
					regaQueueLocale: this.regaQueueLocale,
					regaQueueRequestId: this.regaQueueRequestId,
				};
				try {
					const response = await this.regaService.sendRegaHeader(
						this.regaQueueValId,
						this.regaQueueService,
						this.regaQueueLocale,
						this.regaQueueRequestId
					);
					console.log("API Response:", response);
					if (response) {
						if(response['status'] === 'WAITING'){
							console.log('Request Status Response:', response);
							this.saving = false;
              this.regaAgentInfo = true;
							this.agentMobileName = 'ساهر عثمان';
							this.agentMobileNB = 966570169501;
							this.nafazRandomCode = response['random'];
							this.isNafazModalOpen = true;
							this.nafazNationalId = false;
							this.nafazRandomCodeModal = true;
							// this.redirectToNafazApp();
						} else if(response['status'] === 'EXPIRED'){
							console.log('Request Status Response:', response);
							this.saving = false;
							this.isNafazModalOpen = true;
							this.nafazNationalId = false;
							this.nafazRandomCodeModal = true;
							this.nafazRandomCode = false;
							this.nafazRandomCodeError = await this.translate.get("EXPIRED").toPromise();

						} else if(response['status'] === 'REJECTED'){
							console.log('Request Status Response:', response);
							this.saving = false;
							this.isNafazModalOpen = true;
							this.nafazNationalId = false;
							this.nafazRandomCodeModal = true;
							this.nafazRandomCode = false;
							this.nafazRandomCodeError = await this.translate.get("REJECTED").toPromise();
						} else if(response['status'] === 'COMPLETED'){
							console.log('Request Status Response:', response);
							this.saving = false;
							this.isNafazModalOpen = false;
							this.nafazNationalId = false;
							this.nafazRandomCodeModal = false;
              this.otpVerificationFinished = true;
								// this.onLogin(loginForm);
						}
					} else {
						console.error("Request To Rega Failed:", response.error);
						this.saving = false;
						this.nafazNationalId = true;
						// this.nafazRandomCodeModal = false;
					}
				} catch (error) {
					console.error("Failed to send ID", error);
					this.saving = false;
					this.regaValId = false;
          this.submitted = false;
					this.nafazRandomCode = true;
          this.isNafazModalOpen = true;
				} finally {
        }
    } else {
      this.valIdAvailableError = true;
    }
	}
}