import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { satisfies } from 'semver';
import { environment } from '../../environments/environment';
import { DbApp } from './dbapp';

@Injectable()
export class EmailServiceFirstParty {

  constructor(
    public dbapp: DbApp,
    private http: HttpClient,
		public translate: TranslateService,
    ) {}

  async sendEmailFirstParty(emailHeaderClientFirstParty: any): Promise<any> {
    const emailDataFirstParty = {
      from_email_first_party: emailHeaderClientFirstParty.from_email_first_party,
      to_email_first_party: emailHeaderClientFirstParty.to_email_first_party,
      cc_email_first_party: emailHeaderClientFirstParty.cc_email_first_party,
      bcc_email_first_party: emailHeaderClientFirstParty.bcc_email_first_party,
      subject_first_party: emailHeaderClientFirstParty.subject_first_party,
      email_content_first_party: emailHeaderClientFirstParty.email_content_first_party,
      format_first_party: emailHeaderClientFirstParty.format_first_party,
      charset_first_party: emailHeaderClientFirstParty.charset_first_party,
      smtp_secure_first_party: emailHeaderClientFirstParty.smtp_secure_first_party,
      ar_attachments_first_party: emailHeaderClientFirstParty.ar_attachments_first_party,
      ar_images_first_party: emailHeaderClientFirstParty.ar_images_first_party,
      ar_properties_first_party: emailHeaderClientFirstParty.ar_properties_first_party,
      extra_userfullname_first_party: emailHeaderClientFirstParty.extra_userfullname_first_party,
		  extra_transaction_details_title_first_party: emailHeaderClientFirstParty.extra_transaction_details_title_first_party,
		  extra_transaction_details_link_first_party: emailHeaderClientFirstParty.extra_transaction_details_link_first_party,
      extra_instructions_title_first_party: emailHeaderClientFirstParty.extra_instructions_title_first_party,
      extra_instructions_subtitle_first_party: emailHeaderClientFirstParty.extra_instructions_subtitle_first_party,
      extra_instructions_content_first_party: emailHeaderClientFirstParty.extra_instructions_content_first_party,
      extra_brand_name: emailHeaderClientFirstParty.extra_brand_name,
      extra_terms_conditions_content: emailHeaderClientFirstParty.extra_terms_conditions_content,
      extra_locale: emailHeaderClientFirstParty.extra_locale
    };

return this.http.post(environment.apiUrl, emailDataFirstParty).toPromise().then(async (data: any) => {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  });
  data = data || {};
  if (data.version && !satisfies(data.version, this.dbapp.requiredApiVersion))
    await this.translate.get("INCOMPATIBLE_API", { s: data.version }).toPromise();
    
  if (data.success)
        console.log('Email Sent');
      return data;
    });
  }
  
  // Add a new method to pass the message header from the client-side
  async sendEmailHeaderFirstParty(
      from_email_first_party: string,
      to_email_first_party: string,
      cc_email_first_party: string,
      bcc_email_first_party: string,
      subject_first_party: string,
      email_content_first_party: string,
      format_first_party: string,
      charset_first_party: string,
      smtp_secure_first_party: string,
      ar_attachments_first_party: any[],
      ar_images_first_party: any[],
      ar_properties_first_party: any,
      extra_userfullname_first_party: string,
      extra_transaction_details_title_first_party: string,
      extra_transaction_details_link_first_party: string,
      extra_instructions_title_first_party: string,
      extra_instructions_subtitle_first_party: string,
      extra_instructions_content_first_party: string,
      extra_brand_name: string,
      extra_terms_conditions_content: string,
      extra_locale: string
    ): Promise<any> {
    const emailClientFirstParty = {
      from_email_first_party: from_email_first_party,
      to_email_first_party: to_email_first_party,
      cc_email_first_party: cc_email_first_party,
      bcc_email_first_party: bcc_email_first_party,
      subject_first_party: subject_first_party,
      email_content_first_party: email_content_first_party,
      format_first_party: format_first_party,
      charset_first_party: charset_first_party,
      smtp_secure_first_party: smtp_secure_first_party,
      ar_attachments_first_party: ar_attachments_first_party,
      ar_images_first_party: ar_images_first_party,
      ar_properties_first_party: ar_properties_first_party,
      extra_userfullname_first_party: extra_userfullname_first_party,
		  extra_transaction_details_title_first_party: extra_transaction_details_title_first_party,
		  extra_transaction_details_link_first_party: extra_transaction_details_link_first_party,
      extra_instructions_title_first_party: extra_instructions_title_first_party,
      extra_instructions_subtitle_first_party: extra_instructions_subtitle_first_party,
      extra_instructions_content_first_party: extra_instructions_content_first_party,
      extra_brand_name: extra_brand_name,
      extra_terms_conditions_content: extra_terms_conditions_content,
      extra_locale: extra_locale
    };
    return this.sendEmailFirstParty(emailClientFirstParty);
  }
}