import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { LocalDatePipe, LocalNumberPipe, LocalCurrencyPipe, LocalPercentPipe, AuthFilePipe, ShortNumberPipe, DurationPipe, CountdownPipe, PropertyStatusPipe, PropertyTypePipe, PropertyCategoryPipe, PropertyFeaturePipe, PropertyTypeIconsPipe } from './';


// Module
@NgModule({
	declarations:[
		LocalDatePipe,
		LocalNumberPipe,
		LocalCurrencyPipe,
		LocalPercentPipe,
		AuthFilePipe,
		ShortNumberPipe,
		DurationPipe,
		CountdownPipe,
		PropertyStatusPipe,
		PropertyTypePipe,
		PropertyCategoryPipe,
		PropertyFeaturePipe,
		PropertyTypeIconsPipe
	],
	imports:[CommonModule],
	exports:[
		LocalDatePipe,
		LocalNumberPipe,
		LocalCurrencyPipe,
		LocalPercentPipe,
		AuthFilePipe,
		ShortNumberPipe,
		DurationPipe,
		CountdownPipe,
		PropertyStatusPipe,
		PropertyTypePipe,
		PropertyCategoryPipe,
		PropertyFeaturePipe,
		PropertyTypeIconsPipe
	]
})

// Export
export class DbAppPipe {}