import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { satisfies } from 'semver';
import { environment } from '../../environments/environment';
import { DbApp } from './dbapp';

@Injectable()
export class EmailService {

  constructor(
    public dbapp: DbApp,
    private http: HttpClient,
		public translate: TranslateService,
    ) {}

  async sendEmail(emailHeaderClient: any): Promise<any> {
    const emailData = {
      from_email: emailHeaderClient.from_email,
      to_email: emailHeaderClient.to_email,
      cc_email: emailHeaderClient.cc_email,
      bcc_email: emailHeaderClient.bcc_email,
      subject: emailHeaderClient.subject,
      email_content: emailHeaderClient.email_content,
      format: emailHeaderClient.format,
      charset: emailHeaderClient.charset,
      smtp_secure: emailHeaderClient.smtp_secure,
      ar_attachments: emailHeaderClient.ar_attachments,
      ar_images: emailHeaderClient.ar_images,
      ar_properties: emailHeaderClient.ar_properties,
      extra_userfullname: emailHeaderClient.extra_userfullname,
		  extra_brand_name: emailHeaderClient.extra_brand_name,
		  extra_transaction_details_title: emailHeaderClient.extra_transaction_details_title,
		  extra_transaction_details_link: emailHeaderClient.extra_transaction_details_link,
      extra_instructions_title: emailHeaderClient.extra_instructions_title,
      extra_instructions_subtitle: emailHeaderClient.extra_instructions_subtitle,
      extra_instructions_content: emailHeaderClient.extra_instructions_content,
      extra_terms_conditions_content: emailHeaderClient.extra_terms_conditions_content,
      extra_locale: emailHeaderClient.extra_locale
    };

return this.http.post(environment.apiUrl, emailData).toPromise().then(async (data: any) => {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  });
  data = data || {};
  if (data.version && !satisfies(data.version, this.dbapp.requiredApiVersion))
    await this.translate.get("INCOMPATIBLE_API", { s: data.version }).toPromise();
    
  if (data.success)
        console.log('Email Sent');
      return data;
    });
  }
  
  // Add a new method to pass the message header from the client-side
  async sendEmailHeader(
      from_email: string,
      to_email: string,
      cc_email: string,
      bcc_email: string,
      subject: string,
      email_content: string,
      format: string,
      charset: string,
      smtp_secure: string,
      ar_attachments: any[],
      ar_images: any[],
      ar_properties: any,
      extra_userfullname: string,
      extra_brand_name: string,
      extra_transaction_details_title: string,
      extra_transaction_details_link: string,
      extra_instructions_title: string,
      extra_instructions_subtitle: string,
      extra_instructions_content: string,
      extra_terms_conditions_content: string,
      extra_locale: string
    ): Promise<any> {
    const emailClient = {
      from_email: from_email,
      to_email: to_email,
      cc_email: cc_email,
      bcc_email: bcc_email,
      subject: subject,
      email_content: email_content,
      format: format,
      charset: charset,
      smtp_secure: smtp_secure,
      ar_attachments: ar_attachments,
      ar_images: ar_images,
      ar_properties: ar_properties,
      extra_userfullname: extra_userfullname,
		  extra_brand_name: extra_brand_name,
		  extra_transaction_details_title: extra_transaction_details_title,
		  extra_transaction_details_link: extra_transaction_details_link,
      extra_instructions_title: extra_instructions_title,
      extra_instructions_subtitle: extra_instructions_subtitle,
      extra_instructions_content: extra_instructions_content,
      extra_terms_conditions_content: extra_terms_conditions_content,
      extra_locale: extra_locale
    };
    return this.sendEmail(emailClient);
  }
}