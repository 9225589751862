import { HttpClient } from '@angular/common/http';
import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DbApp } from './dbapp';
import { UserData } from './user-data';
import { DbTable } from './dbtable';
import { DbRecord } from './dbrecord';
import { DbField } from './dbfield';
import { LocaleService } from './locale.service';
import { LocalDatePipe } from './localdate.pipe';
import { LocalNumberPipe } from './localnumber.pipe';
import { LocalCurrencyPipe } from './localcurrency.pipe';
import { LocalPercentPipe } from './localpercent.pipe';
import { AuthFilePipe } from './authfile.pipe';

// offers_prices
@Injectable({
	providedIn: 'root'
})
export class offers_prices extends DbTable {
	pageId: string;
	fieldVars = ["properties_price_offer_id","properties_price_offer_amount","properties_property_id","system_user_id","properties_price_offer_agree_status","properties_price_offer_status","properties_price_offer_last_edit_grogorian","properties_price_offer_last_edit_by"];
	fieldNames = []; // Array([name, var]) for fields with name != var
	listFields: string[] = ["properties_price_offer_id","properties_price_offer_amount","properties_property_id","system_user_id","properties_price_offer_agree_status","properties_price_offer_status","properties_price_offer_last_edit_grogorian","properties_price_offer_last_edit_by"];
	viewFields: string[] = ["properties_price_offer_id","properties_price_offer_amount","properties_property_id","system_user_id","properties_price_offer_agree_status","properties_price_offer_status","properties_price_offer_last_edit_grogorian","properties_price_offer_last_edit_by"];
	addFields: string[] = ["properties_price_offer_id","properties_price_offer_amount","properties_property_id","system_user_id","properties_price_offer_agree_status","properties_price_offer_status","properties_price_offer_last_edit_grogorian","properties_price_offer_last_edit_by"];
	editFields: string[] = ["properties_price_offer_id","properties_price_offer_amount","properties_property_id","system_user_id","properties_price_offer_agree_status","properties_price_offer_status","properties_price_offer_last_edit_grogorian"];
	lookupTables: any = {};
	displayValueSeparators: any = {};
	errorMessages: any;
	row: any; // Current row (rendered)
	labelAttribute: string = "name";

	// Constructor
	constructor(public dbapp: DbApp,
		public user: UserData,
		public translate: TranslateService,
		public locale: LocaleService,
		public router: Router,
			public http: HttpClient) {
		super(dbapp, http, user, translate, router);
		this.name = "offers_prices";
		this.translate.get(this.fieldVars.map(fldvar => "__tables." + this.name + ".fields." + fldvar + ".errMsg")).subscribe(values => {
			for (let k in values)
				values[k] = (k != values[k]) ? values[k] : "";
			this.errorMessages = values;
		});
		this.translate.get(["__tables.offers_prices.fields.properties_price_offer_agree_status.tagValues","__tables.offers_prices.fields.properties_price_offer_status.tagValues"]).subscribe(values => {
			this.lookupTables.properties_price_offer_agree_status = dbapp.convertUserValues(values["__tables.offers_prices.fields.properties_price_offer_agree_status.tagValues"]);
			this.lookupTables.properties_price_offer_status = dbapp.convertUserValues(values["__tables.offers_prices.fields.properties_price_offer_status.tagValues"]);
		});
		this.infiniteScroll = true;
		this.pageSize = 8;
	}

	// Lookup
	async lookup(item: any, pageId: string) {
		if (!item)
			return;
		pageId = pageId == "signup" ? "register" : pageId;
		let page = pageId != "register" ? this.name + "_" + pageId : pageId;
		this.pageId = pageId;
		let p = [];

		// properties_property_id
		if (this.renderField("properties_property_id", pageId)) {
			let params;
			if (Array.isArray(item)) { // List
				let keys1 = item.map(row => row["properties_property_id"]).filter((v, i, a) =>  !this.dbapp.isEmpty(v) && a.indexOf(v) == i);
				params = { action: "lookup", ajax: "modal", page: page, field: "properties_property_id", keys: keys1 };
			} else { // Add/Edit/View
				params = { action: "lookup", ajax: "updateoption", page: page, field: "properties_property_id" };
				if (pageId == "view")
					params["v0"] = item["properties_property_id"];
			}
			p.push(this.query(params).then(items => {
				items.forEach(item => item["name"] = this.dbapp.displayValue(item, this.displayValueSeparators.properties_property_id));
				return items;
			}));
		}

		// system_user_id
		if (this.renderField("system_user_id", pageId)) {
			let params;
			if (Array.isArray(item)) { // List
				let keys2 = item.map(row => row["system_user_id"]).filter((v, i, a) =>  !this.dbapp.isEmpty(v) && a.indexOf(v) == i);
				params = { action: "lookup", ajax: "modal", page: page, field: "system_user_id", keys: keys2 };
			} else { // Add/Edit/View
				params = { action: "lookup", ajax: "updateoption", page: page, field: "system_user_id" };
				if (pageId == "view")
					params["v0"] = item["system_user_id"];
			}
			p.push(this.query(params).then(items => {
				items.forEach(item => item["name"] = this.dbapp.displayValue(item, this.displayValueSeparators.system_user_id));
				return items;
			}));
		}

		// properties_price_offer_last_edit_by
		if (this.renderField("properties_price_offer_last_edit_by", pageId)) {
			let params;
			if (Array.isArray(item)) { // List
				let keys3 = item.map(row => row["properties_price_offer_last_edit_by"]).filter((v, i, a) =>  !this.dbapp.isEmpty(v) && a.indexOf(v) == i);
				params = { action: "lookup", ajax: "modal", page: page, field: "properties_price_offer_last_edit_by", keys: keys3 };
			} else { // Add/Edit/View
				params = { action: "lookup", ajax: "updateoption", page: page, field: "properties_price_offer_last_edit_by" };
				if (pageId == "view")
					params["v0"] = item["properties_price_offer_last_edit_by"];
			}
			p.push(this.query(params).then(items => {
				items.forEach(item => item["name"] = this.dbapp.displayValue(item, this.displayValueSeparators.properties_price_offer_last_edit_by));
				return items;
			}));
		}

		// Get lookup results
		try {
			[this.lookupTables.properties_property_id, this.lookupTables.system_user_id, this.lookupTables.properties_price_offer_last_edit_by] = await Promise.all(p);
		} catch(err) {
			console.log(err);
		}
	}

	// Render field
	renderField(fieldName: string, pageId: string) {
		if (["list", "view", "add", "edit", "register"].includes(pageId))
			return this[pageId + "Fields"].includes(fieldName);
		return false;
	}

	// Get field variable name
	getFieldVar(name) {
		let f = this.fieldNames.find(f => f[0] == name);
		return f ? f[1] : name;
	}

	// Get field variable name
	getFieldName(varname) {
		let f = this.fieldNames.find(f => f[1] == varname);
		return f ? f[0] : varname;
	}

	// Render row
	async renderRow(item: any, pageId: string) {
		this.pageId = pageId;
		let row = new DbRecord(item, this.fieldNames, this.errorMessages);
		this.rowOnRender(row);

		// properties_property_id
		if (this.renderField("properties_property_id", pageId)) {
			row["properties_property_id"].value = ""; // Value to be looked up
			let selectedRow = this.lookupTables.properties_property_id.find(r => r.lf == row["properties_property_id"].dbValue); // Compare with db value
			row["properties_property_id"].formValue = row["properties_property_id"].dbValue; // FormControl value cannot be undefined
			row["properties_property_id"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.properties_property_id) : row["properties_property_id"].dbValue;
		}

		// system_user_id
		if (this.renderField("system_user_id", pageId)) {
			row["system_user_id"].value = ""; // Value to be looked up
			let selectedRow = this.lookupTables.system_user_id.find(r => r.lf == row["system_user_id"].dbValue); // Compare with db value
			row["system_user_id"].formValue = row["system_user_id"].dbValue; // FormControl value cannot be undefined
			row["system_user_id"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.system_user_id) : row["system_user_id"].dbValue;
		}

		// properties_price_offer_agree_status
		if (this.renderField("properties_price_offer_agree_status", pageId)) {
			let selectedRow = this.lookupTables.properties_price_offer_agree_status.find(r => r.lf == row["properties_price_offer_agree_status"].dbValue); // Compare with db value
			row["properties_price_offer_agree_status"].formValue = row["properties_price_offer_agree_status"].dbValue; // FormControl value cannot be undefined
			row["properties_price_offer_agree_status"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.properties_price_offer_agree_status) : row["properties_price_offer_agree_status"].dbValue;
			row["properties_price_offer_agree_status"].value = this.dbapp.getBool(row["properties_price_offer_agree_status"].dbValue);
		}

		// properties_price_offer_status
		if (this.renderField("properties_price_offer_status", pageId)) {
			let selectedRow = this.lookupTables.properties_price_offer_status.find(r => r.lf == row["properties_price_offer_status"].dbValue); // Compare with db value
			row["properties_price_offer_status"].formValue = row["properties_price_offer_status"].dbValue; // FormControl value cannot be undefined
			row["properties_price_offer_status"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.properties_price_offer_status) : row["properties_price_offer_status"].dbValue;
			row["properties_price_offer_status"].value = this.dbapp.getBool(row["properties_price_offer_status"].dbValue);
		}

		// properties_price_offer_last_edit_by
		if (this.renderField("properties_price_offer_last_edit_by", pageId)) {
			row["properties_price_offer_last_edit_by"].value = ""; // Value to be looked up
			let selectedRow = this.lookupTables.properties_price_offer_last_edit_by.find(r => r.lf == row["properties_price_offer_last_edit_by"].dbValue); // Compare with db value
			row["properties_price_offer_last_edit_by"].formValue = row["properties_price_offer_last_edit_by"].dbValue; // FormControl value cannot be undefined
			row["properties_price_offer_last_edit_by"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.properties_price_offer_last_edit_by) : row["properties_price_offer_last_edit_by"].dbValue;
		}
		row.rendered = true;
		this.rowAfterRendered(row);
		this.row = row; // Set current row
		return row;
	}

	// Render file URL
	async renderFileUrl(url: string) {
		return this.http.get(url, { responseType: "blob" }).toPromise().then(blob => {
			const reader = new FileReader();
			return new Promise((resolve, reject) => {
				reader.onloadend = () => resolve(reader.result as string);
				reader.readAsDataURL(blob);
			});
		});
	}
}