import { OnDestroy, OnInit, Input } from '@angular/core';
import { NavController, ModalController, AlertController, LoadingController, PopoverController, ActionSheetController, ToastController, IonSearchbar  } from '@ionic/angular';
import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { UntypedFormBuilder, FormGroup, FormControl, FormArray, Validators, NgForm } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Observable, Subscription, BehaviorSubject, from, timer } from 'rxjs';
import { IonInfiniteScroll, Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { File, Entry, FileEntry } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FileUploadComponent, FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { UserData, DbRecord, DbFile, LocaleService, Settings, DbApp, DbAppValidators, History } from '../../providers';
import { environment } from '../../../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { properties_schemas } from '../../providers';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { messagesAddPage } from '../../pages/messagesadd/messagesadd';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ticketsAddPage } from '../ticketsadd/ticketsadd';
import { files_media_photosListPage } from '../files_media_photoslist/files_media_photoslist';
import { neighborhoodsViewPage } from '../neighborhoodsview/neighborhoodsview';
import { SharedService } from '../../providers/shared_services';
import { ScrollHideConfig } from '../../components/expandable-header/expandable-header.component';
import { add_appointmentsAddPage } from '../add_appointmentsadd/add_appointmentsadd';


// Component
@Component({
	selector: 'page-properties_schemas-view',
	templateUrl: 'properties_schemasview.html',
	styleUrls: ['properties_schemasview.scss'],
	providers: [CallNumber, SocialSharing,
		SharedService],
})
export class properties_schemasViewPage implements OnDestroy, OnInit {
	// @Input() filter: string;
	footerScrollConfig: ScrollHideConfig = { cssProperty: 'margin-bottom', cssPropertyReverse: '', cssPropertyOpacity: '', maxValue: undefined };
	headerScrollConfig: ScrollHideConfig = { cssProperty: '', cssPropertyReverse: 'background', cssPropertyOpacity: '', maxValue: 0.9 };
	opacityScrollConfig: ScrollHideConfig = { cssProperty: '', cssPropertyReverse: '', cssPropertyOpacity: 'opacity', maxValue: 100 };
	key: any;
	keyCount: number = 1;
	item: any;
	item$: BehaviorSubject<DbRecord>;
	pageId: string = "view";
	pageUrl: string = "properties_schemasview";
	loadingMessage: string;
	dataLoaded: boolean;
	isLoggedIn: boolean;
	isAdmin: boolean;
	userId: string;
	permissions: any;
	userIdAllowed: boolean;
	segmentSchemaNearbyServicesModelInfo: string = 'map';
	segmentSchemaModelInfo: string = 'specifications';
	fitBounds: boolean = true;
	sharedService: any;
	current_user_latitude: number;
	current_user_longitude: number;
	properties_property_schema_id: string;
	private navigationSubscription: Subscription;

	// Constructor
	constructor(
		public dbapp: DbApp,
		public translate: TranslateService,
		public locale: LocaleService,
		public navController: NavController,
		public loadingController: LoadingController,
		public actionSheetController: ActionSheetController,
		public modalController: ModalController,
		public alertController: AlertController,
		public toastController: ToastController,
		public router: Router,
		public formBuilder: UntypedFormBuilder,
		public user: UserData,
		public inAppBrowser: InAppBrowser,
		private activatedRoute: ActivatedRoute,
		public history: History,
		private camera: Camera,
		private file: File,
		private webview: WebView,
		private platform: Platform,
		private changeDetectorRef: ChangeDetectorRef,
		private filePath: FilePath,
		private fileChooser: FileChooser,
		private sanitizer: DomSanitizer,
		public items: properties_schemas,
		public statusBar: StatusBar,
		private callNumber: CallNumber,
		private socialSharing: SocialSharing,) {
		this.key = this.getPrimaryKey();
		this.item$ = new BehaviorSubject<DbRecord>(null);
		this.userIdAllowed = this.dbapp.userIdAllow("properties_schemas", this.pageId);
		this.getUserData();
	}

	// OnInit
	ngOnInit() {
		this.navigationSubscription = this.router.events.subscribe(async (e: any) => {
			if (e instanceof NavigationEnd && this.history.isCurrentPage(this.pageUrl)) { // Re-init component
				await this.init();
			}
		});
		
		if (this.properties_property_schema_id) {
			this.key = this.getPrimaryKey();
		  	this.init();
		}
		this.current_user_latitude = parseFloat(localStorage.getItem('current_user_latitude'));
		this.current_user_longitude = parseFloat(localStorage.getItem('current_user_longitude'));
	}

	// OnDestroy
	ngOnDestroy() {
		if (this.navigationSubscription)
			this.navigationSubscription.unsubscribe(); // Clean up
	}

	// Init
	async init() {
		this.loadingMessage = await this.translate.get("LOADING").toPromise();
		if (this.dbapp.isObject(this.key)) {
			const loading = await this.loadingController.create({
				spinner: null,
				cssClass: 'global-loader',
				showBackdrop: true,
			});
			await loading.present();
			try {
				let item = await this.items.query(Object.assign({ action: "view" }, this.key)); // Use "view" action to get the record
				if (this.dbapp.isObject(item)) {
					await this.items.lookup(item, this.pageId);
					this.item = await this.items.renderRow(item, this.pageId);
					this.item$.next(this.item);
				}
			} catch(err) {
				this.showError(err);
			} finally {
				await loading.dismiss();
			}
		}
	}

	/**
	 * Get primary key
	 */
	getPrimaryKey(): any {
		let keys = {}, key;
		key = this.properties_property_schema_id ? this.properties_property_schema_id : this.activatedRoute.snapshot.paramMap.get("properties_property_schema_id");

		if (!this.dbapp.isEmpty(key))
			keys["properties_property_schema_id"] = key;
		return (Object.keys(keys).length === this.keyCount) ? keys : false;
	}

	// Get user data
	getUserData() {
		this.user.isLoggedIn.subscribe(res => {
			this.isLoggedIn = res;
			this.isAdmin = this.user.isAdmin;
			this.userId = this.user.userId;
			this.permissions = this.user.permissions;
		});
	}

	/**
	 * Show message
	 */
	async showMessage(msg: string, header: string) {
		let values = await this.translate.get(["OK_BUTTON", header]).toPromise();
		const alert = await this.alertController.create({
			header: values[header],
			message: msg,
			buttons: [values.OK_BUTTON]
		});
		await alert.present();
	}

	/**
	 * Show error
	 */
	async showError(err: any) {
		let msg = (err instanceof Error) ? err.message : err;
		return this.showMessage(msg, "ERROR");
	}

	/**
	 * Show success message
	 */
	async showSuccess(msg: string) {
		return this.showMessage(msg, "SUCCESS");
	}

	/**
	 * Show detail table buttons
	 */
	async presentDetails() {
		let ids = [
			"__tables.properties_schemas_segments.caption",
			"__tables.properties_lists.caption",
			"__tables.properties_schemas_segments_map.caption",
			"DETAILS",
			"CANCEL_BUTTON"
		];
		let values = await this.translate.get(ids).toPromise();
		let buttons = [];
		if (this.user.permissions.list.properties_schemas_segments) {
			buttons.push({
				text: values["__tables.properties_schemas_segments.caption"],
				handler: () => {
					this.openDetail("properties_schemas_segments");
				}
			});
		}
		if (this.user.permissions.list.properties_lists) {
			buttons.push({
				text: values["__tables.properties_lists.caption"],
				handler: () => {
					this.openDetail("properties_lists");
				}
			});
		}
		if (this.user.permissions.list.properties_schemas_segments_map) {
			buttons.push({
				text: values["__tables.properties_schemas_segments_map.caption"],
				handler: () => {
					this.openDetail("properties_schemas_segments_map");
				}
			});
		}
		let actionSheet = await this.actionSheetController.create({
			header: values.DETAILS,
			buttons: [...buttons,
				{
					text: values.CANCEL_BUTTON,
					role: 'cancel',
					handler: () => {
						console.log('Cancel clicked');
					}
				}
			]
		});
		await actionSheet.present();
	}

	/**
	 * Open detail table List page
	 * @param detailTable
	 */
	openDetail(detailTable: string) {
		if (!this.item)
			return;
		switch (detailTable) {
			case "properties_schemas_segments":
				this.navController.navigateForward([detailTable + "list", { properties_property_schema_id: this.item.properties_property_schema_id.dbValue }]);
				break;
			case "properties_lists":
				this.navController.navigateForward([detailTable + "list", { properties_schema_id: this.item.properties_property_schema_id.dbValue }]);
				break;
			case "properties_schemas_segments_map":
				this.navController.navigateForward([detailTable + "list", { properties_property_schema_id: this.item.properties_property_schema_id.dbValue }]);
				break;
		}
	}

	// Open URL
	openUrl(url: string, target?: string) {
		this.inAppBrowser.create(url, target);
	}

	// other
	launchDialer(n: string) {
		this.callNumber
		  .callNumber(n, true)
		  .then(() => console.log("Launched dialer!"))
		  .catch(() => console.log("Error launching dialer"));
	  }
	
		  compilemsg(title: string, properties_property_schema_files: string, properties_property_schema_id: string): string {
			var msg = title + "\n\n" + properties_property_schema_files + "\n" + properties_property_schema_id;
			return msg;
		  }
		  
		  whatsappShare(title: string, properties_property_schema_files: string, properties_property_schema_id: string) {
			var msg = this.compilemsg(title, properties_property_schema_files, properties_property_schema_id);
		  
			// Specify the image path
			let imagePath = 'https://obaykan.s3.us-east-2.amazonaws.com/files/properties_property_schema_files/' + properties_property_schema_files;
		  
			// Share the image via WhatsApp
			this.socialSharing.shareViaWhatsApp(msg, imagePath).then(() => {
			  console.log('Shared successfully via WhatsApp');
			}).catch((error) => {
			  console.log('Error sharing via WhatsApp:', error);
			});
		  }
		  mapSchemaReady(map: google.maps.Map, polygonData: any): void {
			  if(polygonData){
			  // const locations_city_name = this.properties_city_name;
			  const parsedPolygonData = JSON.parse(polygonData);
			  const polygonCoordinates = parsedPolygonData[0].geometry[0].map(([lat, lng]) => ({ lat, lng }));
			  const polygon = new google.maps.Polygon({
				paths: polygonCoordinates,
				strokeColor: '#000000',
				strokeOpacity: 0.8,
				strokeWeight: 1,
				fillColor: '#FF0000',
				fillOpacity: 0.35
			  });
			  this.fitBounds= false;
			  polygon.setMap(map);
						  
			  // Calculate the centroid of the polygon
			  const polygonBounds = new google.maps.LatLngBounds();
			  for (let i = 0; i < polygonCoordinates.length; i++) {
				  polygonBounds.extend(polygonCoordinates[i]);
			  }
			  const polygonCenter = polygonBounds.getCenter();
		  
			  // Create a marker with a label at the centroid
			  // const marker = new google.maps.Marker({
			  // 	position: polygonCenter,
			  // 	label: locations_neighborhood_name,
			  // 	map: map
			  // });
		  
			  let bounds = new google.maps.LatLngBounds();
			  for (let i = 0; i < polygonCoordinates.length; i++) {
				  bounds.extend(polygonCoordinates[i]);
			  }
		  
			  const ne = bounds.getNorthEast();
			  const sw = bounds.getSouthWest();
		  
			  console.log('Polygon bounds - NE:', ne.toString());
			  console.log('Polygon bounds - SW:', sw.toString());
			  map.fitBounds(bounds);
			  console.log(parsedPolygonData)
		  }
	  }

	  async openModalAddMessage(userId: string) {
		  const modalAddRating = await this.modalController.create({
			component: messagesAddPage,
			componentProps  : {openedAddMessageModal: 'openedAddMessageModal', system_user_id: userId},
			initialBreakpoint: 0.8,
			cssClass: 'custom-modal',
			backdropDismiss: true,
		  });
		  modalAddRating.onDidDismiss().then(item => {
			modalAddRating.dismiss();
		  });
		  return await modalAddRating.present();
	  
		}

	  async openModalNeighborhoodsView(neighborhoodId: string) {
		const modalNeighborhoodsView = await this.modalController.create({
		  component: neighborhoodsViewPage,
		  componentProps  : {openedNeighborhoodsViewModal: 'openedNeighborhoodsViewModal', locations_neighborhood_id: neighborhoodId},
		  initialBreakpoint: 0.8,
		  cssClass: 'custom-modal',
		  backdropDismiss: true,
		});
		modalNeighborhoodsView.onDidDismiss().then(item => {
		  modalNeighborhoodsView.dismiss();
		});
		return await modalNeighborhoodsView.present();
	  }
	
	async openModalAppointment(propId: string) {
	  const modalAppointment = await this.modalController.create({
		component: add_appointmentsAddPage,
		componentProps  : {addappointment: 'addappointment', properties_property_schema_id: propId},
		breakpoints: [0, 0.3, 0.5, 1],
		initialBreakpoint: 0.72,
		cssClass: 'custom-modal',
		backdropDismiss: false,
	  });
	  
	  let appointment_info_by: number;
	  let appointment_info_date_time: string;
	  modalAppointment.onDidDismiss().then(item => {
		  if (item && item.data) {
			  appointment_info_by = item.data.appointmentInfoBy;
			  appointment_info_date_time = item.data.appointmentInfoDateTime;
			  
		  this.showAppointmentSubmitMessage(appointment_info_by, appointment_info_date_time);
		  }
	  });
	  return await modalAppointment.present();
	  // await modalAppointment.dismiss();

	}

	async openModalAddReport(propId: string) {
	  const modalAppointment = await this.modalController.create({
		component: ticketsAddPage,
		componentProps  : { openedReportModal: 'openedReportModal',parentPageName: this.pageUrl, properties_property_schema_id: propId},
		breakpoints: [0],
		initialBreakpoint: 0.8,
		cssClass: 'custom-modal',
		backdropDismiss: false,
	  });
	  console.log(this.pageUrl)
	  let appointment_info_by: number;
	  let appointment_info_date_time: string;
	  modalAppointment.onDidDismiss().then(item => {
		  if (item && item.data) {
			  appointment_info_by = item.data.appointmentInfoBy;
			  appointment_info_date_time = item.data.appointmentInfoDateTime;
			  
		  this.showAppointmentSubmitMessage(appointment_info_by, appointment_info_date_time);
		  }
	  });
	  return await modalAppointment.present();
	  // await modalAppointment.dismiss();

	}
  
async showAppointmentSubmitMessage(appointment_info_by: number, appointment_info_date_time: string) {
const header = 'thanks_to_take_an_appointment';
const msgPart1 = 'your_appintment_dedicated_by';
const msgPart2 = 'in_date_time';
let appointmentInfoBy: string;
appointment_info_by === 1 ? appointmentInfoBy = 'local_visit' : appointmentInfoBy = 'remote_visit';
let values = await this.translate.get(["OK_BUTTON", header, msgPart1, msgPart2, appointmentInfoBy]).toPromise();
const alert = await this.alertController.create({
	header: values[header],
	message: values[msgPart1] + ' ' + values[appointmentInfoBy] + ' ' + values[msgPart2] + ' ' + appointment_info_date_time,
	buttons: [values.OK_BUTTON]
});
await alert.present();
}

// shouldShowScrollableContent(): boolean {
//     // Check if the platform is md or lg
//     return this.platform.is('md') || this.platform.is('lg');
//   }
}