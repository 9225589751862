import { DbApp } from './dbapp';
import { UserData } from './user-data';
import { DbTable } from './dbtable';
import { DbRecord } from './dbrecord';
import { DbField, DbFile } from './dbfield';
import { LocalDatePipe } from './localdate.pipe';
import { LocalNumberPipe } from './localnumber.pipe';
import { LocalCurrencyPipe } from './localcurrency.pipe';
import { LocalPercentPipe } from './localpercent.pipe';
import { AuthFilePipe } from './authfile.pipe';
import { ShortNumberPipe } from './short-number.pipe';
import { DurationPipe } from './duration.pipe';
import { CountdownPipe } from './countdown.pipe';
import { PropertyStatusPipe } from './propertystatus.pipe';
import { PropertyTypePipe } from './properttype.pipe';
import { PropertyCategoryPipe } from './propertycategory.pipe';
import { PropertyFeaturePipe } from './propertyfeatures.pipe';
import { PropertyTypeIconsPipe } from './propertytypeicons.pipe';
import { LocaleService } from './locale.service';
import { History } from './dbapp.history';
import { Settings } from './dbapp.settings';
import { date, time, creditCard, float, integer,saudiphone, usphone, uszip, usssn, guid, mustMatch, files } from './dbapp.validators';
import { add_appointments } from './add_appointments';
import { add_property_1 } from './add_property_1';
import { auctions_logs } from './auctions_logs';
import { cities } from './cities';
import { clientsrequests } from './clientsrequests';
import { countries } from './countries';
import { files_media_panos } from './files_media_panos';
import { files_media_photos } from './files_media_photos';
import { files_media_videos } from './files_media_videos';
import { messages } from './messages';
import { my_properties } from './my_properties';
import { nearby_services } from './nearby_services';
import { nearby_services_types } from './nearby_services_types';
import { neighborhoods } from './neighborhoods';
import { notifications } from './notifications';
import { profiles } from './profiles';
import { profiles_public } from './profiles_public';
import { properties_schemas } from './properties_schemas';
import { properties_schemas_segments } from './properties_schemas_segments';
import { regions } from './regions';
import { services } from './services';
import { streets } from './streets';
import { user_level_permissions } from './user_level_permissions';
import { user_levels } from './user_levels';
import { users } from './users';
import { wishlists } from './wishlists';
import { zip_codes } from './zip_codes';
import { properties_lists } from './properties_lists';
import { properties_edits } from './properties_edits';
import { properties_linked } from './properties_linked';
import { news } from './news';
import { offers_prices } from './offers_prices';
import { tickets } from './tickets';
import { earnests_logs } from './earnests_logs';
import { property_history } from './property_history';
import { ratings } from './ratings';
import { clients_views } from './clients_views';
import { invests_logs } from './invests_logs';
import { rents_logs } from './rents_logs';
import { payments } from './payments';
import { financial_supports } from './financial_supports';
import { sell_orders } from './sell_orders';
import { sell_orders_details } from './sell_orders_details';
import { mediation_contracts } from './mediation_contracts';
import { rooms } from './rooms';
import { properties_schemas_segments_edit } from './properties_schemas_segments_edit';
import { properties_schemas_edit } from './properties_schemas_edit';
import { control_operations } from './control_operations';
import { EmailService } from "./email.service";
import { SMSService } from "./sms.service";
import { NafazService } from './nafaz.service';
import { RegaService } from './rega.service';

// Classes and pipes
export {
	DbApp,
	UserData,
	Settings,
	DbTable,
	DbRecord,
	DbField,
	DbFile,
	LocalDatePipe,
	LocalNumberPipe,
	LocalCurrencyPipe,
	LocalPercentPipe,
	AuthFilePipe,
	ShortNumberPipe,
	DurationPipe,
	CountdownPipe,
	PropertyStatusPipe,
	PropertyTypePipe,
	PropertyCategoryPipe,
	PropertyFeaturePipe,
	PropertyTypeIconsPipe,
	LocaleService,
	History,
	add_appointments,
	add_property_1,
	auctions_logs,
	cities,
	clientsrequests,
	countries,
	files_media_panos,
	files_media_photos,
	files_media_videos,
	messages,
	my_properties,
	nearby_services,
	nearby_services_types,
	neighborhoods,
	notifications,
	profiles,
	profiles_public,
	properties_schemas,
	properties_schemas_segments,
	regions,
	services,
	streets,
	user_level_permissions,
	user_levels,
	users,
	wishlists,
	zip_codes,
	properties_lists,
	properties_edits,
	properties_linked,
	news,
	offers_prices,
	tickets,
	earnests_logs,
	property_history,
	ratings,
	clients_views,
	invests_logs,
	rents_logs,
	payments,
	financial_supports,
	sell_orders,
	sell_orders_details,
	mediation_contracts,
	rooms,
	properties_schemas_segments_edit,
	properties_schemas_edit,
	control_operations,
	EmailService,
	SMSService,
	NafazService,
	RegaService
};

// Validators
export const DbAppValidators = {
	creditCard,
	date,
	time,
	integer,
	float,
	saudiphone,
	usphone,
	uszip,
	usssn,
	guid,
	mustMatch,
	files
}