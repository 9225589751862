import { OnDestroy, OnInit } from '@angular/core';
import { NavController, ModalController, AlertController, LoadingController, PopoverController, ActionSheetController, ToastController, IonSearchbar  } from '@ionic/angular';
import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, NgForm, AbstractControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Observable, Subscription, BehaviorSubject, from, timer } from 'rxjs';
import { IonInfiniteScroll, Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { File, Entry, FileEntry } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FileUploadComponent, FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { UserData, DbRecord, DbFile, LocaleService, Settings, DbApp, DbAppValidators, History } from '../../providers';
import { environment } from '../../../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { invests_logs, notifications } from '../../providers';
import { EmailServiceFirstParty } from '../../providers/email.firstparty.service';
import { SMSServiceFirstParty } from '../../providers/sms.firstparty.service';
import { EmailServiceSecondParty } from '../../providers/email.secondparty.service';
import { SMSServiceSecondParty } from '../../providers/sms.secondparty.service';
import { v4 as uuidv4 } from 'uuid';

// Component
@Component({
	selector: 'page-invests_logs-add',
	templateUrl: 'invests_logsadd.html',
	styleUrls: ['invests_logsadd.scss'],
	providers: [EmailServiceFirstParty, SMSServiceFirstParty, EmailServiceSecondParty, SMSServiceSecondParty],
})
export class invests_logsAddPage implements OnDestroy, OnInit {
	key: any;
	keyCount: number = 1;
	item: any;
	item$: BehaviorSubject<DbRecord>;
	foreignKey: any;
	pageId: string = "add";
	pageUrl: string = "invests_logsadd";
	returnUrl: string = "/invests_logslist";
	loadingMessage: string;
	dataLoaded: boolean;
	formGroup: FormGroup;
	isLoggedIn: boolean;
	isAdmin: boolean;
	userIdFirstParty: string;
	userIdSecondParty: string;
	permissions: any;
	userIdAllowed: boolean;
	submitted: boolean;
	uuidv4: string = uuidv4();
	properties_property_id: string;
	properties_reference_code: string;
	properties_property_type_id: string;
	invest_security_amount: number;
	invest_amount: number;
	userEmailFirstParty: string;
	userMobileFirstParty: number;
	userFullNameFirstParty: string;
	smsQueueRecipientFirstParty: string;
	smsQueueContentFirstParty: string;
	fromEmailFirstParty: string;
	toEmailFirstParty: string;
	ccEmailFirstParty: string;
	bccEmailFirstParty: string;
	subjectFirstParty: string;
	emailContentFirstParty: string;
	formatFirstParty: string;
	charsetFirstParty: string;
	smtpSecureFirstParty: string;
	arAttachmentsFirstParty: any[];
	arImagesFirstParty: any[];
	arPropertiesFirstParty: any;
	brandName: string;
	userTitleFullNameFirstParty: string;
	transactionDetailsTitleFirstParty: string;
	transactionDetailsLinkFirstParty: string;
	instructionsTitleFirstParty: string;
	instructionsSubTitleFirstParty: string;
	instructionsContentFirstParty: string;
	termsConditionsContent: string;
	extra_brand_name: string;
	extra_transaction_details_title_first_party: string;
	extra_transaction_details_link_first_party: string;
	extra_instructions_title_first_party: string;
	extra_instructions_subtitle_first_party: string;
	extra_instructions_content_first_party: string;
	extra_terms_conditions_content: string;
	extra_locale: string;
	posted_invest_amount: number;
	userEmailSecondParty: string;
	userMobileSecondParty: number;
	smsQueueRecipientSecondParty: string;
	smsQueueContentSecondParty: string;
	fromEmailSecondParty: string;
	toEmailSecondParty: string;
	ccEmailSecondParty: string;
	bccEmailSecondParty: string;
	subjectSecondParty: string;
	emailContentSecondParty: string;
	formatSecondParty: string;
	charsetSecondParty: string;
	smtpSecureSecondParty: string;
	arAttachmentsSecondParty: any[];
	arImagesSecondParty: any[];
	arPropertiesSecondParty: any;
	userTitleFullNameSecondParty: string;
	userFullNameSecondParty: string
	transactionDetailsTitleSecondParty: string;
	transactionDetailsLinkSecondParty: string;
	instructionsTitleSecondParty: string;
	instructionsSubTitleSecondParty: string;
	instructionsContentSecondParty: string;
	extra_transaction_details_title_second_party: string;
	extra_transaction_details_link_second_party: string;
	extra_instructions_title_second_party: string;
	extra_instructions_subtitle_second_party: string;
	extra_instructions_content_second_party: string;
	private saving: boolean;
	private subscription: Subscription; // For AutoSuggest
	private currentPage: number; // For AutoSuggest
	private navigationSubscription: Subscription;

	// Constructor
	constructor(
		public dbapp: DbApp,
		public translate: TranslateService,
		public locale: LocaleService,
		public navController: NavController,
		public loadingController: LoadingController,
		public actionSheetController: ActionSheetController,
		public modalController: ModalController,
		public alertController: AlertController,
		public toastController: ToastController,
		public router: Router,
		public formBuilder: FormBuilder,
		public user: UserData,
		public inAppBrowser: InAppBrowser,
		private activatedRoute: ActivatedRoute,
		public history: History,
		private camera: Camera,
		private file: File,
		private webview: WebView,
		private platform: Platform,
		private changeDetectorRef: ChangeDetectorRef,
		private filePath: FilePath,
		private fileChooser: FileChooser,
		private sanitizer: DomSanitizer,
		public items: invests_logs,
		public notificationItems: notifications,
		private emailServiceFirstParty: EmailServiceFirstParty,
		private smsServiceFirstParty: SMSServiceFirstParty,
		private emailServiceSecondParty: EmailServiceSecondParty,
		private smsServiceSecondParty: SMSServiceSecondParty
		) {
		this.item$ = new BehaviorSubject<DbRecord>(null);
		this.userIdAllowed = this.dbapp.userIdAllow("invests_logs", this.pageId);
		this.getUserData();
	}

	// OnInit
	ngOnInit() {
		this.navigationSubscription = this.router.events.subscribe(async (e: any) => {
			if (e instanceof NavigationEnd && this.history.isCurrentPage(this.pageUrl)) { // Re-init component
				await this.init();
			}
		});
	}

	async ionViewWillEnter(){
		await this.init();
	}

	// OnDestroy
	ngOnDestroy() {
		if (this.navigationSubscription)
			this.navigationSubscription.unsubscribe(); // Clean up
	}

	validateBidAmount(control: AbstractControl): { [key: string]: any } | null {
	  const propertiesBidAmount = control.value;
  
	  if (propertiesBidAmount !== '' && parseFloat(propertiesBidAmount) <= this.invest_amount) {
		return { 'amountInvalid': true };
	  }
	  return null;
	}

	// Init
	async init() {
		this.key = this.getPrimaryKey();
		this.foreignKey = await this.getForeignKey();
		this.formGroup = this.formBuilder.group({
			properties_invest_id: [{ value: "", disabled: false }, [DbAppValidators.guid]],
			properties_invest_amount: [{ value: "", disabled: false }, [DbAppValidators.float]],
			properties_property_id: [{ value: "", disabled: false }, [DbAppValidators.guid]],
			system_user_id: [{ value: "", disabled: false }, []],
			properties_invest_status: [{ value: "", disabled: false }, []],
			properties_invest_last_edit_grogorian: [{ value: "", disabled: false }, []],
			properties_invest_last_edit_by: [{ value: "", disabled: false }, []],
		});
		await this.getItem();
	}

	// Get empty item
	getEmptyItem() {
		return {
			"properties_invest_id": this.uuidv4,
			"properties_invest_amount": "",
			"properties_property_id": this.properties_property_id,
			"system_user_id": this.userIdFirstParty,
			"properties_invest_status": "",
			"properties_invest_last_edit_grogorian": "",
			"properties_invest_last_edit_by": ""
		};
	}

	// Get item
	async getItem() {
		this.loadingMessage = await this.translate.get("LOADING").toPromise();
		const loading = await this.loadingController.create({
			spinner: null,
			cssClass: 'global-loader',
			showBackdrop: true,
		});
		this.dataLoaded = false;
		await loading.present();
		try {
			let item = this.dbapp.isObject(this.key)
				? await this.items.query(Object.assign({ action: "view" }, this.key)) // Copy, use "view" action to get the record
				: this.getEmptyItem(); // Add
			if (this.dbapp.isObject(item)) {
				if (this.foreignKey) {
					let fk = Object.assign({}, this.foreignKey);
					delete(fk[this.dbapp.TABLE_MASTER])
					for (let k in fk)
						item[this.items.getFieldName(k)] = fk[k];
				}
				await this.items.lookup(item, this.pageId);
				this.item = await this.items.renderRow(item, this.pageId);
				this.item$.next(this.item);
				this.formGroup.patchValue({
					properties_invest_id: this.item['properties_invest_id'].dbValue,
					properties_invest_amount: this.item['properties_invest_amount'].dbValue,
					properties_property_id: this.item['properties_property_id'].dbValue,
					system_user_id: this.item['system_user_id'].dbValue,
					properties_invest_status: this.item['properties_invest_status'].value,
					properties_invest_last_edit_grogorian: this.item['properties_invest_last_edit_grogorian'].dbValue,
					properties_invest_last_edit_by: this.item['properties_invest_last_edit_by'].dbValue
				});
			}
		} catch(err) {
			this.showError(err);
		} finally {
			this.dataLoaded = true;
			await loading.dismiss();
		}
	}

	/**
	 * Add
	 */
	async add() {
		this.submitted = true;
		if (!this.formGroup.valid) {
			let err = await this.translate.get("VALIDATION_ERROR").toPromise();
			if (err != "VALIDATION_ERROR")
				this.showError(err);
			return false;
		} else {
			let value = await this.unformatValue(this.formGroup.value), res;
			this.saving = true;
			try {
				res = await this.items.add(value);
			} finally {
				this.saving = false;
			}
			if (res && res.success) {
				this.posted_invest_amount = await this.formGroup.get('properties_invest_amount').value;
				this.invest_amount = this.posted_invest_amount;
				this.formGroup.reset();
				this.submitted = false;
				this.modalController.dismiss();
				this.presentInvestBidToast();
				if(this.userEmailFirstParty)
				this.sendEmailFirstParty();
				if(this.userEmailSecondParty)
				this.sendEmailSecondParty();
				if(this.userMobileFirstParty)
					this.sendSMSFirstParty();
				if(this.userMobileSecondParty)
					this.sendSMSSecondParty();
				if(this.userIdFirstParty){
						const value = {					  
							notifications_id: this.uuidv4,
							// notifications_publish: this.durationPipe.transform(this.dbapp.formatTimestamp),
							notifications_type_id: '4',
							notifications_source: this.userIdFirstParty,
							notifications_user_id: this.userIdSecondParty,
							notifications_seen: '0',
							notifications_title: await this.translate.get("new_invest_submitted_title", { s: this.properties_reference_code }).toPromise(),
							notifications_body: await this.translate.get("new_invest_submitted_body", { p: this.userFullNameFirstParty, d: this.dbapp.formatTimestamp(Date.now()), s: this.posted_invest_amount, c: this.translate.instant("SAR") }).toPromise(),
							notifications_link: this.uuidv4,
							notifications_status_id: '1'
						};
						try {
						const res = await this.notificationItems.add(value);
							console.log("Add", this.notificationItems);
							} catch (err) {
						}
					}
				if (this.foreignKey) {
					let fk = Object.assign({}, this.foreignKey);
					delete(fk[this.dbapp.TABLE_MASTER])
					// this.router.navigate([this.returnUrl, fk]);
				} else {
					// this.router.navigate([this.returnUrl]);
				}
			} else if (res && !res.success && res.failureMessage) {
				if (environment.production) {
					let err = await this.translate.get("FAILED_TO_ADD").toPromise();
					await this.showError(err);
				} else {
					await this.showError(res.failureMessage);
				}
			}
		}
	}

	/**
	 * Get foreign key
	 */
	async getForeignKey() {
		return await this.user.get("invests_logs_foreignKey") || {};
	}

	/**
	 * Get primary key
	 */
	getPrimaryKey(): any {
		let keys = {}, key;
		key = this.activatedRoute.snapshot.paramMap.get("properties_invest_id");
		if (!this.dbapp.isEmpty(key))
			keys["properties_invest_id"] = key;
		return (Object.keys(keys).length === this.keyCount) ? keys : false;
	}

	// Get user data
	getUserData() {
		this.user.isLoggedIn.subscribe(res => {
			this.isLoggedIn = res;
			this.isAdmin = this.user.isAdmin;
			this.userIdFirstParty = this.user.userId;
			this.userEmailFirstParty = this.user.userEmail;
			this.userMobileFirstParty = this.user.userMobile;
			this.userFullNameFirstParty = this.user.userFullname;
			this.permissions = this.user.permissions;
		});
	}

	/**
	 * Show message
	 */
	async showMessage(msg: string, header: string) {
		let values = await this.translate.get(["OK_BUTTON", header]).toPromise();
		const alert = await this.alertController.create({
			header: values[header],
			message: msg,
			buttons: [values.OK_BUTTON]
		});
		await alert.present();
	}

	/**
	 * Show error
	 */
	async showError(err: any) {
		let msg = (err instanceof Error) ? err.message : err;
		return this.showMessage(msg, "ERROR");
	}

	/**
	 * Show success message
	 */
	async showSuccess(msg: string) {
		return this.showMessage(msg, "SUCCESS");
	}

	/**
	 * Filter (SELECT)
	 */
	filter(items: any, text: string) {
		return items.filter(item => item.name.toLowerCase().includes(text));
	}

	/**
	 * On search fail
	 */
	onSearchFail(event: {
		component: IonicSelectableComponent,
		text: string
	}) {
		if (event.component.addItemTemplate) {
			let name = (event.component as any)._element.nativeElement.getAttribute("formControlName"),
				fg = this.formGroup.get(name + "Option"),
				f = fg.get("displayField") || fg.get("linkField");
			if (f)
				f.setValue(event.text);
			event.component.showAddItemTemplate();
		}
	}

	/**
	 * On search success
	 */
	onSearchSuccess(event: {
		component: IonicSelectableComponent,
		text: string
	}) {
		if (event.component.addItemTemplate)
			event.component.hideAddItemTemplate();
	}

	/**
	 * Get AutoSuggest parameters (TEXT)
	 */
	private getAutoSuggestParams(component: IonicSelectableComponent) {
		let fldname = (component as any)._element.nativeElement.dataset.field,
			params = {
				action: "lookup",
				ajax: "autosuggest",
				page: this.items.name + "_" + this.pageId,
				field: fldname,
				n: this.dbapp.autoSuggestPageSize,
				start: (this.currentPage - 1) * this.dbapp.autoSuggestPageSize
			};
		return params;
	}

	/**
	 * Get suggestions (TEXT)
	 */
	async getSuggestions(event: {
		component: IonicSelectableComponent,
		text: string
	}) {
		let component = event.component;
		let oldItems = component.hasValue()
			? component.items.filter(item => (component as any)._valueItems.includes(item[component.itemValueField]))
			: [];
		let text = event.text.trim();
		component.startSearch();

		// Close any running subscription.
		if (this.subscription)
			this.subscription.unsubscribe();
		if (!text) {

			// Close any running subscription
			if (this.subscription)
				this.subscription.unsubscribe();
			component.items = oldItems;
			component.endSearch();
			return;
		}
		this.currentPage = 1;
		let params = this.getAutoSuggestParams(component);
		params["q"] = text;
		this.subscription = from(this.items.query(params)).subscribe(items => {

			// Subscription will be closed when unsubscribed manually
			if (this.subscription.closed)
				return;
			let fldvar = (component as any)._element.nativeElement.getAttribute("formControlName");
			items.forEach(item => item["name"] = this.dbapp.displayValue(item, this.items.displayValueSeparators[fldvar]));
			if (items.length) {
				for (let oldItem of oldItems) { // Add old items
					if (!items.some(item => oldItem[component.itemValueField] == item[component.itemValueField]))
						items = [oldItem, ...items];
				}
				component.items = items;
				component.endSearch();

				// Infinite scroll
				if (items.totalRecordCount > params.start + items.length) {
					this.currentPage++;
					component.enableInfiniteScroll();
				} else {
					component.disableInfiniteScroll();
				}
			} else {
				component.items = oldItems;
				component.endSearch();
				if (text && component.addItemTemplate) {
					this.formGroup.get(fldvar + "Option").get("displayField").setValue(text);
					component.showAddItemTemplate();
				}
			}
		});
	}

	/**
	 * Get more suggestions (TEXT)
	 */
	getMoreSuggestions(event: {
		component: IonicSelectableComponent,
		text: string
	}) {
		let params = this.getAutoSuggestParams(event.component);
		params["q"] = (event.text || "").trim();
		from(this.items.query(params)).subscribe(items => {
			let fldvar = (event.component as any)._element.nativeElement.getAttribute("formControlName");
			items.forEach(item => item["name"] = this.dbapp.displayValue(item, this.items.displayValueSeparators[fldvar]));
			event.component.items = event.component.items.concat(items);
			event.component.endInfiniteScroll();

			// Infinite scroll
			if (items.totalRecordCount > params.start + items.length) {
				this.currentPage++;
				event.component.enableInfiniteScroll();
			} else {
				event.component.disableInfiniteScroll();
			}
		});
	}

	/**
	 * Create form group for new option
	 */
	getNewOptionFormGroup() {
		return this.formBuilder.group({
			linkField: "",
			displayField: "",
			displayField2: "",
			displayField3: "",
			displayField4: ""
		});
	}

	/**
	 * Add option
	 */
	async addOption(name: string) {
		let component = this[name];
		if (!(component instanceof IonicSelectableComponent))
			return;

		// Get values
		let el = (component as any)._element.nativeElement,
			dataset = el.dataset,
			linkTable = dataset.lt,
			option = this.formGroup.get(name + "Option"),
			value = option.value,
			data = {},
			item = {};
		if (dataset.lf)
			item["lf"] = data[dataset.lf] = value.linkField;
		if (dataset.df)
			item["df"] = data[dataset.df] = value.displayField;
		if (dataset.df2)
			item["df2"] = data[dataset.df2] = value.displayField2;
		if (dataset.df3)
			item["df3"] = data[dataset.df3] = value.displayField3;
		if (dataset.df4)
			item["df4"] = data[dataset.df4] = value.displayField4;

		// Add new option
		try {
			let result = await this.items.send(Object.assign({ object: linkTable, action: "add" }, data));

			// Add item
			if (!dataset.autoSuggest) {
				let fldvar = el.getAttribute("formControlName");
				if (dataset.lfAuto) // Link field is autoinc
					item["lf"] = result[linkTable][dataset.lfAuto];
				item["df"] = item["df"] || item["lf"]; // Display field = Link field
				item["name"] = this.dbapp.displayValue(item, this.items.displayValueSeparators[fldvar]);
				await component.addItem(item);
			}

			// Search the new option
			component.search(data[dataset.df]);

			// Reset
			option.reset();

			// Show list
			component.hideAddItemTemplate();
		} catch(err) {
			await this.showError(err);
		}
	}

	// Unformat field values before submit
	async unformatValue(value: any) {
		let gpSep = this.locale.groupSeparator,
			decSep = this.locale.decimalSeparator;
		for (let fldvar of ["properties_invest_status"])
			value[fldvar] = this.dbapp.parseNumber(value[fldvar], gpSep, decSep);
		for (let fldvar of ["properties_invest_last_edit_grogorian"])
			value[fldvar] = this.dbapp.parseDate(value[fldvar]);
		for (let fldvar of ["properties_invest_status"])
			value[fldvar] = value[fldvar] ? 1 : 0;
		return value;
	}

	// Quick access to form controls
	get f() {
		return this.formGroup.controls;
	}

	// Open URL
	openUrl(url: string, target?: string) {
		this.inAppBrowser.create(url, target);
	}

	/**
	 * Select image
	 * @param {string} sourceType Source type ('PHOTOLIBRARY'|'CAMERA'|'SAVEDPHOTOALBUM'|'FILE')
	 * @param {string} fldvar Field variable name
	 * e.g. <ion-button (click)="selectImage('CAMERA', 'fldvar')"><ion-icon slot="start" name="camera"></ion-icon></ion-button>
	 */
	async selectImage(sourceType: string, fldvar: string) {
		let entry = await this.takePicture(sourceType),
			file = new DbFile(entry);
		file.previewUrl = this.webview.convertFileSrc(this.file.dataDirectory + entry.name);
		this.item[fldvar].addFiles([file]);
	}

	/**
	 * Take picture and copy to this.file.dataDirectory
	 * e.g. this.takePicture(this.camera.PictureSourceType.CAMERA)
	 * @param {string} sourceType Source type ('PHOTOLIBRARY'|'CAMERA'|'SAVEDPHOTOALBUM'|'FILE')
	 * @returns {Entry}
	 */
	async takePicture(sourceType: string) {
		let options = Object.assign({}, this.dbapp.cameraOptions, { sourceType: this.camera.PictureSourceType[sourceType] }),
			isCamera = ["PHOTOLIBRARY", "CAMERA", "SAVEDPHOTOALBUM"].includes(sourceType),
			correctPath, currentName;
		try {
			let imagePath = isCamera ? await this.camera.getPicture(options) : await this.fileChooser.open();
			if (this.platform.is("android") && sourceType === "PHOTOLIBRARY") {
				currentName = imagePath.substring(imagePath.lastIndexOf("/") + 1, imagePath.lastIndexOf("?"));
				let filePath = await this.filePath.resolveNativePath(imagePath);
				correctPath = filePath.substr(0, filePath.lastIndexOf("/") + 1);
			} else {
				currentName = imagePath.substr(imagePath.lastIndexOf("/") + 1);
				correctPath = imagePath.substr(0, imagePath.lastIndexOf("/") + 1);
			}
			let entry = await this.file.copyFile(correctPath, currentName, this.file.dataDirectory, this.dbapp.createFileName());
			return await this.file.resolveLocalFilesystemUrl(this.file.dataDirectory + entry.name);
		} catch(err) { // e.g. Cordova not available
			this.showError(err);
		}
	}

	/**
	 * Remove a file from a field
	 * @param {string} fldvar Field name
	 * @param {DbFile} dbfile File to be removed
	 */
	async removeFile(fldvar: string, dbfile: DbFile) {
		let i = this.item[fldvar].files.indexOf(dbfile),
			f = dbfile.file;
		if (f) {
			if (f instanceof Blob) { // File upload component
				this[fldvar].control.removeFile(f); // Will trigger valueChanges
			} else if (f.isFile) { // Entry (Take picture)
				let fullPath = this.file.dataDirectory + f.name,
					correctPath = fullPath.substr(0, fullPath.lastIndexOf("/") + 1);
				await this.file.removeFile(correctPath, f.name);
			}
		}
		if (dbfile)
			this.item[fldvar].removeFile(dbfile);
	}

	/**
	 * Remove files from a field
	 * @param {string} fldvar Field variable name
	 */
	async removeFiles(fldvar: string) {
		let files = this.item[fldvar].files;
		for (let i = files.length - 1; i >= 0; i--) {
			if (files[i].isBlob)
				this.item[fldvar].removeFile(i); // Do not remove files from the File upload component
			else
				this.removeFile(fldvar, files[i]);
		}
	}
	async presentInvestBidToast() {
		let values = await this.translate.get(["invest_bid_succeesful"]).toPromise();
		const toast = await this.toastController.create({
		  message: values['invest_bid_succeesful'],
		  duration: 2000
		});
		toast.present();
	  }


	  /** Start Notifications First Party Functions */
	  async sendEmailFirstParty() {
		this.fromEmailFirstParty = 'admin@toor.ooo';
		this.toEmailFirstParty = this.userEmailFirstParty;
		this.ccEmailFirstParty = '';
		this.bccEmailFirstParty = '';
		this.subjectFirstParty = await this.translate.get("your_bid_for_invest_done_email_subject_first_party", { s: this.properties_reference_code }).toPromise();
		this.emailContentFirstParty = await this.translate.get("your_bid_for_invest_done_email_content_first_party", { s: this.posted_invest_amount, m: this.translate.instant("riyal_saudi"), f: this.properties_reference_code, v: this.properties_property_type_id, d: this.dbapp.formatTimestamp(Date.now()) }).toPromise();
		this.formatFirstParty = 'text';
		this.charsetFirstParty = 'UTF-8';
		this.smtpSecureFirstParty = 'smtp';
		this.arAttachmentsFirstParty = [];
		this.arImagesFirstParty = [];
		this.arPropertiesFirstParty = null;
		this.userTitleFullNameFirstParty = await this.translate.get("mr_mrs", { s: this.userFullNameFirstParty }).toPromise();
		this.transactionDetailsTitleFirstParty = await this.translate.get("see_invest_bid_details_first_party").toPromise();
		this.transactionDetailsLinkFirstParty = 'https://www.toor.ooo/invests_logsview/'+this.uuidv4;
		this.instructionsTitleFirstParty = await this.translate.get("invest_instructions_title_first_party").toPromise();
		this.instructionsSubTitleFirstParty = await this.translate.get("invest_instructions_subtitle_first_party").toPromise();
		this.instructionsContentFirstParty = await this.translate.get("invest_instructions_content_first_party").toPromise();
		this.brandName = await this.translate.get("toorprime").toPromise();
		this.termsConditionsContent = await this.translate.get("terms_conditions_for_email").toPromise();
		const emailHeaderClientFirstParty = {
		  from_email_first_party: this.fromEmailFirstParty,
		  to_email_first_party: this.toEmailFirstParty,
		  cc_email_first_party: this.ccEmailFirstParty,
		  bcc_email_first_party: this.bccEmailFirstParty,
		  subject_first_party: this.subjectFirstParty,
		  email_content_first_party: this.emailContentFirstParty,
		  format_first_party: this.formatFirstParty,
		  charset_first_party: this.charsetFirstParty,
		  smtp_secure_first_party: this.smtpSecureFirstParty,
		  ar_attachments_first_party: this.arAttachmentsFirstParty,
		  ar_images_first_party: this.arImagesFirstParty,
		  ar_properties_first_party:this.arPropertiesFirstParty,
		  extra_userfullname_first_party: this.userTitleFullNameFirstParty,
		  extra_transaction_details_title_first_party: this.transactionDetailsTitleFirstParty,
		  extra_transaction_details_link_first_party: this.transactionDetailsLinkFirstParty,
		  extra_instructions_title_first_party: this.instructionsTitleFirstParty,
		  extra_instructions_subtitle_first_party: this.instructionsSubTitleFirstParty,
		  extra_instructions_content_first_party: this.instructionsContentFirstParty,
		  extra_brand_name: this.brandName,
		  extra_terms_conditions_content: this.termsConditionsContent,
		  extra_locale: this.locale.locale
		};
	  
		try {
		  const response = await this.emailServiceFirstParty.sendEmailHeaderFirstParty(
			this.fromEmailFirstParty,
			this.toEmailFirstParty,
			this.ccEmailFirstParty,
			this.bccEmailFirstParty,
			this.subjectFirstParty,
			this.emailContentFirstParty,
			this.formatFirstParty,
			this.charsetFirstParty,
			this.smtpSecureFirstParty,
			this.arAttachmentsFirstParty,
			this.arImagesFirstParty,
			this.arPropertiesFirstParty,
			this.userTitleFullNameFirstParty,
			this.transactionDetailsTitleFirstParty,
			this.transactionDetailsLinkFirstParty,
			this.instructionsTitleFirstParty,
			this.instructionsSubTitleFirstParty,
			this.instructionsContentFirstParty,
			this.brandName,
			this.termsConditionsContent,
			this.locale.locale
		  );
		  console.log('Email sent successfully', response);
		} catch (error) {
		  console.error('Failed to send Email', error);
		}
	  }
	  
		async sendSMSFirstParty() {
		  this.smsQueueRecipientFirstParty = '+966'+this.userMobileFirstParty;
		  this.smsQueueContentFirstParty = await this.translate.get("your_bid_for_invest_done_sms_content_first_party", { s: this.properties_reference_code }).toPromise();
		  const msgHeaderClientFirstParty = {
			  smsQueueRecipientFirstParty: this.smsQueueRecipientFirstParty,
			  smsQueueContentFirstParty: this.smsQueueContentFirstParty
		  };
		  try {
			const response = await this.smsServiceFirstParty.sendMessageHeaderFirstParty(this.smsQueueRecipientFirstParty, this.smsQueueContentFirstParty);
			console.log('SMS sent successfully', response);
		  } catch (error) {
			console.error('Failed to send SMS', error);
		  }
		}
/** End Notifications First Party Functions */



/** Start Notifications Second Party Functions */
async sendEmailSecondParty() {
this.fromEmailSecondParty = 'admin@toor.ooo';
this.toEmailSecondParty = this.userEmailSecondParty;
this.ccEmailSecondParty = '';
this.bccEmailSecondParty = '';
this.subjectSecondParty = await this.translate.get("your_bid_for_invest_done_email_subject_second_party", { s: this.properties_reference_code }).toPromise();
this.emailContentSecondParty = await this.translate.get("your_bid_for_invest_done_email_content_second_party", { s: this.posted_invest_amount, m: this.translate.instant("riyal_saudi"), f: this.properties_reference_code, v: this.properties_property_type_id, d: this.dbapp.formatTimestamp(Date.now()) }).toPromise();
this.formatSecondParty = 'text';
this.charsetSecondParty = 'UTF-8';
this.smtpSecureSecondParty = 'smtp';
this.arAttachmentsSecondParty = [];
this.arImagesSecondParty = [];
this.arPropertiesSecondParty = null;
this.userTitleFullNameSecondParty = await this.translate.get("mr_mrs", { s: this.userFullNameSecondParty }).toPromise();
this.transactionDetailsTitleSecondParty = await this.translate.get("see_invest_bid_details_second_party").toPromise();
this.transactionDetailsLinkSecondParty = 'https://www.toor.ooo/invests_logsview/'+this.uuidv4;
this.instructionsTitleSecondParty = await this.translate.get("invest_instructions_title_second_party").toPromise();
this.instructionsSubTitleSecondParty = await this.translate.get("invest_instructions_subtitle_second_party").toPromise();
this.instructionsContentSecondParty = await this.translate.get("invest_instructions_content_second_party").toPromise();
this.brandName = await this.translate.get("toorprime").toPromise();
this.termsConditionsContent = await this.translate.get("terms_conditions_for_email").toPromise();
const emailHeaderClientSecondParty = {
from_email_second_party: this.fromEmailSecondParty,
to_email_second_party: this.toEmailSecondParty,
cc_email_second_party: this.ccEmailSecondParty,
bcc_email_second_party: this.bccEmailSecondParty,
subject_second_party: this.subjectSecondParty,
email_content_second_party: this.emailContentSecondParty,
format_second_party: this.formatSecondParty,
charset_second_party: this.charsetSecondParty,
smtp_secure_second_party: this.smtpSecureSecondParty,
ar_attachments_second_party: this.arAttachmentsSecondParty,
ar_images_second_party: this.arImagesSecondParty,
ar_properties_second_party:this.arPropertiesSecondParty,
extra_userfullname_second_party: this.userFullNameSecondParty,
extra_transaction_details_title_second_party: this.transactionDetailsTitleSecondParty,
extra_transaction_details_link_second_party: this.transactionDetailsLinkSecondParty,
extra_instructions_title_second_party: this.instructionsTitleSecondParty,
extra_instructions_subtitle_second_party: this.instructionsSubTitleSecondParty,
extra_instructions_content_second_party: this.instructionsContentSecondParty,
extra_brand_name: this.brandName,
extra_terms_conditions_content: this.termsConditionsContent,
extra_locale: this.locale.locale
};

try {
const response = await this.emailServiceSecondParty.sendEmailHeaderSecondParty(
  this.fromEmailSecondParty,
  this.toEmailSecondParty,
  this.ccEmailSecondParty,
  this.bccEmailSecondParty,
  this.subjectSecondParty,
  this.emailContentSecondParty,
  this.formatSecondParty,
  this.charsetSecondParty,
  this.smtpSecureSecondParty,
  this.arAttachmentsSecondParty,
  this.arImagesSecondParty,
  this.arPropertiesSecondParty,
  this.userTitleFullNameSecondParty,
  this.transactionDetailsTitleSecondParty,
  this.transactionDetailsLinkSecondParty,
  this.instructionsTitleSecondParty,
  this.instructionsSubTitleSecondParty,
  this.instructionsContentSecondParty,
  this.brandName,
  this.termsConditionsContent,
  this.locale.locale
);
console.log('Email sent successfully', response);
} catch (error) {
console.error('Failed to send Email', error);
}
}

async sendSMSSecondParty() {
this.smsQueueRecipientSecondParty = '+966'+this.userMobileSecondParty;
this.smsQueueContentSecondParty = await this.translate.get("your_bid_for_invest_done_sms_content_second_party", { s: this.properties_reference_code }).toPromise();
const msgHeaderClientSecondParty = {
	smsQueueRecipientSecondParty: this.smsQueueRecipientSecondParty,
	smsQueueContentSecondParty: this.smsQueueContentSecondParty
};
try {
  const response = await this.smsServiceSecondParty.sendMessageHeaderSecondParty(this.smsQueueRecipientSecondParty, this.smsQueueContentSecondParty);
  console.log('SMS sent successfully', response);
} catch (error) {
  console.error('Failed to send SMS', error);
}
}
/** End Notifications First Party Functions */

	cancel() {
		this.modalController.dismiss();
	  }
}