import { NgModule, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserData, Settings, LocaleService, DbApp, History } from './';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'propertyFeature'
})
export class PropertyFeaturePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(properties_property_features_id: string): string {
    const propertyFeatureId = parseInt(properties_property_features_id, 10);

    switch (propertyFeatureId) {
      case 0:
        return this.translate.instant("undefiend");
      case 1:
        return this.translate.instant("low_price");
      case 2:
        return this.translate.instant("limited_period");
      case 3:
        return this.translate.instant("open_to_customers");
      case 4:
        return this.translate.instant("investment_opportunity");
      case 5:
        return this.translate.instant("lowest_price");
      case 6:
        return this.translate.instant("funding_accepted");
    }
  }
}