import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { satisfies } from 'semver';
import { environment } from '../../environments/environment';
import { DbApp } from './dbapp';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentService {
  constructor(
    public dbapp: DbApp,
    private http: HttpClient,
    public translate: TranslateService,
  ) {}

  async sendPayment(paymentHeaderClient: any): Promise<any> {
    const postPaymentHeader = {
      product_id: paymentHeaderClient.product_id,
      customer_id: paymentHeaderClient.customer_id,
      first_name: paymentHeaderClient.first_name,
      last_name: paymentHeaderClient.last_name,
      customer_email: paymentHeaderClient.customer_email,
      amount: paymentHeaderClient.amount,
      currency: paymentHeaderClient.currency,
      product: paymentHeaderClient.product,
      token: paymentHeaderClient.token,
    };

    console.log(paymentHeaderClient);
    return this.http.post(environment.apiUrl, postPaymentHeader).toPromise().then(async (data: any) => {
      data = data || {};
      if (data.version && !satisfies(data.version, this.dbapp.requiredApiVersion)) {
        await this.translate.get("INCOMPATIBLE_API", { s: data.version }).toPromise();
      }
      
      if (data.success) {
        console.log('Payment Sent');
      }
      
      return data;
    });
  }

  // New method to send a payment header and return an Observable
  sendPaymentHeader(postPaymentHeader: any): Observable<any> {
    const paymentHeaderClient = {
      product_id: postPaymentHeader.product_id,
      customer_id: postPaymentHeader.customer_id,
      first_name: postPaymentHeader.first_name,
      last_name: postPaymentHeader.last_name,
      customer_email: postPaymentHeader.customer_email,
      amount: postPaymentHeader.amount,
      currency: postPaymentHeader.currency,
      product: postPaymentHeader.product,
      token: postPaymentHeader.token,
    };
    console.log("paymentHeaderClient", paymentHeaderClient);
    
    return new Observable((observer) => {
      this.sendPayment(paymentHeaderClient)
        .then((data) => {
          observer.next(data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }
}