import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { satisfies } from 'semver';
import { environment } from '../../environments/environment';
import { DbApp } from './dbapp';

@Injectable()
export class EmailServiceSecondParty {

  constructor(
    public dbapp: DbApp,
    private http: HttpClient,
		public translate: TranslateService,
    ) {}

  async sendEmailSecondParty(emailHeaderClientSecondParty: any): Promise<any> {
    const emailDataSecondParty = {
      from_email_second_party: emailHeaderClientSecondParty.from_email_second_party,
      to_email_second_party: emailHeaderClientSecondParty.to_email_second_party,
      cc_email_second_party: emailHeaderClientSecondParty.cc_email_second_party,
      bcc_email_second_party: emailHeaderClientSecondParty.bcc_email_second_party,
      subject_second_party: emailHeaderClientSecondParty.subject_second_party,
      email_content_second_party: emailHeaderClientSecondParty.email_content_second_party,
      format_second_party: emailHeaderClientSecondParty.format_second_party,
      charset_second_party: emailHeaderClientSecondParty.charset_second_party,
      smtp_secure_second_party: emailHeaderClientSecondParty.smtp_secure_second_party,
      ar_attachments_second_party: emailHeaderClientSecondParty.ar_attachments_second_party,
      ar_images_second_party: emailHeaderClientSecondParty.ar_images_second_party,
      ar_properties_second_party: emailHeaderClientSecondParty.ar_properties_second_party,
      extra_userfullname_second_party: emailHeaderClientSecondParty.extra_userfullname_second_party,
		  extra_transaction_details_title_second_party: emailHeaderClientSecondParty.extra_transaction_details_title_second_party,
		  extra_transaction_details_link_second_party: emailHeaderClientSecondParty.extra_transaction_details_link_second_party,
      extra_instructions_title_second_party: emailHeaderClientSecondParty.extra_instructions_title_second_party,
      extra_instructions_subtitle_second_party: emailHeaderClientSecondParty.extra_instructions_subtitle_second_party,
      extra_instructions_content_second_party: emailHeaderClientSecondParty.extra_instructions_content_second_party,
      extra_brand_name: emailHeaderClientSecondParty.extra_brand_name,
      extra_terms_conditions_content: emailHeaderClientSecondParty.extra_terms_conditions_content,
      extra_locale: emailHeaderClientSecondParty.extra_locale
    };

return this.http.post(environment.apiUrl, emailDataSecondParty).toPromise().then(async (data: any) => {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  });
  data = data || {};
  if (data.version && !satisfies(data.version, this.dbapp.requiredApiVersion))
    await this.translate.get("INCOMPATIBLE_API", { s: data.version }).toPromise();
    
  if (data.success)
        console.log('Email Sent');
      return data;
    });
  }
  
  // Add a new method to pass the message header from the client-side
  async sendEmailHeaderSecondParty(
      from_email_second_party: string,
      to_email_second_party: string,
      cc_email_second_party: string,
      bcc_email_second_party: string,
      subject_second_party: string,
      email_content_second_party: string,
      format_second_party: string,
      charset_second_party: string,
      smtp_secure_second_party: string,
      ar_attachments_second_party: any[],
      ar_images_second_party: any[],
      ar_properties_second_party: any,
      extra_userfullname_second_party: string,
      extra_transaction_details_title_second_party: string,
      extra_transaction_details_link_second_party: string,
      extra_instructions_title_second_party: string,
      extra_instructions_subtitle_second_party: string,
      extra_instructions_content_second_party: string,
      extra_brand_name: string,
      extra_terms_conditions_content: string,
      extra_locale: string
    ): Promise<any> {
    const emailClientSecondParty = {
      from_email_second_party: from_email_second_party,
      to_email_second_party: to_email_second_party,
      cc_email_second_party: cc_email_second_party,
      bcc_email_second_party: bcc_email_second_party,
      subject_second_party: subject_second_party,
      email_content_second_party: email_content_second_party,
      format_second_party: format_second_party,
      charset_second_party: charset_second_party,
      smtp_secure_second_party: smtp_secure_second_party,
      ar_attachments_second_party: ar_attachments_second_party,
      ar_images_second_party: ar_images_second_party,
      ar_properties_second_party: ar_properties_second_party,
      extra_userfullname_second_party: extra_userfullname_second_party,
		  extra_transaction_details_title_second_party: extra_transaction_details_title_second_party,
		  extra_transaction_details_link_second_party: extra_transaction_details_link_second_party,
      extra_instructions_title_second_party: extra_instructions_title_second_party,
      extra_instructions_subtitle_second_party: extra_instructions_subtitle_second_party,
      extra_instructions_content_second_party: extra_instructions_content_second_party,
      extra_brand_name: extra_brand_name,
      extra_terms_conditions_content: extra_terms_conditions_content,
      extra_locale: extra_locale
    };
    return this.sendEmailSecondParty(emailClientSecondParty);
  }
}