import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { satisfies } from 'semver';
import { environment } from '../../environments/environment';
import { DbApp } from './dbapp';

@Injectable()
export class SMSServiceFirstParty {

  constructor(
    public dbapp: DbApp,
    private http: HttpClient,
    public translate: TranslateService,
  ) {}

  async sendSMSMessageFirstParty(msgHeaderClientFirstParty: any): Promise<any> {
    const bodyFirstParty = {
      sms_queue_recipient_first_party: msgHeaderClientFirstParty.sms_queue_recipient_first_party,
      sms_queue_content_first_party: msgHeaderClientFirstParty.sms_queue_content_first_party
    };

    return this.http.post(environment.apiUrl, bodyFirstParty).toPromise().then(async (data: any) => {
      data = data || {};
      if (data.version && !satisfies(data.version, this.dbapp.requiredApiVersion)) {
        await this.translate.get("INCOMPATIBLE_API", { s: data.version }).toPromise();
      }
      
      if (data.success) {
        console.log('SMS Sent');
      }
      
      return data;
    });
  }
  
  // Add a new method to pass the message header from the client-side
  async sendMessageHeaderFirstParty(smsQueueRecipientFirstParty: string, smsQueueContentFirstParty: string): Promise<any> {
    const msgHeaderClientFirstParty = {
      sms_queue_recipient_first_party: smsQueueRecipientFirstParty,
      sms_queue_content_first_party: smsQueueContentFirstParty
    };
    
    return this.sendSMSMessageFirstParty(msgHeaderClientFirstParty);
  }
}