import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { satisfies } from 'semver';
import { environment } from '../../environments/environment';
import { DbApp } from './dbapp';

@Injectable()
export class SMSService {

  constructor(
    public dbapp: DbApp,
    private http: HttpClient,
    public translate: TranslateService,
  ) {}

  async sendSMSMessage(msgHeaderClient: any): Promise<any> {
    const body = {
      sms_queue_recipient: msgHeaderClient.sms_queue_recipient,
      sms_queue_content: msgHeaderClient.sms_queue_content
    };

    console.log(msgHeaderClient);

    return this.http.post(environment.apiUrl, body).toPromise().then(async (data: any) => {
      data = data || {};
      if (data.version && !satisfies(data.version, this.dbapp.requiredApiVersion)) {
        await this.translate.get("INCOMPATIBLE_API", { s: data.version }).toPromise();
      }
      
      if (data.success) {
        console.log('SMS Sent');
      }
      
      return data;
    });
  }
  
  // Add a new method to pass the message header from the client-side
  async sendMessageHeader(smsQueueRecipient: string, smsQueueContent: string): Promise<any> {
    const msgHeaderClient = {
      sms_queue_recipient: smsQueueRecipient,
      sms_queue_content: smsQueueContent
    };
    
    return this.sendSMSMessage(msgHeaderClient);
  }
}