import { HttpClient } from '@angular/common/http';
import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DbApp } from './dbapp';
import { UserData } from './user-data';
import { DbTable } from './dbtable';
import { DbRecord } from './dbrecord';
import { DbField } from './dbfield';
import { LocaleService } from './locale.service';
import { LocalDatePipe } from './localdate.pipe';
import { LocalNumberPipe } from './localnumber.pipe';
import { LocalCurrencyPipe } from './localcurrency.pipe';
import { LocalPercentPipe } from './localpercent.pipe';
import { AuthFilePipe } from './authfile.pipe';

// financial_supports
@Injectable({
	providedIn: 'root'
})
export class financial_supports extends DbTable {
	pageId: string;
	fieldVars = ["financial_support_id","financial_support_status","properties_property_id","system_user_id","financial_support_applicant_name","financial_support_applicant_id","financial_support_applicant_email","financial_support_applicant_mobile","financial_support_deserving_status","financial_support_applicant_dob","financial_support_applicant_monthly_income","financial_support_applicant_monthly_commitments","financial_support_applicant_career","financial_support_applicant_career_military_rank","financial_support_applicant_has_personal_loan","financial_support_added_datetime","financial_support_company_id","financial_support_branch_id","financial_support_last_edit_gregorian","financial_support_last_edit_hijri","financial_support_last_edit_by"];
	fieldNames = []; // Array([name, var]) for fields with name != var
	listFields: string[] = ["financial_support_id","financial_support_status","properties_property_id","system_user_id","financial_support_applicant_name","financial_support_applicant_id","financial_support_applicant_email","financial_support_applicant_mobile","financial_support_deserving_status","financial_support_applicant_dob","financial_support_applicant_monthly_income","financial_support_applicant_monthly_commitments","financial_support_applicant_career","financial_support_applicant_career_military_rank","financial_support_applicant_has_personal_loan","financial_support_added_datetime","financial_support_company_id","financial_support_branch_id","financial_support_last_edit_gregorian","financial_support_last_edit_hijri","financial_support_last_edit_by"];
	viewFields: string[] = ["financial_support_id","financial_support_status","properties_property_id","system_user_id","financial_support_applicant_name","financial_support_applicant_id","financial_support_applicant_email","financial_support_applicant_mobile","financial_support_deserving_status","financial_support_applicant_dob","financial_support_applicant_monthly_income","financial_support_applicant_monthly_commitments","financial_support_applicant_career","financial_support_applicant_career_military_rank","financial_support_applicant_has_personal_loan","financial_support_added_datetime","financial_support_company_id","financial_support_branch_id","financial_support_last_edit_gregorian","financial_support_last_edit_hijri","financial_support_last_edit_by"];
	addFields: string[] = ["financial_support_id","financial_support_status","properties_property_id","system_user_id","financial_support_applicant_name","financial_support_applicant_id","financial_support_applicant_email","financial_support_applicant_mobile","financial_support_deserving_status","financial_support_applicant_dob","financial_support_applicant_monthly_income","financial_support_applicant_monthly_commitments","financial_support_applicant_career","financial_support_applicant_career_military_rank","financial_support_applicant_has_personal_loan","financial_support_added_datetime","financial_support_company_id","financial_support_branch_id","financial_support_last_edit_gregorian","financial_support_last_edit_hijri","financial_support_last_edit_by"];
	editFields: string[] = ["financial_support_id","financial_support_status","properties_property_id","system_user_id","financial_support_applicant_name","financial_support_applicant_id","financial_support_applicant_email","financial_support_applicant_mobile","financial_support_deserving_status","financial_support_applicant_dob","financial_support_applicant_monthly_income","financial_support_applicant_monthly_commitments","financial_support_applicant_career","financial_support_applicant_career_military_rank","financial_support_applicant_has_personal_loan","financial_support_added_datetime","financial_support_company_id","financial_support_branch_id","financial_support_last_edit_gregorian","financial_support_last_edit_hijri","financial_support_last_edit_by"];
	lookupTables: any = {};
	displayValueSeparators: any = {};
	errorMessages: any;
	row: any; // Current row (rendered)
	labelAttribute: string = "name";

	// Constructor
	constructor(public dbapp: DbApp,
		public user: UserData,
		public translate: TranslateService,
		public locale: LocaleService,
		public router: Router,
			public http: HttpClient) {
		super(dbapp, http, user, translate, router);
		this.name = "financial_supports";
		this.translate.get(this.fieldVars.map(fldvar => "__tables." + this.name + ".fields." + fldvar + ".errMsg")).subscribe(values => {
			for (let k in values)
				values[k] = (k != values[k]) ? values[k] : "";
			this.errorMessages = values;
		});
		this.translate.get(["__tables.financial_supports.fields.financial_support_status.tagValues","__tables.financial_supports.fields.financial_support_deserving_status.tagValues","__tables.financial_supports.fields.financial_support_applicant_career.tagValues","__tables.financial_supports.fields.financial_support_applicant_career_military_rank.tagValues","__tables.financial_supports.fields.financial_support_applicant_has_personal_loan.tagValues"]).subscribe(values => {
			this.lookupTables.financial_support_status = dbapp.convertUserValues(values["__tables.financial_supports.fields.financial_support_status.tagValues"]);
			this.lookupTables.financial_support_deserving_status = dbapp.convertUserValues(values["__tables.financial_supports.fields.financial_support_deserving_status.tagValues"]);
			this.lookupTables.financial_support_applicant_career = dbapp.convertUserValues(values["__tables.financial_supports.fields.financial_support_applicant_career.tagValues"]);
			this.lookupTables.financial_support_applicant_career_military_rank = dbapp.convertUserValues(values["__tables.financial_supports.fields.financial_support_applicant_career_military_rank.tagValues"]);
			this.lookupTables.financial_support_applicant_has_personal_loan = dbapp.convertUserValues(values["__tables.financial_supports.fields.financial_support_applicant_has_personal_loan.tagValues"]);
		});
		this.infiniteScroll = true;
		this.pageSize = 8;
	}

	// Lookup
	async lookup(item: any, pageId: string) {
		if (!item)
			return;
		pageId = pageId == "signup" ? "register" : pageId;
		let page = pageId != "register" ? this.name + "_" + pageId : pageId;
		this.pageId = pageId;
		let p = [];

		// Get lookup results
		try {
			[] = await Promise.all(p);
		} catch(err) {
			console.log(err);
		}
	}

	// Render field
	renderField(fieldName: string, pageId: string) {
		if (["list", "view", "add", "edit", "register"].includes(pageId))
			return this[pageId + "Fields"].includes(fieldName);
		return false;
	}

	// Get field variable name
	getFieldVar(name) {
		let f = this.fieldNames.find(f => f[0] == name);
		return f ? f[1] : name;
	}

	// Get field variable name
	getFieldName(varname) {
		let f = this.fieldNames.find(f => f[1] == varname);
		return f ? f[0] : varname;
	}

	// Render row
	async renderRow(item: any, pageId: string) {
		this.pageId = pageId;
		let row = new DbRecord(item, this.fieldNames, this.errorMessages);
		this.rowOnRender(row);

		// financial_support_status
		if (this.renderField("financial_support_status", pageId)) {
			let selectedRow = this.lookupTables.financial_support_status.find(r => r.lf == row["financial_support_status"].dbValue); // Compare with db value
			row["financial_support_status"].formValue = row["financial_support_status"].dbValue; // FormControl value cannot be undefined
			row["financial_support_status"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.financial_support_status) : row["financial_support_status"].dbValue;
		}

		// financial_support_applicant_id
		if (this.renderField("financial_support_applicant_id", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["financial_support_applicant_id"].value = formatNumber(row["financial_support_applicant_id"].value, this.locale.locale);
		}

		// financial_support_applicant_mobile
		if (this.renderField("financial_support_applicant_mobile", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["financial_support_applicant_mobile"].value = formatNumber(row["financial_support_applicant_mobile"].value, this.locale.locale);
		}

		// financial_support_deserving_status
		if (this.renderField("financial_support_deserving_status", pageId)) {
			let selectedRow = this.lookupTables.financial_support_deserving_status.find(r => r.lf == row["financial_support_deserving_status"].dbValue); // Compare with db value
			row["financial_support_deserving_status"].formValue = row["financial_support_deserving_status"].dbValue; // FormControl value cannot be undefined
			row["financial_support_deserving_status"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.financial_support_deserving_status) : row["financial_support_deserving_status"].dbValue;
			row["financial_support_deserving_status"].value = this.dbapp.getBool(row["financial_support_deserving_status"].dbValue);
		}

		// financial_support_applicant_career
		if (this.renderField("financial_support_applicant_career", pageId)) {
			let selectedRow = this.lookupTables.financial_support_applicant_career.find(r => r.lf == row["financial_support_applicant_career"].dbValue); // Compare with db value
			row["financial_support_applicant_career"].formValue = row["financial_support_applicant_career"].dbValue; // FormControl value cannot be undefined
			row["financial_support_applicant_career"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.financial_support_applicant_career) : row["financial_support_applicant_career"].dbValue;
		}

		// financial_support_applicant_career_military_rank
		if (this.renderField("financial_support_applicant_career_military_rank", pageId)) {
			let selectedRow = this.lookupTables.financial_support_applicant_career_military_rank.find(r => r.lf == row["financial_support_applicant_career_military_rank"].dbValue); // Compare with db value
			row["financial_support_applicant_career_military_rank"].formValue = row["financial_support_applicant_career_military_rank"].dbValue; // FormControl value cannot be undefined
			row["financial_support_applicant_career_military_rank"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.financial_support_applicant_career_military_rank) : row["financial_support_applicant_career_military_rank"].dbValue;
		}

		// financial_support_applicant_has_personal_loan
		if (this.renderField("financial_support_applicant_has_personal_loan", pageId)) {
			let selectedRow = this.lookupTables.financial_support_applicant_has_personal_loan.find(r => r.lf == row["financial_support_applicant_has_personal_loan"].dbValue); // Compare with db value
			row["financial_support_applicant_has_personal_loan"].formValue = row["financial_support_applicant_has_personal_loan"].dbValue; // FormControl value cannot be undefined
			row["financial_support_applicant_has_personal_loan"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.financial_support_applicant_has_personal_loan) : row["financial_support_applicant_has_personal_loan"].dbValue;
			row["financial_support_applicant_has_personal_loan"].value = this.dbapp.getBool(row["financial_support_applicant_has_personal_loan"].dbValue);
		}
		row.rendered = true;
		this.rowAfterRendered(row);
		this.row = row; // Set current row
		return row;
	}

	// Render file URL
	async renderFileUrl(url: string) {
		return this.http.get(url, { responseType: "blob" }).toPromise().then(blob => {
			const reader = new FileReader();
			return new Promise((resolve, reject) => {
				reader.onloadend = () => resolve(reader.result as string);
				reader.readAsDataURL(blob);
			});
		});
	}
}