import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { satisfies } from 'semver';
import { environment } from '../../environments/environment';
import { DbApp } from './dbapp';

@Injectable()
export class NafazService {
  constructor(
    public dbapp: DbApp,
    private http: HttpClient,
    public translate: TranslateService,
    ) {}

  async sendNafazRequest(nafazHeaderClient: any): Promise<any> {
    const body = {
      nationalId: nafazHeaderClient.nafaz_queue_nationalid,
      service: nafazHeaderClient.nafaz_queue_service,
      locale: nafazHeaderClient.nafaz_queue_locale,
      requestId: nafazHeaderClient.nafaz_queue_requestid,
    };

return this.http.post(environment.apiUrl, body, { responseType: 'json' }).toPromise().then(async (data: any) => {
  data = data || {};
  if (data.version && !satisfies(data.version, this.dbapp.requiredApiVersion)) {
    await this.translate.get("INCOMPATIBLE_API", { s: data.version }).toPromise();
  }
  
  if (data.success) {
    console.log('data', data);
  }
  return data;
});
}
  // Add a new method to pass the Nafaz header from the client-side
  async sendNafazHeader(nafazQueueNationalId: string, nafazQueueService: string, nafazQueueLocale: string, nafazQueueRequestId: string): Promise<any> {
    const nafazHeaderClient = {
      nafaz_queue_nationalid: nafazQueueNationalId,
      nafaz_queue_service: nafazQueueService,
      nafaz_queue_locale: nafazQueueLocale,
      nafaz_queue_requestid: nafazQueueRequestId
    };
    
    return this.sendNafazRequest(nafazHeaderClient);
  }

}