import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OnDestroy, OnInit, Input } from '@angular/core';
import { NavController, ModalController, AlertController, LoadingController, AnimationController, PopoverController, ActionSheetController, ToastController, IonSearchbar  } from '@ionic/angular';
import { Component, ViewChild, ChangeDetectorRef, Injectable, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { FormBuilder, FormGroup, AbstractControl, FormControl, FormArray, Validators, NgForm, ValidationErrors } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Observable, Subscription, BehaviorSubject, from, timer, take, filter, concatMap  } from 'rxjs';
import { IonInfiniteScroll, Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { File, Entry, FileEntry } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FileUploadComponent, FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { UserData, DbRecord, DbFile, LocaleService, Settings, DbApp, DbAppValidators, History, profiles_public } from '../../providers';
import { environment } from '../../../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { properties_lists, clients_views, users, properties_linked, rooms } from '../../providers';
/** Additional */
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { AfterViewInit, ElementRef } from '@angular/core';
import { DomController, GestureController, IonHeader } from '@ionic/angular';
import { ScrollHideConfig } from '../../components/expandable-header/expandable-header.component';
import { Chart, ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { default as Annotation } from 'chartjs-plugin-annotation';
import { ratingsAddPage } from '../ratingsadd/ratingsadd';
import { files_media_photosListPage } from '../files_media_photoslist/files_media_photoslist';
import { add_appointmentsAddPage } from '../add_appointmentsadd/add_appointmentsadd';
import { messagesAddPage } from '../../pages/messagesadd/messagesadd';
import { auctions_logsAddPage } from '../auctions_logsadd/auctions_logsadd';
import { earnests_logsAddPage } from '../earnests_logsadd/earnests_logsadd';
import { invests_logsAddPage } from '../invests_logsadd/invests_logsadd';
import { offers_pricesAddPage } from '../offers_pricesadd/offers_pricesadd';
import { rents_logsAddPage } from '../rents_logsadd/rents_logsadd';
import { paymentsComponentPage } from '../../components/payments/payments.component';
import { profiles_publicViewPage } from '../profiles_publicview/profiles_publicview';
import { roomsViewPage } from '../roomsview/roomsview';
import { financial_supportsAddPage } from '../financial_supportsadd/financial_supportsadd';
import { ticketsAddPage } from '../ticketsadd/ticketsadd';
import { LoginPage } from '../login/login';
import { neighborhoodsViewPage } from '../neighborhoodsview/neighborhoodsview';
import { properties_schemasViewPage } from '../properties_schemasview/properties_schemasview';
import { v4 as uuidv4 } from 'uuid';
import { SharedService } from '../../providers/shared_services';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { PropertyTypePipe } from '../../providers/properttype.pipe';
import { PdfService } from '../../providers/pdf.service';
// import { PropertyFeaturePipe } from '../../providers/propertyfeatures.pipe';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';

interface Loan {
	supportedByProgram: boolean;
	birthDate: string;
	monthlyIncome: number;
	monthlyCommitments: number;
	jobType: string;
	militaryRank?: string;
	hasPersonalLoan: boolean;
  }

// Component
@Component({
	selector: 'page-properties_lists-view',
	templateUrl: 'properties_listsview.html',
	styleUrls: ['properties_listsview.scss'],
	providers: [CallNumber, SocialSharing, SharedService, EmailComposer, PropertyTypePipe, PdfService],
})
@Injectable({providedIn:'root'})
export class properties_listsViewPage implements OnDestroy, OnInit {
	@ViewChild('heart', { read: ElementRef }) heart: ElementRef;
	@ViewChild("crm_appointment_type_id", { static: false }) crm_appointment_type_id: IonicSelectableComponent;
	@ViewChild("crm_appointment_financial_talk", { static: false }) crm_appointment_financial_talk: IonicSelectableComponent;
	@ViewChild('propertyModal') propertyModal: HTMLIonModalElement;
	@Input() properties_property_id: string;
	@Input('properties_property_type_id') properties_property_type_id: string;
	@Input('properties_last_sale_price') properties_last_sale_price: number;
	@Input() filter: string;
	valueSubject: BehaviorSubject<string> = new BehaviorSubject<string>('Initial Value');
	footerScrollConfig: ScrollHideConfig = { cssProperty: 'margin-bottom', cssPropertyReverse: '', cssPropertyOpacity: '', maxValue: undefined };
	headerScrollConfig: ScrollHideConfig = { cssProperty: '', cssPropertyReverse: 'background', cssPropertyOpacity: '', maxValue: 0.9 };
	opacityScrollConfig: ScrollHideConfig = { cssProperty: '', cssPropertyReverse: '', cssPropertyOpacity: 'opacity', maxValue: 100 };
	key: any;
	keyCount: number = 1;
	item: any;
	item$: BehaviorSubject<DbRecord>;
	itemParent: any;
	itemParent$: BehaviorSubject<DbRecord>;
	itemParentUser: any;
	itemParentUser$: BehaviorSubject<DbRecord>;
	currentItems: any;
	currentLinkedItems: any;
	currentRoomsLinkedItems: any;
	pageId: string = "view";
	pageUrl: string = "properties_listsview";
	apiUrl: string = environment.apiUrl;
	loadingMessage: string;
	dataLoaded: boolean;
	loadingStatus: boolean = false;
	isLoggedIn: boolean;
	isAdmin: boolean;
	userId: string;
	userEmail: string;
	userMobile: number;
	userAccountingNB: number;
	userFullName: string;
	permissions: any;
	userIdAllowed: boolean;
	system_user_wallet_in: number;
	system_mobile_1: number;
	system_email: string;
	section: string = 'two';
    somethings: any = new Array(20);
	segmentModelInfo: string = "specifications";
	fitBounds: boolean = true;
	formGroup: FormGroup;
	properties_reference_code: string;
	minimumBalance: number = 0;
	auctionCountdownFinished: boolean = false;
	private newLabel? = 'New label';
	private navigationSubscription: Subscription;
	current_user_latitude: number;
	current_user_longitude: number;
	properties_linked_id: string;
	languageLocales: { [key: string]: string } = {
		en: 'en-US',
		ar: 'ar-LY',
		// Add more language code/locale mappings here
		// ...
	  };
	id: string | null;

	subheader: string = 'push_it_up_message_title_first_party';
	  loanForm: FormGroup;

	// Constructor
	constructor(
		public dbapp: DbApp,
		public translate: TranslateService,
		public locale: LocaleService,
		public navController: NavController,
		public loadingController: LoadingController,
		public actionSheetController: ActionSheetController,
		public modalController: ModalController,
		public alertController: AlertController,
		public toastController: ToastController,
		public router: Router,
		public formBuilder: FormBuilder,
		public user: UserData,
		public inAppBrowser: InAppBrowser,
		private activatedRoute: ActivatedRoute,
		public history: History,
		private camera: Camera,
		private file: File,
		private webview: WebView,
		private platform: Platform,
		private changeDetectorRef: ChangeDetectorRef,
		private filePath: FilePath,
		private fileChooser: FileChooser,
		private sanitizer: DomSanitizer,
		public items: properties_lists,
		public clients_viewsItems: clients_views,
		public userItems: users,
		public statusBar: StatusBar,
		private callNumber: CallNumber,
		private socialSharing: SocialSharing,
		private sharedService: SharedService,
		private http: HttpClient,
		private animationCtrl: AnimationController,
		private emailComposer: EmailComposer,
		private propertyTypePipe: PropertyTypePipe,
		private cdr: ChangeDetectorRef,
		private propertiesLinkedItem: properties_linked,
		private parentUserLinkedItem: profiles_public,
		private roomsLinkedItem: rooms,
		private pdfService: PdfService
		// private propertyFeaturePipe: PropertyFeaturePipe
		) {
		this.key = this.getPrimaryKey();
		this.item$ = new BehaviorSubject<DbRecord>(null);
		this.itemParent$ = new BehaviorSubject<DbRecord>(null);
		this.itemParentUser$ = new BehaviorSubject<DbRecord>(null);
		this.userIdAllowed = this.dbapp.userIdAllow("properties_lists", this.pageId);
		this.getUserData();
		Chart.register(Annotation);
		
		const queryParams = this.activatedRoute.snapshot.queryParams;
		const id = queryParams['id'];
		console.log('Query Params id:', id);
	  
		if (id) {
		  console.log('Redirecting to:', ['properties_listsview', id]);
		  this.router.navigate(['properties_listsview', id]);
		}
		this.propertiesLinkedItem.currentPage = 1;
		this.parentUserLinkedItem.currentPage = 1;
		  
	}


	changeMapType: string = 'roadmap';
	toggleMapType(): void {
		this.changeMapType = this.changeMapType === 'roadmap' ? 'satellite' : 'roadmap';
	}
	
	// OnInit
	ngOnInit() {
		this.navigationSubscription = this.router.events.subscribe(async (e: any) => {
			if (e instanceof NavigationEnd && this.history.isCurrentPage(this.pageUrl)) { // Re-init component
				await this.init();
			}
		});
		
		this.key = this.getPrimaryKey();
		if(this.properties_property_id)
		this.init();
		this.sharedService.setPropertyId( this.activatedRoute.snapshot.paramMap.get("properties_property_id"));
		this.postViewCountData();
		// this.getUserItems();
		this.createLoanForm();
		console.log('this.userWallet', this.system_user_wallet_in);
		// this.postData();
		console.log('this.userId', this.userId);
		console.log('this.properties_property_id', this.properties_property_id);
		console.log('this.userEmail', this.userEmail);
		console.log('this.userMobile', this.userMobile);
		console.log('this.user.userEmail',  this.user.userEmail);
		// console.log('this.user.userMobile', this.user.userMobile.toString().padStart(10, '0'));
		console.log('this.user.userAccountingNB',  this.user.userAccountingNB);
		console.log('this.user.userFullName',  this.userFullName);
		console.log('this.langId',  this.locale.locale);

		this.current_user_latitude = parseFloat(localStorage.getItem('current_user_latitude'));
		this.current_user_longitude = parseFloat(localStorage.getItem('current_user_longitude'));
	}

	async  ngAfterViewInit() {
	}
	ngOnChanges(changes: SimpleChanges) {
		let content = document.querySelector('ion-content');
		content.scrollEvents = true;
		content.addEventListener('ionScrollStart', () => {
			  document.querySelector('ion-toolbar').style.display = 'none';
		});
		content.addEventListener('ionScrollEnd', () => {
			  document.querySelector('ion-toolbar').style.display = 'flex';
		});
	}
	// OnDestroy
	ngOnDestroy() {
		if (this.navigationSubscription)
			this.navigationSubscription.unsubscribe(); // Clean up
	}

	getCurrentLocale(): string {
		const currentLanguage = this.translate.currentLang;
		return this.languageLocales[currentLanguage] || 'ar-LY'; // Default to English locale if no mapping found
	  }

	// Init
	async init() {
		if (this.dbapp.isObject(this.key)) {
			const loading = await this.loadingController.create({
				spinner: null,
				cssClass: 'global-loader',
	
				message: this.loadingMessage,
				showBackdrop: true,
			});
			await loading.present();
			try {
				let item = await this.items.query(Object.assign({ action: "view" }, this.key)); // Use "view" action to get the record
				if (this.dbapp.isObject(item)) {
					await this.items.lookup(item, this.pageId);
					this.item = await this.items.renderRow(item, this.pageId);
					this.item$.next(this.item);
					if(this.item.system_user_id.value){
						this.getParentUserLinked(this.item.system_user_id.value);
					}
					if(this.item.properties_property_id.value){
						this.getPropertiesLinked(this.item.properties_property_id.value);
					}
					if(this.item.properties_linked_id.value){
						this.getRoomsLinked(this.item.properties_linked_id.value);
					}
					if(this.item.properties_linked_id.value){
						this.getParentPropertyLinked(this.item.properties_linked_id.value);
					}
				}
			} catch(err) {
				this.showError(err);
			} finally {
				await loading.dismiss();
			}
		}
	}

	
	// /**
	//  * Get query parameters
	//  */
	// private getParams() {
	// 	let params = { property_supervisor_id: this.filter };
	// 	if (this.filter)
	// 		params = Object.assign(params);
	// 	return Object.assign(params);
	// }

	/**
	 * Search for the proper items
	 */
	// async getItems(ev?: any) {
	// 	this.items.currentPage = 1; // Reset to the first page
	// 	const loading = await this.loadingController.create({
	// 		message: this.loadingMessage,
	// 		showBackdrop: false,
	// 		mode: this.dbapp.loadingMode
	// 	});
	// 	await loading.present();
	// 	try {
	// 		let items = await this.items.query(this.getParams());
	// 		if (items) {
	// 			await this.items.lookup(items, this.pageId);
	// 			this.currentItems = await Promise.all(items.map(async item => await this.items.renderRow(item, this.pageId)));
	// 		}
	// 	} catch(err) {
	// 		await this.showError(err);
	// 	} finally {
	// 		await loading.dismiss();
	// 	}
	// }

	/**
	 * Get primary key
	 */
	getPrimaryKey(): any {
		let keys = {}, key;
		key = this.properties_property_id ? this.properties_property_id : this.activatedRoute.snapshot.paramMap.get("properties_property_id");

		if (!this.dbapp.isEmpty(key))
			keys["properties_property_id"] = key;
		return (Object.keys(keys).length === this.keyCount) ? keys : false;
	}

	// Get user data
	getUserData() {
		this.user.isLoggedIn.subscribe(res => {
			this.isLoggedIn = res;
			this.isAdmin = this.user.isAdmin;
			this.userId = this.user.userId;
			this.userEmail = this.user.userEmail;
			this.userMobile = this.user.userMobile;
			this.userAccountingNB =  this.user.userAccountingNB;
			this.userFullName = this.user.userFullname;
			this.permissions = this.user.permissions;
		});
	}
			
	/**
	 * Show message
	 */
	async showMessage(msg: string, header: string) {
		let values = await this.translate.get(["OK_BUTTON", header]).toPromise();
		const alert = await this.alertController.create({
			header: values[header],
			message: msg,
			buttons: [values.OK_BUTTON]
		});
		await alert.present();
	}

	/**
	 * Show error
	 */
	async showError(err: any) {
		let msg = (err instanceof Error) ? err.message : err;
		return this.showMessage(msg, "ERROR");
	}

	/**
	 * Show success message
	 */
	async showSuccess(msg: string) {
		return this.showMessage(msg, "SUCCESS");
	}

	/**
	 * Show detail table buttons
	 */
	async presentDetails() {
		let ids = [
			"__tables.files_media_panos.caption",
			"__tables.files_media_photos.caption",
			"__tables.files_media_videos.caption",
			"DETAILS",
			"CANCEL_BUTTON"
		];
		let values = await this.translate.get(ids).toPromise();
		let buttons = [];
		if (this.user.permissions.list.files_media_panos) {
			buttons.push({
				text: values["__tables.files_media_panos.caption"],
				handler: () => {
					this.openDetail("files_media_panos");
				}
			});
		}
		if (this.user.permissions.list.files_media_photos) {
			buttons.push({
				text: values["__tables.files_media_photos.caption"],
				handler: () => {
					this.openDetail("files_media_photos");
				}
			});
		}
		if (this.user.permissions.list.files_media_videos) {
			buttons.push({
				text: values["__tables.files_media_videos.caption"],
				handler: () => {
					this.openDetail("files_media_videos");
				}
			});
		}
		let actionSheet = await this.actionSheetController.create({
			header: values.DETAILS,
			buttons: [...buttons,
				{
					text: values.CANCEL_BUTTON,
					role: 'cancel',
					handler: () => {
						console.log('Cancel clicked');
					}
				}
			]
		});
		await actionSheet.present();
	}

	/**
	 * Open detail table List page
	 * @param detailTable
	 */
	openDetail(detailTable: string) {
		if (!this.item)
			return;
		switch (detailTable) {
			case "files_media_panos":
				this.navController.navigateForward([detailTable + "list", { properties_property_id: this.item.properties_property_id.dbValue }]);
				break;
			case "files_media_photos":
				this.navController.navigateForward([detailTable + "list", { properties_property_id: this.item.properties_property_id.dbValue }]);
				break;
			case "files_media_videos":
				this.navController.navigateForward([detailTable + "list", { properties_property_id: this.item.properties_property_id.dbValue }]);
				break;
		}
	}

	// Open URL
	openUrl(url: string, target?: string) {
		this.inAppBrowser.create(url, target);
	}

	// other
	async goLoginModal() {
		const modalLogin = await this.modalController.create({
			component: LoginPage,
			componentProps: { openedLoginModal: 'openedLoginModal'},
			cssClass: 'custom-modal-backdrop-clear'
		  });
		  modalLogin.present();
	  
		  if (!this.isLoggedIn) {
		  } else {
			await this.modalController.dismiss();
		  }
	}

	mapPropertyReady(map: google.maps.Map): void {
		// const locations_city_name = this.properties_city_name;
		const parsedPolygonData = JSON.parse(this.item['properties_dimension_geometry'].value);
		const polygonCoordinates = parsedPolygonData[0].geometry[0].map(([lat, lng]) => ({ lat, lng }));
		const polygon = new google.maps.Polygon({
		  paths: polygonCoordinates,
		  strokeColor: '#000000',
		  strokeOpacity: 0.8,
		  strokeWeight: 1,
		  fillColor: '#FF0000',
		  fillOpacity: 0.35
		});
		this.fitBounds= false;
		polygon.setMap(map);
					
		// Calculate the centroid of the polygon
		const polygonBounds = new google.maps.LatLngBounds();
		for (let i = 0; i < polygonCoordinates.length; i++) {
			polygonBounds.extend(polygonCoordinates[i]);
		}
		const polygonCenter = polygonBounds.getCenter();
	
		// Create a marker with a label at the centroid
		// const marker = new google.maps.Marker({
		// 	position: polygonCenter,
		// 	label: locations_neighborhood_name,
		// 	map: map
		// });
	
		let bounds = new google.maps.LatLngBounds();
		for (let i = 0; i < polygonCoordinates.length; i++) {
			bounds.extend(polygonCoordinates[i]);
		}
	
		const ne = bounds.getNorthEast();
		const sw = bounds.getSouthWest();
		map.fitBounds(bounds);
}

mapSchemaReady(map: google.maps.Map): void {
	// const locations_city_name = this.properties_city_name;
	const parsedPolygonData = JSON.parse(this.item['properties_property_schema_geometry'].value);
	const polygonCoordinates = parsedPolygonData[0].geometry[0].map(([lat, lng]) => ({ lat, lng }));
	const polygon = new google.maps.Polygon({
	  paths: polygonCoordinates,
	  strokeColor: '#000000',
	  strokeOpacity: 0.8,
	  strokeWeight: 1,
	  fillColor: '#FF0000',
	  fillOpacity: 0.35
	});
	this.fitBounds= false;
	polygon.setMap(map);
				
	// Calculate the centroid of the polygon
	const polygonBounds = new google.maps.LatLngBounds();
	for (let i = 0; i < polygonCoordinates.length; i++) {
		polygonBounds.extend(polygonCoordinates[i]);
	}
	const polygonCenter = polygonBounds.getCenter();

	// Create a marker with a label at the centroid
	// const marker = new google.maps.Marker({
	// 	position: polygonCenter,
	// 	label: locations_neighborhood_name,
	// 	map: map
	// });

	let bounds = new google.maps.LatLngBounds();
	for (let i = 0; i < polygonCoordinates.length; i++) {
		bounds.extend(polygonCoordinates[i]);
	}

	const ne = bounds.getNorthEast();
	const sw = bounds.getSouthWest();
	map.fitBounds(bounds);
}

	launchDialer(n: string) {
		this.callNumber
		  .callNumber(n, true)
		  .then(() => console.log("Launched dialer!"))
		  .catch(() => console.log("Error launching dialer"));
	  }
	
		  compilemsg(propertyShortDescription: string, propertyPicture: string, propertiesPropertyId: string): string {
			var msg = propertyShortDescription + "\n\n" + propertyPicture + "\n" + propertiesPropertyId;
			return msg;
		  }
		  
		  whatsappShare(propertyShortDescription: string, propertyPicture: string, propertiesPropertyId: string) {
			var msg = this.compilemsg(propertyShortDescription, propertyPicture, propertiesPropertyId);
		  
			// Specify the image path
			let imagePath = 'https://obaykan.s3.us-east-2.amazonaws.com/files/properties_picture/' + propertyPicture;
		  
			// Share the image via WhatsApp
			this.socialSharing.shareViaWhatsApp(msg, imagePath).then(() => {
			  console.log('Shared successfully via WhatsApp');
			}).catch((error) => {
			  console.log('Error sharing via WhatsApp:', error);
			});
		  }


/** Start of Wallet */
	// async getUserItems() {
	// 	if (this.isLoggedIn){
	// 		try {
	// 			let item = await this.userItems.query(Object.assign({ action: "view" }, {system_user_id: this.userId})); // Use "view" action to get the record
	// 			if (this.dbapp.isObject(item)) {
	// 				await this.userItems.lookup(item, this.pageId);
	// 				this.item = await this.userItems.renderRow(item, this.pageId);
	// 				this.item$.next(this.item);
	// 				console.log('this.item', this.item);
				
	// 				this.system_user_wallet_in = item.system_user_wallet_in;
	// 				this.system_mobile_1 = item.system_mobile_1;
	// 				this.system_email = item.system_email;
	// 				console.log('system_user_wallet_in:', this.system_user_wallet_in);
					
	// 			}
	// 			} catch(err) {
	// 				this.showError(err);
	// 			} finally {
	// 		}
	// 	}
	// }

  /** Offer Action */
	async offerValidation(
		bid_type: number, 
		properties_property_id: string,
		properties_reference_code: number,
		properties_property_type_id: string,
		sell_security_amount: number,
		sell_amount: number,
		userIdSecondParty: string,	
        userFullNameSecondParty: string,
		userEmailSecondParty: string,
		userMobileSecondParty: number
		){
		if (!this.isLoggedIn) {
			this.goLoginModal();
		  } else {
			if (this.system_user_wallet_in === 0) {
				this.NotEnoughBallanceMessage('ballance_message_title', 'ballance_message_subtitle', 'ballance_message_1', 'ballance_message_2', 1000, 'charge_now');
			  } else {
					if (sell_amount >= 1 && sell_amount < 500000) {
					this.minimumBalance = 1000;
					} else if (sell_amount >= 500000 && sell_amount < 1000000) {
						this.minimumBalance = 2000;
					} else if (sell_amount >= 1000000 && sell_amount < 5000000) {
						this.minimumBalance = 3000;
					} else if (sell_amount >= 5000000 && sell_amount < 10000000) {
						this.minimumBalance = 4000;
					} else if (sell_amount >= 10000000) {
						this.minimumBalance = 5000;
					}
				}
				if (this.system_user_wallet_in < this.minimumBalance) {
				  this.NotEnoughBallanceMessage('ballance_message_title', 'ballance_message_subtitle', 'ballance_message_1', 'ballance_message_2', this.minimumBalance, 'charge_now');
				} else { 
					console.log('this.system_user_wallet_in', this.system_user_wallet_in)
					this.bidMessage(
						bid_type, 
						properties_property_id,
						properties_reference_code,
						properties_property_type_id,
						sell_security_amount,
						sell_amount,
						userIdSecondParty,
						userFullNameSecondParty,
						userEmailSecondParty,
						userMobileSecondParty,
						'offer_message_title_first_party',
						'offer_message_subtitle_first_party',
						'offer_message_first_party',
						'offer_now',
						'how_it_works',
						'term_button',
					);
			}
	}
}
	async openModalOfferBid(
		properties_property_id: string,
		properties_reference_code: number,
		properties_property_type_id: string,
		sell_security_amount: number,
		sell_amount: number,
		userIdSecondParty: string,	
        userFullNameSecondParty: string,
		userEmailSecondParty: string,
		userMobileSecondParty: number
		) {
	  const modalOfferBid = await this.modalController.create({
		component: offers_pricesAddPage,
		componentProps: {
			'properties_property_id': properties_property_id,
			'properties_reference_code': properties_reference_code,
			'properties_property_type_id': this.propertyTypePipe.transform(properties_property_type_id),
			'sell_security_amount': sell_security_amount,
			'sell_amount': sell_amount,
			'userIdSecondParty': userIdSecondParty,
			'userFullNameSecondParty': userFullNameSecondParty,
			'userEmailSecondParty': userEmailSecondParty, 
			'userMobileSecondParty': userMobileSecondParty
	 },
		initialBreakpoint: 1,
		cssClass: 'custom-modal-backdrop-clear'
	  });
	  
		console.log(properties_property_id);
		console.log(properties_reference_code);
		console.log(properties_property_type_id);
		console.log(properties_property_type_id);
		console.log(sell_security_amount);
		console.log(sell_amount);
		console.log(userIdSecondParty);
        console.log(userFullNameSecondParty);
		console.log(userEmailSecondParty);
		console.log(userMobileSecondParty);
		
	  return await modalOfferBid.present();
	}
  /** Offer Action */

  /** Earnest Action */
	async earnestValidation(
		bid_type: number,
		properties_property_id: string,
		properties_reference_code: number,
		properties_property_type_id: string,
		sell_security_earnest: number,
		sell_earnest: number,
		userIdSecondParty: string,	
        userFullNameSecondParty: string,
		userEmailSecondParty: string,
		userMobileSecondParty: number
		){
	  if(!this.isLoggedIn){
		  this.goLoginModal();
	  } else if(this.system_user_wallet_in == 0 || this.system_user_wallet_in < sell_security_earnest) {
		this.NotEnoughBallanceMessage('ballance_message_title','ballance_message_subtitle','ballance_message_1','ballance_message_2',sell_security_earnest,'charge_now');
	} else {
		this.bidMessage(
			bid_type, 
			properties_property_id, 
			properties_reference_code,
			properties_property_type_id,
			sell_security_earnest,
			sell_earnest,
			userIdSecondParty,
			userFullNameSecondParty,
			userEmailSecondParty,
			userMobileSecondParty,
			'earnest_message_title_first_party',
			'earnest_message_subtitle_first_party',
			'earnest_message_first_party',
			'earnest_now',
			'how_it_works',
			'term_button'
			);
	  }
	}
	async openModalEarnetBid(
		properties_property_id: string,
		properties_reference_code: number,
		properties_property_type_id: string,
		sell_security_earnest: number,
		sell_earnest: number,
		userIdSecondParty: string,	
        userFullNameSecondParty: string,
		userEmailSecondParty: string,
		userMobileSecondParty: number
		) {
	  const modalEarnetBid = await this.modalController.create({
		component: earnests_logsAddPage,
		componentProps: {
			'properties_property_id': properties_property_id,
			'properties_reference_code': properties_reference_code,
			'properties_property_type_id': this.propertyTypePipe.transform(properties_property_type_id),
			'sell_security_earnest': sell_security_earnest,
			'sell_earnest': sell_earnest,
			'userIdSecondParty': userIdSecondParty,
			'system_full_name': userFullNameSecondParty,
			'system_email': userEmailSecondParty, 
			'system_mobile_1': userMobileSecondParty
		},
		initialBreakpoint: 1,
		cssClass: 'custom-modal-backdrop-clear'
	  });
	  return await modalEarnetBid.present();
	}
  /** End Earnest Action */

  /** Start Rent Action */
	async rentValidation(
		bid_type: number,
		properties_property_id: string,
		properties_reference_code: number,
		properties_property_type_id: string,
		rent_security_amount: number,
		rent_amount: number,
		userIdSecondParty: string,	
        userFullNameSecondParty: string,
		userEmailSecondParty: string,
		userMobileSecondParty: number
		){
		if(!this.isLoggedIn){
			this.goLoginModal();
		} else if(this.system_user_wallet_in == 0 || this.system_user_wallet_in < rent_amount) {
			this.NotEnoughBallanceMessage('ballance_message_title','ballance_message_subtitle','ballance_message_1','ballance_message_2',rent_amount,'charge_now');
		} else { 
			this.bidMessage(
				bid_type,
				properties_property_id,
				properties_reference_code,
				properties_property_type_id,
				rent_security_amount,
				rent_amount,
				userIdSecondParty,
				userFullNameSecondParty,
				userEmailSecondParty,
				userMobileSecondParty,
				'rent_message_title_first_party',
				'rent_message_subtitle_first_party',
				'rent_message_first_party',
				'rent_now',
				'how_it_works',
				'term_button'
				);
		}
	}
	async openModalRentBid(
		properties_property_id: string,
		properties_reference_code: number,
		properties_property_type_id: string,
		rent_security_amount: number,
		rent_amount: number,
		userIdSecondParty: string,	
        userFullNameSecondParty: string,
		userEmailSecondParty: string,
		userMobileSecondParty: number
		) {
	  const modalRentBid = await this.modalController.create({
		component: rents_logsAddPage,
		componentProps: {
			'properties_property_id': properties_property_id,
			'properties_reference_code': properties_reference_code,
			'properties_property_type_id': this.propertyTypePipe.transform(properties_property_type_id),
			'rent_security_amount': rent_security_amount,
			'rent_amount': rent_amount,
			'userIdSecondParty': userIdSecondParty,
			'system_full_name': userFullNameSecondParty,
			'system_email': userEmailSecondParty, 
			'system_mobile_1': userMobileSecondParty
		},
		initialBreakpoint: 1,
		cssClass: 'custom-modal-backdrop-clear'
	  });
	  return await modalRentBid.present();
	}

  /** End Rent Action */

  /** Start Invest Action */
	async investValidation(
		bid_type: number,
		properties_property_id: string,
		properties_reference_code: number,
		properties_property_type_id: string,
		invest_security_amount: number,
		invest_amount: number,
		userIdSecondParty: string,	
        userFullNameSecondParty: string,
		userEmailSecondParty: string,
		userMobileSecondParty: number
		){
		if(!this.isLoggedIn){
			this.goLoginModal();
		} else if(this.system_user_wallet_in == 0 || this.system_user_wallet_in < invest_amount) {
			this.NotEnoughBallanceMessage('ballance_message_title','ballance_message_subtitle','ballance_message_1','ballance_message_2',invest_amount,'charge_now');
		} else { 
			this.bidMessage(
				bid_type,
				properties_property_id,
				properties_reference_code,
				properties_property_type_id,
				invest_security_amount,
				invest_amount,
				userIdSecondParty,
				userFullNameSecondParty,
				userEmailSecondParty,
				userMobileSecondParty,
				'invest_message_title_first_party',
				'invest_message_subtitle_first_party',
				'invest_message_first_party',
				'invest_now',
				'how_it_works',
				'term_button'
				);

		}
	}
	async openModalInvestBid(
		properties_property_id: string,
		properties_reference_code: number,
		properties_property_type_id: string,
		invest_security_amount: number,
		invest_amount: number,
		userIdSecondParty: string,	
        userFullNameSecondParty: string,
		userEmailSecondParty: string,
		userMobileSecondParty: number
		) {
	  const modalInvestBid = await this.modalController.create({
		component: invests_logsAddPage,
		componentProps: {
			'properties_property_id': properties_property_id,
			'properties_reference_code': properties_reference_code,
			'properties_property_type_id': this.propertyTypePipe.transform(properties_property_type_id),
			'invest_security_amount': invest_security_amount,
			'invest_amount': invest_amount,
			'userIdSecondParty': userIdSecondParty,
			'system_full_name': userFullNameSecondParty,
			'system_email': userEmailSecondParty,
			'system_mobile_1': userMobileSecondParty
		},
		initialBreakpoint: 1,
		cssClass: 'custom-modal-backdrop-clear'
	  });
	  return await modalInvestBid.present();
	}
  /** End Invest Action */

  /** Start Auctions Action */
	async auctionValidation(
		bid_type: number,
		properties_property_id: string,
		properties_reference_code: number,
		properties_property_type_id: string,
		auction_security_amount: number,
		auction_amount: number,
		userIdSecondParty: string,	
        userFullNameSecondParty: string,
		userEmailSecondParty: string,
		userMobileSecondParty: number
		) {
	  if(!this.isLoggedIn){
		  this.goLoginModal();
		} else if(this.system_user_wallet_in == 0 || this.system_user_wallet_in < auction_security_amount) {
			this.NotEnoughBallanceMessage('ballance_message_title','ballance_message_subtitle','ballance_message_1','ballance_message_2',auction_security_amount,'charge_now');
		} else { 
		  this.bidMessage(
			bid_type,
			properties_property_id,
			properties_reference_code,
			properties_property_type_id,
			auction_security_amount,
			auction_amount,
			userIdSecondParty,
			userFullNameSecondParty,
			userEmailSecondParty,
			userMobileSecondParty,
			'auction_message_title_first_party',
			'auction_message_subtitle_first_party',
			'auction_message_first_party',
			'auction_now',
			'how_it_works',
			'term_button'
			);

	  }
	}
	async openModalAuctionBid(
		properties_property_id: string,
		properties_reference_code: number,
		properties_property_type_id: string,
		auction_security_amount: number,
		auction_amount: number,
		userIdSecondParty: string,	
        userFullNameSecondParty: string,
		userEmailSecondParty: string,
		userMobileSecondParty: number
		) {
	  const modalAuctionBid = await this.modalController.create({
		component: auctions_logsAddPage,
		componentProps: {
			'properties_property_id': properties_property_id,
			'properties_reference_code': properties_reference_code,
			'properties_property_type_id': this.propertyTypePipe.transform(properties_property_type_id),
			'auction_security_amount': auction_security_amount,
			'auction_amount': auction_amount,
			'userIdSecondParty': userIdSecondParty,
			'userFullNameSecondParty': userFullNameSecondParty,
			'userEmailSecondParty': userEmailSecondParty, 
			'userMobileSecondParty': userMobileSecondParty
		},
		initialBreakpoint: 1,
		cssClass: 'custom-modal-backdrop-clear'
	  });
	  console.log(properties_property_id);
	  console.log(properties_reference_code);
	  console.log(properties_property_type_id);
	  console.log(properties_property_type_id);
	  console.log(auction_security_amount);
	  console.log(auction_amount);
	  console.log(userIdSecondParty);
	  console.log(userFullNameSecondParty);
	  console.log(userEmailSecondParty);
	  console.log(userMobileSecondParty);

	  console.log('system_full_name2',userFullNameSecondParty)
	  console.log('system_email2',userEmailSecondParty)
	  console.log('system_mobile_12',userMobileSecondParty)
	  modalAuctionBid.present();
		  const { data, role } = await modalAuctionBid.onWillDismiss();
	}
  /** End Auctions Action */

  /** Start Payments Action */
	async bidMessage(
		bid_type: number,
		properties_property_id: string, 
		properties_reference_code: number, 
		properties_property_type_id: string,
		bid_security_amount:number,
		bid_amount:number,
		userIdSecondParty: string,	
        userFullNameSecondParty: string,
		userEmailSecondParty: string,
		userMobileSecondParty: number,
		header: string,
		subHeader: string,
		msg: string,
		goBidButton: string,
		goHowItWorksButton: string,
		termButton: string,
	  ) {
		let values = await this.translate
		  .get([header, subHeader, msg, goBidButton, goHowItWorksButton, termButton])
		  .toPromise();
		const alert = await this.alertController.create({
		  header: values[header],
		  subHeader: values[subHeader],
		  message: values[msg],
		  buttons: [
			{
				text: values[termButton],
				handler: () => {
					if(bid_type == 1){
						this.openModalTerm(
							'offer_term_title_first_party',
							'offer_term_subtitle_first_party',
							'offer_term_content_first_party',
							'CLOSE'
						);
					} else if(bid_type == 2){
						this.openModalTerm(
							'earnest_term_title_first_party',
							'earnest_term_subtitle_first_party',
							'earnest_term_content_first_party',
							'CLOSE'
						);
					} else if(bid_type == 3){
						this.openModalTerm(
							'invest_term_title_first_party',
							'invest_term_subtitle_first_party',
							'invest_term_content_first_party',
							'CLOSE'
						);
					} else if(bid_type == 4){
						this.openModalTerm(
							'rent_term_title_first_party',
							'rent_term_subtitle_first_party',
							'rent_term_content_first_party',
							'CLOSE'
						);
					} else if(bid_type == 5){
						this.openModalTerm(
							'auction_term_title_first_party',
							'auction_term_subtitle_first_party',
							'auction_term_content_first_party',
							'CLOSE'
						);
					}
				},
			},
			{
				text: values[goHowItWorksButton],
				handler: () => {
					if(bid_type == 1){
						this.openModalHowItWorks(
							'offer_instructions_title_first_party',
							'offer_instructions_subtitle_first_party',
							'offer_instructions_content_first_party',
							'CLOSE'
						);
					} else if(bid_type == 2){
						this.openModalHowItWorks(
							'earnest_instructions_title_first_party',
							'earnest_instructions_subtitle_first_party',
							'earnest_instructions_content_first_party',
							'CLOSE'
						);
					} else if(bid_type == 3){
						this.openModalHowItWorks(
							'invest_instructions_title_first_party',
							'invest_instructions_subtitle_first_party',
							'invest_instructions_content_first_party',
							'CLOSE'
						);
					} else if(bid_type == 4){
						this.openModalHowItWorks(
							'rent_instructions_title_first_party',
							'rent_instructions_subtitle_first_party',
							'rent_instructions_content_first_party',
							'CLOSE'
						);
					} else if(bid_type == 5){
						this.openModalHowItWorks(
							'auction_instructions_title_first_party',
							'auction_instructions_subtitle_first_party',
							'auction_instructions_content_first_party',
							'CLOSE'
						);
					}
				},
			},
			{
			  text: values[goBidButton],
			  handler: () => {
				  if(bid_type == 1){
					  this.openModalOfferBid(properties_property_id, properties_reference_code,properties_property_type_id, bid_security_amount, bid_amount, userIdSecondParty, userFullNameSecondParty, userEmailSecondParty, userMobileSecondParty);
				  } else if(bid_type == 2){
					  this.openModalEarnetBid(properties_property_id, properties_reference_code,properties_property_type_id, bid_security_amount, bid_amount, userIdSecondParty, userFullNameSecondParty, userEmailSecondParty, userMobileSecondParty);
				  } else if(bid_type == 3){
					  this.openModalRentBid(properties_property_id, properties_reference_code,properties_property_type_id, bid_security_amount, bid_amount, userIdSecondParty, userFullNameSecondParty, userEmailSecondParty, userMobileSecondParty);
				  } else if(bid_type == 4){
					  this.openModalInvestBid(properties_property_id, properties_reference_code,properties_property_type_id, bid_security_amount, bid_amount, userIdSecondParty, userFullNameSecondParty, userEmailSecondParty, userMobileSecondParty);
				  } else if(bid_type == 5){
					  this.openModalAuctionBid(properties_property_id, properties_reference_code,properties_property_type_id, bid_security_amount, bid_amount, userIdSecondParty, userFullNameSecondParty, userEmailSecondParty, userMobileSecondParty);
				  }
			  },
		  },
		  ],
		});
		await alert.present();
	  }
	async NotEnoughBallanceMessage(
	  header: string,
	  subHeader: string,
	  msg_1: string,
	  msg_2: string,
	  amount: number,
	  goPayButton: string
	) {
	  let values = await this.translate.get([header, subHeader, msg_1, msg_2, goPayButton, "SAR"], { s: this.system_user_wallet_in, c: this.translate.instant("SAR")  }).toPromise();
	  const alert = await this.alertController.create({
		header: values[header],
		subHeader: values[subHeader],
		message: `
		${values[msg_1]}<br>
		${values[msg_2]} ${amount} 
	  `,
		buttons: [
		  {
			text: values[goPayButton],
			handler: () => {
			  this.openModalCheckOut('ChargeWalletSubmitRequest'); // Go to start page
			},
		  },
		],
	  });
	  await alert.present();
	}
	async openModalHowItWorks(
		  header: string,
		  subHeader: string,
		  artical: string,
		  CLOSE: string
		) {
		  let values = await this.translate
			.get([
			  header,
			  subHeader,
			  artical,
			  CLOSE
			])
			.toPromise();
		  const alert = await this.alertController.create({
			header: values[header],
			subHeader: values[subHeader],
			message: `
			  ${values[artical]}
			`,
			buttons: [
			  {
				text: values[CLOSE],
				// handler: () => {
				//   this.openModalPayment('ChargeWalletSubmitRequest'); // Go to start page
				// },
			  },
			],
		  });
		  await alert.present();
		}

	async openModalTerm(
		header: string,
		subHeader: string,
		artical: string,
		CLOSE: string
	  ) {
		let values = await this.translate
		  .get([
			header,
			subHeader,
			artical,
			CLOSE
		  ])
		  .toPromise();
		const alert = await this.alertController.create({
		  header: values[header],
		  subHeader: values[subHeader],
		  message: `
			${values[artical]}
		  `,
		  buttons: [
			{
			  text: values[CLOSE],
			//   handler: () => {
			// 	this.openModalPayment('ChargeWalletSubmitRequest'); // Go to start page
			//   },
			},
		  ],
		});
		await alert.present();
	  }
	  
	async openModalCheckOut(appType: string) {
		const modalCheckOut = await this.modalController.create({
		  component: paymentsComponentPage,
		  cssClass: 'custom-modal-backdrop-clear'
		});
		return await modalCheckOut.present();
	
		// const { data, role } = await modalPayment.onWillDismiss();
	
		// if (role === 'confirm') {
		//   this.message = `Hello, ${data}!`;
		// }
	}
/** End Payments Action */  
/** Start Notifications Actions */


//   sendNotificationToDevice(deviceToken: string) {
// 	const message = {
// 	  token: deviceToken,
// 	  notification: {
// 		title: 'New Notification',
// 		body: 'You have received a new notification.'
// 	  },
// 	  data: {
// 		// Custom data to include with the notification (optional)
// 	  }
// 	};
  
// 	this.fcm.send(message)
// 	  .then(() => {
// 		console.log('Push notification sent successfully.');
// 	  })
// 	  .catch((error) => {
// 		console.error('Error sending push notification:', error);
// 	  });
//   }
  
/** End Notifications Actions */
/** End of Wallet */
	// async cancel() {
	// 	try {
	// 	  await this.modalController.dismiss();
	// 	  const response = await this.loadingController.dismiss();
	// 	  console.log('Loader closed!', response);
	// 	} catch (error) {
	// 	  console.log('Error occurred while cancelling:', error);
	// 	}
	//   }
	//   async cancelCalc() {
	// 	try {
	// 	 this.resetForm();
	// 	  await this.modalController.dismiss();
	// 	  const response = await this.loadingController.dismiss();
	// 	  console.log('Loader closed!', response);
	// 	} catch (error) {
	// 	  console.log('Error occurred while cancelling:', error);
	// 	}
	//   }
	
	
	cancel() {
		this.modalController.dismiss();
	  }
/** Start of Modals */
	async openModalNeighborhoodsView(neighborhoodId: string) {
		const modalNeighborhoodsView = await this.modalController.create({
		  component: neighborhoodsViewPage,
		  componentProps  : {openedNeighborhoodsViewModal: 'openedNeighborhoodsViewModal', locations_neighborhood_id: neighborhoodId},
		  cssClass: 'custom-modal-backdrop-clear',
		  backdropDismiss: true,
		});
		modalNeighborhoodsView.onDidDismiss().then(item => {
		  modalNeighborhoodsView.dismiss();
		});
		return await modalNeighborhoodsView.present();

	  }

	  async openModalAddRatings(propId: string) {
		  const modalAddRating = await this.modalController.create({
			component: ratingsAddPage,
			componentProps  : {openedAddRateModal: 'openedAddRateModal',ratingStatusId: 1, properties_property_id: propId},
			cssClass: 'custom-modal-backdrop-clear',
			backdropDismiss: true,
		  });
		  modalAddRating.onDidDismiss().then(item => {
			modalAddRating.dismiss();
		  });
		  return await modalAddRating.present();
  
		}

	  async openModalGallery(propId: string) {
		const modalGallery = await this.modalController.create({
		component: files_media_photosListPage,
		componentProps: { openedListGalleryModal: 'openedListGalleryModal', properties_property_id: propId },
		cssClass: 'custom-modal-backdrop-clear'
		
	});
		return await modalGallery.present();
		// const { data, role } = await modalGallery.onWillDismiss();
		// if (role === 'confirm') {
		//   this.message = `Hello, ${data}!`;
		// }
	}
	
	async openModalAppointment(propId: string) {
	  const modalAppointment = await this.modalController.create({
		component: add_appointmentsAddPage,
		componentProps  : {addappointment: 'addappointment', properties_property_id: propId},
		cssClass: 'custom-modal-backdrop-clear',
		backdropDismiss: false,
	  });
	  
	  let appointment_info_by: number;
	  let appointment_info_date_time: string;
	  modalAppointment.onDidDismiss().then(item => {
		  if (item && item.data) {
			  appointment_info_by = item.data.appointmentInfoBy;
			  appointment_info_date_time = item.data.appointmentInfoDateTime;
			  
		  this.showAppointmentSubmitMessage(appointment_info_by, appointment_info_date_time);
		  }
	  });
	  return await modalAppointment.present();
	  // await modalAppointment.dismiss();

	}
	async openModalAddReport(propId: string) {
	  const modalAppointment = await this.modalController.create({
		component: ticketsAddPage,
		componentProps  : { openedReportModal: 'openedReportModal',parentPageName: this.pageUrl, properties_property_id: propId},
		cssClass: 'custom-modal-backdrop-clear',
		backdropDismiss: false,
	  });
	  console.log(this.pageUrl)
	  let appointment_info_by: number;
	  let appointment_info_date_time: string;
	  modalAppointment.onDidDismiss().then(item => {
		  if (item && item.data) {
			  appointment_info_by = item.data.appointmentInfoBy;
			  appointment_info_date_time = item.data.appointmentInfoDateTime;
			  
		  this.showAppointmentSubmitMessage(appointment_info_by, appointment_info_date_time);
		  }
	  });
	  return await modalAppointment.present();
	  // await modalAppointment.dismiss();

	}
  
async showAppointmentSubmitMessage(appointment_info_by: number, appointment_info_date_time: string) {
const header = 'thanks_to_take_an_appointment';
const msgPart1 = 'your_appintment_dedicated_by';
const msgPart2 = 'in_date_time';
let appointmentInfoBy: string;
appointment_info_by === 1 ? appointmentInfoBy = 'local_visit' : appointmentInfoBy = 'remote_visit';
let values = await this.translate.get(["OK_BUTTON", header, msgPart1, msgPart2, appointmentInfoBy]).toPromise();
const alert = await this.alertController.create({
	header: values[header],
	message: values[msgPart1] + ' ' + values[appointmentInfoBy] + ' ' + values[msgPart2] + ' ' + appointment_info_date_time,
	buttons: [values.OK_BUTTON]
});
await alert.present();
}

  async openReportModal() {
	const modalReport = await this.modalController.create({
		component: LoginPage,
		cssClass: 'custom-modal-backdrop-clear'
	  });
	  modalReport.present();
  
	  if (!this.isLoggedIn) {
	  } else {
		await this.modalController.dismiss();
	  }
}

async openModalAddMessage(userId: string) {
	const modalAddMessage = await this.modalController.create({
	  component: messagesAddPage,
	  componentProps  : {openedAddMessageModal: 'openedAddMessageModal', messages_user_id: userId},
	  cssClass: 'custom-modal-backdrop-clear',
	  backdropDismiss: true,
	});
	modalAddMessage.onDidDismiss().then(item => {
	  modalAddMessage.dismiss();
	});
	return await modalAddMessage.present();

  }

  async openModalFinancialSupportAdd(supportedByProgram: string, birthDate: Date,monthlyIncome: number,monthlyCommitments: number,jobType: number,militaryRank: number,hasPersonalLoan: string) {
	  const modalAddlFinancialSupport = await this.modalController.create({
		component: financial_supportsAddPage,
		componentProps  : {
			openedAddlFinancialSupportModal: 'openedAddlFinancialSupportModal',
			properties_property_id: this.properties_property_id,
			properties_reference_code: this.properties_reference_code,
			properties_property_type_id: this.properties_property_type_id,
			properties_last_sale_price: this.properties_last_sale_price,
			supportedByProgram: supportedByProgram,
			birthDate: birthDate,
			monthlyIncome: monthlyIncome,
			monthlyCommitments: monthlyCommitments,
			jobType: jobType,
			militaryRank: militaryRank,
			hasPersonalLoan: hasPersonalLoan,
			totalAmountWithInterest: this.totalAmountWithInterest
	},
	cssClass: 'custom-modal-backdrop-clear'
	,
		backdropDismiss: true,
	  });
	  modalAddlFinancialSupport.onDidDismiss().then(item => {
		modalAddlFinancialSupport.dismiss();
	  });
	  return await modalAddlFinancialSupport.present();
  
	}

  async openModalProfile(property_supervisor_id: string) {
	const modalProfile = await this.modalController.create({
	component: profiles_publicViewPage,
	componentProps: {
		openedViewProfilePublicModal: 'openedViewProfilePublicModal', viewsupervisor: 'viewsupervisor', property_supervisor_id: property_supervisor_id,
	},
	cssClass: 'custom-modal-backdrop-clear',
	backdropDismiss: true,
	});
	modalProfile.onDidDismiss().then(item => {
		
	});
	console.log("property_supervisor_id", property_supervisor_id)
	return await modalProfile.present();
}

/** End of Modals */

/** Start of Chart */

public sales = [ 81, 56, 55, 40 ];
public sales_units: any = this.translate.get(["no_city_selected"]).toPromise();
public rents = [ 19, 86, 27, 90 ];
public rents_units: any = 'Rent';
public invest = [ 90, 1000, 270, 400 ];
public invest_units: any = 'Invest';
  public years_stat: any = [ '2020', '2021', '2022', '2023' ];

public lineChartData: ChartConfiguration['data'] = {
datasets: [
  {
	data: this.sales,
	label: this.sales_units,
	backgroundColor: 'rgba(148,159,177,0.2)',
	borderColor: 'rgba(148,159,177,1)',
	pointBackgroundColor: 'rgba(148,159,177,1)',
	pointBorderColor: '#fff',
	pointHoverBackgroundColor: '#fff',
	pointHoverBorderColor: 'rgba(148,159,177,0.8)',
	fill: 'origin',
  },
  {
	data: this.rents,
	label: this.rents_units,
	backgroundColor: 'rgba(77,83,96,0.2)',
	borderColor: 'rgba(77,83,96,1)',
	pointBackgroundColor: 'rgba(77,83,96,1)',
	pointBorderColor: '#fff',
	pointHoverBackgroundColor: '#fff',
	pointHoverBorderColor: 'rgba(77,83,96,1)',
	fill: 'origin',
  },
  {
	data: this.invest,
	label: this.invest_units,
	yAxisID: 'y1',
	backgroundColor: 'rgba(255,0,0,0.3)',
	borderColor: 'red',
	pointBackgroundColor: 'rgba(148,159,177,1)',
	pointBorderColor: '#fff',
	pointHoverBackgroundColor: '#fff',
	pointHoverBorderColor: 'rgba(148,159,177,0.8)',
	fill: 'origin',
  }
],
labels: this.years_stat
};

public lineChartOptions: ChartConfiguration['options'] = {
elements: {
  line: {
	tension: 0.5
  }
},
scales: {
  // We use this empty structure as a placeholder for dynamic theming.
  y:
	{
	  position: 'right',
	},
//   y1: {
// 	position: 'right',
// 	grid: {
// 	  color: 'rgba(255,0,0,0.3)',
// 	},
// 	ticks: {
// 	  color: 'red'
// 	}
//   }
},

plugins: {
  legend: { display: true },
  annotation: {
	annotations: [
	  {
		// type: 'line',
		// scaleID: 'x',
		// value: '2023',
		// borderColor: 'orange',
		// borderWidth: 2,
		label: {
		  display: true,
		  position: 'center',
		  color: 'orange',
		  content: 'LineAnno',
		  font: {
			weight: 'bold'
		  }
		}
	  },
	],
  }
}
};

public lineChartType: ChartType = 'line';
//   public lineChartType: ChartType = 'pie';

@ViewChild(BaseChartDirective) chart?: BaseChartDirective;

private static generateNumber(i: number): number {
return Math.floor((Math.random() * (i < 2 ? 100 : 1000)) + 1);
}

public randomize(): void {
for (let i = 0; i < this.lineChartData.datasets.length; i++) {
  for (let j = 0; j < this.lineChartData.datasets[i].data.length; j++) {
	this.lineChartData.datasets[i].data[j] = 444;
  }
}
this.chart?.update();
}

// events
public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
console.log(event, active);
}

public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
console.log(event, active);
}

public hideOne(): void {
const isHidden = this.chart?.isDatasetHidden(1);
this.chart?.hideDataset(1, !isHidden);
}

public pushOne(): void {
this.lineChartData.datasets.forEach((x, i) => {
  const num = 444;
  x.data.push(num);
});
this.lineChartData?.labels?.push(`Label ${ this.lineChartData.labels.length }`);

this.chart?.update();
}

public changeColor(): void {
this.lineChartData.datasets[2].borderColor = 'green';
this.lineChartData.datasets[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;

this.chart?.update();
}

public changeLabel(): void {
const tmp = this.newLabel;
this.newLabel = this.lineChartData.datasets[2].label;
this.lineChartData.datasets[2].label = tmp;

this.chart?.update();
}

/** End of Charts */


    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });

    // const url = 'ratingsedit/d766fc2c-9fe3-4d72-b52f-ab0cc147cbbc'; // Replace with your actual URL
    // console.log('url', url);

    // // Prepare the data to be sent
    // const data = {
    //   properties_auction_opening_amount: '1', // Replace with your actual data properties
    // };

    // try {
    //   const response = await this.http.post(url, data, { headers }).toPromise();
    //   // Handle the response
    //   console.log(response);
    // } catch (error) {
    //   // Handle errors
    //   console.error(error);
    // }

	// async postData() {
	// 	// const apiUrl = 'https://obykan.herokuapp.com/appapi/';
	// 	const headers = { 'content-type': 'application/json' };
	// 	const obj = {
	// 	  crm_rating_for_type_id: '3',
	// 	};
	// 	const body = JSON.stringify(obj);
	// 	console.log(body);
	  
	// 	try {
	// 	  const response = await fetch(environment.apiUrl + "ratingsedit/2edcbbaa-68a6-421d-8e09-e993692b5ec6", {
	// 		method: 'POST',
	// 		headers,
	// 		body,
	// 	  });
	// 	  console.log(response);
	// 	  // Handle the response as needed
	// 	} catch (error) {
	// 	  console.error(error);
	// 	  // Handle the error appropriately
	// 	}
	//   }

	// async postData() {
	// 	const apiUrl = 'https://site.local/PropTech2023/appapi/?crm_rating_id=d1406e94-7e67-470c-b75c-1a7efd3b5acd';
	// 	const obj = {
	// 	  crm_rating_for_type_id: '3',
	// 	  crm_rating_comment: '9876543210',
	// 	};
	// 	const body = JSON.stringify(obj);
	  
	// 	try {
	// 	  const response = await axios.post(apiUrl, body);
	  
	// 	  console.log(response.data); // Handle the response data as needed
	// 	} catch (error) {
	// 	  if (axios.isAxiosError(error)) {
	// 		const axiosError = error;
	// 		if (axiosError.response) {
	// 		  // The request was made and the server responded with a status code
	// 		  console.log(axiosError.response.status);
	// 		  console.log(axiosError.response.data);
	// 		} else if (axiosError.request) {
	// 		  // The request was made but no response was received
	// 		  console.log(axiosError.request);
	// 		} else {
	// 		  // Something happened in setting up the request that triggered an error
	// 		  console.log('Error', axiosError.message);
	// 		}
	// 	  } else {
	// 		// Non-Axios error
	// 		console.log('Error', error.message);
	// 	  }
	// 	}
	//   }
	
	/**
	 * Add
	 */
	async postViewCountData() {
			let value = await {
				crm_client_view_id: uuidv4(),
				crm_client_view_for_type_id: 1,
				crm_client_view_for: this.properties_property_id,
				crm_client_view_by: this.userId,
				crm_client_view_datetime: "",
				crm_client_view_added_gregorian: "",
				crm_client_view_added_by: ""
				}, res;
			  try {
				res = await this.clients_viewsItems.add(value);
				  console.log(this.clients_viewsItems);
				} finally {
			  }
	}
	
	
	// async postData() {
	// 	this.submitted = true;
	//   const wishlists_id = 'd1406e94-7e67-470c-b75c-1a7efd3b5acd';
	//   const value = {
	// 	wishlists_product_id: 'd1406e94-7e67-470c-b75c-1a7efd3b5acd',
	// 	wishlists_status: '1'
	//   };
	  
	//   let res;
	
	//   try {
	// 	res = await this.wishlistsItems.edit(wishlists_id, value);
	// 	console.log(this.wishlistsItems);
	//   } catch (error) {
	// 	console.error('Error:', error);
	// 	return;
	//   }
	
	//   if (res && res.success) {
	//   } else if (res && !res.success && res.failureMessage) {
	// 	// Handle failure
	//   }
	
// 	addFavorite(): Observable<any> {
// 		const cartAnimation = this.animationCtrl.create('cart-animation')
// 		.addElement(this.heart.nativeElement)
// 		.keyframes([
// 		  { offset: 0, transform: 'scale(1)' },
// 		  { offset: 0.5, transform: 'scale(1.2)' },
// 		  { offset: 0.8, transform: 'scale(0.9)' },
// 		  { offset: 1, transform: 'scale(1)' }
// 		]);
	
// 	  const cartColorAnimation = this.animationCtrl.create('cart-color-animation')
// 		.addElement(this.heart.nativeElement)
// 		.fromTo('transform', 'rotate(0deg)', 'rotate(45deg)');
	
	
// 	  const parent = this.animationCtrl.create('parent')
// 		.duration(300)
// 		.easing('ease-out')
// 		.iterations(2)
// 		.direction('alternate')
// 		.addAnimation([cartColorAnimation, cartAnimation]);
	
// 	  // Playing the parent starts both animations
// 	  parent.play();
// 		const headers = { 'content-type': 'application/json'} 
// 		const obj = { action: "add", object: "wishlists", wishlists_id: 5, wishlists_product_id: this.activatedRoute.snapshot.paramMap.get("properties_property_id"), wishlists_user_id: this.userId};
// 		const body = JSON.stringify(obj);
// 		console.log(body)
// 		return this.http.post(this.apiUrl, body,{'headers':headers})
// }

	//   async addFavorite() {
	// 	const cartAnimation = this.animationCtrl.create('cart-animation')
	// 	.addElement(this.heart.nativeElement)
	// 	.keyframes([
	// 	  { offset: 0, transform: 'scale(1)' },
	// 	  { offset: 0.5, transform: 'scale(1.2)' },
	// 	  { offset: 0.8, transform: 'scale(0.9)' },
	// 	  { offset: 1, transform: 'scale(1)' }
	// 	]);
	
	//   const cartColorAnimation = this.animationCtrl.create('cart-color-animation')
	// 	.addElement(this.heart.nativeElement)
	// 	.fromTo('transform', 'rotate(0deg)', 'rotate(45deg)');
	
	
	//   const parent = this.animationCtrl.create('parent')
	// 	.duration(300)
	// 	.easing('ease-out')
	// 	.iterations(2)
	// 	.direction('alternate')
	// 	.addAnimation([cartColorAnimation, cartAnimation]);
	
	//   // Playing the parent starts both animations
	//   parent.play();
	// 	if (this.userId) {
	// 		return false;
	// 	} else {
	// 		let value = await {
	// 			wishlists_id: uuidv4(),
	// 			wishlists_product_id: this.properties_property_id,
	// 			wishlists_user_id: this.userId,
	// 			wishlists_status: '1'
	// 		  }, res;
	// 		try {
	// 			res = await this.wishlistsItems.add(value);
	// 				console.log(this.wishlistsItems);
	// 		} finally {
	// 		}

	// 		//console.log(res);
	// 		if (res && res.success) {
	// 		} else if (res && !res.success && res.failureMessage) {
	// 			if (environment.production) {
	// 				let err = await this.translate.get("FAILED_TO_EDIT").toPromise();
	// 				await this.showError(err);
	// 			} else {
	// 				await this.showError(res.failureMessage);
	// 			}
	// 		}
	// 	}
	// }
	
	

  
	/**
	 * Edit
	 */

	// ratings: any = 'ratings';
	// async postData() {
	// 		let value = await {crm_rating_comment: '9876543210'}, res;
	// 		console.log(value)
	// 		try {
	// 			res = await this.ratings.edit('d766fc2c-9fe3-4d72-b52f-ab0cc147cbbc', value);
	// 		} finally {
	// 		}

	// 		//console.log(res);
	// 		if (res && res.success) {
	// 			this.formGroup.reset();
	// 		} else if (res && !res.success && res.failureMessage) {
	// 			if (environment.production) {
	// 				let err = await this.translate.get("FAILED_TO_EDIT").toPromise();
	// 				await this.showError(err);
	// 			} else {
	// 				await this.showError(res.failureMessage);
	// 			}
	// 		}
	// 	}

	async checkLogin(){
		if(!this.isLoggedIn){
			this.goLoginModal();
		}
	}
	// switchValueByPropertyTypeId(properties_property_type_id: string) {
	// 	const propertyTypeId = parseInt(properties_property_type_id, 10);
	  
	// 	switch (propertyTypeId) {
	// 	  case 1:
	// 		return this.translate.instant("lands");
	// 	  case 2:
	// 		return this.translate.instant("apartments");
	// 	  case 3:
	// 		return this.translate.instant("building");
	// 	  case 4:
	// 		return this.translate.instant("residential_compounds");
	// 	  case 5:
	// 		return this.translate.instant("tower");
	// 	  case 6:
	// 		return this.translate.instant("villa");
	// 	  case 7:
	// 		return this.translate.instant("palace");
	// 	  case 8:
	// 		return this.translate.instant("traditional_house");
	// 	  case 9:
	// 		return this.translate.instant("farm");
	// 	  case 10:
	// 		return this.translate.instant("commercial_center");
	// 	  case 11:
	// 		return this.translate.instant("market");
	// 	  case 12:
	// 		return this.translate.instant("commercial_shop");
	// 	  case 13:
	// 		return this.translate.instant("office");
	// 	  case 14:
	// 		return this.translate.instant("restshop");
	// 	  case 15:
	// 		return this.translate.instant("resort");
	// 	  case 16:
	// 		return this.translate.instant("auditorius");
	// 	  case 17:
	// 		return this.translate.instant("hotel");
	// 	  case 18:
	// 		return this.translate.instant("hospital");
	// 	  case 19:
	// 		return this.translate.instant("schools");
	// 	  case 20:
	// 		return this.translate.instant("sports_clubs");
	// 	  case 21:
	// 		return this.translate.instant("gas_station");
	// 	  case 22:
	// 		return this.translate.instant("warehouse");
	// 	  case 23:
	// 		return this.translate.instant("industry");
	// 	  case 24:
	// 		return this.translate.instant("other");
	// 	  default:
	// 		return this.translate.instant("other");
	// 	}
	//   }
	
	//   calculateMonthlyPayment() {
	// 	const monthlyInterestRate = this.interestRate / 100 / 12;
	// 	const totalPayments = this.loanTerm * 12;
	// 	const discountFactor = (Math.pow(1 + monthlyInterestRate, totalPayments) - 1) / (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments));
	// 	this.monthlyPayment = this.loanAmount / discountFactor;
	//   }
	
	createLoanForm() {
		this.loanForm = this.formBuilder.group({
			supportedByProgram: [false, Validators.required],
			birthDate: [false, Validators.required],
			monthlyIncome: [false, Validators.required],
			monthlyCommitments: [false, Validators.required],
			jobType: [false, Validators.required],
			militaryRank: [null, this.validateMilitaryRank] // Use a custom validator function
		  });
		
		  // Subscribe to changes in the 'jobType' control
		  this.loanForm.get('jobType').valueChanges.subscribe(jobType => {
			if (jobType !== 'military') {
			  // If jobType is not 'military', clear the militaryRank control and its validation
			  this.loanForm.get('militaryRank').setValue(null);
			  this.loanForm.get('militaryRank').clearValidators();
			  this.loanForm.get('militaryRank').updateValueAndValidity();
			} else {
			  // If jobType is 'military', set the validator for militaryRank
			  this.loanForm.get('militaryRank').setValidators(Validators.required);
			  this.loanForm.get('militaryRank').updateValueAndValidity();
			}
		  });
		}
		
		validateMilitaryRank(control: AbstractControl): ValidationErrors | null {
		  const jobType = control?.parent?.get('jobType')?.value;
		  const militaryRank = control?.value;
		
		  if (jobType === 'military' && !militaryRank) {
			return { required: true }; // Return required validation error if jobType is 'military' and militaryRank is empty
		  }
		
		  return null; // Return null if the validation passes
		}

	loan: Loan = {
		supportedByProgram: false,
		birthDate: null,
		monthlyIncome: null,
		monthlyCommitments: null,
		jobType: 'civilian',
		militaryRank: null,
		hasPersonalLoan: false
	  };
	  resultsCalculated: boolean = false;
	  maxLoanAmount: number = 0;
	  totalAmountWithInterest: number = 0;
	  loanTermYears: number = 0;
	  loanTermMonths: number = 0;
	  monthlyPayment: number = 0;
	  monthlyPaymentInterests: number = 0;
	
	  calculateLoan(): void {
		if (this.loanForm.valid) {
		// Perform the mortgage calculation logic here
		// You can use the input values from this.loan object
	
		// Example calculation (replace with your actual calculation logic)
		this.maxLoanAmount = this.loan.monthlyIncome * 12;
		this.totalAmountWithInterest = this.maxLoanAmount + (this.maxLoanAmount * 0.1);
		this.loanTermYears = 10;
		this.loanTermMonths = this.loanTermYears * 12;
		this.monthlyPayment = this.totalAmountWithInterest / this.loanTermMonths;
		this.monthlyPaymentInterests = this.monthlyPayment - (this.maxLoanAmount / this.loanTermMonths);
	
		// Set resultsCalculated flag to show the results section
		this.resultsCalculated = true;
	} else {
	  // Handle form validation errors
	  // You can mark the form controls as touched to show validation messages
	  this.loanForm.markAllAsTouched();
	}
	  }
	
	  resetForm(): void {
		this.loanForm.reset();
		this.resultsCalculated = false;
		this.maxLoanAmount = 0;
		this.totalAmountWithInterest = 0;
		this.loanTermYears = 0;
		this.loanTermMonths = 0;
		this.monthlyPayment = 0;
		this.monthlyPaymentInterests = 0;
	  }
	  
	getCurrentTime(): number {
		return new Date().getTime();
	  }
	parseCloseAuctionDate(dateString: string): number {
		try {
			const timestamp = Date.parse(dateString) / 1000;
			if (!isNaN(timestamp)) {
				return timestamp;
			}
			console.log('Date string:', dateString);

		} catch (error) {
			console.error('Error parsing date:', error);
		}
		return 0; // Default fallback value
	}

	onFinish(): void {
		this.auctionCountdownFinished = true;
	  }
	  unitsLength: boolean = false;
	async getPropertiesLinked(properties_property_id: string) {
		if (this.dbapp.isObject(this.key)) {
			const loading = await this.loadingController.create({
				spinner: null,
				cssClass: 'global-loader',
				showBackdrop: true,
			});
			await loading.present();
			try {
				// Properties init
				let propertiesLinkedItem = await this.propertiesLinkedItem.query({ 'properties_linked_id' : properties_property_id });
				// console.log("propertiesLinkedItem", propertiesLinkedItem);
				if (propertiesLinkedItem) {
					await this.propertiesLinkedItem.lookup(propertiesLinkedItem, 'list');
					this.currentLinkedItems = await Promise.all(propertiesLinkedItem.map(async item => await this.propertiesLinkedItem.renderRow(item, 'list')));
					if(this.currentLinkedItems.length > 0){
						this.unitsLength = true;
						this.isUnitsModalOpen = true;
					}
					console.log(this.currentLinkedItems)
				} else {
					this.currentLinkedItems = [];
				}
			} catch(err) {
				this.showError(err);
			} finally {
				this.loadingStatus = true;
				await loading.dismiss();
			}
		}
	}

	async openModalPropertyDetails(propId: string) {
		const modalPropertyDetails = await this.modalController.create({
			component: properties_listsViewPage,
			componentProps: { viewPropertyDetails: 'viewPropertyDetails', properties_property_id: propId },
			cssClass: 'custom-modal-backdrop-clear',
			backdropDismiss: true,
		});

		modalPropertyDetails.onDidDismiss().then(item => {
			if (item && item.data) {

			}
		});
		this.sharedService.setPropertyId(propId);
		return await modalPropertyDetails.present();
	}

    isUnitsModalOpen = false;
    setUnitsOpen(isUnitsOpen: boolean) {
        this.isUnitsModalOpen = isUnitsOpen;
		this.getPropertiesLinked(this.properties_property_id);
    }

	roomsLength: boolean = false;
	async getRoomsLinked(properties_property_id: string) {
		this.loadingMessage = await this.translate.get("LOADING").toPromise();
		if (this.dbapp.isObject(this.key)) {
			const loading = await this.loadingController.create({
				spinner: null,
				cssClass: 'global-loader',
				message: this.loadingMessage,
				showBackdrop: true,
			});
			await loading.present();
			try {
				// Properties init
				let roomsLinkedItem = await this.roomsLinkedItem.query({ properties_property_id : this.properties_property_id });
				console.log("roomsLinkedItem", roomsLinkedItem)
				if (roomsLinkedItem) {
					await this.roomsLinkedItem.lookup(roomsLinkedItem, 'list');
					this.currentRoomsLinkedItems = await Promise.all(roomsLinkedItem.map(async item => await this.roomsLinkedItem.renderRow(item, 'list')));
					if(this.currentRoomsLinkedItems.length > 0){
						this.roomsLength = true;
						this.isRoomsModalOpen = true;
					}
				} else {
					this.currentRoomsLinkedItems = [];
				}
			} catch(err) {
				this.showError(err);
			} finally {
				this.loadingStatus = true;
				await loading.dismiss();
			}
		}
	}
	
    isRoomsModalOpen = false;
    setRoomsOpen(isRoomsOpen: boolean) {
        this.isRoomsModalOpen = isRoomsOpen;
    }

	async openModalRoomDetails(roomId: string) {
		const modalRoomDetails = await this.modalController.create({
			component: roomsViewPage,
			componentProps: { viewRoomDetails: 'viewRoomDetails', properties_room_id: roomId },
			cssClass: 'custom-modal-backdrop-clear l-850',
			backdropDismiss: true,
		});

		modalRoomDetails.onDidDismiss().then(item => {
			if (item && item.data) {

			}
		});
		return await modalRoomDetails.present();
	}

	updateThisProperty(): void {
		this.router.navigate(['/properties_editsedit', this.properties_property_id]);
		this.cancel();
	  }
	  

	// Get Parent Propery Linked
	async getParentPropertyLinked(properties_linked_id: string) {
		if (this.dbapp.isObject(this.key)) {
			try {
				let itemParent = await this.propertiesLinkedItem.query(Object.assign({ action: "view" }, { 'properties_property_id': properties_linked_id }));
				if (this.dbapp.isObject(itemParent)) {
					await this.propertiesLinkedItem.lookup(itemParent, "view");
					this.itemParent = await this.propertiesLinkedItem.renderRow(itemParent, "view");
					this.itemParent$.next(this.itemParent);
				}
			} catch(err) {
				this.showError(err);
			} finally {
			}
		}
	}

	userParentLength: boolean = false;
	// Get Parent User Linked
	async getParentUserLinked(system_parent_user_id: string) {
		if (this.dbapp.isObject(this.key)) {
			try {
				let itemParentUser = await this.parentUserLinkedItem.query(Object.assign({ action: "view" }, { 'system_user_id': system_parent_user_id }));
				if (this.dbapp.isObject(itemParentUser)) {
					await this.parentUserLinkedItem.lookup(itemParentUser, "view");
					this.itemParentUser = await this.parentUserLinkedItem.renderRow(itemParentUser, "view");
					this.itemParentUser$.next(this.itemParentUser);
					if(this.itemParentUser) {
						this.userParentLength = true;
					}
				}
			} catch(err) {
				this.showError(err);
			} finally {
			}
		}
	}

	async openModalSchemaDetails(schemaId: string) {
		const modalSchemaDetails = await this.modalController.create({
			component: properties_schemasViewPage,
			componentProps: { viewSchemaDetails: 'viewSchemaDetails', properties_property_schema_id: schemaId },
			cssClass: 'custom-modal-backdrop-clear',
			backdropDismiss: true,
		});

		modalSchemaDetails.onDidDismiss().then(item => {
			if (item && item.data) {

			}
		});
		return await modalSchemaDetails.present();
	}
	
	async generateShortReportPDF() {
		const loading = await this.loadingController.create({
			spinner: null,
			cssClass: 'global-loader',

			message: this.loadingMessage,
			showBackdrop: true,
		});
		await loading.present();
		try {		
		console.log('this.item.properties_property_id.value', this.item.properties_property_id.value)
		await this.pdfService.generatePDF(this.item.properties_property_id.value, 1);
		} catch(err) {
			this.showError(err);
		} finally {
			this.loadingStatus = true;
			await loading.dismiss();
		}
	  }
	  
	  async generateFullReportPDF() {
		  const loading = await this.loadingController.create({
			  spinner: null,
			  cssClass: 'global-loader',
  
			  message: this.loadingMessage,
			  showBackdrop: true,
		  });
		  await loading.present();
		  try {		
		  console.log('this.item.properties_property_id.value', this.item.properties_property_id.value)
		  await this.pdfService.generatePDF(this.item.properties_property_id.value, 2);
		  } catch(err) {
			  this.showError(err);
		  } finally {
			  this.loadingStatus = true;
			  await loading.dismiss();
		  }
		}
	  
		async generateBannerPDF() {
			const loading = await this.loadingController.create({
				spinner: null,
				cssClass: 'global-loader',
	
				message: this.loadingMessage,
				showBackdrop: true,
			});
			await loading.present();
			try {		
			console.log('this.item.properties_property_id.value', this.item.properties_property_id.value)
			await this.pdfService.generatePDF(this.item.properties_property_id.value, 3);
			} catch(err) {
				this.showError(err);
			} finally {
				this.loadingStatus = true;
				await loading.dismiss();
			}
		  }
	  
  }