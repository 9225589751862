import { NgModule, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserData, Settings, LocaleService, DbApp, History } from './';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'propertyStatus'
})
export class PropertyStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(properties_property_status_id: string): string {
    const propertyFeatureId = parseInt(properties_property_status_id, 10); // Convert to integer
    // properties_property_status_id example: '1','1,2','1,2,3,4' etc...
    switch (propertyFeatureId) {
      case 0:
        return this.translate.instant('properties');
      case 1:
        return this.translate.instant('sell');
      case 2:
        return this.translate.instant('rent');
      case 3:
        return this.translate.instant('invest');
      case 4:
        return this.translate.instant('auction');
      case 5:
        return this.translate.instant('sell_rent');
      case 6:
        return this.translate.instant('sell_invest');
      case 7:
        return this.translate.instant('sell_auction');
      case 8:
        return this.translate.instant('rent_invest');
      case 9:
        return this.translate.instant('rent_auction');
      case 10:
        return this.translate.instant('invest_auction');
      case 11:
        return this.translate.instant('sell_rent_invest');
      case 12:
        return this.translate.instant('sell_rent_auction');
      case 13:
        return this.translate.instant('sell_invest_auction');
      default:
        return this.translate.instant('properties');
    }
  }
}