// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedService {
  private propertyIdSubject = new BehaviorSubject<string>('');

  getPropertyId() {
    return this.propertyIdSubject.asObservable();
  }

  setPropertyId(propertyId: string) {
    this.propertyIdSubject.next(propertyId);
  }
}
