import { HttpClient } from '@angular/common/http';
import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DbApp } from './dbapp';
import { UserData } from './user-data';
import { DbTable } from './dbtable';
import { DbRecord } from './dbrecord';
import { DbField } from './dbfield';
import { LocaleService } from './locale.service';
import { LocalDatePipe } from './localdate.pipe';
import { LocalNumberPipe } from './localnumber.pipe';
import { LocalCurrencyPipe } from './localcurrency.pipe';
import { LocalPercentPipe } from './localpercent.pipe';
import { AuthFilePipe } from './authfile.pipe';
import { MapsAPILoader } from '@agm/core';

// add_property_1
@Injectable({
	providedIn: 'root'
})
export class add_property_1 extends DbTable {
	pageId: string;
	fieldVars = ["properties_property_id","properties_property_status_id","properties_services_id","properties_property_type_id","properties_country_id","properties_region_id","properties_city_id","properties_neighborhood_id","properties_latitude","properties_longitude","properties_dimension_geometry","properties_picture","properties_last_sale_price","properties_last_rent_price","properties_last_investment_amount","properties_auction_opening_amount","properties_total_area","properties_unit_type_id","properties_description","properties_building_built_date_gregorian","properties_owner_id","properties_proxy_id","properties_mandator_id","properties_info_client_confirmed"];
	fieldNames = []; // Array([name, var]) for fields with name != var
	listFields: string[] = ["properties_property_id","properties_property_status_id","properties_services_id","properties_property_type_id","properties_country_id","properties_region_id","properties_city_id","properties_neighborhood_id","properties_latitude","properties_longitude","properties_dimension_geometry","properties_picture","properties_last_sale_price","properties_last_rent_price","properties_last_investment_amount","properties_auction_opening_amount","properties_total_area","properties_unit_type_id","properties_description","properties_building_built_date_gregorian","properties_owner_id","properties_proxy_id","properties_mandator_id","properties_info_client_confirmed"];
	viewFields: string[] = ["properties_property_id","properties_property_status_id","properties_services_id","properties_property_type_id","properties_country_id","properties_region_id","properties_city_id","properties_neighborhood_id","properties_latitude","properties_longitude","properties_dimension_geometry","properties_picture","properties_last_sale_price","properties_last_rent_price","properties_last_investment_amount","properties_auction_opening_amount","properties_total_area","properties_unit_type_id","properties_description","properties_building_built_date_gregorian","properties_owner_id","properties_proxy_id","properties_mandator_id","properties_info_client_confirmed"];
	addFields: string[] = ["properties_property_id","properties_property_status_id","properties_services_id","properties_property_type_id","properties_country_id","properties_region_id","properties_city_id","properties_neighborhood_id","properties_latitude","properties_longitude","properties_dimension_geometry","properties_picture","properties_last_sale_price","properties_last_rent_price","properties_last_investment_amount","properties_auction_opening_amount","properties_total_area","properties_unit_type_id","properties_description","properties_building_built_date_gregorian","properties_owner_id","properties_proxy_id","properties_mandator_id","properties_info_client_confirmed"];
	editFields: string[] = ["properties_property_id","properties_property_status_id","properties_services_id","properties_property_type_id","properties_country_id","properties_region_id","properties_city_id","properties_neighborhood_id","properties_latitude","properties_longitude","properties_dimension_geometry","properties_picture","properties_last_sale_price","properties_last_rent_price","properties_last_investment_amount","properties_auction_opening_amount","properties_total_area","properties_unit_type_id","properties_description","properties_building_built_date_gregorian","properties_owner_id","properties_proxy_id","properties_mandator_id","properties_info_client_confirmed"];
	lookupTables: any = {};
	displayValueSeparators: any = {};
	errorMessages: any;
	row: any; // Current row (rendered)
	labelAttribute: string = "name";
	map: any;
	latitude: number;
	longitude: number;
	private markers: any[] = [];
	private geocoder: Promise<any>;

	// Constructor
	constructor(public dbapp: DbApp,
		public user: UserData,
		public translate: TranslateService,
		public locale: LocaleService,
		public router: Router,
			private mapsAPILoader: MapsAPILoader,
			public http: HttpClient) {
		super(dbapp, http, user, translate, router);
		this.name = "add_property_1";
		this.translate.get(this.fieldVars.map(fldvar => "__tables." + this.name + ".fields." + fldvar + ".errMsg")).subscribe(values => {
			for (let k in values)
				values[k] = (k != values[k]) ? values[k] : "";
			this.errorMessages = values;
		});
		this.translate.get(["__tables.add_property_1.fields.properties_property_status_id.tagValues","__tables.add_property_1.fields.properties_property_type_id.tagValues"]).subscribe(values => {
			this.lookupTables.properties_property_status_id = dbapp.convertUserValues(values["__tables.add_property_1.fields.properties_property_status_id.tagValues"]);
			this.lookupTables.properties_property_type_id = dbapp.convertUserValues(values["__tables.add_property_1.fields.properties_property_type_id.tagValues"]);
		});
		this.infiniteScroll = true;
		this.pageSize = 8;
		let p = this.mapsAPILoader.load();
		this.geocoder = p.then(() => new google.maps.Geocoder());
	}

	// Lookup
	async lookup(item: any, pageId: string) {
		if (!item)
			return;
		pageId = pageId == "signup" ? "register" : pageId;
		let page = pageId != "register" ? this.name + "_" + pageId : pageId;
		this.pageId = pageId;
		let p = [];

		// properties_services_id
		if (this.renderField("properties_services_id", pageId)) {
			let params;
			if (Array.isArray(item)) { // List
				let keys1 = item.map(row => row["properties_services_id"]).filter((v, i, a) =>  !this.dbapp.isEmpty(v) && a.indexOf(v) == i);
				params = { action: "lookup", ajax: "modal", page: page, field: "properties_services_id", keys: keys1 };
			} else { // Add/Edit/View
				params = { action: "lookup", ajax: "updateoption", page: page, field: "properties_services_id" };
				if (pageId == "view")
					params["v0"] = item["properties_services_id"];
			}
			p.push(this.query(params).then(items => {
				items.forEach(item => item["name"] = this.dbapp.displayValue(item, this.displayValueSeparators.properties_services_id));
				return items;
			}));
		}

		// properties_country_id
		if (this.renderField("properties_country_id", pageId)) {
			let params;
			if (Array.isArray(item)) { // List
				let keys2 = item.map(row => row["properties_country_id"]).filter((v, i, a) =>  !this.dbapp.isEmpty(v) && a.indexOf(v) == i);
				params = { action: "lookup", ajax: "modal", page: page, field: "properties_country_id", keys: keys2 };
			} else { // Add/Edit/View
				params = { action: "lookup", ajax: "updateoption", page: page, field: "properties_country_id" };
				if (pageId == "view")
					params["v0"] = item["properties_country_id"];
			}
			p.push(this.query(params).then(items => {
				items.forEach(item => item["name"] = this.dbapp.displayValue(item, this.displayValueSeparators.properties_country_id));
				return items;
			}));
		}

		// properties_region_id
		if (this.renderField("properties_region_id", pageId)) {
			let params;
			if (Array.isArray(item)) { // List
				let keys3 = item.map(row => row["properties_region_id"]).filter((v, i, a) =>  !this.dbapp.isEmpty(v) && a.indexOf(v) == i);
				params = { action: "lookup", ajax: "modal", page: page, field: "properties_region_id", keys: keys3 };
			} else { // Add/Edit/View
				params = { action: "lookup", ajax: "updateoption", page: page, field: "properties_region_id" };
				if (pageId == "view")
					params["v0"] = item["properties_region_id"];
				params["v1"] = item["properties_country_id"];
			}
			p.push(this.query(params).then(items => {
				items.forEach(item => item["name"] = this.dbapp.displayValue(item, this.displayValueSeparators.properties_region_id));
				return items;
			}));
		}

		// properties_city_id
		if (this.renderField("properties_city_id", pageId)) {
			let params;
			if (Array.isArray(item)) { // List
				let keys4 = item.map(row => row["properties_city_id"]).filter((v, i, a) =>  !this.dbapp.isEmpty(v) && a.indexOf(v) == i);
				params = { action: "lookup", ajax: "modal", page: page, field: "properties_city_id", keys: keys4 };
			} else { // Add/Edit/View
				params = { action: "lookup", ajax: "updateoption", page: page, field: "properties_city_id" };
				if (pageId == "view")
					params["v0"] = item["properties_city_id"];
				params["v1"] = item["properties_region_id"];
			}
			p.push(this.query(params).then(items => {
				items.forEach(item => item["name"] = this.dbapp.displayValue(item, this.displayValueSeparators.properties_city_id));
				return items;
			}));
		}

		// properties_neighborhood_id
		if (this.renderField("properties_neighborhood_id", pageId)) {
			let params;
			if (Array.isArray(item)) { // List
				let keys5 = item.map(row => row["properties_neighborhood_id"]).filter((v, i, a) =>  !this.dbapp.isEmpty(v) && a.indexOf(v) == i);
				params = { action: "lookup", ajax: "modal", page: page, field: "properties_neighborhood_id", keys: keys5 };
			} else { // Add/Edit/View
				params = { action: "lookup", ajax: "updateoption", page: page, field: "properties_neighborhood_id" };
				if (pageId == "view")
					params["v0"] = item["properties_neighborhood_id"];
				params["v1"] = item["properties_city_id"];
			}
			p.push(this.query(params).then(items => {
				items.forEach(item => item["name"] = this.dbapp.displayValue(item, this.displayValueSeparators.properties_neighborhood_id));
				return items;
			}));
		}

		// Get lookup results
		try {
			[this.lookupTables.properties_services_id, this.lookupTables.properties_country_id, this.lookupTables.properties_region_id, this.lookupTables.properties_city_id, this.lookupTables.properties_neighborhood_id] = await Promise.all(p);
		} catch(err) {
			console.log(err);
		}
	}

	// Render field
	renderField(fieldName: string, pageId: string) {
		if (["list", "view", "add", "edit", "register"].includes(pageId))
			return this[pageId + "Fields"].includes(fieldName);
		return false;
	}

	// Get field variable name
	getFieldVar(name) {
		let f = this.fieldNames.find(f => f[0] == name);
		return f ? f[1] : name;
	}

	// Get field variable name
	getFieldName(varname) {
		let f = this.fieldNames.find(f => f[1] == varname);
		return f ? f[0] : varname;
	}

	// Render row
	async renderRow(item: any, pageId: string) {
		this.pageId = pageId;
		let row = new DbRecord(item, this.fieldNames, this.errorMessages);
		this.rowOnRender(row);

		// properties_property_id
		if (this.renderField("properties_property_id", pageId)) {
			row["properties_property_id"].map = Object.assign({"field":"properties_property_id","width":"100%","height":"200px","zoom":8,"icon":null,"mapTypeId":"roadmap","markers":[],"useSingleMap":false,"useMarkerClusterer":true,"showValue":true}, {marker:{title:row["properties_city_id"].value,address:row["properties_neighborhood_id"].value,latitude:parseFloat(row["properties_latitude"].value),longitude:parseFloat(row["properties_longitude"].value)}});
			await this.addMarker(row["properties_property_id"].map);
		}

		// properties_property_status_id
		if (this.renderField("properties_property_status_id", pageId)) {
			let selectedValues = (String(row["properties_property_status_id"].dbValue) || "").split(this.dbapp.multipleOptionSeparator),
				selectedRows = this.lookupTables.properties_property_status_id.filter(r => selectedValues.includes(r.lf)); // Compare with db value
			row["properties_property_status_id"].formValue = selectedValues; // FormControl value cannot be undefined
			row["properties_property_status_id"].value = selectedRows.length
				? selectedRows.map(r => this.dbapp.displayValue(r, this.displayValueSeparators.properties_property_status_id)).join(this.dbapp.optionSeparator)
				: row["properties_property_status_id"].dbValue;
		}

		// properties_services_id
		if (this.renderField("properties_services_id", pageId)) {
			row["properties_services_id"].value = ""; // Value to be looked up
			let selectedValues = (String(row["properties_services_id"].dbValue) || "").split(this.dbapp.multipleOptionSeparator),
				selectedRows = this.lookupTables.properties_services_id.filter(r => selectedValues.includes(r.lf)); // Compare with db value
			row["properties_services_id"].formValue = selectedValues; // FormControl value cannot be undefined
			row["properties_services_id"].value = selectedRows.length
				? selectedRows.map(r => this.dbapp.displayValue(r, this.displayValueSeparators.properties_services_id)).join(this.dbapp.optionSeparator)
				: row["properties_services_id"].dbValue;
		}

		// properties_property_type_id
		if (this.renderField("properties_property_type_id", pageId)) {
			let selectedRow = this.lookupTables.properties_property_type_id.find(r => r.lf == row["properties_property_type_id"].dbValue); // Compare with db value
			row["properties_property_type_id"].formValue = row["properties_property_type_id"].dbValue; // FormControl value cannot be undefined
			row["properties_property_type_id"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.properties_property_type_id) : row["properties_property_type_id"].dbValue;
		}

		// properties_country_id
		if (this.renderField("properties_country_id", pageId)) {
			row["properties_country_id"].value = ""; // Value to be looked up
			let selectedRow = this.lookupTables.properties_country_id.find(r => r.lf == row["properties_country_id"].dbValue); // Compare with db value
			row["properties_country_id"].formValue = row["properties_country_id"].dbValue; // FormControl value cannot be undefined
			row["properties_country_id"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.properties_country_id) : row["properties_country_id"].dbValue;
		}

		// properties_region_id
		if (this.renderField("properties_region_id", pageId)) {
			row["properties_region_id"].value = ""; // Value to be looked up
			let selectedRow = this.lookupTables.properties_region_id.find(r => r.lf == row["properties_region_id"].dbValue); // Compare with db value
			row["properties_region_id"].formValue = row["properties_region_id"].dbValue; // FormControl value cannot be undefined
			row["properties_region_id"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.properties_region_id) : row["properties_region_id"].dbValue;
		}

		// properties_city_id
		if (this.renderField("properties_city_id", pageId)) {
			row["properties_city_id"].value = ""; // Value to be looked up
			let selectedRow = this.lookupTables.properties_city_id.find(r => r.lf == row["properties_city_id"].dbValue); // Compare with db value
			row["properties_city_id"].formValue = row["properties_city_id"].dbValue; // FormControl value cannot be undefined
			row["properties_city_id"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.properties_city_id) : row["properties_city_id"].dbValue;
		}

		// properties_neighborhood_id
		if (this.renderField("properties_neighborhood_id", pageId)) {
			row["properties_neighborhood_id"].value = ""; // Value to be looked up
			let selectedRow = this.lookupTables.properties_neighborhood_id.find(r => r.lf == row["properties_neighborhood_id"].dbValue); // Compare with db value
			row["properties_neighborhood_id"].formValue = row["properties_neighborhood_id"].dbValue; // FormControl value cannot be undefined
			row["properties_neighborhood_id"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.properties_neighborhood_id) : row["properties_neighborhood_id"].dbValue;
		}

		// properties_unit_type_id
		if (this.renderField("properties_unit_type_id", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["properties_unit_type_id"].value = formatNumber(row["properties_unit_type_id"].value, this.locale.locale);
		}

		// properties_info_client_confirmed
		if (this.renderField("properties_info_client_confirmed", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["properties_info_client_confirmed"].value = formatNumber(row["properties_info_client_confirmed"].value, this.locale.locale);
		}
		row.rendered = true;
		this.rowAfterRendered(row);
		this.row = row; // Set current row
		return row;
	}

	// Render file URL
	async renderFileUrl(url: string) {
		return this.http.get(url, { responseType: "blob" }).toPromise().then(blob => {
			const reader = new FileReader();
			return new Promise((resolve, reject) => {
				reader.onloadend = () => resolve(reader.result as string);
				reader.readAsDataURL(blob);
			});
		});
	}

	// Add marker
	async addMarker(map: any) {
		if (!map || !this.map || !this.map.markers) // No marker or not single map
			return;
		let add = async (marker) => {
			let str = marker.latitude.toString() + "," + marker.longitude.toString();
			if (!this.markers.includes(str)) {
				this.markers.push(str);
				this.map.markers.push(marker);
			}
		};
		if (map.marker.latitude && map.marker.longitude) { // By coordinate
			add(map.marker);
		} else if (map.address) { // By address
			(await this.geocoder).geocode({ "address": map.address }, (results, status) => {
				if (status == "OK") {
					map.marker.latitude = results[0].geometry.location.lat();
					map.marker.longitude = results[0].geometry.location.lng();
					add(map.marker);
				} else {
					console.log("Geocode was not successful for the following reason: " + status);
				}
			});
		}
	}

	// Clear markers
	clearMarkers() {
		this.markers = [];
		if (this.map && this.map.markers)
			this.map.markers = [];
	}
}