import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PropertyIdResolver implements Resolve<string> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string {
    // Extract the 'properties_property_id' value from the query parameter 'id'
    const properties_property_id = route.queryParamMap.get('id');

    // Ensure you validate or sanitize the propertyId as needed
    console.log('Get properties_property_id From: ', properties_property_id);

    return properties_property_id;
  }
}