import { NgModule, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserData, Settings, LocaleService, DbApp, History } from './';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyTypeIcons'
})
export class PropertyTypeIconsPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(
    properties_property_type_id: string,
    properties_building_rooms_total: number,
    properties_building_toilets_total: number,
    properties_total_area: number
  ): string {
    const propertyTypeIconsId = parseInt(properties_property_type_id, 10);
    const propertiesBuildingRoomsTotal = properties_building_rooms_total;
    const propertiesBuildingToiletsTotal = properties_building_toilets_total;
    const propertiesTotalArea = properties_total_area;

    switch (propertyTypeIconsId) {
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        // Check if properties_building_rooms_total is defined and not 0
        const bedChip = (propertiesBuildingRoomsTotal !== undefined && propertiesBuildingRoomsTotal !== null && propertiesBuildingRoomsTotal !== 0) ? `
        <div class="col">
          <div class="icons-info-group-items">
              <img src="../../../assets/icon/bedroom.svg"></img>
              <h6>${propertiesBuildingRoomsTotal}</h6>
          </div>
        </div>` : '';

        // Check if properties_building_toilets_total is defined and not 0
        const bathChip = (propertiesBuildingToiletsTotal !== undefined && propertiesBuildingToiletsTotal !== null && propertiesBuildingToiletsTotal !== 0) ? `
        <div class="col">
          <div class="icons-info-group-items">
            <img src="../../../assets/icon/bath.svg"></img>
            <h6>${propertiesBuildingToiletsTotal}</h6>
            </div>
          </div>` : '';

        // Check if properties_total_area is defined and not 0
        const lengthChip = (propertiesTotalArea !== undefined && propertiesTotalArea !== null && propertiesTotalArea !== 0) ? `
        <div class="col">
          <div class="icons-info-group-items">
         <img src="../../../assets/icon/length.svg"></img>
         <h6>${propertiesTotalArea}</h6>
            <small>${this.translate.instant('m2')}</small>
            </div>
          </div>` : '';
        return `${bedChip}${bathChip}${lengthChip}`;
      
      case 9:
        // Check if properties_building_toilets_total is defined and not 0
        return (propertiesBuildingToiletsTotal !== undefined && propertiesBuildingToiletsTotal !== null && propertiesBuildingToiletsTotal !== 0) ? `
        <div class="col">
          <div class="icons-info-group-items">
            <img src="../../../assets/icon/bath.svg"></img>
            <h6>${propertiesBuildingToiletsTotal}</h6>
            </div>
          </div>` : '';
      
      default:
        // Check if properties_total_area is defined and not 0
        return (propertiesTotalArea !== undefined && propertiesTotalArea !== null && propertiesTotalArea !== 0) ? `
        <div class="col">
          <div class="icons-info-group-items">
            <img src="../../../assets/icon/length.svg"></img>
            <h6>${propertiesTotalArea}</h6>
            <label><small>${this.translate.instant('m2')}</small></label>
            </div>
          </div>` : '';
    }
  }
}


