import { HttpClient } from '@angular/common/http';
import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DbApp } from './dbapp';
import { UserData } from './user-data';
import { DbTable } from './dbtable';
import { DbRecord } from './dbrecord';
import { DbField } from './dbfield';
import { LocaleService } from './locale.service';
import { LocalDatePipe } from './localdate.pipe';
import { LocalNumberPipe } from './localnumber.pipe';
import { LocalCurrencyPipe } from './localcurrency.pipe';
import { LocalPercentPipe } from './localpercent.pipe';
import { AuthFilePipe } from './authfile.pipe';

// wishlists
@Injectable({
	providedIn: 'root'
})
export class wishlists extends DbTable {
	pageId: string;
	fieldVars = ["wishlists_id","wishlists_added","wishlists_product_id","wishlists_user_id","wishlists_status","wishlists_last_edit_datetime_gregorian","wishlists_last_edit_datetime_hijri","wishlists_last_edit_by"];
	fieldNames = []; // Array([name, var]) for fields with name != var
	listFields: string[] = ["wishlists_product_id","wishlists_user_id","wishlists_status"];
	viewFields: string[] = ["wishlists_id","wishlists_added","wishlists_product_id","wishlists_user_id","wishlists_status","wishlists_last_edit_datetime_gregorian","wishlists_last_edit_datetime_hijri","wishlists_last_edit_by"];
	addFields: string[] = ["wishlists_id","wishlists_added","wishlists_product_id","wishlists_user_id","wishlists_status","wishlists_last_edit_datetime_gregorian","wishlists_last_edit_datetime_hijri","wishlists_last_edit_by"];
	editFields: string[] = ["wishlists_status"];
	lookupTables: any = {};
	displayValueSeparators: any = {};
	errorMessages: any;
	row: any; // Current row (rendered)
	labelAttribute: string = "name";

	// Constructor
	constructor(public dbapp: DbApp,
		public user: UserData,
		public translate: TranslateService,
		public locale: LocaleService,
		public router: Router,
			public http: HttpClient) {
		super(dbapp, http, user, translate, router);
		this.name = "wishlists";
		this.translate.get(this.fieldVars.map(fldvar => "__tables." + this.name + ".fields." + fldvar + ".errMsg")).subscribe(values => {
			for (let k in values)
				values[k] = (k != values[k]) ? values[k] : "";
			this.errorMessages = values;
		});
		this.infiniteScroll = true;
		this.pageSize = 8;
	}

	// Lookup
	async lookup(item: any, pageId: string) {
		if (!item)
			return;
		pageId = pageId == "signup" ? "register" : pageId;
		let page = pageId != "register" ? this.name + "_" + pageId : pageId;
		this.pageId = pageId;
		let p = [];

		// wishlists_product_id
		if (this.renderField("wishlists_product_id", pageId)) {
			let params;
			if (Array.isArray(item)) { // List
				let keys1 = item.map(row => row["wishlists_product_id"]).filter((v, i, a) =>  !this.dbapp.isEmpty(v) && a.indexOf(v) == i);
				params = { action: "lookup", ajax: "modal", page: page, field: "wishlists_product_id", keys: keys1 };
			} else { // Add/Edit/View
				params = { action: "lookup", ajax: "updateoption", page: page, field: "wishlists_product_id" };
				if (pageId == "view")
					params["v0"] = item["wishlists_product_id"];
			}
			p.push(this.query(params).then(items => {
				items.forEach(item => item["name"] = this.dbapp.displayValue(item, this.displayValueSeparators.wishlists_product_id));
				return items;
			}));
		}

		// Get lookup results
		try {
			[this.lookupTables.wishlists_product_id] = await Promise.all(p);
		} catch(err) {
			console.log(err);
		}
	}

	// Render field
	renderField(fieldName: string, pageId: string) {
		if (["list", "view", "add", "edit", "register"].includes(pageId))
			return this[pageId + "Fields"].includes(fieldName);
		return false;
	}

	// Get field variable name
	getFieldVar(name) {
		let f = this.fieldNames.find(f => f[0] == name);
		return f ? f[1] : name;
	}

	// Get field variable name
	getFieldName(varname) {
		let f = this.fieldNames.find(f => f[1] == varname);
		return f ? f[0] : varname;
	}

	// Render row
	async renderRow(item: any, pageId: string) {
		this.pageId = pageId;
		let row = new DbRecord(item, this.fieldNames, this.errorMessages);
		this.rowOnRender(row);

		// wishlists_product_id
		if (this.renderField("wishlists_product_id", pageId)) {
			row["wishlists_product_id"].value = ""; // Value to be looked up
			let selectedRow = this.lookupTables.wishlists_product_id.find(r => r.lf == row["wishlists_product_id"].dbValue); // Compare with db value
			row["wishlists_product_id"].formValue = row["wishlists_product_id"].dbValue; // FormControl value cannot be undefined
			row["wishlists_product_id"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.wishlists_product_id) : row["wishlists_product_id"].dbValue;
			if (row["wishlists_product_id"])
				row.title = row["wishlists_product_id"].value; // Title field
		}

		// wishlists_status
		if (this.renderField("wishlists_status", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["wishlists_status"].value = formatNumber(row["wishlists_status"].value, this.locale.locale);
		}
		row.rendered = true;
		this.rowAfterRendered(row);
		this.row = row; // Set current row
		return row;
	}

	// Render file URL
	async renderFileUrl(url: string) {
		return this.http.get(url, { responseType: "blob" }).toPromise().then(blob => {
			const reader = new FileReader();
			return new Promise((resolve, reject) => {
				reader.onloadend = () => resolve(reader.result as string);
				reader.readAsDataURL(blob);
			});
		});
	}
}