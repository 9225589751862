import { HttpClient } from '@angular/common/http';
import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DbApp } from './dbapp';
import { UserData } from './user-data';
import { DbTable } from './dbtable';
import { DbRecord } from './dbrecord';
import { DbField } from './dbfield';
import { LocaleService } from './locale.service';
import { LocalDatePipe } from './localdate.pipe';
import { LocalNumberPipe } from './localnumber.pipe';
import { LocalCurrencyPipe } from './localcurrency.pipe';
import { LocalPercentPipe } from './localpercent.pipe';
import { AuthFilePipe } from './authfile.pipe';

// users
@Injectable({
	providedIn: 'root'
})
export class users extends DbTable {
	pageId: string;
	fieldVars = ["system_user_id","system_user_level_id","system_user_name","system_password","system_account_activation_id","system_identity_no","system_mobile_1","system_email","system_parent_user_id","system_users_latitude","system_users_longitude","system_welcome_sms","system_welcome_email","system_created_datetime_gregorian","system_user_sms_status","system_user_email_status","system_user_invest_service_statues","system_user_auction_service_statues","system_user_management_service_statues","system_user_list_service_statues","system_user_wallet_in","clients_account_id","system_user_photo","system_full_name","sellers_permit_nb","sellers_permit_issued_date_gregorian","sellers_permit_expire_date_gregorian","sellers_services_id","system_confirm_mobile_1","system_confirm_mobile_2","system_confirm_email","system_confirm_identity_no","system_confirm_permit_nb","system_user_is_company","system_user_wallet_reserved"];
	fieldNames = []; // Array([name, var]) for fields with name != var
	listFields: string[] = ["system_user_id","system_user_level_id","system_user_name","system_password","system_account_activation_id","system_identity_no","system_mobile_1","system_email","system_users_latitude","system_users_longitude","system_welcome_sms","system_welcome_email","system_created_datetime_gregorian","system_user_sms_status","system_user_email_status","system_user_invest_service_statues","system_user_auction_service_statues","system_user_management_service_statues","system_user_list_service_statues","system_user_wallet_in","clients_account_id","system_user_photo","system_full_name","sellers_permit_nb","sellers_permit_issued_date_gregorian","sellers_permit_expire_date_gregorian","sellers_services_id","system_confirm_mobile_1","system_confirm_mobile_2","system_confirm_email","system_confirm_identity_no","system_confirm_permit_nb","system_user_is_company","system_user_wallet_reserved"];
	viewFields: string[] = ["system_user_id","system_user_level_id","system_user_name","system_password","system_account_activation_id","system_identity_no","system_mobile_1","system_email","system_parent_user_id","system_users_latitude","system_users_longitude","system_welcome_sms","system_welcome_email","system_created_datetime_gregorian","system_user_sms_status","system_user_email_status","system_user_invest_service_statues","system_user_auction_service_statues","system_user_management_service_statues","system_user_list_service_statues","system_user_wallet_in","clients_account_id","system_user_photo","system_full_name","sellers_permit_nb","sellers_permit_issued_date_gregorian","sellers_permit_expire_date_gregorian","sellers_services_id","system_confirm_mobile_1","system_confirm_mobile_2","system_confirm_email","system_confirm_identity_no","system_confirm_permit_nb","system_user_is_company","system_user_wallet_reserved"];
	addFields: string[] = ["system_user_id","system_user_level_id","system_user_name","system_password","system_account_activation_id","system_identity_no","system_mobile_1","system_email","system_parent_user_id","system_users_latitude","system_users_longitude","system_welcome_sms","system_welcome_email","system_created_datetime_gregorian","system_user_sms_status","system_user_email_status","system_user_invest_service_statues","system_user_auction_service_statues","system_user_management_service_statues","system_user_list_service_statues","system_user_wallet_in","clients_account_id","system_user_photo","system_full_name","sellers_permit_nb","sellers_permit_issued_date_gregorian","sellers_permit_expire_date_gregorian","sellers_services_id","system_confirm_mobile_1","system_confirm_mobile_2","system_confirm_email","system_confirm_identity_no","system_confirm_permit_nb","system_user_is_company","system_user_wallet_reserved"];
	editFields: string[] = ["system_user_level_id","system_user_name","system_password","system_account_activation_id","system_identity_no","system_mobile_1","system_email","system_parent_user_id","system_users_latitude","system_users_longitude","system_welcome_sms","system_welcome_email","system_created_datetime_gregorian","system_user_sms_status","system_user_email_status","system_user_invest_service_statues","system_user_auction_service_statues","system_user_management_service_statues","system_user_list_service_statues","system_user_wallet_in","clients_account_id","system_user_photo","system_full_name","sellers_permit_nb","sellers_permit_issued_date_gregorian","sellers_permit_expire_date_gregorian","sellers_services_id","system_confirm_mobile_1","system_confirm_mobile_2","system_confirm_email","system_confirm_identity_no","system_confirm_permit_nb","system_user_is_company","system_user_wallet_reserved"];
	registerFields: string[] = ["system_user_id","system_user_name","system_password","system_account_activation_id","system_identity_no","system_mobile_1","system_email","sellers_permit_nb","system_confirm_mobile_1","system_confirm_mobile_2","system_confirm_email","system_confirm_identity_no","system_confirm_permit_nb"];
	lookupTables: any = {};
	displayValueSeparators: any = {};
	errorMessages: any;
	row: any; // Current row (rendered)
	labelAttribute: string = "name";

	// Constructor
	constructor(public dbapp: DbApp,
		public user: UserData,
		public translate: TranslateService,
		public locale: LocaleService,
		public router: Router,
			public http: HttpClient) {
		super(dbapp, http, user, translate, router);
		this.name = "users";
		this.translate.get(this.fieldVars.map(fldvar => "__tables." + this.name + ".fields." + fldvar + ".errMsg")).subscribe(values => {
			for (let k in values)
				values[k] = (k != values[k]) ? values[k] : "";
			this.errorMessages = values;
		});
		this.translate.get(["__tables.users.fields.system_user_level_id.tagValues"]).subscribe(values => {
			this.lookupTables.system_user_level_id = dbapp.convertUserValues(values["__tables.users.fields.system_user_level_id.tagValues"]);
		});
		this.infiniteScroll = true;
		this.pageSize = 8;
	}

	// Lookup
	async lookup(item: any, pageId: string) {
		if (!item)
			return;
		pageId = pageId == "signup" ? "register" : pageId;
		let page = pageId != "register" ? this.name + "_" + pageId : pageId;
		this.pageId = pageId;
		let p = [];

		// Get lookup results
		try {
			[] = await Promise.all(p);
		} catch(err) {
			console.log(err);
		}
	}

	// Render field
	renderField(fieldName: string, pageId: string) {
		if (["list", "view", "add", "edit", "register"].includes(pageId))
			return this[pageId + "Fields"].includes(fieldName);
		return false;
	}

	// Get field variable name
	getFieldVar(name) {
		let f = this.fieldNames.find(f => f[0] == name);
		return f ? f[1] : name;
	}

	// Get field variable name
	getFieldName(varname) {
		let f = this.fieldNames.find(f => f[1] == varname);
		return f ? f[0] : varname;
	}

	// Render row
	async renderRow(item: any, pageId: string) {
		this.pageId = pageId;
		let row = new DbRecord(item, this.fieldNames, this.errorMessages);
		this.rowOnRender(row);

		// system_user_level_id
		if (this.renderField("system_user_level_id", pageId)) {
			let selectedRow = this.lookupTables.system_user_level_id.find(r => r.lf == row["system_user_level_id"].dbValue); // Compare with db value
			row["system_user_level_id"].formValue = row["system_user_level_id"].dbValue; // FormControl value cannot be undefined
			row["system_user_level_id"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.system_user_level_id) : row["system_user_level_id"].dbValue;
		}

		// system_account_activation_id
		if (this.renderField("system_account_activation_id", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_account_activation_id"].value = formatNumber(row["system_account_activation_id"].value, this.locale.locale);
		}

		// system_identity_no
		if (this.renderField("system_identity_no", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_identity_no"].value = formatNumber(row["system_identity_no"].value, this.locale.locale);
		}

		// system_mobile_1
		if (this.renderField("system_mobile_1", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_mobile_1"].value = formatNumber(row["system_mobile_1"].value, this.locale.locale);
		}

		// system_welcome_sms
		if (this.renderField("system_welcome_sms", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_welcome_sms"].value = formatNumber(row["system_welcome_sms"].value, this.locale.locale);
		}

		// system_welcome_email
		if (this.renderField("system_welcome_email", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_welcome_email"].value = formatNumber(row["system_welcome_email"].value, this.locale.locale);
		}

		// system_user_sms_status
		if (this.renderField("system_user_sms_status", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_user_sms_status"].value = formatNumber(row["system_user_sms_status"].value, this.locale.locale);
		}

		// system_user_email_status
		if (this.renderField("system_user_email_status", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_user_email_status"].value = formatNumber(row["system_user_email_status"].value, this.locale.locale);
		}

		// system_user_invest_service_statues
		if (this.renderField("system_user_invest_service_statues", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_user_invest_service_statues"].value = formatNumber(row["system_user_invest_service_statues"].value, this.locale.locale);
		}

		// system_user_auction_service_statues
		if (this.renderField("system_user_auction_service_statues", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_user_auction_service_statues"].value = formatNumber(row["system_user_auction_service_statues"].value, this.locale.locale);
		}

		// system_user_management_service_statues
		if (this.renderField("system_user_management_service_statues", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_user_management_service_statues"].value = formatNumber(row["system_user_management_service_statues"].value, this.locale.locale);
		}

		// system_user_list_service_statues
		if (this.renderField("system_user_list_service_statues", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_user_list_service_statues"].value = formatNumber(row["system_user_list_service_statues"].value, this.locale.locale);
		}

		// clients_account_id
		if (this.renderField("clients_account_id", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["clients_account_id"].value = formatNumber(row["clients_account_id"].value, this.locale.locale);
		}

		// system_full_name
		if (this.renderField("system_full_name", pageId)) {
			if (row["system_full_name"])
				row.title = row["system_full_name"].value; // Title field
		}

		// sellers_permit_nb
		if (this.renderField("sellers_permit_nb", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["sellers_permit_nb"].value = formatNumber(row["sellers_permit_nb"].value, this.locale.locale);
		}

		// system_confirm_mobile_1
		if (this.renderField("system_confirm_mobile_1", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_confirm_mobile_1"].value = formatNumber(row["system_confirm_mobile_1"].value, this.locale.locale);
		}

		// system_confirm_mobile_2
		if (this.renderField("system_confirm_mobile_2", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_confirm_mobile_2"].value = formatNumber(row["system_confirm_mobile_2"].value, this.locale.locale);
		}

		// system_confirm_email
		if (this.renderField("system_confirm_email", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_confirm_email"].value = formatNumber(row["system_confirm_email"].value, this.locale.locale);
		}

		// system_confirm_identity_no
		if (this.renderField("system_confirm_identity_no", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_confirm_identity_no"].value = formatNumber(row["system_confirm_identity_no"].value, this.locale.locale);
		}

		// system_confirm_permit_nb
		if (this.renderField("system_confirm_permit_nb", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_confirm_permit_nb"].value = formatNumber(row["system_confirm_permit_nb"].value, this.locale.locale);
		}

		// system_user_is_company
		if (this.renderField("system_user_is_company", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["system_user_is_company"].value = formatNumber(row["system_user_is_company"].value, this.locale.locale);
		}
		row.rendered = true;
		this.rowAfterRendered(row);
		this.row = row; // Set current row
		return row;
	}

	// Render file URL
	async renderFileUrl(url: string) {
		return this.http.get(url, { responseType: "blob" }).toPromise().then(blob => {
			const reader = new FileReader();
			return new Promise((resolve, reject) => {
				reader.onloadend = () => resolve(reader.result as string);
				reader.readAsDataURL(blob);
			});
		});
	}
}