import { Injectable } from '@angular/core';
import { LocaleService, DbRecord, DbApp, UserData, properties_lists } from './';
import { Observable, Subscription, BehaviorSubject, from, timer, take, filter, concatMap  } from 'rxjs';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { TranslateService } from '@ngx-translate/core';
import { TajawalRegular } from '../../assets/fonts/Tajawal-Regular';
import { TajawalMedium } from '../../assets/fonts/Tajawal-Medium';
import { TajawalBold } from '../../assets/fonts/Tajawal-Bold';
import { TajawalExtraBold } from '../../assets/fonts/Tajawal-ExtraBold';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs = pdfFonts.pdfMake;
pdfMake.vfs = pdfFonts.pdfMake.vfs['Tajawal-Regular']=TajawalRegular;
pdfMake.vfs = pdfFonts.pdfMake.vfs['Tajawal-Medium']=TajawalMedium;
pdfMake.vfs = pdfFonts.pdfMake.vfs['Tajawal-Bold']=TajawalBold;
pdfMake.vfs = pdfFonts.pdfMake.vfs['Tajawal-ExtraBold']=TajawalExtraBold;
@Injectable({
  providedIn: 'root',
	// providers: [DbAppPipe],
})
export class PdfService {
//   subheader: string = 'push_it_up_message_title_first_party';
	item: any;
	item$: BehaviorSubject<DbRecord>;
	isLoggedIn: boolean;
	isAdmin: boolean;
	userId: string;
	userEmail: string;
	userMobile: number;
	userAccountingNB: number;
	userFullName: string;
	permissions: any;
	userIdAllowed: boolean;
	key: any;
	keyCount: number = 1;
  googleMapImage: any;
  report_qr: string;
  title: string;
  constructor(
		public dbapp: DbApp,
		public user: UserData,
		public items: properties_lists,
		public locale: LocaleService,
    private translate: TranslateService) {

    this.item$ = new BehaviorSubject<DbRecord>(null);

		this.getUserData();
    // Load fonts dynamically
    const fontNameDefault = 'Roboto';
    const fontTajawal = 'Tajawal';
    const fontPathDefault = 'Roboto-Regular.ttf';
    const fontTajawalRegular = 'Tajawal-Regular';
    const fontTajawalMedium = 'Tajawal-Medium';
    const fontTajawalBold = 'Tajawal-Bold';
    const fontTajawalExtraBold = 'Tajawal-ExtraBold';

    pdfMake.fonts = pdfMake.fonts || {};
    pdfMake.fonts[fontNameDefault] = {
      normal: fontPathDefault,
      bold: fontPathDefault,
      italics: fontPathDefault,
      bolditalics: fontPathDefault,
    };
    pdfMake.fonts[fontTajawal] = {
      normal: fontTajawalRegular,
      medium: fontTajawalMedium,
      bold: fontTajawalBold,
      extrabold: fontTajawalExtraBold,
      italics: fontTajawalMedium,
      bolditalics: fontTajawalRegular,
    };
    // Load vfs fonts
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

	// Get user data
	getUserData() {
		this.user.isLoggedIn.subscribe(res => {
			this.isLoggedIn = res;
			this.isAdmin = this.user.isAdmin;
			this.userId = this.user.userId;
			this.userEmail = this.user.userEmail;
			this.userMobile = this.user.userMobile;
			this.userAccountingNB =  this.user.userAccountingNB;
			this.userFullName = this.user.userFullname;
			this.permissions = this.user.permissions;
		});
	}

	// Init
	async init(properties_property_id) {
			try {
				let item = await this.items.query(Object.assign({ action: "view" }, {'properties_property_id': properties_property_id})); // Use "view" action to get the record
				if (this.dbapp.isObject(item)) {
					await this.items.lookup(item, 'view');
					this.item = await this.items.renderRow(item, 'view');
					this.item$.next(this.item);
				}
			} catch(err) {
			} finally {
			}
	}
  
  async generatePDF(properties_property_id: string, report_type: number): Promise<any> {

      await this.init(properties_property_id);

      try {
        const geometryData = JSON.parse(this.item.properties_dimension_geometry.value);
        const coordinates = geometryData;
      
        if (coordinates && Array.isArray(coordinates)) {
          const encodedPath = await this.encodePolygon(coordinates);
          console.log(encodedPath);
      
          this.googleMapImage = `https://maps.googleapis.com/maps/api/staticmap?maptype=satellite&libraries=geometry&center=${parseFloat(this.item.properties_latitude)},${parseFloat(this.item.properties_longitude)}&zoom=18&size=570x200&path=fillcolor:blue%7Cweight:4%7Ccolor:black%7Ccolor:blue%7Cenc:${encodedPath}&key=AIzaSyDyxpnNHRWe5RiDB3dWJ_F6XqDgMVnd-dw`;
      
          console.error(this.googleMapImage);
        } else {
          this.googleMapImage = `https://maps.googleapis.com/maps/api/staticmap?maptype=satellite&libraries=geometry&center=${parseFloat(this.item.properties_latitude)},${parseFloat(this.item.properties_longitude)}&zoom=18&size=570x200&markers=color:blue|${parseFloat(this.item.properties_latitude)},${parseFloat(this.item.properties_longitude)}&key=AIzaSyDyxpnNHRWe5RiDB3dWJ_F6XqDgMVnd-dw`;
        }
      } catch (error) {
        console.error('Error decoding or encoding polygon coordinates:', error);
      }

      // switch (this.item.properties_property_type_id.value) {
      //   case '1':
      //     this.item.properties_info_qr.value = this.item.properties_info_qr_sell.value;
      //     break;
      //   case '2':
      //     this.item.properties_info_qr.value = this.item.properties_info_qr_rent;
      //     break;
      //   case '3':
      //     this.item.properties_info_qr.value = this.item.properties_info_qr_invest;
      //     break;
      //   case '4':
      //     this.item.properties_info_qr.value = this.item.properties_info_qr_auction;
      //     break;
      //   default:
      //     this.item.properties_info_qr.value = this.item.properties_info_qr_sell.value;
      //     break;
      // }      
    
      // this.mapPropertyReady(this.map);
    //   const title = await this.translate.get('toor_platform').toPromise();
  
    //   // Check if properties_picture is defined and has a value property
    //   const imageUrl = this.item.properties_picture.url;
  
    //   if (typeof imageUrl === 'undefined') {
    //     console.error('Image URL is undefined.');
    //     return;
    //   }

    //   const header = {
    //     margin: 10,
    //     columns: [
    //       {
    //         image:  await this.getImageDataUrl(imageUrlLogo),
    //         width: 40,
    //         style: this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText',
    //         alignment: 'right',
    //       },
    //       {
    //         margin: [10, 0, 0, 0],
    //         text: title.split(' ').reverse().join('  '),
    //         style: this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText',
    //         alignment: 'right',
    //       },
    //     ],
    //   };

    // // const values = await this.translate.get(['pass']).toPromise();
    // // Check if translation for subheader is available
    // if (!this.item.properties_picture.url) {
    //   console.error('Translation not available for subheader:',this.item.properties_reference_nb.value);
    //   return;
    // }
    // { qr: url },
    
// // Example usage
// const inputString = "Hello, 你好, مرحبًا";
// const utf8Bytes = this.encodeToUtf8(inputString);
// console.log("UTF-8 Bytes:", utf8Bytes);

// const decodedString = this.decodeFromUtf8(utf8Bytes);
// console.log("Decoded String:", decodedString);

try {
  this.title = await this.translate.get('toor_platform').toPromise();
  // properties_reference_code
  const properties_reference_code_key = await this.translate.get(`__tables.properties_lists.fields.properties_reference_code.caption`).toPromise();
  const properties_reference_code_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_reference_code_key) : properties_reference_code_key;
  const properties_reference_code_value = this.item.properties_reference_code;
  // properties_latitude
  const properties_latitude_key = await this.translate.get(`__tables.properties_lists.fields.properties_latitude.caption`).toPromise();
  const properties_latitude_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_latitude_key) : properties_latitude_key;
  const properties_latitude_value = this.item.properties_latitude;
  // properties_longitude
  const properties_longitude_key = await this.translate.get(`__tables.properties_lists.fields.properties_longitude.caption`).toPromise();
  const properties_longitude_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_longitude_key) : properties_longitude_key;
  const properties_longitude_value = this.item.properties_longitude;
  const mini_report = await this.translate.get('mini_report').toPromise();
  const subject = await this.translate.get('subject').toPromise();
  const username = await this.translate.get('USERNAME').toPromise();
  const coordinates = await this.translate.get('coordinates').toPromise();
  const latitude = await this.translate.get('latitude').toPromise();
  const longitude = await this.translate.get('longitude').toPromise();
  const bordersandlengthes = await this.translate.get('bordersandlengthes').toPromise();
  const borders = await this.translate.get('borders').toPromise();
  const lengthes = await this.translate.get('lengthes').toPromise();
  const bynature = await this.translate.get('bynature').toPromise();
  const byinstrument = await this.translate.get('byinstrument').toPromise();
  const north = await this.translate.get('north').toPromise();
  const south = await this.translate.get('south').toPromise();
  const east = await this.translate.get('east').toPromise();
  const west = await this.translate.get('west').toPromise();
  const area = await this.translate.get('area').toPromise();
  const imageUrlLogo = 'https://obaykan.s3.us-east-2.amazonaws.com/files/logos/icon.png';
  const header = {
    stack: [
      {
        table: {
          widths: ['auto', 'auto', '*', 'auto', 'auto'],
          body: [
            // Row 1
            [
              { text: await this.rtlSorting(mini_report)+'\n\n'+await this.rtlSorting(this.userFullName), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], border: [false, false, false, false] },
              { text: await this.rtlSorting(subject)+'\n\n'+await this.rtlSorting(username), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, border: [false, false, false, false] },
              { image: await this.getImageDataUrl(imageUrlLogo), width: 40, alignment: 'center', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], border: [false, false, false, false] },
              { text: this.dbapp.formatTimestamp(Date.now())+'\n\n'+properties_reference_code_value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], border: [false, false, false, false] },
              { text: await this.translate.get('date').toPromise()+'\n\n'+properties_reference_code_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, border: [false, false, false, false] },
            ],
            // Row 2
            [
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
              { text: await this.rtlSorting(this.title), alignment: 'center', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
          ]
        },
      }
    ],
    margin: [0, 0, 0, 10],
    alignment: 'center',
  }

  if (!this.item.properties_picture.url) {
    console.error('Translation not available for subheader:',this.item.properties_reference_nb.value);
    return;
  }
  const imageUrl = this.item.properties_picture.url;
  // // properties_property_type_id
  const properties_property_type_id_key = await this.translate.get(`__tables.properties_lists.fields.properties_property_type_id.caption`).toPromise();
  const properties_property_type_id_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_property_type_id_key) : properties_property_type_id_key;
  // const properties_property_type_id_value = this.item.properties_property_type_id = await this.rtlSorting(this.item.properties_property_type_id);
  // // properties_property_category_id
  const properties_property_category_id_key = await this.translate.get(`__tables.properties_lists.fields.properties_property_category_id.caption`).toPromise();
  const properties_property_category_id_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_property_category_id_key) : properties_property_category_id_key;
  // const properties_property_category_id_value = this.item.properties_property_category_id;
  // // properties_building_built_date_gregorian
  const properties_building_built_date_gregorian_key = await this.translate.get(`__tables.properties_lists.fields.properties_building_built_date_gregorian.caption`).toPromise();
  const properties_building_built_date_gregorian_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_building_built_date_gregorian_key) : properties_building_built_date_gregorian_key;
  // const properties_building_built_date_gregorian_value = this.item.properties_building_built_date_gregorian;
  // // properties_services_id
  const properties_services_id_key = await this.translate.get(`__tables.properties_lists.fields.properties_services_id.caption`).toPromise();
  const properties_services_id_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_services_id_key) : properties_services_id_key;
  // const properties_services_id_value = this.item.properties_services_id = (this.item.properties_services_id && !isNaN(this.item.properties_services_id)) ? this.item.properties_services_id : (this.item.properties_services_id ? await this.rtlSorting(this.item.properties_services_id) : null);
  // // properties_case_id
  const properties_case_id_key = await this.translate.get(`__tables.properties_lists.fields.properties_case_id.caption`).toPromise();
  const properties_case_id_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_case_id_key) : properties_case_id_key;
  // const properties_case_id_value = this.item.properties_case_id = await this.rtlSorting(this.item.properties_case_id);
  // // property_status_publish_from_date_gregorian
  const property_status_publish_from_date_gregorian_key = await this.translate.get(`__tables.properties_lists.fields.property_status_publish_from_date_gregorian.caption`).toPromise();
  const property_status_publish_from_date_gregorian_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(property_status_publish_from_date_gregorian_key) : property_status_publish_from_date_gregorian_key;
  // const property_status_publish_from_date_gregorian_value = this.item.property_status_publish_from_date_gregorian;
  // // properties_last_sale_price
  const properties_last_sale_price_key = await this.translate.get(`__tables.properties_lists.fields.properties_last_sale_price.caption`).toPromise();
  const properties_last_sale_price_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_last_sale_price_key) : properties_last_sale_price_key;
  // const properties_last_sale_price_value = this.item.properties_last_sale_price;
  // // system_full_name
  const system_full_name_key = await this.translate.get(`__tables.properties_lists.fields.system_full_name.caption`).toPromise();
  const system_full_name_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(system_full_name_key) : system_full_name_key;
  // const system_full_name_value = this.item.system_full_name = await this.rtlSorting(this.item.system_full_name);
  // // properties_building_floors_total
  const properties_building_floors_total_key = await this.translate.get(`__tables.properties_lists.fields.properties_building_floors_total.caption`).toPromise();
  const properties_building_floors_total_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_building_floors_total_key) : properties_building_floors_total_key;
  // const properties_building_floors_total_value = this.item.properties_building_floors_total;
  // // properties_building_type_id
  const properties_building_type_id_key = await this.translate.get(`__tables.properties_lists.fields.properties_building_type_id.caption`).toPromise();
  const properties_building_type_id_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_building_type_id_key) : properties_building_type_id_key;
  // const properties_building_type_id_value = await this.rtlSorting(this.item.properties_building_type_id);
  // // properties_building_living_units_total
  const properties_building_living_units_total_key = await this.translate.get(`__tables.properties_lists.fields.properties_building_living_units_total.caption`).toPromise();
  const properties_building_living_units_total_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_building_living_units_total_key) : properties_building_living_units_total_key;
  // const properties_building_living_units_total_value = this.item.properties_building_living_units_total;
  // // properties_building_rooms_total
  const properties_building_rooms_total_key = await this.translate.get(`__tables.properties_lists.fields.properties_building_rooms_total.caption`).toPromise();
  const properties_building_rooms_total_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_building_rooms_total_key) : properties_building_rooms_total_key;
  // const properties_building_rooms_total_value = this.item.properties_building_rooms_total;
  // // properties_building_toilets_total
  const properties_building_toilets_total_key = await this.translate.get(`__tables.properties_lists.fields.properties_building_toilets_total.caption`).toPromise();
  const properties_building_toilets_total_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_building_toilets_total_key) : properties_building_toilets_total_key;
  // const properties_building_toilets_total_value = this.item.properties_building_toilets_total;
  // // properties_common_electric_meter_total
  const properties_common_electric_meter_total_key = await this.translate.get(`__tables.properties_lists.fields.properties_common_electric_meter_total.caption`).toPromise();
  const properties_common_electric_meter_total_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_common_electric_meter_total_key) : properties_common_electric_meter_total_key;
  // const properties_common_electric_meter_total_value = this.item.properties_common_electric_meter_total;
  // // properties_common_water_tank_capacity
  const properties_common_water_tank_capacity_key = await this.translate.get(`__tables.properties_lists.fields.properties_common_water_tank_capacity.caption`).toPromise();
  const properties_common_water_tank_capacity_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_common_water_tank_capacity_key) : properties_common_water_tank_capacity_key;
  // const properties_common_water_tank_capacity_value = this.item.properties_common_water_tank_capacity;
  // // properties_elevators_total
  const properties_elevators_total_key = await this.translate.get(`__tables.properties_lists.fields.properties_elevators_total.caption`).toPromise();
  const properties_elevators_total_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_elevators_total_key) : properties_elevators_total_key;
  // const properties_elevators_total_value = this.item.properties_elevators_total;
  // // properties_building_shops_units_total
  const properties_building_shops_units_total_key = await this.translate.get(`__tables.properties_lists.fields.properties_building_shops_units_total.caption`).toPromise();
  const properties_building_shops_units_total_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_building_shops_units_total_key) : properties_building_shops_units_total_key;
  // const properties_building_shops_units_total_value = this.item.properties_building_shops_units_total;
  // // properties_common_private_parking_total
  const properties_common_private_parking_total_key = await this.translate.get(`__tables.properties_lists.fields.properties_common_private_parking_total.caption`).toPromise();
  const properties_common_private_parking_total_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_common_private_parking_total_key) : properties_common_private_parking_total_key;
  // const properties_common_private_parking_total_value = this.item.properties_common_private_parking_total;
  // // properties_expected_gross_margin
  const properties_expected_gross_margin_key = await this.translate.get(`__tables.properties_lists.fields.properties_expected_gross_margin.caption`).toPromise();
  const properties_expected_gross_margin_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_expected_gross_margin_key) : properties_expected_gross_margin_key;
  // const properties_expected_gross_margin_value = this.item.properties_expected_gross_margin;
  // // locations_region_name
  const locations_region_name_key = await this.translate.get(`__tables.properties_lists.fields.locations_region_name.caption`).toPromise();
  const locations_region_name_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(locations_region_name_key) : locations_region_name_key;
  // const locations_region_name_value = await this.rtlSorting(this.item.locations_region_name);
  // // locations_city_name
  const locations_city_name_key = await this.translate.get(`__tables.properties_lists.fields.locations_city_name.caption`).toPromise();
  const locations_city_name_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(locations_city_name_key) : locations_city_name_key;
  // const locations_city_name_value = await this.rtlSorting(this.item.locations_city_name);
  // // locations_neighborhood_name
  const locations_neighborhood_name_key = await this.translate.get(`__tables.properties_lists.fields.locations_neighborhood_name.caption`).toPromise();
  const locations_neighborhood_name_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(locations_neighborhood_name_key) : locations_neighborhood_name_key;
  // const locations_neighborhood_name_value = await this.rtlSorting(this.item.locations_neighborhood_name);
  // // locations_zip_code
  const locations_zip_code_key = await this.translate.get(`__tables.properties_lists.fields.locations_zip_code.caption`).toPromise();
  const locations_zip_code_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(locations_zip_code_key) : locations_zip_code_key;
  // const locations_zip_code_value = this.item.locations_zip_code;
  // // locations_street_name
  const locations_street_name_key = await this.translate.get(`__tables.properties_lists.fields.locations_street_name.caption`).toPromise();
  const locations_street_name_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(locations_street_name_key) : locations_street_name_key;
  // const locations_street_name_value = await this.rtlSorting(this.item.locations_street_name);
  // // properties_property_schema_name
  const properties_property_schema_name_key = await this.translate.get(`__tables.properties_lists.fields.properties_property_schema_name.caption`).toPromise();
  const properties_property_schema_name_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_property_schema_name_key) : properties_property_schema_name_key;
  // const properties_property_schema_name_value = await this.rtlSorting(this.item.properties_property_schema_name);
  // // properties_property_schema_segment_name
  const properties_property_schema_segment_name_key = await this.translate.get(`__tables.properties_lists.fields.properties_property_schema_segment_name.caption`).toPromise();
  const properties_property_schema_segment_name_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_property_schema_segment_name_key) : properties_property_schema_segment_name_key;
  // const properties_property_schema_segment_name_value = this.item.properties_property_schema_segment_name;
  // // properties_unit_no
  const properties_unit_no_key = await this.translate.get(`__tables.properties_lists.fields.properties_unit_no.caption`).toPromise();
  const properties_unit_no_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_unit_no_key) : properties_unit_no_key;
  // const properties_unit_no_value = this.item.properties_unit_no;

  // // properties_instrument_length_north
  // const properties_instrument_length_north_key = await this.translate.get(`__tables.properties_lists.fields.properties_instrument_length_north.caption`).toPromise();
  // const properties_instrument_length_north_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_instrument_length_north_key) : properties_instrument_length_north_key;
  // const properties_instrument_length_north_value = await this.rtlSorting(this.item.properties_instrument_length_north);

  // // properties_instrument_border_north
  // const properties_instrument_border_north_key = await this.translate.get(`__tables.properties_lists.fields.properties_instrument_border_north.caption`).toPromise();
  // const properties_instrument_border_north_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_instrument_border_north_key) : properties_instrument_length_north_key;
  // const properties_instrument_border_north_value = await this.rtlSorting(this.item.properties_instrument_border_north);

  // // properties_instrument_length_south
  // const properties_instrument_length_south_key = await this.translate.get(`__tables.properties_lists.fields.properties_instrument_length_south.caption`).toPromise();
  // const properties_instrument_length_south_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_instrument_length_south_key) : properties_instrument_length_south_key;
  // const properties_instrument_length_south_value = await this.rtlSorting(this.item.properties_instrument_length_south);

  // // properties_instrument_border_south
  // const properties_instrument_border_south_key = await this.translate.get(`__tables.properties_lists.fields.properties_instrument_border_south.caption`).toPromise();
  // const properties_instrument_border_south_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_instrument_border_south_key) : properties_instrument_length_south_key;
  // const properties_instrument_border_south_value = await this.rtlSorting(this.item.properties_instrument_border_south);

  // // properties_instrument_length_east
  // const properties_instrument_length_east_key = await this.translate.get(`__tables.properties_lists.fields.properties_instrument_length_east.caption`).toPromise();
  // const properties_instrument_length_east_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_instrument_length_east_key) : properties_instrument_length_east_key;
  // const properties_instrument_length_east_value = await this.rtlSorting(this.item.properties_instrument_length_east);

  // // properties_instrument_border_east
  // const properties_instrument_border_east_key = await this.translate.get(`__tables.properties_lists.fields.properties_instrument_border_east.caption`).toPromise();
  // const properties_instrument_border_east_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_instrument_border_east_key) : properties_instrument_length_east_key;
  // const properties_instrument_border_east_value = await this.rtlSorting(this.item.properties_instrument_border_east);
  
  // // properties_instrument_length_west
  // const properties_instrument_length_west_key = await this.translate.get(`__tables.properties_lists.fields.properties_instrument_length_west.caption`).toPromise();
  // const properties_instrument_length_west_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_instrument_length_west_key) : properties_instrument_length_west_key;
  // const properties_instrument_length_west_value = await this.rtlSorting(this.item.properties_instrument_length_west);

  // // properties_instrument_border_west
  // const properties_instrument_border_west_key = await this.translate.get(`__tables.properties_lists.fields.properties_instrument_border_west.caption`).toPromise();
  // const properties_instrument_border_west_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_instrument_border_west_key) : properties_instrument_length_west_key;
  // const properties_instrument_border_west_value = await this.rtlSorting(this.item.properties_instrument_border_west);

  // // properties_nature_length_north
  // const properties_nature_length_north_key = await this.translate.get(`__tables.properties_lists.fields.properties_nature_length_north.caption`).toPromise();
  // const properties_nature_length_north_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_nature_length_north_key) : properties_nature_length_north_key;
  // const properties_nature_length_north_value = await this.rtlSorting(this.item.properties_nature_length_north);

  // // properties_nature_border_north
  // const properties_nature_border_north_key = await this.translate.get(`__tables.properties_lists.fields.properties_nature_border_north.caption`).toPromise();
  // const properties_nature_border_north_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_nature_border_north_key) : properties_nature_length_north_key;
  // const properties_nature_border_north_value = await this.rtlSorting(this.item.properties_nature_border_north);

  // // properties_nature_length_south
  // const properties_nature_length_south_key = await this.translate.get(`__tables.properties_lists.fields.properties_nature_length_south.caption`).toPromise();
  // const properties_nature_length_south_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_nature_length_south_key) : properties_nature_length_south_key;
  // const properties_nature_length_south_value = await this.rtlSorting(this.item.properties_nature_length_south);

  // // properties_nature_border_south
  // const properties_nature_border_south_key = await this.translate.get(`__tables.properties_lists.fields.properties_nature_border_south.caption`).toPromise();
  // const properties_nature_border_south_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_nature_border_south_key) : properties_nature_length_south_key;
  // const properties_nature_border_south_value = await this.rtlSorting(this.item.properties_nature_border_south);

  // // properties_nature_length_east
  // const properties_nature_length_east_key = await this.translate.get(`__tables.properties_lists.fields.properties_nature_length_east.caption`).toPromise();
  // const properties_nature_length_east_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_nature_length_east_key) : properties_nature_length_east_key;
  // const properties_nature_length_east_value = await this.rtlSorting(this.item.properties_nature_length_east);

  // // properties_nature_border_east
  // const properties_nature_border_east_key = await this.translate.get(`__tables.properties_lists.fields.properties_nature_border_east.caption`).toPromise();
  // const properties_nature_border_east_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_nature_border_east_key) : properties_nature_length_east_key;
  // const properties_nature_border_east_value = await this.rtlSorting(this.item.properties_nature_border_east);
  
  // // properties_nature_length_west
  // const properties_nature_length_west_key = await this.translate.get(`__tables.properties_lists.fields.properties_nature_length_west.caption`).toPromise();
  // const properties_nature_length_west_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_nature_length_west_key) : properties_nature_length_west_key;
  // const properties_nature_length_west_value = await this.rtlSorting(this.item.properties_nature_length_west);

  // // properties_nature_border_west
  // const properties_nature_border_west_key = await this.translate.get(`__tables.properties_lists.fields.properties_nature_border_west.caption`).toPromise();
  // const properties_nature_border_west_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_nature_border_west_key) : properties_nature_length_west_key;
  // const properties_nature_border_west_value = await this.rtlSorting(this.item.properties_nature_border_west);
  
  // // properties_instrument_total_area
  // const properties_instrument_total_area_key = await this.translate.get(`__tables.properties_lists.fields.properties_instrument_total_area.caption`).toPromise();
  // const properties_instrument_total_area_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_instrument_total_area_key) : properties_nature_length_west_key;
  // const properties_instrument_total_area_value = await this.rtlSorting(this.item.properties_instrument_total_area);
  
  // // properties_nature_total_area
  // const properties_nature_total_area_key = await this.translate.get(`__tables.properties_lists.fields.properties_nature_total_area.caption`).toPromise();
  // const properties_nature_total_area_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_nature_total_area_key) : properties_nature_total_area_key;
  // const properties_nature_total_area_value = await this.rtlSorting(this.item.properties_nature_total_area);
  
  // properties_description
  const properties_description_key = await this.translate.get(`__tables.properties_lists.fields.properties_description.caption`).toPromise();
  const properties_description_dir =  this.locale.locale === 'ar' ? await this.rtlSorting(properties_description_key) : properties_description_key;
  
  const essentials = {
    stack: [
      {
        table: {
          widths: [94, 100, 94, 100, '*'],
          body: [
            // Row 1
            [
              { text: this.item.properties_building_floors_total.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_building_floors_total_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell', 'fieldCaption'], bold: true, fillColor: '#eeeeee' },
              { text: this.item.properties_property_type_id, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell', 'valueCaption'], fillColor: '#eeeeee' },
              { text: properties_property_type_id_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell', 'fieldCaption'], fillColor: '#eeeeee' },
              { image: await this.getImageDataUrl(imageUrl), width: 140, fit: [140, 140], style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee', alignment: 'right', rowSpan: 11 },
            ],
            // Row 2
            [
              { text: await this.rtlSorting(this.item.properties_building_type_id.value), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_building_type_id_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              { text: await this.rtlSorting(this.item.properties_property_category_id.value), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_property_category_id_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              {},
            ],
            // Row 3
            [
              { text: this.item.properties_building_living_units_total.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_building_living_units_total_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              { text: this.item.properties_building_built_date_gregorian.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_building_built_date_gregorian_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              {},
            ],
            // Row 4
            [
              { text: this.item.properties_building_rooms_total.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_building_rooms_total_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              { text: this.item.properties_services_id.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_services_id_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              {},
            ],
            // Row 5
            [
              { text: this.item.properties_building_rooms_total.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_building_rooms_total_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              { text: this.item.properties_case_id.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_case_id_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              {},
            ],
            // Row 6
            [
              { text: this.item.properties_building_toilets_total.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_building_toilets_total_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              { text: this.item.property_status_publish_from_date_gregorian.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: property_status_publish_from_date_gregorian_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              {},
            ],
            // Row 7
            [
              { text: this.item.properties_common_electric_meter_total.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_common_electric_meter_total_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              { text: this.item.properties_last_sale_price.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_last_sale_price_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              {},
            ],
            // Row 8
            [
              { text: this.item.properties_common_water_tank_capacity.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_common_water_tank_capacity_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              { text: await this.rtlSorting(this.item.system_full_name.dbValue), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: system_full_name_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              {},
            ],
            // Row 9
            [
              { text: '1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: '2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              { text: '3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: '4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              {},
            ],
            // Row 10
            [
              { text: '5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: '6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              { text: '7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: '8', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              {},
            ],
            // Row 11
            [
              { text: '9', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: '10', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              { text: '11', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: '12', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], bold: true, fillColor: '#eeeeee' },
              {},
            ],
          ],
          margin: [0, 0, 0, 20]
        },
      }
    ],
    margin: [0, 0, 0, 0],
  }

// // Location Info
  const location = {
    stack: [
      {
        table: {
          widths: [73, 73, 73, 73, 73, 73, 73],
          body: [
            // Row 1
            [
              { text: properties_unit_no_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_property_schema_segment_name_key, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: properties_property_schema_name_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: locations_street_name_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: locations_neighborhood_name_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: locations_city_name_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: locations_region_name_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            ],
            // Row 2
            [
              { text: this.item.properties_unit_no.dbValue, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: this.item.properties_property_schema_segment_name.dbValue, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: await this.rtlSorting(this.item.properties_property_schema_name.dbValue), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: await this.rtlSorting(this.item.locations_street_name.dbValue), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: await this.rtlSorting(this.item.locations_neighborhood_name.dbValue), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: await this.rtlSorting(this.item.locations_city_name.dbValue), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: await this.rtlSorting(this.item.locations_region_name.dbValue), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            ],
            // Row 3
            [
              { image: await this.getImageDataUrl(this.googleMapImage), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], colSpan: 7 },
            ],
            // Row 4
            [
              { text: await this.rtlSorting(longitude), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', colSpan: 3 },
              {},
              {},
              { text: await this.rtlSorting(latitude) , style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', colSpan: 2 },
              {},
              { text: await this.rtlSorting(coordinates), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2, colSpan: 2 },
              {},
            ],
            // Row 5
            [
              { text: this.item.properties_longitude.value , style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', colSpan: 3 },
              {},
              {},
              { text: this.item.properties_latitude.value , style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', colSpan: 2 },
              {},
              { text: '' , style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', colSpan: 2 },
              {},
            ],
          ],
          margin: [0, 20, 0, 0]
        },
      }
    ],
    margin: [0, 10, 0, 0],
  }
  // console.log('Table:', table2);
  // // Output the entire table body
  // table2.table.body.forEach((row, rowIndex) => {
  //   console.log(`Row ${rowIndex + 1}:`, row);
  //   row.forEach((cell, cellIndex) => {
  //     console.log(`  Cell ${cellIndex + 1}:`, cell);
  //   });
  // });
  
// Coord Info
const lengths_borders = {
  stack: [
    {
      table: {
        widths: [160, 160, '*', 135],
        body: [
          // Row 1
          [
            { text: await this.rtlSorting(borders), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(lengthes), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(bordersandlengthes), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', colSpan: 2 },
            {},
          ],
          // Row 2
          [
            { text: this.item.properties_instrument_border_north.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: this.item.properties_instrument_length_north.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(byinstrument), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(north), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2 },
          ],
          // Row 3
          [
            { text: this.item.properties_nature_border_north.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: this.item.properties_nature_length_north.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(bynature), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            {},
          ],
          // Row 4
          [
            { text: this.item.properties_instrument_border_south.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: this.item.properties_instrument_length_south.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(byinstrument), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(south), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2},
          ],
          // Row 5
          [
            { text: this.item.properties_nature_border_south.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: this.item.properties_nature_length_south.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(bynature), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            {},
          ],
          // Row 6
          [
            { text: this.item.properties_instrument_border_east.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: this.item.properties_instrument_length_east.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(byinstrument), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(east), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2 },
          ],
          // Row 7
          [
            { text: this.item.properties_nature_border_east.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: this.item.properties_nature_length_east.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(bynature), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            {},
          ],
          // Row 8
          [
            { text: this.item.properties_instrument_border_west.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: this.item.properties_instrument_length_west.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(byinstrument), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(west), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2 },
          ],
          // Row 9
          [
            { text: this.item.properties_nature_border_west.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: this.item.properties_nature_length_west.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(bynature), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            {},
          ],
          // Row 10
          [
            { text: this.item.properties_instrument_total_area.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', colSpan: 2 },
            {},
            { text: await this.rtlSorting(byinstrument), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            { text: await this.rtlSorting(area), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2 },
          ],
          // Row 11
          [
            { text: this.item.properties_nature_total_area.value, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', colSpan: 2 },
            {},
            { text: await this.rtlSorting(bynature), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
            {},
          ],
        ],
        margin: [0, 20, 0, 0]
      },
    }
    ],
    margin: [0, 10, 0, 0],
  }

// Decriptions Info
  const decription = {
      stack: [
        {
          table: {
            widths: ['*',300,135],
            // heights: 40,
            body: [
              // Row 1
              [
                { qr: this.item.properties_info_qr.value, fit: 90, alignment: 'center', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'] },
                { text: await this.rtlSorting(this.item.properties_description.dbValue), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'] },
                { text: properties_description_dir, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'] },
              ],
            ],
            style: 'table'
          },
        }
    ],
    margin: [0, 10, 0, 0],
  }
    
// Finantial Info
  const financial = {
    stack: [
      {
        table: {
          widths: [50, 50, 50, 50, 50, 50, '*', 135],
          // heights: 40,
          body: [
            // Row 1
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 8', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2 },
            ],
            // Row 2
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              {},
            ],
            // Row 3
            [
              {},
              {},
              { text: this.item.properties_description.dbValue, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], colSpan: 5 },
              {},
              {},
              {},
              {},
              {},
            ],
          ],
          style: 'table'
        },
      }
  ],
  margin: [0, 10, 0, 0],
  }

// instrument Info
  const instrument = {
    stack: [
      {
        table: {
          widths: [50, 50, 50, 50, 50, 50, '*', 135],
          // heights: 40,
          body: [
            // Row 1
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 8', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2 },
            ],
            // Row 2
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              {},
            ],
            // Row 3
            [
              {},
              {},
              { text: this.item.properties_description.dbValue, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], colSpan: 5 },
              {},
              {},
              {},
              {},
              {},
            ],
          ],
          style: 'table'
        },
      }
  ],
  margin: [0, 10, 0, 0],
  }

// Permit Info
  const permit = {
    stack: [
      {
        table: {
          widths: [50, 50, 50, 50, 50, 50, '*', 135],
          // heights: 40,
          body: [
            // Row 1
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 8', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2 },
            ],
            // Row 2
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              {},
            ],
            // Row 3
            [
              {},
              {},
              { text: this.item.properties_description.dbValue, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], colSpan: 5 },
              {},
              {},
              {},
              {},
              {},
            ],
          ],
          style: 'table'
        },
      }
  ],
  margin: [0, 10, 0, 0],
  }

// Real Estate Info
  const realestate = {
    stack: [
      {
        table: {
          widths: [50, 50, 50, 50, 50, 50, '*', 135],
          // heights: 40,
          body: [
            // Row 1
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 8', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2 },
            ],
            // Row 2
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              {},
            ],
            // Row 3
            [
              {},
              {},
              { text: this.item.properties_description.dbValue, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], colSpan: 5 },
              {},
              {},
              {},
              {},
              {},
            ],
          ],
          style: 'table'
        },
      }
  ],
  margin: [0, 10, 0, 0],
  }

// Building Info
  const building = {
    stack: [
      {
        table: {
          widths: [50, 50, 50, 50, 50, 50, '*', 135],
          // heights: 40,
          body: [
            // Row 1
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 8', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2 },
            ],
            // Row 2
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              {},
            ],
            // Row 3
            [
              {},
              {},
              { text: this.item.properties_description.dbValue, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], colSpan: 5 },
              {},
              {},
              {},
              {},
              {},
            ],
          ],
          style: 'table'
        },
      }
  ],
  margin: [0, 10, 0, 0],
  }

// Building Info
  const additional = {
    stack: [
      {
        table: {
          widths: [50, 50, 50, 50, 50, 50, '*', 135],
          // heights: 40,
          body: [
            // Row 1
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 8', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2 },
            ],
            // Row 2
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              {},
            ],
            // Row 3
            [
              {},
              {},
              { text: this.item.properties_description.dbValue, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], colSpan: 5 },
              {},
              {},
              {},
              {},
              {},
            ],
          ],
          style: 'table'
        },
      }
  ],
  margin: [0, 10, 0, 0],
  }

// Mediation Contract Info
  const mediation_contract = {
    stack: [
      {
        table: {
          widths: [50, 50, 50, 50, 50, 50, '*', 135],
          // heights: 40,
          body: [
            // Row 1
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 8', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2 },
            ],
            // Row 2
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              {},
            ],
            // Row 3
            [
              {},
              {},
              { text: this.item.properties_description.dbValue, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], colSpan: 5 },
              {},
              {},
              {},
              {},
              {},
            ],
          ],
          style: 'table'
        },
      }
  ],
  margin: [0, 10, 0, 0],
  }

// Gallery Info
  const gallery = {
    stack: [
      {
        table: {
          widths: [50, 50, 50, 50, 50, 50, '*', 135],
          // heights: 40,
          body: [
            // Row 1
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 8', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee', rowSpan: 2 },
            ],
            // Row 2
            [
              { text: 'Cell 1', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 2', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 3', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 4', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 5', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 6', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              { text: 'Cell 7', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], fillColor: '#eeeeee' },
              {},
            ],
            // Row 3
            [
              {},
              {},
              { text: this.item.properties_description.dbValue, style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'tableCell'], colSpan: 5 },
              {},
              {},
              {},
              {},
              {},
            ],
          ],
          style: 'table'
        },
      }
  ],
  margin: [0, 10, 0, 0],
  }

// Banner Info
  const banner = {
    stack: [
      {
        table: {
          widths: ['*','*','*'],
          heights: [100, 80, 80, 160, 80],
          style: 'table',
          border: [false, false, false, false],
          body: [
            // Row 1
            [
              {
                table: {
                  widths: ['auto', '*'],
                  border: [false, false, false, false],
                  body: [
                    [
                      {
                        text: await this.rtlSorting('شركة أحمد العبيكان للإستثمار و التطوير العقاري'),
                        style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'bannerStyle'], 
                        bold: true, 
                        noWrap: true,
                        overflow: 'hidden',
                        border: [false, false, false, false]
                      },
                      {
                        image: await this.getImageDataUrl(imageUrlLogo),
                        width: 90,
                        alignment: 'center',
                        style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText'],
                        border: [false, false, false, false], 
                        rowSpan: 2
                      }
                    ],
                    [
                      {
                        text:await this.rtlSorting('رقم الفال'),
                        style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText'], 
                        border: [false, false, false, false]
                      },
                      {}
                    ]
                  ]
                },
                border: [false, false, false, false], 
                colSpan: 3
              },
              {},
              {}

              // { text: '', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'bannerStyle'], extrabold: true, border: [false, false, false, false] },
              // { text: await this.rtlSorting('شركة أحمد العبيكان للإستثمار و التطوير العقاري') + '\n' + await this.rtlSorting('رقم الفال'), style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'bannerStyle'], bold: true, border: [false, false, false, false] },
              // { image: await this.getImageDataUrl(imageUrlLogo), width: 90, alignment: 'right', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'bannerStyle'], border: [false, false, false, false] },
            ],
            // Row 2
            [
              { text: 'شقة للبيع', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'bannerStyle'], bold: true, alignment: "center", border: [false, false, false, false], colSpan: 3 },
            ],
            // Row 3
            [
              { text: '0570169501', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'bannerStyle'], bold: true, alignment: "center",  border: [false, false, false, false] },
              { text: '0570169502', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'bannerStyle'], bold: true, alignment: "center", border: [false, false, false, false] },
              { text: '0570169503', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'bannerStyle'], bold: true, alignment: "center", border: [false, false, false, false] },
            ],
            // Row 4
            [
              {  qr: this.item.properties_info_qr.value, fit: 140, alignment: 'center', style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'bannerStyle'], border: [false, false, false, false], colSpan: 3 },
            ],
            // Row 5
            [
              {
                table: {
                  widths: ['*', '*'],
                  body: [
                    // Row 1
                    [
                      {
                        text: await this.rtlSorting('تم إنشاء هذه اللوحة الإعلانية بواسطة منصة توور'),
                        style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText'],
                        alignment: 'center',
                        border: [false, false, false, false],
                        colSpan: 2
                      },
                    ],
                    // Row 2
                    [
                      {
                        text: await this.rtlSorting(this.title) + '\n' + 'www.toor.ooo',
                        style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText'],
                        border: [false, false, false, false]
                      },
                      {
                        image: await this.getImageDataUrl(imageUrlLogo),
                        width: 40,
                        fit: [40, 40],
                        style: [this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText', 'bannerStyle'],
                        border: [false, false, false, false]
                      },
                    ],
                  ],
                  // Set the alignment for the entire table
                  alignment: 'center',
                },
                border: [false, false, false, false],
                colSpan: 3,
              },
            ]            
          ]
        },
      }
  ],
  margin: [0, 10, 0, 0],
  canvas: [
    {
      type: 'rect',
      x: 0,
      y: 0,
      w: 841.89, // A4 width in landscape orientation
      h: 595.28, // A4 height in landscape orientation
      color: 'yellow', // Set background color to yellow
    },
  ],
  }
    const footer = {
      columns: [
        {
          text: this.item.properties_reference_nb,
          style: 'tajawalStyle',
          alignment: 'center',
          margin: [0, 10, 0, 0]
        }
      ]
    };

    const documentDefinition = {
      content: [header, essentials, location, lengths_borders, decription, financial, instrument, permit, realestate, building, additional, mediation_contract, gallery, banner, footer],
      canvas: [
        {
          type: 'rect',
          x: 0,
          y: 0,
          w: 841.89, // A4 width in landscape orientation
          h: 595.28, // A4 height in landscape orientation
          color: 'yellow', // Set background color to yellow
        },
      ],
      pageOrientation: 'portrait',
      pageMargins: [15, 15, 15, 15],
      styles: {
        defuletText: {
          font: 'Roboto',
          noWrap: { noWrap: false },
          wrapText: { noWrap: false, maxWidth: 100 },
        },
        tajawalStyle: {
            font: "Tajawal",
            alignment: "right",
            direction: "rtl",
            rtl: true,
            wrapText: { noWrap: false, maxWidth: 100 },
        },
        fieldCaption: {
          bold: true,
          fontSize: 10,
          color: 'black',
        },
        fieldValue: {
          medium: true,
          fontSize: 10,
          color: 'black',
        },
        bannerStyle: {
          fontSize: 28,
          color: 'black',
          height: 'auto',
        },
        table: {
          margin: [0, 20, 0, 20],
        },
        tableHeader: {
          fontSize: 12,
          color: 'black',
        },
        tableCell: {
          fontSize: 10,
          color: 'black',
          height: 'auto',
        },
      },
    };

    switch (report_type) {
      case 1:
        const itemsToRemove1 = [financial, instrument, permit, realestate, building, additional, mediation_contract, gallery, banner];
    
        itemsToRemove1.forEach(itemToRemove => {
          const indexToRemove = documentDefinition.content.findIndex(item => item === itemToRemove);
          if (indexToRemove !== -1) {
            documentDefinition.content.splice(indexToRemove, 1);
          }
        });
        break;
      case 2:
        const itemsToRemove2 = [banner];
    
        itemsToRemove2.forEach(itemToRemove => {
          const indexToRemove = documentDefinition.content.findIndex(item => item === itemToRemove);
          if (indexToRemove !== -1) {
            documentDefinition.content.splice(indexToRemove, 1);
          }
        });
        break;
      case 3:
        const itemsToRemove3 = [header, essentials, location, lengths_borders, decription, financial, instrument, permit, realestate, building, additional, mediation_contract, gallery, footer];
    
        itemsToRemove3.forEach(itemToRemove => {
          const indexToRemove = documentDefinition.content.findIndex(item => item === itemToRemove);
          if (indexToRemove !== -1) {
            documentDefinition.content.splice(indexToRemove, 1);
          }
        });
        documentDefinition.pageOrientation = 'landscape';
        break;
    
      default:
        // Handle the default case if needed
        break;
    }
    

    try {
      pdfMake.createPdf(documentDefinition).open({}, window.open());
    } catch (error) {
      console.error("PDF generation error:", error);
    }
    
    
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

private async getImageDataUrl(imageUrl: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      resolve(canvas.toDataURL('image/jpeg'));
    };

    img.onerror = (errorEvent) => {
      // Handle image loading error
      console.error('Error loading image:', errorEvent);

      // Provide a default image or handle the situation as needed
      const defaultImageUrl = 'https://obaykan.s3.us-east-2.amazonaws.com/files/logos/icon.png';
      
      // Set a default image source
      img.src = defaultImageUrl;

      // Optionally, you can also resolve the promise with the default image data URL
      resolve(canvas.toDataURL('image/jpeg'));
      
      // Reject the promise with the error
      // reject(errorEvent);
    };

    img.crossOrigin = 'anonymous';
    img.src = imageUrl;
  });
}

  async encodePolygon(coordinates) {
    if (!coordinates || !coordinates[0] || !coordinates[0].geometry || !coordinates[0].geometry[0]) {
      console.error('Invalid coordinates format');
      console.error(coordinates);
      return '';
    }

    const polygonCoordinates = coordinates[0].geometry[0].map(([lat, lng]) => ({ lat, lng }));
    if (!polygonCoordinates || polygonCoordinates.length === 0) {
      console.error('Invalid polygon coordinates');
      return '';
    }

    const encodedPath = google.maps.geometry.encoding.encodePath(polygonCoordinates);
    console.error(encodedPath);
    return encodedPath;
  }

  async rtlSorting(input: any) {
    if (typeof input === 'string') {
      // const data = input.split(' ').reduceRight((acc, word) => acc + ' ' + word, '').trim();
      return input.split(' ').reverse().join('  ');
    } else {
      return input;
    }
  }
  
//   // Encode a string to UTF-8 bytes
// encodeToUtf8(input: string): Uint8Array {
//   const encoder = new TextEncoder();
//   return encoder.encode(input.split(' ').reverse().join(' '));
// }

// // Decode UTF-8 bytes to a string
// decodeFromUtf8(bytes: Uint8Array): string {
//   const decoder = new TextDecoder();
//   return decoder.decode(bytes);
// }


  async ttbSorting(input: any) {
    if (typeof input === 'string') {
      const words = input.trim().split(/\s+/); // Split the input into an array of words

      if (words.length <= 1) {
        // Return the input unchanged if it has only one word or is empty
        return input;
      }
    
      const sortedWords = words.map(word => word.split('').sort().join('')); // Sort characters in each word
    
      const result = sortedWords.join(' '); // Join the sorted words into a string
    
      return result;
    }
  }
}

// try {
//   const tableData = [];
//   let currentRow = [];
  
//   // Iterate through all key-value pairs in this.item
//   for (const key in this.item) {
//     if (this.item.hasOwnProperty(key)) {
//       const value = this.item[key];
  
//       // Skip if the value is null, undefined, or 0
//       if (value == null || value === undefined || value === 0) {
//         continue;
//       }
  
//       // Check if the value is a URL (you might need a more robust URL validation)
//       const isUrl = value.url;
  
//       // Create a cell object based on the type of value
//       let cell;
//       if (isUrl) {
//         // If it's a URL, create an image cell
//         cell = { image: await this.getImageDataUrl(value), fit: [100, 100], style: 'tajawalStyle' };
//         console.log('URL exists:', isUrl);
//       } else {
//         // If it's not a URL, create a text cell
//         const translatedKey = await this.translate.get(`__tables.properties_lists.fields.${key}.caption`).toPromise().catch(() => {
//           console.warn(`Translation not found for key: ${key}`);
//           return key;
//         });
  
//         // Check if the value is a string before attempting to split it
//         const formattedValue = typeof value === 'string' ? value.split(' ').reverse().join('  ') : value;
  
//         // Create a text cell object with the translated key and formatted value
//         cell = { text: `${translatedKey?.split(' ').reverse().join('  ')}: ${formattedValue}`, style: this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText' };
//       }
  
//       // Add the cell to the current row
//       currentRow.push(cell);
  
//       // Check if three cells have been added to the current row
//       if (currentRow.length === 3) {
//         // Add the current row to the table
//         tableData.push(currentRow);
  
//         // Reset the current row for the next set of three key-value pairs
//         currentRow = [];
//       }
//     }
//   }
  
//   // Check if there are any remaining cells in the current row
//   if (currentRow.length > 0) {
//     // Pad the row with empty cells if needed
//     while (currentRow.length < 3) {
//       currentRow.push({ text: '', style: this.locale.locale === 'ar' ? 'tajawalStyle' : 'defaultText' });
//     }
  
//     // Add the last row to the table
//     tableData.push(currentRow);
//   }
//   // Rest of the code remains unchanged
//   const table = {
//     table: {
//       widths: ['*', '*', '*'], // Set the width of each column (you may need to adjust this based on your content)
//       body: [
//         // Add headers based on your requirements
//         [{ text: 'Header 1', style: 'tableHeader' }, { text: 'Header 2', style: 'tableHeader' }, { text: 'Header 3', style: 'tableHeader' }],
//         ...tableData,
//       ],
//     },
//   };
  
//   const footer = {
//       text: this.item.properties_reference_nb,
//       style: 'tajawalStyle',
//       alignment: 'center',
//       margin: [0, 10, 0, 0],
//   };

//   const documentDefinition = {
//     content: [header, table, footer],
//     styles: {
//       defuletText: {
//         fontSize: 14,
//         bold: true,
//         margin: [0, 0, 0, 5],
//         font: 'Roboto',
//         noWrap: { noWrap: false },
//         wrapText: { noWrap: false, maxWidth: 100 },
//         tableHeader: { bold: true, fontSize: 13, color: 'black', alignment: 'center' }, 
//       },
//       tajawalStyle: {
//           fontSize: 14,
//           bold: true,
//           margin: [0, 0, 0, 5],
//           font: "Tajawal",
//           alignment: "right",
//           direction: "rtl",
//           rtl: true,
//           wrapText: { noWrap: false, maxWidth: 100 },
//           tableHeader: { bold: true, fontSize: 13, color: 'black', alignment: 'center' },
//       },
//     },
//   };
//   pdfMake.createPdf(documentDefinition).open();
  
  
// } catch (error) {
//   console.error('An error occurred:', error);
// }