import { CUSTOM_ELEMENTS_SCHEMA, OnDestroy, OnInit, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NavController, ModalController, AlertController, LoadingController, PopoverController, ActionSheetController, ToastController, IonSearchbar } from '@ionic/angular';
import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, NgForm } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Observable, Subscription, BehaviorSubject, from, timer, first, lastValueFrom } from 'rxjs';
import { IonInfiniteScroll, Platform, NavParams } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { File, Entry, FileEntry } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { FileUploadComponent, FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { UserData, DbRecord, DbFile, LocaleService, Settings, DbApp, DbAppValidators, History, payments } from '../../providers';
import { environment } from '../../../environments/environment';
import { EmailService } from '../../providers/email.service';
import { SMSService } from '../../providers/sms.service';
import { PaymentService } from '../../providers/payment.service';
import { CryptoPaymentService } from '../../providers/cryptopayment.service';
import { ApplePayEventsEnum, GooglePayEventsEnum, PaymentFlowEventsEnum, PaymentSheetEventsEnum, Stripe } from '@capacitor-community/stripe';
import { HttpClient, HttpParams } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { countriesListPage } from '../../pages/countrieslist/countrieslist';
// import { StripeOnramp } from '@stripe/stripe-js';
import { loadStripeOnramp, StripeOnramp } from '@stripe/crypto';


// declare var paypal: {
//     Buttons: any; // Adjust the type as needed
//   };
  
// Component
@Component({
	selector: 'payments-component',
	templateUrl: 'payments.component.html',
	styleUrls: ['payments.component.scss'],
	providers: [EmailService, SMSService, PaymentService, CryptoPaymentService],
})

export class paymentsComponentPage implements OnDestroy, OnInit {
    // @ViewChild('paypalButton', { static: true }) paypalButton!: ElementRef;
    @ViewChild('onrampElement') onrampElement: ElementRef;
	private subscription: Subscription; // For AutoSuggest
	private currentPage: number; // For AutoSuggest
	private navigationSubscription: Subscription;
	wallet: any = { charge: '' };
	loadingMessage: string;
	dataLoaded: boolean;
	returnUrl: string = "/wallet";
    isLoggedIn: boolean;
    isAdmin: boolean;
    userId: string;
    permissions: any;
	submitted: boolean;
	uuidv4: string = uuidv4();
	userEmail: string;
	userMobile: number;
	userFullName: string;
	userFirstName: string;
	userLastName: string;
	smsQueueRecipient: string;
	smsQueueContent: string;
	fromEmail: string;
	toEmail: string;
	ccEmail: string;
	bccEmail: string;
	subject: string;
	emailContent: string;
	format: string;
	charset: string;
	smtpSecure: string;
	arAttachments: any[];
	arImages: any[];
	arProperties: any;
	brandName: string;
	userTitleFullName: string;
	transactionDetailsTitle: string;
	transactionDetailsLink: string;
	instructionsTitle: string;
	instructionsSubTitle: string;
	instructionsContent: string;
	termsConditionsContent: string;
	extra_brand_name: string;
	extra_transaction_details_title: string;
	extra_transaction_details_link: string;
	extra_instructions_title: string;
	extra_instructions_subtitle: string;
	extra_instructions_content: string;
	extra_terms_conditions_content: string;
	extra_locale: string;

    product_id: any = "6ec22072-05af-47cc-95c4-bca89f31cbf1";
    customer_id: any = "6ec22072-05af-47cc-95c4-bca89f31cbf1";
    // first_name: any = "Saher";
    // last_name: any = "Osman";
    // customer_email: any = "saher.osman@gmail.com";
    // amount: any = 900;
    // currency: any = "usd";
    product: any = "Test";
    token: any = "6ec22072-05af-47cc-95c4-bca89f31cbf1";
	locations_country_id: string;
	locations_country_name: string;
	locations_country_currency: string = "Saudi Ryial"
	locations_country_currency_symbol: string = "SAR";
	locations_country_flag: string = 'sa.png';

    isCashModalOpen = false;
    isPaypalModalOpen = false;
    isCryptoModalOpen = false;
    paymentData: any;
    cryptoPaymentData: any;
    destination_currency: string = 'usdc';
    destination_exchange_amount: number;
    destination_network: string = 'ethereum';
    
    private stripeOnramp: StripeOnramp;
    private clientSecret: string = 'cos_1Lb6vsAY1pjOSNXVWF3nUtkV_secret_8fuPvTzBaxj3XRh14C6tqvdl600rpW7hG4G';
  
	// Constructor
	constructor(
		public dbapp: DbApp,
		public translate: TranslateService,
		public locale: LocaleService,
		public navController: NavController,
		public loadingController: LoadingController,
		public actionSheetController: ActionSheetController,
		public modalController: ModalController,
		public alertController: AlertController,
		public toastController: ToastController,
		public router: Router,
		public formBuilder: FormBuilder,
		public user: UserData,
		public inAppBrowser: InAppBrowser,
		private activatedRoute: ActivatedRoute,
		public history: History,
		private camera: Camera,
		private file: File,
		private webview: WebView,
		private platform: Platform,
		private changeDetectorRef: ChangeDetectorRef,
		private filePath: FilePath,
		private fileChooser: FileChooser,
		private sanitizer: DomSanitizer,
        private paymentsItems: payments,
		private emailService: EmailService,
		private smsService: SMSService,
		private paymentService: PaymentService,
        private cryptoPaymentService: CryptoPaymentService,
		private http: HttpClient,
        private navParams: NavParams
	) {
        this.getUserData();
		Stripe.initialize({
			publishableKey: environment.stripe.publishablekey
		});
	}
    
	// OnInit
	async ngOnInit() {
        
    }
  
    private async loadStripeOnramp() {
      const stripeOnramp = await loadStripeOnramp('pk_test_MXg3tga70LY0r3Weh8KVWOQz00Q6yrCXqB');
      this.stripeOnramp = stripeOnramp;
      this.initializeOnramp();
    }
  
    private async initializeOnramp(): Promise<void> {
        try {
          const onrampSession = await this.stripeOnramp.createSession({ clientSecret: this.clientSecret });
          await onrampSession.mount('#onramp-element');
          console.log('Onramp element mounted successfully');
        } catch (error) {
          console.error('Error creating Onramp session:', error);
        }
      }
      
      
      

    ionViewWillEnter() {
        // Retrieve data passed from the parent component
        this.paymentData = this.navParams.get('paymentData');
        console.log('this.paymentData', this.paymentData);
      }

	// OnDestroy
	ngOnDestroy() {
		if (this.navigationSubscription)
			this.navigationSubscription.unsubscribe(); // Clean up
	}
    
	// Get user data
	getUserData() {
		this.user.isLoggedIn.subscribe(res => {
			this.isLoggedIn = res;
			this.isAdmin = this.user.isAdmin;
			this.userId = this.user.userId;
			this.userEmail = this.user.userEmail;
			this.userMobile = this.user.userMobile;
			this.userFullName = this.user.userFullname;
			this.permissions = this.user.permissions;
		});
	}
    
	async cancel() {
		await this.modalController.dismiss();
	  }

	async openModalPaymentOptions(form: NgForm) {
		this.submitted = true;
		if (form.valid) {
			let ids = [
			"payments_methods",
			"cash_pay",
			"card_pay",
			"paypal_pay",
			"google_pay",
			"apple_pay",
			"amazon_pay",
			"crypto_pay",
			"other_pay",,
			'CANCEL_BUTTON'
			];
			let doCashPayOption = (fldvar) => {
			        this.cashPay();
			};
			let doCardPayOption = (fldvar) => {
					this.cardPay();
			};
			let doPaypalPayOption = (fldvar) => {
                this.isPaypalModalOpen = true;
                // this.paypalPay();
			};
			let doGooglePayOption = (fldvar) => {
					this.googlePay();
			};
			let doApplePayOption = (fldvar) => {
					this.applePay();
			};
			let doAmazonPayOption = (fldvar) => {
					this.amazonPay();
			};
			let doCryptoPayOption = (fldvar) => {
                    this.isCryptoModalOpen = true;
					this.cryptoPay();
			};
			let doOtherPayOption = (fldvar) => {
			        this.otherPay();
			};
			let values = await this.translate.get(ids).toPromise();
			let actionSheet = await this.actionSheetController.create({
			header: values.payments_methods,
			buttons: [
				{
				text: values.cash_pay,
                icon: "./assets/icon/ask_question_colored.svg",
				handler: () => {
                    this.isCashModalOpen = true;
				},
				},
				{
				text: values.card_pay,
                icon: "./assets/icon/visa.svg",
				handler: () => {
					doCardPayOption(this.wallet.charge);
				},
				},
				{
				text: values.paypal_pay,
                icon: "./assets/icon/paypal.svg",
				handler: () => {
                    doPaypalPayOption("");
				},
				},
				{
				text: values.google_pay,
                icon: "./assets/icon/google_wallet.svg",
				handler: () => {
					doGooglePayOption(this.wallet.charge);
				},
				},
				{
				text: values.apple_pay,
                icon: "./assets/icon/apple.svg",
				handler: () => {
					doApplePayOption(this.wallet.charge);
				},
				},
				{
				text: values.amazon_pay,
                icon: "./assets/icon/amazon.svg",
				handler: () => {
					doAmazonPayOption(this.wallet.charge);
				},
				},
				{
				text: values.crypto_pay,
                icon: "./assets/icon/bitcoin.svg",
				handler: () => {
                    doCryptoPayOption("");
				},
				},
				{
				text: values.other_pay,
                icon: "./assets/icon/ask_question_colored.svg",
				handler: () => {
					doOtherPayOption("");
				},
				},
				{
					text: values.CANCEL_BUTTON,
					role: 'cancel',
					handler: () => {
						console.log('Cancel clicked');
					}
				}
			],
			cssClass: "secondary",
			});
			await actionSheet.present();
			this.submitted = false;
		}
	  }

		isLoading = false;
	  
		async openModalCountriesCurrencies() {
		  this.isLoading = true;
		  const modalCountriesCurrencies = await this.modalController.create({
			component: countriesListPage,
			componentProps: { openedViewCountriesCurrenciesModal: true },
			initialBreakpoint: 1,
		  });
		  modalCountriesCurrencies.onDidDismiss().then(item => {
			this.isLoading = false;
			if (item && item.data) {
			  this.locations_country_id = item.data.selectedCountryId;
			  this.locations_country_name = item.data.selectedCountryName;
			  this.locations_country_currency = item.data.selectedCountryCurrency;
			  this.locations_country_currency_symbol = item.data.selectedCountryCurrencySymbol;
			  this.locations_country_flag = item.data.selectedCountryFlag;
			}
		  });
		  return await modalCountriesCurrencies.present();
		}
    
    setCashOpen(isCashOpen: boolean) {
        this.isCashModalOpen = isCashOpen;
    }

    setPaypalOpen(isPaypalOpen: boolean) {
        this.isPaypalModalOpen = isPaypalOpen;
    }

    setCryptoOpen(isCryptoOpen: boolean) {
      this.isCryptoModalOpen = isCryptoOpen;
    }

    async getPaymentData(){
        let amount = parseFloat(this.wallet.charge.toString());

        if (Number.isNaN(amount)) {
        // Handle the case where the input is not a valid number
        amount = 0;
        }

        const decimalCount = (amount.toString().split('.')[1] || '').length;

        if (decimalCount === 0) {
        // No decimals, multiply by 100
        amount *= 100;
        } else {
        // Less than or equal to 2 decimals, no change needed
        amount = Math.round(amount * 100) / 100;
        amount = amount * 100;
        }

            this.paymentData = {
                product_id: this.product_id,
                customer_id: this.customer_id,
                first_name: this.userFullName ? this.userFullName.split(' ')[0] : null,
                last_name: this.userFullName ? this.userFullName.split(' ').slice(1).join(' ') : null,
                customer_email: this.userEmail,
                charge: this.wallet.charge,
                amount: amount,
                currency: this.locations_country_currency_symbol,
                product: this.product,
                token: this.token
            };
    }

    async getCryptoPaymentData(){
        let amount = parseFloat(this.wallet.charge.toString());

        if (Number.isNaN(amount)) {
        // Handle the case where the input is not a valid number
        amount = 0;
        }

        const decimalCount = (amount.toString().split('.')[1] || '').length;

        if (decimalCount === 0) {
        // No decimals, multiply by 100
        amount *= 100;
        } else {
        // Less than or equal to 2 decimals, no change needed
        amount = Math.round(amount * 100) / 100;
        amount = amount * 100;
        }

            this.cryptoPaymentData = {
                destination_currency: this.destination_currency,
                destination_exchange_amount: amount,
                destination_network: this.destination_network
            };
    }
	// paymentData: any = {
	// 	product_id: this.product_id,
	// 	customer_id: this.customer_id,
	// 	first_name: this.first_name,
	// 	last_name: this.last_name,
	// 	customer_email: this.customer_email,
	// 	amount: this.amount,
	// 	currency: this.currency,
	// 	product: this.product,
	// 	token: this.token
	// };
    
    async cashPay() {
        try {
            Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
                console.log("PaymentSheetEventsEnum.Completed");
            });

            const paymentData = new HttpParams({
                fromObject: this.paymentData
            });

            // Connect to your backend endpoint, and get evry key.
            // const data$ = this.http.post<{
            // 	paymentIntent: string;
            // 	ephemeralKey: string;
            // 	customer: string;
            // }>(environment.api + 'payment-sheet', data).pipe(first());

            const data$ = await this.paymentService.sendPaymentHeader(this.paymentData);

            const { paymentIntent, ephemeralKey, customer } = await lastValueFrom(data$);

            console.log('paymentIntent: ', paymentIntent);
            console.log("paymentData", paymentData);
            console.log(data$);

            // prepare PaymentSheet with CreatePaymentSheetOption.
            await Stripe.createPaymentSheet({
                paymentIntentClientSecret: paymentIntent,
                customerId: customer,
                customerEphemeralKeySecret: ephemeralKey,
                merchantDisplayName: 'TOOR'
            });

            console.log('createPaymentSheet');
            // present Payment and get result.
            const result = await Stripe.presentPaymentSheet();
            console.log('result: ', result);
            if (result && result.paymentResult === PaymentSheetEventsEnum.Completed) {
                // Happy path
            }
        } catch (e) {
            console.log(e);
        }
    }

    async madaPay() {
        try {
            Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
                console.log("PaymentSheetEventsEnum.Completed");
            });

            const paymentData = new HttpParams({
                fromObject: this.paymentData
            });

            // Connect to your backend endpoint, and get evry key.
            // const data$ = this.http.post<{
            // 	paymentIntent: string;
            // 	ephemeralKey: string;
            // 	customer: string;
            // }>(environment.api + 'payment-sheet', data).pipe(first());

            const data$ = await this.paymentService.sendPaymentHeader(this.paymentData);

            const { paymentIntent, ephemeralKey, customer } = await lastValueFrom(data$);

            console.log('paymentIntent: ', paymentIntent);
            console.log("paymentData", paymentData);
            console.log(data$);

            // prepare PaymentSheet with CreatePaymentSheetOption.
            await Stripe.createPaymentSheet({
                paymentIntentClientSecret: paymentIntent,
                customerId: customer,
                customerEphemeralKeySecret: ephemeralKey,
                merchantDisplayName: 'TOOR'
            });

            console.log('createPaymentSheet');
            // present Payment and get result.
            const result = await Stripe.presentPaymentSheet();
            console.log('result: ', result);
            if (result && result.paymentResult === PaymentSheetEventsEnum.Completed) {
                // Happy path
            }
        } catch (e) {
            console.log(e);
        }
    }

    async cardPay() {
		this.loadingMessage = await this.translate.get("LOADING").toPromise();
        const loading = await this.loadingController.create({
            spinner: null,
            cssClass: 'global-loader',
            showBackdrop: true,
        });
		await loading.present();
        await this.getPaymentData();
        try {
          Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
            console.log("PaymentSheetEventsEnum.Completed");
          });
      
          const paymentData = new HttpParams({
            fromObject: this.paymentData
          });
      
          const data$ = await this.paymentService.sendPaymentHeader(this.paymentData);
      
          const { paymentIntent, ephemeralKey, customer } = await lastValueFrom(data$);
      
          console.log('paymentIntent: ', paymentIntent);
          console.log("paymentData", paymentData);
          console.log(data$);

          const paymentSheetOptions = {
            paymentIntentClientSecret: paymentIntent,
            customerId: customer,
            customerEphemeralKeySecret: ephemeralKey,
            merchantDisplayName: 'TOOR',
            // countryCode: 'US',
            // paymentMethodTypes: [
            //     'card',
            //     'paypal',
            //     'cashapp'
            // ],
            automatic_payment_methods: { enabled: true },
            // payment_method_options: {
            //   // Customize the fields to be collected
            //   card: {
            //     preferred_language: 'fr',
            //     billing_details: {
            //       name: this.userFullName,
            //       email: this.userEmail,
            //     }
            //   }
            // },
            //   layout: {
            //     type: 'tabs',
            //     defaultCollapsed: false,
            // },
            withZipCode: false,
            returnURL: '/'
          };
      
          // Create and present Payment Sheet
          await Stripe.createPaymentSheet(paymentSheetOptions);
      
          console.log('createPaymentSheet');
      
                    // this.splitAndJoin(paymentIntent);

          // Present Payment Sheet and get result
          const result = await Stripe.presentPaymentSheet();
          console.log('result: ', result);
      
          if (result && result.paymentResult === PaymentSheetEventsEnum.Completed) {
            this.registerPayment();
          } else {
            alert('Faild ' + '!');
          }
        } catch (e) {
          console.log(e);
		} finally {
			loading.dismiss();
			await this.modalController.dismiss();
            const toast = await this.toastController.create({
              message: await this.translate.get("your_paid_succeesful").toPromise(),
              duration: 8000,
              position: 'top'
            });
            toast.present();
		}
      }
      
    async paymentFlow() {
        try {
            // be able to get event of PaymentFlow
            Stripe.addListener(PaymentFlowEventsEnum.Completed, () => {
                console.log('PaymentFlowEventsEnum.Completed');
            });

            const data = new HttpParams({
                fromObject: this.paymentData
            });

            // Connect to your backend endpoint, and get every key.
            // const {paymentIntent, ephemeralKey, customer} = await this.http.post<{
            // 	paymentIntent: string;
            // 	ephemeralKey: string;
            // 	customer: string;
            // }>(environment.api + 'payment-sheet', {}).pipe(first()).toPromise(Promise);

            const data$ = await this.paymentService.sendPaymentHeader(data);

            const { paymentIntent, setupIntent, ephemeralKey, customer } = await lastValueFrom(data$);

            console.log('paymentIntent: ', paymentIntent);

            // Prepare PaymentFlow with CreatePaymentFlowOption.
            Stripe.createPaymentFlow({
                paymentIntentClientSecret: paymentIntent,
                setupIntentClientSecret: setupIntent,
                customerEphemeralKeySecret: ephemeralKey,
                customerId: customer,
            });

            // Present PaymentFlow. **Not completed yet.**
            const presentResult = await Stripe.presentPaymentFlow();
            console.log(presentResult); // { cardNumber: "●●●● ●●●● ●●●● ****" }

            // Confirm PaymentFlow. Completed.
            const confirmResult = await Stripe.confirmPaymentFlow();
            if (confirmResult.paymentResult === PaymentFlowEventsEnum.Completed) {
                // Happy path
            }
        } catch (e) {
            console.log(e);
        }
    }

    async cryptoPay() {
        await this.loadStripeOnramp();
        await this.getCryptoPaymentData();
        try {
      
          const cryptoPaymentData = new HttpParams({
            fromObject: this.cryptoPaymentData
          });
      
          const data$ = await this.cryptoPaymentService.sendCryptoPaymentHeader(this.cryptoPaymentData);} catch (e) {
            console.log(e);
        }
    }

    async googlePay() {
        try {
            // Check to be able to use Google Pay on device
            const isAvailable = Stripe.isGooglePayAvailable().catch(() => undefined);
            if (isAvailable === undefined) {
                // disable to use Google Pay
                return;
            }

            Stripe.addListener(GooglePayEventsEnum.Completed, () => {
                console.log('GooglePayEventsEnum.Completed');
            });

            const paymentData = new HttpParams({
                fromObject: this.paymentData
            });

            // Connect to your backend endpoint, and get paymentIntent.
            // const { paymentIntent } = await this.http.post<{
            // 	paymentIntent: string;
            // }>(environment.api + 'payment-sheet', {}).pipe(first()).toPromise(Promise);

            const data$ = await this.paymentService.sendPaymentHeader(this.paymentData);

            const { paymentIntent } = await lastValueFrom(data$);

            console.log('paymentIntent: ', paymentIntent);

            // Prepare Google Pay
            await Stripe.createGooglePay({
                paymentIntentClientSecret: paymentIntent,

                // Web only. Google Pay on Android App doesn't need
                paymentSummaryItems: [{
                    label: 'Wallet',
                    amount: 1099.00
                }],
                merchantIdentifier: 'merchant.com.getcapacitor.stripe',
                countryCode: 'US',
                currency: 'USD',
            });

            // Present Google Pay
            const result = await Stripe.presentGooglePay();
            if (result.paymentResult === GooglePayEventsEnum.Completed) {
                // Happy path
            }
        } catch (e) {
            console.log(e);
        }
    }

    async applePay() {
        try {
            // Check to be able to use Apple Pay on device
            const isAvailable = Stripe.isApplePayAvailable().catch(() => undefined);
            if (isAvailable === undefined) {
                // disable to use Google Pay
                return;
            }

            // be able to get event of Apple Pay
            Stripe.addListener(ApplePayEventsEnum.Completed, () => {
                console.log('ApplePayEventsEnum.Completed');
            });

            const data = new HttpParams({
                fromObject: this.paymentData
            });

            // Connect to your backend endpoint, and get paymentIntent.
            // const { paymentIntent } = await this.http.post<{
            // 	paymentIntent: string;
            // }>(environment.api + 'payment-sheet', {}).pipe(first()).toPromise(Promise);

            const data$ = await this.paymentService.sendPaymentHeader(data);

            const { paymentIntent } = await lastValueFrom(data$);

            console.log('paymentIntent: ', paymentIntent);

            // Prepare Apple Pay
            await Stripe.createApplePay({
                paymentIntentClientSecret: paymentIntent,
                paymentSummaryItems: [{
                    label: 'Product Name',
                    amount: 1099.00
                }],
                merchantIdentifier: 'TOOR',
                countryCode: 'US',
                currency: 'USD',
            });

            // Present Apple Pay
            const result = await Stripe.presentApplePay();
            if (result.paymentResult === ApplePayEventsEnum.Completed) {
                // Happy path
            }
        } catch (e) {
            console.log(e);
        }
    }

    async amazonPay() {

    }

    // async paypalPay() {
    //     try {
    //         let charge = parseFloat(this.wallet.charge);
    //         let currency = this.locations_country_currency_symbol;
    //     setTimeout(() => {
    //       // Render the PayPal button into #paypal-button-container
    //      paypal.Buttons({
    
    //         // Set up the transaction
    //         createOrder: function (data, actions) {
    //             console.log("charge", charge)
    //           return actions.order.create({
    //             purchase_units: [{
    //               amount: {
    //                 value: charge,
    //                 currency: currency
    //               }
    //             }]
    //           });
    //         },
    
    //         // Finalize the transaction
    //         onApprove: function (data, actions) {
    //           return actions.order.capture()
    //             .then(function (details) {
    //               // Show a success message to the buyer
    //                 this.registerPayment();
    //               alert('Transaction completed by ' + details.payer.name.given_name + '!');
    //             })
    //             .catch(err => {
    //               console.log(err);
    //             })
    //         }
    //       }).render('#paypal-button-container');
    //     }, 500);
    //  } catch (e) {
    //         console.log(e);
    //       } finally {
    //       }
    // }

    async otherPay() {

    }

    splitAndJoin(paymentIntent){
        const result = paymentIntent.split('_').slice(0,2).join('_');
        console.log(result);
        return result;
    }
    
    async registerPayment(){
        const value = {
            system_payment_info_id: this.uuidv4,
            system_user_id: this.userId,
            system_payment_info_status: '1',
            system_payment_info_last_pay: this.paymentData.charge,
            system_payment_info_method_id: '',
            system_payment_info_provider_id: '',
            system_payment_info_rate_amount: '',
            system_payment_info_holder_fname: this.userFullName ? this.userFullName.split(' ')[0] : null,
            system_payment_info_holder_mname: '',
            system_payment_info_holder_lname: this.userFullName ? this.userFullName.split(' ').slice(1).join(' ') : null,
            system_payment_info_card_nb: '',
            system_payment_info_secret: '',
            system_payment_info_expire_month: '',
            system_payment_info_expire_year: '',
            system_payment_info_mobile: this.paymentData.mobile,
            system_payment_info_save_confirmation: '1'
        };
        try {
        const res = await this.paymentsItems.add(value);
            console.log("Add", this.paymentsItems);
            localStorage.setItem('system_user_wallet_in', parseFloat(localStorage.getItem('system_user_wallet_in')) + this.paymentData.charge);
            this.sendEmail();
            this.sendSMS();
            this.router.navigate([this.returnUrl]);
            } catch (err) {
        }
    }
	/** Start Notifications Functions */
	async sendEmail() {
		this.fromEmail = 'admin@toor.ooo';
		this.toEmail = this.userEmail;
		this.ccEmail = '';
		this.bccEmail = '';
		this.subject = await this.translate.get("your_payment_for_wallet_done_email_subject").toPromise();
		this.emailContent = await this.translate.get("your_payment_for_wallet_done_email_content", { s: this.paymentData.charge, c: this.translate.instant(this.paymentData.currency), d: this.dbapp.formatTimestamp(Date.now()) }).toPromise();
		this.format = 'text';
		this.charset = 'UTF-8';
		this.smtpSecure = 'smtp';
		this.arAttachments = [];
		this.arImages = [];
		this.arProperties = null;
		this.userTitleFullName = await this.translate.get("mr_mrs", { s: this.userFullName }).toPromise();
		this.brandName = await this.translate.get("toorprime").toPromise();
		this.transactionDetailsTitle = await this.translate.get("see_payment_for_wallet_details").toPromise();
		this.transactionDetailsLink = 'https://www.toor.ooo/paymentsview/' + this.uuidv4;
		this.instructionsTitle = await this.translate.get("payment_instructions_title").toPromise();
		this.instructionsSubTitle = await this.translate.get("payment_instructions_subtitle").toPromise();
		this.instructionsContent = await this.translate.get("payment_instructions_content").toPromise();
		this.termsConditionsContent = await this.translate.get("terms_conditions_for_email").toPromise();
		const emailHeaderClient = {
			from_email: this.fromEmail,
			to_email: this.toEmail,
			cc_email: this.ccEmail,
			bcc_email: this.bccEmail,
			subject: this.subject,
			email_content: this.emailContent,
			format: this.format,
			charset: this.charset,
			smtp_secure: this.smtpSecure,
			ar_attachments: this.arAttachments,
			ar_images: this.arImages,
			ar_properties: this.arProperties,
			extra_userfullname: this.userTitleFullName,
			extra_brand_name: this.brandName,
			extra_transaction_details_title: this.transactionDetailsTitle,
			extra_transaction_details_link: this.transactionDetailsLink,
			extra_instructions_title: this.instructionsTitle,
			extra_instructions_subtitle: this.instructionsSubTitle,
			extra_instructions_content: this.instructionsContent,
			extra_terms_conditions_content: this.termsConditionsContent,
			extra_locale: this.locale.locale
		};

		try {
			const response = await this.emailService.sendEmailHeader(
				this.fromEmail,
				this.toEmail,
				this.ccEmail,
				this.bccEmail,
				this.subject,
				this.emailContent,
				this.format,
				this.charset,
				this.smtpSecure,
				this.arAttachments,
				this.arImages,
				this.arProperties,
				this.userTitleFullName,
				this.brandName,
				this.transactionDetailsTitle,
				this.transactionDetailsLink,
				this.instructionsTitle,
				this.instructionsSubTitle,
				this.instructionsContent,
				this.termsConditionsContent,
				this.locale.locale
			);
			console.log('Email sent successfully', response);
		} catch (error) {
			console.error('Failed to send Email', error);
		}
	}

	async sendSMS() {
		this.smsQueueRecipient = '+' + this.userMobile;
		this.smsQueueContent = await this.translate.get("your_bid_for_auction_done_sms_content", { s: 'this.posted_payment_amount' }).toPromise();
		const msgHeaderClient = {
			smsQueueRecipient: this.smsQueueRecipient,
			smsQueueContent: this.smsQueueContent
		};
		try {
			const response = await this.smsService.sendMessageHeader(this.smsQueueRecipient, this.smsQueueContent);
			console.log('SMS sent successfully', response);
		} catch (error) {
			console.error('Failed to send SMS', error);
		}
	}
	/** End Notifications Functions */
}