import { HttpClient } from '@angular/common/http';
import { formatNumber } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DbApp } from './dbapp';
import { UserData } from './user-data';
import { DbTable } from './dbtable';
import { DbRecord } from './dbrecord';
import { DbField } from './dbfield';
import { LocaleService } from './locale.service';
import { LocalDatePipe } from './localdate.pipe';
import { LocalNumberPipe } from './localnumber.pipe';
import { LocalCurrencyPipe } from './localcurrency.pipe';
import { LocalPercentPipe } from './localpercent.pipe';
import { AuthFilePipe } from './authfile.pipe';

// messages
@Injectable({
	providedIn: 'root'
})
export class messages extends DbTable {
	pageId: string;
	fieldVars = ["messages_id","messages_source","messages_user_id","messages_title","messages_body","messages_link","messages_publish","messages_datetime","messages_seen","messages_loop","messages_repeat","messages_company_id","messages_branch_id","messages_last_edit_datetime_gregorian","messages_last_edit_datetime_hijri","messages_last_edit_by"];
	fieldNames = []; // Array([name, var]) for fields with name != var
	listFields: string[] = ["messages_id","messages_source","messages_user_id","messages_title","messages_body","messages_link","messages_publish","messages_datetime","messages_seen","messages_loop","messages_repeat","messages_company_id","messages_branch_id","messages_last_edit_datetime_gregorian","messages_last_edit_datetime_hijri","messages_last_edit_by"];
	viewFields: string[] = ["messages_id","messages_source","messages_user_id","messages_title","messages_body","messages_link","messages_publish","messages_datetime","messages_seen","messages_loop","messages_repeat","messages_company_id","messages_branch_id","messages_last_edit_datetime_gregorian","messages_last_edit_datetime_hijri","messages_last_edit_by"];
	addFields: string[] = ["messages_id","messages_source","messages_user_id","messages_title","messages_body","messages_link","messages_publish","messages_datetime","messages_seen","messages_loop","messages_repeat","messages_company_id","messages_branch_id","messages_last_edit_datetime_gregorian","messages_last_edit_datetime_hijri","messages_last_edit_by"];
	editFields: string[] = ["messages_source","messages_user_id","messages_title","messages_body","messages_link","messages_publish","messages_datetime","messages_seen","messages_loop","messages_repeat","messages_company_id","messages_branch_id","messages_last_edit_datetime_gregorian","messages_last_edit_datetime_hijri","messages_last_edit_by"];
	lookupTables: any = {};
	displayValueSeparators: any = {};
	errorMessages: any;
	row: any; // Current row (rendered)
	labelAttribute: string = "name";

	// Constructor
	constructor(public dbapp: DbApp,
		public user: UserData,
		public translate: TranslateService,
		public locale: LocaleService,
		public router: Router,
			public http: HttpClient) {
		super(dbapp, http, user, translate, router);
		this.name = "messages";
		this.translate.get(this.fieldVars.map(fldvar => "__tables." + this.name + ".fields." + fldvar + ".errMsg")).subscribe(values => {
			for (let k in values)
				values[k] = (k != values[k]) ? values[k] : "";
			this.errorMessages = values;
		});
		this.translate.get(["__tables.messages.fields.messages_seen.tagValues"]).subscribe(values => {
			this.lookupTables.messages_seen = dbapp.convertUserValues(values["__tables.messages.fields.messages_seen.tagValues"]);
		});
		this.infiniteScroll = true;
		this.pageSize = 8;
	}

	// Lookup
	async lookup(item: any, pageId: string) {
		if (!item)
			return;
		pageId = pageId == "signup" ? "register" : pageId;
		let page = pageId != "register" ? this.name + "_" + pageId : pageId;
		this.pageId = pageId;
		let p = [];

		// Get lookup results
		try {
			[] = await Promise.all(p);
		} catch(err) {
			console.log(err);
		}
	}

	// Render field
	renderField(fieldName: string, pageId: string) {
		if (["list", "view", "add", "edit", "register"].includes(pageId))
			return this[pageId + "Fields"].includes(fieldName);
		return false;
	}

	// Get field variable name
	getFieldVar(name) {
		let f = this.fieldNames.find(f => f[0] == name);
		return f ? f[1] : name;
	}

	// Get field variable name
	getFieldName(varname) {
		let f = this.fieldNames.find(f => f[1] == varname);
		return f ? f[0] : varname;
	}

	// Render row
	async renderRow(item: any, pageId: string) {
		this.pageId = pageId;
		let row = new DbRecord(item, this.fieldNames, this.errorMessages);
		this.rowOnRender(row);

		// messages_title
		if (this.renderField("messages_title", pageId)) {
			if (row["messages_title"])
				row.title = row["messages_title"].value; // Title field
		}

		// messages_seen
		if (this.renderField("messages_seen", pageId)) {
			let selectedRow = this.lookupTables.messages_seen.find(r => r.lf == row["messages_seen"].dbValue); // Compare with db value
			row["messages_seen"].formValue = row["messages_seen"].dbValue; // FormControl value cannot be undefined
			row["messages_seen"].value = selectedRow ? this.dbapp.displayValue(selectedRow, this.displayValueSeparators.messages_seen) : row["messages_seen"].dbValue;
			row["messages_seen"].value = this.dbapp.getBool(row["messages_seen"].dbValue);
		}

		// messages_loop
		if (this.renderField("messages_loop", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["messages_loop"].value = formatNumber(row["messages_loop"].value, this.locale.locale);
		}

		// messages_repeat
		if (this.renderField("messages_repeat", pageId)) {
			if (!["list", "view"].includes(pageId))
				row["messages_repeat"].value = formatNumber(row["messages_repeat"].value, this.locale.locale);
		}
		row.rendered = true;
		this.rowAfterRendered(row);
		this.row = row; // Set current row
		return row;
	}

	// Render file URL
	async renderFileUrl(url: string) {
		return this.http.get(url, { responseType: "blob" }).toPromise().then(blob => {
			const reader = new FileReader();
			return new Promise((resolve, reject) => {
				reader.onloadend = () => resolve(reader.result as string);
				reader.readAsDataURL(blob);
			});
		});
	}
}