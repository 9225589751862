import { NgModule, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserData, Settings, LocaleService, DbApp, History } from './';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'propertyCategory'
})
export class PropertyCategoryPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(properties_property_category_id: string): string {
    const propertyCategoryId = parseInt(properties_property_category_id, 10);

    switch (propertyCategoryId) {
      case 0:
        return this.translate.instant("undefiend");
      case 1:
        return this.translate.instant("residential");
      case 2:
        return this.translate.instant("commercial");
      case 3:
        return this.translate.instant("residential_commercial");
      case 4:
        return this.translate.instant("industrial");
      case 5:
        return this.translate.instant("agricultural");
      case 6:
        return this.translate.instant("touristic");
      case 7:
        return this.translate.instant("administrative");
      case 8:
        return this.translate.instant("healthical");
    }
  }
}