import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule, Storage } from '@ionic/storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserData, Settings, LocaleService, DbApp, History } from './providers';
import { DbAppPipe } from './providers/dbapp.pipe.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { DbAppHttpInterceptor } from './dbapp.http.interceptor';
import { IonicSelectableModule } from 'ionic-selectable';
import { EmbeddedMediaModule } from '@hkvstore/ngx-embedded-media';
import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import {
	add_appointments,
	add_property_1,
	auctions_logs,
	cities,
	clientsrequests,
	countries,
	files_media_panos,
	files_media_photos,
	files_media_videos,
	messages,
	my_properties,
	nearby_services,
	nearby_services_types,
	neighborhoods,
	notifications,
	profiles,
	profiles_public,
	properties_schemas,
	properties_schemas_segments,
	regions,
	services,
	streets,
	user_level_permissions,
	user_levels,
	users,
	wishlists,
	zip_codes,
	properties_lists,
	properties_edits,
	properties_linked,
	news,
	offers_prices,
	tickets,
	earnests_logs,
	property_history,
	ratings,
	EmailService,
	SMSService,
	NafazService,
	RegaService,
	clients_views,
	invests_logs,
	rents_logs,
	payments,
	financial_supports,
	sell_orders,
	sell_orders_details,
	mediation_contracts,
	rooms, 
	properties_schemas_segments_edit, 
	properties_schemas_edit, 
	control_operations
  } from "./providers";
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import { NgxSimpleCountdownModule } from 'ngx-simple-countdown-ar';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { NgChartsModule, NgChartsConfiguration } from 'ng2-charts';
import { FCM } from '@ionic-native/fcm/ngx';
import { ShortNumberPipe } from './providers/short-number.pipe';
// import { ratingsListPage } from './pages/ratingslist/ratingslist';


export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Settings
export function provideSettings(storage: Storage, dbapp: DbApp) {
	return new Settings(storage, dbapp.settings);
}

// Module
@NgModule({
	declarations: [
		AppComponent,
		// ratingsListPage
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		NoopAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		IonicSelectableModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
		DbAppPipe,
		IonicModule.forRoot(),
		IonicStorageModule.forRoot(),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production
		}),
		EmbeddedMediaModule.forRoot(),
		AgmCoreModule.forRoot({
			apiKey: "AIzaSyDyxpnNHRWe5RiDB3dWJ_F6XqDgMVnd-dw",
			libraries: ["places","drawing","geometry"],
			channel: "3",
			language: "ar",
			region: 'SA',
		}),
		AgmJsMarkerClustererModule,
		NgxSimpleCountdownModule,
		NgxSliderModule,
		NgChartsModule,
		IonCustomScrollbarModule,
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: !isDevMode(),
    // Register the ServiceWorker as soon as the application is stable
    // or after 30 seconds (whichever comes first).
    registrationStrategy: 'registerWhenStable:30000'
  }),
	],
	providers: [
		DbApp,
		UserData,
		History,
		AndroidPermissions,
		add_appointments,
		add_property_1,
		auctions_logs,
		cities,
		clientsrequests,
		countries,
		files_media_panos,
		files_media_photos,
		files_media_videos,
		messages,
		my_properties,
		nearby_services,
		nearby_services_types,
		neighborhoods,
		notifications,
		profiles,
		profiles_public,
		properties_lists,
		properties_edits,
		properties_linked,
		properties_schemas,
		properties_schemas_segments,
		regions,
		services,
		streets,
		user_level_permissions,
		user_levels,
		users,
		wishlists,
		zip_codes,
		news,
		offers_prices,
		tickets,
		earnests_logs,
		property_history,
		ratings,
		clients_views,
		invests_logs,
		rents_logs,
		payments,
		financial_supports,
		sell_orders,
		sell_orders_details,
		mediation_contracts,
		rooms,
		properties_schemas_segments_edit, 
		properties_schemas_edit, 
		control_operations,
		{ provide: HTTP_INTERCEPTORS, useClass: DbAppHttpInterceptor, multi: true },
		{ provide: LOCALE_ID, deps: [LocaleService], useFactory: (localeService) => localeService.locale },
		{ provide: Settings, useFactory: provideSettings, deps: [Storage, DbApp] },
		{ provide: NgChartsConfiguration, useValue: { generateColors: false }},
		InAppBrowser,
		SplashScreen,
		StatusBar,
		Camera,
		File,
		WebView,
		FilePath,
		FileChooser,
		MobileAccessibility,
		StatusBar,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		CallNumber,
		SocialSharing,
		NgxSimpleCountdownModule,
		NgxSliderModule,
		Push,
		Geolocation,
		NativeGeocoder,
		FCM,
		EmailService,
		SMSService,
		NafazService,
		RegaService,
		ShortNumberPipe
	],
	bootstrap: [AppComponent]
})
export class AppModule {}