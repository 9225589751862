import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { satisfies } from 'semver';
import { environment } from '../../environments/environment';
import { DbApp } from './dbapp';

@Injectable()
export class SMSServiceSecondParty {

  constructor(
    public dbapp: DbApp,
    private http: HttpClient,
    public translate: TranslateService,
  ) {}

  async sendSMSMessageSecondParty(msgHeaderClientSecondParty: any): Promise<any> {
    const bodySecondParty = {
      sms_queue_recipient_second_party: msgHeaderClientSecondParty.sms_queue_recipient_second_party,
      sms_queue_content_second_party: msgHeaderClientSecondParty.sms_queue_content_second_party
    };

    return this.http.post(environment.apiUrl, bodySecondParty).toPromise().then(async (data: any) => {
      data = data || {};
      if (data.version && !satisfies(data.version, this.dbapp.requiredApiVersion)) {
        await this.translate.get("INCOMPATIBLE_API", { s: data.version }).toPromise();
      }
      
      if (data.success) {
        console.log('SMS Sent');
      }
      
      return data;
    });
  }
  
  // Add a new method to pass the message header from the client-side
  async sendMessageHeaderSecondParty(smsQueueRecipientSecondParty: string, smsQueueContentSecondParty: string): Promise<any> {
    const msgHeaderClientSecondParty = {
      sms_queue_recipient_second_party: smsQueueRecipientSecondParty,
      sms_queue_content_second_party: smsQueueContentSecondParty
    };
    
    return this.sendSMSMessageSecondParty(msgHeaderClientSecondParty);
  }
}