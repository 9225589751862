import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { satisfies } from 'semver';
import { environment } from '../../environments/environment';
import { DbApp } from './dbapp';

@Injectable()
export class RegaService {
  constructor(
    public dbapp: DbApp,
    private http: HttpClient,
    public translate: TranslateService,
    ) {}

    async sendRegaRequest(regaHeaderClient: any): Promise<any> {
    const body = {
      nationalId: regaHeaderClient.rega_queue_nationalid,
      service: regaHeaderClient.rega_queue_service,
      locale: regaHeaderClient.rega_queue_locale,
      requestId: regaHeaderClient.rega_queue_requestid,
    };

return this.http.post(environment.apiUrl, body, { responseType: 'json' }).toPromise().then(async (data: any) => {
  data = data || {};
  if (data.version && !satisfies(data.version, this.dbapp.requiredApiVersion)) {
    await this.translate.get("INCOMPATIBLE_API", { s: data.version }).toPromise();
  }
  
  if (data.success) {
    console.log('data', data);
  }
  return data;
});
}
  async sendRegaHeader(regaQueueNationalId: string, regaQueueService: string, regaQueueLocale: string, regaQueueRequestId: string): Promise<any> {
    const regaHeaderClient = {
      rega_queue_nationalid: regaQueueNationalId,
      rega_queue_service: regaQueueService,
      rega_queue_locale: regaQueueLocale,
      rega_queue_requestid: regaQueueRequestId
    };
    
    return this.sendRegaRequest(regaHeaderClient);
  }

}