import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Pipe({ name: 'countdown', pure: false })
export class CountdownPipe implements PipeTransform {
  private countdownSubject = new BehaviorSubject<string>('');

  constructor(public translate: TranslateService) {}

  transform(endTime: string, closeMessage: string, closedMessage: string): Observable<string> {
    const endDate = new Date(endTime).getTime();
    const today = new Date().setHours(0, 0, 0, 0);

    interval(1000)
      .pipe(
        map(() => {
          const currentTime = new Date().getTime();
          const remainingTime = endDate - currentTime;

          if (remainingTime <= 0) {
            return closeMessage;
          }

          if (endDate < today) {
            return closedMessage;
          }

          const days = Math.floor(remainingTime / 86400000);
          const hours = Math.floor((remainingTime % 86400000) / 3600000);
          const minutes = Math.floor((remainingTime % 3600000) / 60000);
          const seconds = Math.floor((remainingTime % 60000) / 1000);

          let countdownString = '';
          if (days > 0) {
            countdownString += `${this.translate.instant('days')} `;
          }
          countdownString += `${this.padTime(hours)}:${this.padTime(minutes)}:${this.padTime(seconds)}`;

          return countdownString;
        }),
        take(1)
      )
      .subscribe((countdown) => {
        this.countdownSubject.next(countdown);
      });

    return this.countdownSubject.asObservable();
  }

  private padTime(time: number): string {
    return time.toString().padStart(2, '0');
  }
}
